import React, { useContext, useEffect, useRef, useState } from "react";
import "react-month-picker-input/dist/react-month-picker-input.css";
import { monthDates } from "../../common/util";
import SmallSpinner from "../utility/small_spinner";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { API_URL, rGET } from "../../actions/api";
import { numberFormat } from "../utility/currency_format";
import { FormattedMessage } from "react-intl";
import { LocaleContext, PropertyIDContext } from "../../global_contexts";
import { DatePartLocalised } from "../utility/date_part_localised";
import { getCalendarRange } from "../calendar/calendar_service";
import { MonthYearDropdownULEl } from "../calendar/month_year_dropdown";
import { MATERIALIZE_REF } from "../dynamic_onboarding/contexts";

const PerformanceC = () => {
  const { locale } = useContext(LocaleContext);
  const M = useContext(MATERIALIZE_REF);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { propertyID } = useContext(PropertyIDContext);
  const [moreInfo, setMoreInfo] = useState([false, false, false]);
  const [isMobileExpanded, setIsMobileExpanded] = useState(false);
  const [currency, setCurrency] = useState(localStorage.getItem("currency"));
  const start_date = monthDates()["date_start"];
  const end_date = monthDates()["date_end"];
  const [performanceDates, setPerformanceDates] = useState(
    JSON.stringify({ start_date, end_date })
  );
  const monthYearDropdownRef = useRef(null);
  const [listMonths, setListMonths] = useState([]);

  useEffect(() => {
    removeForecastGraph(propertyID);
    addforecastGraph(propertyID);
  }, [propertyID, locale]);

  useEffect(() => {
    getMonthList();
    M.Dropdown.init(monthYearDropdownRef.current);
  }, [propertyID]);

  useEffect(() => {
    updatePerformanceDates();
  }, [selectedDate]);

  useEffect(() => {
    const activeDates = JSON.parse(performanceDates);
    occupancyInfo(propertyID, activeDates.start_date, activeDates.end_date);
    pullNetRevenue(propertyID, activeDates.start_date, activeDates.end_date);
    averageReviews(propertyID, activeDates.start_date, activeDates.end_date);
  }, [propertyID, performanceDates]);

  useEffect(() => {
    if (currency !== localStorage.getItem("currency")) {
      setCurrency(localStorage.getItem("currency"));
    }
  }, [localStorage.getItem("currency")]);

  const updatePerformanceDates = () => {
    const start_date = monthDates({
      y: new Date(selectedDate).getFullYear(),
      m: new Date(selectedDate).getMonth(),
    }).date_start;
    const end_date = monthDates({
      y: new Date(selectedDate).getFullYear(),
      m: new Date(selectedDate).getMonth(),
    }).date_end;
    setPerformanceDates(JSON.stringify({ start_date, end_date }));
  };

  // Modernised from Redux
  const [occupancy, setOccupancy] = useState();
  const [netRevenue, setNetRevenue] = useState();
  const [averageReview, setAverageReview] = useState();

  //occupancy
  function occupancyInfo(property_id, start_date, end_date) {
    rGET(`/occupancy/${property_id}/${start_date}/to/${end_date}/`).then((resp) => {
      const occupancy = resp && resp.data.results[0];
      setOccupancy(occupancy);
    });
  }

  //net revenue
  function pullNetRevenue(property_id, start_date, end_date) {
    rGET(`/performance/${property_id}/net_revenue/${start_date}/to/${end_date}/`).then(
      (resp) => {
        const net_revenue = resp && resp.data.results[0];
        setNetRevenue(net_revenue);
      }
    );
  }

  //average review
  function averageReviews(property_id, start_date, end_date) {
    rGET(
      `/performance/${property_id}/average_review_rating/${start_date}/to/${end_date}/`
    ).then((resp) => {
      const average_reviews = resp && resp.data.results[0];
      setAverageReview(average_reviews);
    });
  }

  function removeForecastGraph(propertyID) {
    if (propertyID) {
      let charts = document.querySelectorAll("[data-chart]");
      if (charts) {
        charts.forEach(function (el) {
          el.remove();
        });
      }
      let scripts = document
        .getElementById("forecast-graph")
        .querySelectorAll("script");
      if (scripts) {
        scripts.forEach(function (el) {
          el.remove();
        });
      }
      let divs = document.getElementById("forecast-graph").querySelectorAll("div");
      if (divs) {
        divs.forEach(function (el) {
          el.remove();
        });
      }
    }
  }

  function addforecastGraph(propertyID) {
    if (propertyID) {
      const script = document.createElement("script");
      const BASE_URL = API_URL.replace("/api/v3", "");
      script.src = `${BASE_URL}/static/forecast/js/chart.js`;
      script.dataset.chart = "bar";
      script.dataset.target = "portal";
      script.dataset.source = "property";
      script.dataset.property = propertyID;
      script.dataset.comparison = "net_revenue";
      script.dataset.timeline = "split";
      script.dataset.languageCode = locale;
      script.async = true;
      if (document.getElementById("forecast-graph")) {
        document.getElementById("forecast-graph").appendChild(script);
      }
    }
  }

  function clickInfo(id) {
    let moreInfoAux = [];
    switch (id) {
      case 0:
        moreInfoAux = [true, false, false];
        break;
      case 1:
        moreInfoAux = [false, true, false];
        break;
      case 2:
        moreInfoAux = [false, false, true];
        break;
      default:
        moreInfoAux = [false, false, false];
        break;
    }
    setIsMobileExpanded(true);
    setMoreInfo(moreInfoAux);
  }

  function hideInfo() {
    setIsMobileExpanded(false);
    setMoreInfo([false, false, false]);
  }

  async function getMonthList() {
    let list = await getCalendarRange(propertyID);
    list = list.reverse();
    setListMonths(list);
  }

  return (
    <div className="performance">
      <MonthYearDropdownULEl
        id="dropdownMonths"
        classes="dropdown-content"
        listMonths={listMonths}
        updateOnClick={setSelectedDate}
      />
      <div className="row">
        <div className="col s12 m4">
          <h4>
            <FormattedMessage id="performance.title" defaultMessage="Performance" />
          </h4>
        </div>
        <div id="month_picker" className="col s12 m8">
          <div
            className="form-container"
            data-target="dropdownMonths"
            ref={monthYearDropdownRef}>
            <span className="p-calendar">
              <Icon
                icon={ICONS.CALENDAR.d}
                width={21}
                height={22}
                viewBox="0 0 29 30"
              />
            </span>
            <label className="label-container">
              <span className="text-label">
                <FormattedMessage
                  id="performance.select_month"
                  defaultMessage="Select Month"
                />
              </span>
            </label>
            <button className="month_year_dropdown">
              <DatePartLocalised
                date={selectedDate}
                options={{ month: "long", year: "numeric" }}
              />
            </button>
            <span className="dropdown">
              <Icon
                icon={ICONS.DOWN_ARROW.d}
                width={ICONS.DOWN_ARROW.width}
                height={ICONS.DOWN_ARROW.height}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="statics">
        <div
          className={`${
            isMobileExpanded ? (moreInfo[0] ? "fullWidth" : "hide-block") : ""
          } elements occupancy`}>
          <div className={`${isMobileExpanded ? "left-mobile " : ""}left-elements`}>
            <div className="title">
              <FormattedMessage id="performance.occupancy" defaultMessage="Occupancy" />
            </div>
            {occupancy ? (
              <div className="value ">
                {occupancy !== null ? `${Math.round(occupancy.occupancy_rate)}%` : "-"}
              </div>
            ) : (
              <div className="value">
                <SmallSpinner />
              </div>
            )}
            {isMobileExpanded ? (
              <button
                className="btn info-btn hide-on-med-and-up"
                onClick={() => hideInfo()}>
                <FormattedMessage
                  id="performance.hide_info"
                  defaultMessage="Hide Info"
                />
              </button>
            ) : (
              <button
                className="btn info-btn hide-on-med-and-up"
                onClick={() => clickInfo(0)}>
                <FormattedMessage
                  id="performance.more_info"
                  defaultMessage="More Info"
                />
              </button>
            )}
          </div>
          <div
            className={`${isMobileExpanded ? "" : "hide-on-small-only"} ${
              isMobileExpanded ? "right-mobile " : ""
            }right-elements`}>
            <p id="occupancy">
              <FormattedMessage
                id="performance.occupancy_text"
                defaultMessage="Occupancy is the proportion of your available days that has paying customers. Our target rate varies by season."
              />
            </p>
          </div>
        </div>
        <div
          className={`${
            isMobileExpanded ? (moreInfo[1] ? "fullWidth" : "hide-block") : ""
          } elements net-revenue`}>
          <div className={`${isMobileExpanded ? "left-mobile " : ""}left-elements`}>
            <div className="title">
              <FormattedMessage
                id="performance.net_income"
                defaultMessage="Net Income"
              />
            </div>
            {netRevenue ? (
              <div className="net value">
                {netRevenue.net_revenue > 0
                  ? numberFormat(locale, netRevenue.net_revenue, currency, 1, "compact")
                  : "-"}
              </div>
            ) : (
              <div className="value ">
                <SmallSpinner />
              </div>
            )}
            {isMobileExpanded ? (
              <button
                className="btn info-btn hide-on-med-and-up"
                onClick={() => hideInfo()}>
                <FormattedMessage
                  id="performance.hide_info"
                  defaultMessage="Hide Info"
                />
              </button>
            ) : (
              <button
                className="btn info-btn hide-on-med-and-up"
                onClick={() => clickInfo(1)}>
                <FormattedMessage
                  id="performance.more_info"
                  defaultMessage="More Info"
                />
              </button>
            )}
          </div>
          <div
            className={`${isMobileExpanded ? "" : "hide-on-small-only"} ${
              isMobileExpanded ? "right-mobile " : ""
            }right-elements`}>
            <p id="average_day_rate">
              <FormattedMessage
                id="performance.average_day_rate_text"
                defaultMessage="Your income after all expenses (such as platform fee, PTK fee, cleaning costs) have been paid."
              />
            </p>
          </div>
        </div>
        <div
          className={`${
            isMobileExpanded ? (moreInfo[2] ? "fullWidth" : "hide-block") : ""
          } elements review`}>
          <div className={`${isMobileExpanded ? "left-mobile " : ""}left-elements`}>
            <div className="title">
              <FormattedMessage id="performance.reviews" defaultMessage="Reviews" />
            </div>
            {averageReview ? (
              <div
                className={`${
                  isMobileExpanded && moreInfo[2] ? "review" : ""
                } col s6 m12 value squash`}>
                <div>
                  <strong className="mr-10">
                    {averageReview.average_review_rating > 0
                      ? averageReview.average_review_rating.toFixed(1)
                      : "-"}
                  </strong>
                  {averageReview.average_review_rating > 0 && (
                    <span>
                      <Icon
                        icon={ICONS.STAR_SMALL.d}
                        width={ICONS.STAR_SMALL.width}
                        height={ICONS.STAR_SMALL.height}
                        color="#ec9a3a"
                        viewBox={ICONS.STAR_SMALL.viewBox}
                      />
                    </span>
                  )}
                </div>
              </div>
            ) : (
              <div className="col s6 m12 value squash ">
                <SmallSpinner />
              </div>
            )}
            {isMobileExpanded ? (
              <button
                className="btn info-btn hide-on-med-and-up"
                onClick={() => hideInfo()}>
                <FormattedMessage
                  id="performance.hide_info"
                  defaultMessage="Hide Info"
                />
              </button>
            ) : (
              <button
                className="btn info-btn hide-on-med-and-up"
                onClick={() => clickInfo(2)}>
                <FormattedMessage
                  id="performance.more_info"
                  defaultMessage="More Info"
                />
              </button>
            )}
          </div>
          <div
            className={`${isMobileExpanded ? "" : "hide-on-small-only"} ${
              isMobileExpanded ? "right-mobile " : ""
            }right-elements`}>
            <p id="reviews">
              <FormattedMessage
                id="performance.reviews_text"
                defaultMessage="The average review for your property based on aggregated data from platforms such as Airbnb and Booking.com"
              />
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div id="forecast-graph" className="col s12 mt-0 shadow" />
      </div>
    </div>
  );
};

export default PerformanceC;
