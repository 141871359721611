import React, { useRef } from "react";
import PropTypes from "prop-types";

const Icon: any = (props: any) => {
  // Use colour set in props or default to ptk blue
  const iconColour = useRef(props.color || "#071db5");
  const styles: any = {
    svg: {
      display: "inline-block",
      verticalAlign: "middle",
      position: props.position ? props.position : "auto",
      top: 0,
      left: 0,
    },
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={styles.svg}
      width={`${props.width}px`}
      height={`${props.height}px`}
      viewBox={
        props.viewBox ? `${props.viewBox}` : `0 0 ${props.width} ${props.height}`
      }>
      {props.multiplePaths ? (
        <g>
          {props.multiplePaths.map((path: any, i: number) =>
            iconColour.current === "#FFF" ? (
              <path
                key={i}
                style={styles.path}
                d={path.d}
                fillRule="nonzero"
                stroke="#071db5"
                fill="none"
              />
            ) : (
              <path key={i} style={styles.path} d={path.d} fill={path.color} />
            )
          )}
        </g>
      ) : iconColour.current === "#FFF" ? (
        <path
          style={styles.path}
          d={props.icon}
          fillRule="nonzero"
          stroke="#071db5"
          fill="none"
        />
      ) : (
        <path style={styles.path} d={props.icon} fill={iconColour.current} />
      )}
    </svg>
  );
};

Icon.propTypes = {
  icon: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  multiplePaths: PropTypes.array,
};

export default Icon;
