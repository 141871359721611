import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";

class Patnership extends Component {
  componentDidMount() {
    document.querySelector(".main-container .container").classList.add("full-width");
  }

  render() {
    return (
      <div className="great-host-guide-pages">
        <div className="row">
          <div className="col s12">
            <NavLink exact to="/great-host-guide/" className="left-align">
              <span className="mr-10">
                <Icon
                  icon={ICONS.BACK_ARROW.d}
                  width={ICONS.BACK_ARROW.width}
                  height={12}
                  viewBox="0 0 10 20"
                />
              </span>
              <span className="cobalt-blue">Back to Guide summary</span>
            </NavLink>
            <h4 className="center-align">A True Partnership - We’re in it together</h4>
          </div>
        </div>
        <div className="row top-img-container partnership">
          <img
            className="top-img"
            alt="A True Partnership"
            src={require(`../../styles/img/partnership@2x-min.jpg`)}
          />
        </div>
        <div className="row">
          <div className="col s12">
            <h5>Why A Partnership?</h5>
            <p>
              Simple! We think you should have a voice and know how your property is
              doing. We want to build a strong relationship with you, and the best way
              to make this happen is to be totally transparent.
            </p>

            <h5>Our Local Management Teams</h5>
            <p>
              Our Franchise partnership model allows the local teams to work with you
              across every element of your properties' success from a pricing strategy
              to match the local market. Using local suppliers for property management
              tasks, and liaising with you directly on your individual financial goals
              and how we can work together to achieve them
            </p>

            <h5>We Have You In Mind!</h5>
            <p>
              Whether you envisage our relationship as an investment strategy or as a
              way to make a little extra while your home is empty, we’ll always put your
              goals and desires at the centre of everything we do.
              <br />
              When things go wrong, we’ll always make sure the resolution works for both
              you and your guests.
            </p>

            <h5>The Journey Is Nearly Complete...</h5>
            <p>
              We’re proactive - that’s why we’ve asked a lot of questions. We want to
              make sure we both have the right expectations and goals for our
              partnership.
              <br />
              We love feedback, so please let us know if you have any comments or ideas.
              You can share feedback quick and easily via the Client portal, simply
              click on the{" "}
              <NavLink exact to="/contact-us/" className="left-align">
                <span className="cobalt-blue">Contact Us</span>
              </NavLink>{" "}
              tab and let us know how we are doing and why!
            </p>
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    document.querySelector(".main-container .container").classList.remove("full-width");
  }
}

export default Patnership;
