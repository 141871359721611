import "../../../../globals.js";
import React, { useState } from "react";
import { TextField } from "../../schema";
import Trumbowyg from "react-trumbowyg";
import "react-trumbowyg/dist/trumbowyg.min.css";

export const InputRichTextComponent = (props: {
  onValueChange: any;
  field: TextField;
}) => {
  const [value, setValue] = useState(props.field.value || "");

  const onHTMLChange = (e: any) => {
    // Due to Trumbowyg, we can't update the local value since it causes the 'typing cursor' to constantly reset to the left.
    props.onValueChange(e.currentTarget, e.currentTarget.innerHTML);
  };

  return (
    <>
      <input
        id={`${props.field.key}#rich_text`}
        hidden
        required={props.field.required}
      />
      <Trumbowyg
        id={props.field.key}
        data={value}
        autogrow={true}
        removeformatPasted={true}
        onBlur={(e: any) => {
          onHTMLChange(e);
        }}
        buttons={[
          ["undo", "redo"],
          ["strong", "em"],
        ]}
        required={props.field.required}
      />
    </>
  );
};
