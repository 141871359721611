import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import Settings from "./settings";
import { Contacts } from "./contacts";
import { FormattedMessage } from "react-intl";
import { PropertyIDContext } from "../../global_contexts";
import { rGET, rPUT } from "../../actions/api";

const defaultSettings = {
  property: "",
  involvement: "",
  replace_vacuum: "",
  replace_kettle: "",
  replace_toaster: "",
  replace_iron: "",
  replace_basic_items: "",
  replace_hairdryer: "",
  replace_duvet: "",
  replace_kitchen_utensils: "",
};

const Preferences = () => {
  const { propertyID } = useContext(PropertyIDContext);
  const submitFooter = useRef(null);
  const [initialSettings, setInitialSettings] = useState(defaultSettings);
  const [submitSettings, setSubmitSettings] = useState(defaultSettings);

  useEffect(() => {
    getPreferencesData();
  }, [propertyID]);

  function getPreferencesData() {
    const getPreferences = rGET(`/propertypreferences/${propertyID}/`);
    if (getPreferences) {
      getPreferences.then((resp) => {
        const load_preferences = resp && resp.data.results[0];
        if (load_preferences && load_preferences.involvement === "Hassle Free") {
          if (document.getElementById("hassle_free")) {
            document.getElementById("hassle_free").checked = true;
          }
        } else {
          if (document.getElementById("get_involved")) {
            document.getElementById("get_involved").checked = true;
          }
        }
        setInitialSettings(load_preferences);
        setSubmitSettings(load_preferences);
      });
    } else {
      // likely testing, so we set some default values
      const testingSettingsValues = {
        property: null,
        involvement: "Hassle Free",
        replace_vacuum: false,
        replace_kettle: false,
        replace_toaster: false,
        replace_iron: false,
        replace_basic_items: false,
        replace_hairdryer: false,
        replace_duvet: false,
        replace_kitchen_utensils: false,
      };
      setInitialSettings(testingSettingsValues);
      setSubmitSettings(testingSettingsValues);
    }
  }

  function updatePreferencesData(data) {
    rPUT(`/propertypreferences/${propertyID}/`, data).then((resp) => {
      const load_preferences = resp && resp.data;
      return load_preferences;
    });
  }

  function formChanged() {
    const footer = submitFooter.current;
    if (footer) footer.classList.remove("hide");
    involvedChoice();
  }

  function involvedChoice() {
    let val_selected = document.querySelector('input[name="involvement"]:checked');
    const elID = val_selected.id.replace("_", "");
    const el = document.getElementById(elID);
    el.classList.add("selected-opt");
    const icon = document.querySelector(`#${elID}>span`);
    icon.classList.remove("hide");
    if (elID === "hasslefree") {
      document.querySelector("#get_involved").value = false;
      const unselected_el = document.getElementById("getinvolved");
      unselected_el.classList.remove("selected-opt");
      const icon = document.querySelector("#getinvolved>span");
      icon.classList.add("hide");
    } else {
      document.querySelector("#hassle_free").value = false;
      const unselected_el = document.getElementById("hasslefree");
      unselected_el.classList.remove("selected-opt");
      const icon = document.querySelector("#hasslefree>span");
      icon.classList.add("hide");
    }
  }

  function hideFooter() {
    if (submitSettings.involvement === "Hassle Free") {
      document.getElementById("hassle_free").checked = true;
      document.getElementById("get_involved").checked = false;
    } else {
      document.getElementById("get_involved").checked = true;
      document.getElementById("hassle_free").checked = false;
    }
    involvedChoice();
    const footer = submitFooter.current;
    if (footer) footer.classList.add("hide");
  }

  function settingsFormSubmit() {
    updatePreferencesData(submitSettings);
    hideFooter();
  }

  function updateField(fieldToUpdate, newFieldValue) {
    setSubmitSettings({ ...submitSettings, [fieldToUpdate]: newFieldValue });
  }

  function resetSubmitSettings() {
    submitSettings.involvement = initialSettings.involvement; // For some reason the line below isn't successfully updating the involvement value - no idea why - hence we need to achieve this using this alternate method
    setSubmitSettings(initialSettings);
    hideFooter();
  }

  return (
    <div className="row preferences">
      <div className="col s12">
        <div className="row">
          <div className="col s12">
            <h4>
              <FormattedMessage id="preferences.title" defaultMessage="Preferences" />
            </h4>
          </div>
          <div className="col s12 mt-10">
            <div>
              <div id="contacts" className="col s12 form-container z-depth-1">
                <Contacts />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col s12 mt-25">
            <div>
              <form
                id="settings"
                className="col s12 form-container z-depth-1"
                onChange={() => formChanged()}
                onSubmit={settingsFormSubmit}>
                <Settings fields={submitSettings} updateField={updateField} />
                <div className="pb-20">
                  <p>
                    <FormattedMessage
                      id="preferences.callout_fee_part_one"
                      defaultMessage="Please note we charge call-out fees on top of the cost. Please read"
                    />{" "}
                    <NavLink exact to="/maintenance/">
                      <FormattedMessage
                        id="preferences.callout_fee_part_two"
                        defaultMessage="here"
                      />
                    </NavLink>{" "}
                    <FormattedMessage
                      id="preferences.callout_fee_part_three"
                      defaultMessage="for more info."
                    />
                  </p>
                </div>
                <footer
                  ref={submitFooter}
                  id="submit-footer"
                  className="preferencesFooter hide">
                  <div>
                    <button
                      id="clearBtn"
                      className="btn"
                      type="button"
                      onClick={resetSubmitSettings}>
                      <FormattedMessage id="preferences.clear" defaultMessage="Clear" />
                    </button>
                    <button id="settingsBtn" className="btn" action="submit">
                      <FormattedMessage id="preferences.save" defaultMessage="Save" />
                    </button>
                  </div>
                </footer>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preferences;
