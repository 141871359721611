import {
  GENERATE_REDIRECT,
  GENERATE_REDIRECT_ERROR,
  CURRENT_STATUS,
} from "../actions/types";

export default function directDebitReducer(state = {}, action) {
  switch (action.type) {
    case GENERATE_REDIRECT:
      return { ...state, generate_redirect: action.payload };
    case GENERATE_REDIRECT_ERROR:
      return { ...state, generate_redirect_error: action.payload };
    case CURRENT_STATUS:
      return { ...state, current_status: action.payload };
    default:
      return state;
  }
}
