import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import * as actions from "../../actions";
import { isMobile } from "../../common/util";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";

const M = window.M;

class DetailPhotos extends Component {
  constructor(props) {
    super(props);
    this.prevPhoto = this.prevPhoto.bind(this);
    this.nextPhoto = this.nextPhoto.bind(this);
    this.scrollCarousel = this.scrollCarousel.bind(this);
    this.elem = null;
    this.photoWidth = isMobile() ? 750 : 1300;
    this.instanceCar = null;
    this.state = {
      containerWidth: 0,
      photoSelected: undefined,
      photos: [],
    };
  }

  UNSAFE_componentWillMount() {
    if (localStorage.getItem("detailPhotos")) {
      this.setState(() => ({
        photos: JSON.parse(localStorage.getItem("detailPhotos")),
      }));
    } else {
      this.setState(() => ({
        photos: JSON.parse(localStorage.getItem("listPhotos")),
      }));
    }
    let detailPhotos = JSON.parse(localStorage.getItem("detailPhotos"));
    this.setState(() => ({ photos: detailPhotos }));
    if (!detailPhotos || !detailPhotos[0]) {
      this.props.history.push("/photos/");
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.property !== this.props.property) {
      const property_id = localStorage.getItem("property_id");
      this.props.listPhotos(
        property_id,
        this.props.match.params.filter,
        this.props.intl
      );
      localStorage.removeItem("detailPhotos");
      this.props.history.push("/photos/");
    }
  }

  initialiseCarousel(photos, onChangeProperty = false) {
    if (photos && photos[0]) {
      this.elem = document.querySelector(".carousel");
      M.Carousel.init(this.elem, {
        fullWidth: true,
        onCycleTo: () => {
          let activeNum = document
            .querySelector(".carousel-item.active")
            .getAttribute("number");
          let listElImg = [...document.querySelectorAll("[number]")];
          if (!onChangeProperty) {
            listElImg.forEach(function (el) {
              if (el.getAttribute("number") === activeNum) {
                el.classList.add("active");
                document
                  .querySelector(`.carousel-item-small[number="${activeNum}"]`)
                  .scrollIntoView();
              } else {
                el.classList.remove("active");
              }
            });
          }
        },
      });
      this.instanceCar = M.Carousel.getInstance(this.elem);
      this.setState(() => ({
        containerWidth: document.getElementsByClassName("container")[1].offsetWidth,
      }));
    }
  }

  componentDidMount() {
    var elemModal = document.querySelector("#modalPhoto.modal");
    M.Modal.init(elemModal);
    this.initialiseCarousel(this.state.photos);
  }

  nextPhoto() {
    this.instanceCar.next();
  }

  prevPhoto() {
    this.instanceCar.prev();
  }

  scrollCarousel(number) {
    this.instanceCar.set(number);
  }

  openModal(photoLink) {
    var elemModal = document.querySelector("#modalPhoto.modal");
    var instance = M.Modal.getInstance(elemModal);
    this.setState((prevState, props) => ({ photoSelected: photoLink }));
    instance.open();
  }

  render() {
    return (
      <div className="row photos">
        {/* Modal Full Screen */}
        <div id="modalPhoto" className="modal">
          <img
            className="loading full-screen-img"
            src={this.state.photoSelected}
            onError={(e) =>
              (e.target.src = this.state.photoSelected.replace(/\?.+/g, ""))
            }
            alt=""
          />
        </div>
        {/* ******** */}
        <div className="col s12">
          <div className="row title-row">
            <div className="back col s12 m4">
              <NavLink exact to="/photos/" className="left-align">
                <span className="mr-10">
                  <Icon
                    icon={ICONS.BACK_ARROW.d}
                    width={ICONS.BACK_ARROW.width}
                    height={12}
                    viewBox="0 0 10 20"
                  />
                </span>
                <span className="cobalt-blue">
                  <FormattedMessage
                    id="photos.back_to_all"
                    defaultMessage="Back to all photos"
                  />
                </span>
              </NavLink>
            </div>
            <div className="title col s12 m4">
              <h5>{this.props.match.params.filter.split("-").join("/")}</h5>
            </div>
          </div>
          <div className="row">
            {this.state.photos && this.state.photos[0] && (
              <div className="col s12">
                <div
                  className="carousel carousel-slider center"
                  width={this.state.containerWidth}>
                  {this.state.photos.map((photo, id) => {
                    return (
                      <div key={photo.id} className="carousel-item" number={id + 1}>
                        <div
                          onClick={() =>
                            this.openModal(
                              photo.link.replace(/\?.+/, `?width=${this.photoWidth}`)
                            )
                          }>
                          <img
                            className="loading"
                            src={photo.link.replace(
                              /\?.+/,
                              `?width=${this.photoWidth}`
                            )}
                            onError={(e) =>
                              (e.target.src = photo.link.replace(/\?.+/g, ""))
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    );
                  })}
                  <div className="arrows-container hide-on-med-and-down">
                    <div className="fix-display">
                      <span onClick={() => this.prevPhoto()}>
                        <Icon
                          icon={ICONS.LEFT_ARROW.d}
                          width={ICONS.LEFT_ARROW.width}
                          height={ICONS.LEFT_ARROW.height}
                          color="#ffffff"
                        />
                      </span>
                      <span onClick={() => this.nextPhoto()}>
                        <Icon
                          icon={ICONS.RIGHT_ARROW.d}
                          width={ICONS.RIGHT_ARROW.width}
                          height={ICONS.RIGHT_ARROW.height}
                          color="#ffffff"
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="small-carousel mt-10">
                  {this.state.photos.map((photo, id) => {
                    return (
                      <div
                        key={id}
                        className="carousel-item-small"
                        height={100}
                        width={200}
                        number={id + 1}
                        onClick={() => this.scrollCarousel(id)}>
                        <img
                          className="loading"
                          src={photo.link}
                          onError={(e) =>
                            (e.target.src = photo.link.replace(/\?.+/g, ""))
                          }
                          alt=""
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    property: state.property.property_info,
    list_photos: state.photos.list_photos,
    original_filter: state.photos.original_filter,
    recent_filter: state.photos.recent_filter,
  };
}

export default withRouter(connect(mapStateToProps, actions)(injectIntl(DetailPhotos)));
