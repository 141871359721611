import React, { useContext, useEffect, useState } from "react";
import { validate } from "./contacts";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { MATERIALIZE_REF } from "../dynamic_onboarding/contexts";

export const EditContact = (props) => {
  const M = useContext(MATERIALIZE_REF);
  const [errors, setErrors] = useState({});
  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    if (props.selectedContact) {
      setValue("contact_type", props.selectedContact.contact_type);
      setValue("name", props.selectedContact.name);
      setValue("telephone", props.selectedContact.telephone);
      setValue("email", props.selectedContact.email);
      setValue("id", props.selectedContact.id);
      setValue("property", props.selectedContact.property);
      M.FormSelect.init(document.getElementById("edit_contact_selector"));
    }
  }, [props.selectedContact]);

  function editContactFormSubmit({
    contact_type,
    name,
    telephone,
    email,
    id,
    property,
  }) {
    const contact = { contact_type, name, telephone, email, id, property };
    let tempErrors = validate({ contact_type, name, telephone });
    setErrors(tempErrors);
    if (Object.getOwnPropertyNames(tempErrors).length === 0) {
      props.updateContact(contact);
      let instance = M.Modal.getInstance(document.querySelector("#modalEdit"));
      instance.close();
    }
  }

  return (
    <div id="editContact">
      <form
        id="edit_contact"
        className="contact-container"
        onSubmit={handleSubmit(editContactFormSubmit.bind(this))}>
        <div className="input-field">
          <select
            data-testid="edit_contact_select"
            id="edit_contact_selector"
            {...register("contact_type")}>
            <FormattedMessage
              id="contact.contact_type.Primary Contact"
              defaultMessage="Primary Contact">
              {(localised_text) => (
                <option value="Primary Contact">{localised_text}</option>
              )}
            </FormattedMessage>
            <FormattedMessage
              id="contact.contact_type.Emergency"
              defaultMessage="Emergency">
              {(localised_text) => <option value="Emergency">{localised_text}</option>}
            </FormattedMessage>
            <FormattedMessage
              id="contact.contact_type.Warrant Provider"
              defaultMessage="Warrant Provider">
              {(localised_text) => (
                <option value="Warrant Provider">{localised_text}</option>
              )}
            </FormattedMessage>
            <FormattedMessage
              id="contact.contact_type.Contractor"
              defaultMessage="Contractor">
              {(localised_text) => <option value="Contractor">{localised_text}</option>}
            </FormattedMessage>
          </select>
          <div className="error">{errors.contact_type}</div>
        </div>

        <div className="input-field">
          <FormattedMessage id="preferences.contacts.name" defaultMessage="Name">
            {(placeholder_text) => (
              <input
                data-testid="edit_contact_name"
                id="name"
                {...register("name")}
                placeholder={placeholder_text}
                type="text"
              />
            )}
          </FormattedMessage>
          <div className="error">{errors.name}</div>
        </div>

        <div className="input-field">
          <FormattedMessage
            id="preferences.contacts.telephone"
            defaultMessage="Telephone">
            {(placeholder_text) => (
              <input
                data-testid="edit_contact_telephone"
                id="telephone"
                {...register("telephone")}
                placeholder={placeholder_text}
                type="text"
              />
            )}
          </FormattedMessage>
          <div className="error">{errors.telephone}</div>
        </div>

        <div className="input-field">
          <FormattedMessage id="preferences.contacts.email" defaultMessage="Email">
            {(placeholder_text) => (
              <input
                data-testid="edit_contact_email"
                id="email"
                {...register("email")}
                placeholder={placeholder_text}
                type="email"
              />
            )}
          </FormattedMessage>
        </div>

        <input hidden {...register("id")} />
        <input hidden {...register("property")} />

        <button
          data-testid={"edit_contact_submit"}
          id="editBtn"
          className="btn"
          action="submit">
          <FormattedMessage
            id="preferences.contacts.edit_contact.edit"
            defaultMessage="Edit"
          />
        </button>
      </form>
    </div>
  );
};
