import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Icon from "../svg/svg_sprite";
import { ICONS } from "../svg/icons";

export function SectionColourKey() {
  const [show, setShow] = useState(true);

  // Check if the user has previously toggled the section colour key in this user session
  useEffect(() => {
    const temp_local = localStorage.getItem("show_section_colour_key");
    if (temp_local) {
      setShow(JSON.parse(temp_local));
    }
  }, []);

  useEffect(() => {
    if (show !== undefined) {
      localStorage.setItem("show_section_colour_key", show.toString());
    }
  }, [show]);

  return (
    <div
      id="section_colour_key"
      className={"key-container onboarding_checklist__section"}>
      <div
        id="section_colour_key_wrapper"
        className={
          show
            ? "onboarding_checklist__group_wrapper onboarding_checklist__group_wrapper--active"
            : "onboarding_checklist__group_wrapper"
        }>
        <div className="dropdown" onClick={() => setShow(!show)}>
          <span className="dropdown--title">
            <FormattedMessage
              id="onboarding_checklist.section_colour_key.title"
              defaultMessage="Onboarding Section Status Colour Key"
            />
          </span>
          <Icon
            icon={ICONS.CHEVRON.d}
            width={ICONS.CHEVRON.width}
            height={ICONS.CHEVRON.height}
          />
        </div>
        <div className="key-container__colour-key">
          {show && (
            <>
              <div className="mobile-left">
                <div className="colour-key key-container__active-section"></div>
                <span>
                  <FormattedMessage
                    id="onboarding_checklist.section_colour_key.active_section"
                    defaultMessage="Active Section"
                  />
                </span>
              </div>
              <div className="mobile-right">
                <div className="colour-key key-container__not-started"></div>
                <span>
                  <FormattedMessage
                    id="onboarding_checklist.section_colour_key.not_started"
                    defaultMessage="Not Started"
                  />
                </span>
              </div>
              <div className="mobile-left">
                <div className="colour-key key-container__in-progress"></div>
                <span>
                  <FormattedMessage
                    id="onboarding_checklist.section_colour_key.in_progress"
                    defaultMessage="In Progress"
                  />
                </span>
              </div>
              <div className="mobile-right">
                <div className="colour-key key-container__complete"></div>
                <span>
                  <FormattedMessage
                    id="onboarding_checklist.section_colour_key.complete"
                    defaultMessage="Complete"
                  />
                </span>
              </div>
              <div className="mobile-right">
                <div className="colour-key key-container__errors"></div>
                <span>
                  <FormattedMessage
                    id="onboarding_checklist.section_colour_key.errors"
                    defaultMessage="Errors"
                  />
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
