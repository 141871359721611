import { CHARGE_TYPE, SELECTED_PROPERTY } from "../actions/types";

export default function propertyReducer(state = {}, action) {
  switch (action.type) {
    case CHARGE_TYPE:
      return { ...state, charge_type: action.payload };
    case SELECTED_PROPERTY:
      return { ...state, property_info: action.payload };
    default:
      return state;
  }
}
