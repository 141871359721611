import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import * as actions from "../../actions";

class PaymentAccount extends Component {
  constructor(props) {
    super(props);
    this.goToDirectDebitPage = this.goToDirectDebitPage.bind(this);
    this.checkCurrentStatus = this.checkCurrentStatus.bind(this);
    this.state = {
      status: "",
      loading: false,
    };
  }

  loading() {
    return (
      <div className="progress">
        <div className="indeterminate" />
      </div>
    );
  }

  componentDidMount() {
    this.props.checkAuthState(this.props.history);
    this.checkCurrentStatus();
  }

  checkCurrentStatus() {
    this.setState(() => ({ loading: true }));
    const property_id = localStorage.getItem("property_id");
    this.props.getDirectDebitCurrentStatus(property_id);
  }

  goToDirectDebitPage(redirectPage) {
    this.setState(() => ({ loading: true }));
    const property_id = localStorage.getItem("property_id");
    this.props.generateRedirect(property_id, redirectPage);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.current_status) !==
      JSON.stringify(this.props.current_status)
    ) {
      this.setState(() => ({ status: this.props.current_status.status }));
      setTimeout(() => this.setState(() => ({ loading: false })), 1000);
    }
    if (prevProps.direct_debit !== this.props.direct_debit) {
      window.open(this.props.direct_debit, "_self");
      setTimeout(() => {
        this.setState(() => ({ loading: false }));
      }, 1000);
    }
  }

  PaymentComponent(name, user, isProfile, ddStatus) {
    const redirectPage = isProfile ? "profile" : "checklist";
    switch (name) {
      case "active":
      case "pending_customer_approval":
      case "pending_submission":
      case "submitted":
        return this.completedDD();
      case "failed":
      case "cancelled":
      case "expired":
        return this.failedDD(user.is_manager, isProfile, ddStatus);
      default:
        if (!user.is_manager) {
          return (
            <div>
              <p>
                {this.props.intl.formatMessage({
                  id: "direct_debit.setup_desc",
                  defaultMessage:
                    "We collect payment via Direct Debit, please click the button below to set this up.",
                })}
              </p>
              <div
                id="dd-btn"
                className="btn-small"
                rel="noopener noreferrer"
                onClick={(_) => this.goToDirectDebitPage(redirectPage)}>
                <span className="mr-10">
                  <i className="material-icons">credit_card</i>
                </span>
                <span>
                  {this.props.intl.formatMessage({
                    id: "direct_debit.setup_dd",
                    defaultMessage: "Set up Direct Debit",
                  })}
                </span>
              </div>
            </div>
          );
        } else {
          return this.failedDD(user.is_manager);
        }
    }
  }

  profileDDComponent(ddStatus, isManager) {
    return (
      <div>
        <span className="mr-10">
          <i className="material-icons">do_not_disturb_alt</i>
        </span>
        <span className="left-align">
          {this.props.intl.formatMessage({
            id: "direct_debit.dd_status",
            defaultMessage: "Direct Debit Status",
          })}
          {!isManager && <span className="mx-5">-</span>}
          {!isManager && (
            <span className="underline">
              {this.props.intl.formatMessage({
                id: "direct_debit.update",
                defaultMessage: "Click here to update",
              })}
            </span>
          )}
        </span>
        <strong className="status capitalize">{ddStatus}</strong>
      </div>
    );
  }

  failedDD(IsManager, isProfile, ddStatus) {
    const redirectPage = isProfile ? "profile" : "checklist";
    if (!IsManager) {
      return (
        <div className="failed" onClick={(_) => this.goToDirectDebitPage(redirectPage)}>
          {!isProfile ? (
            <div>
              <span className="mr-10">
                <i className="material-icons">do_not_disturb_alt</i>
              </span>
              <span>
                {this.props.intl.formatMessage({
                  id: "direct_debit.dd_setup_failed",
                  defaultMessage: "Direct Debit Setup Failed",
                })}
                <span className="mx-5">-</span>
                <span className="underline">
                  {this.props.intl.formatMessage({
                    id: "direct_debit.try_again",
                    defaultMessage: "Try Again",
                  })}
                </span>
              </span>
            </div>
          ) : (
            this.profileDDComponent(ddStatus, IsManager)
          )}
        </div>
      );
    } else {
      if (!isProfile) {
        return (
          <div className="failed">
            <span className="mr-10">
              <i className="material-icons">close</i>
            </span>
            <span>
              {this.props.intl.formatMessage({
                id: "direct_debit.dd_setup_incomplete",
                defaultMessage: "Direct Debit Setup Incomplete",
              })}
            </span>
          </div>
        );
      } else {
        return (
          <div className="failed">{this.profileDDComponent(ddStatus, IsManager)}</div>
        );
      }
    }
  }

  completedDD() {
    return (
      <div className="completed">
        <span className="mr-10">
          <i className="material-icons">check_box</i>
        </span>
        <span>
          {this.props.intl.formatMessage({
            id: "direct_debit.dd_setup_complete",
            defaultMessage: "Direct Debit Setup Completed",
          })}
        </span>
      </div>
    );
  }

  render() {
    const user = JSON.parse(localStorage.getItem("user")) || "client";
    const showDDSection =
      this.props.direct_debit_status !== "organization_disabled" &&
      !this.props.direct_debit_error;
    let classes = user.is_manager ? "not-click " : " ";
    if (!this.state.status) {
      classes += this.props.status;
    }
    return showDDSection ? (
      <div id="direct-debit" className={classes}>
        {!this.props.profile &&
          ((
            <p className="title">
              <strong>
                {this.props.intl.formatMessage({
                  id: "direct_debit.payment_account",
                  defaultMessage: "Payment Account",
                })}
              </strong>
            </p>
          ),
          (
            <p className={this.props.dynamicOnboardingComponent ? "hide" : ""}>
              {this.props.intl.formatMessage({
                id: "direct_debit.payment_account.description",
                defaultMessage:
                  "This is the bank account we will collect funds from, e.g. management fees, disbursements and other charges.",
              })}
            </p>
          ))}
        {this.state.loading
          ? this.loading()
          : this.PaymentComponent(
              this.state.status,
              user,
              this.props.profile,
              this.state.status
            )}
      </div>
    ) : (
      ""
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    direct_debit: state.direct_debit.generate_redirect,
    direct_debit_error: state.direct_debit.generate_redirect_error,
    direct_debit_status: state.checklist.direct_debit_status,
    current_status: state.direct_debit.current_status,
  };
}

export default connect(mapStateToProps, actions)(injectIntl(PaymentAccount));
