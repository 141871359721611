import React from "react";
import { DataField, FieldDependency } from "./schema";

export function compileFieldsFromGroup(groups: any) {
  let consolidatedNestedGroups = [] as any;

  for (let i = 0; i < groups.length; i++) {
    const FIELD = groups[i];
    const groupsUnderGroup = FIELD.fields.filter((obj: any) => obj.type === "group");
    if (groupsUnderGroup.length > 0) {
      const nestedGroups = compileFieldsFromGroup(groupsUnderGroup);
      consolidatedNestedGroups = consolidatedNestedGroups.concat(nestedGroups);
    }
    const fieldsUnderGroup = FIELD.fields.filter((obj: any) => obj.type !== "group");
    consolidatedNestedGroups = consolidatedNestedGroups.concat(fieldsUnderGroup);
  }

  return consolidatedNestedGroups;
}

export function getAllSectionFields(section: any) {
  const topLevelSectionFields = section.fields.filter(
    (obj: any) => obj.type !== "group"
  );
  const topLevelSectionGroups = section.fields.filter(
    (obj: any) => obj.type === "group"
  );
  // compile all fields
  const allSectionFields = topLevelSectionFields.concat(
    compileFieldsFromGroup(topLevelSectionGroups)
  );
  return allSectionFields;
}

export function getAllRelevantRequiredEmptyValueFields(section: any) {
  const allSectionFields = getAllSectionFields(section);
  // grab all the required fields that are empty
  const allRequiredEmptyValueFields = allSectionFields.filter(
    (obj: any) =>
      obj.required === true &&
      (obj.value === null ||
        obj.value === "" ||
        (Array.isArray(obj.value) && obj.value.length === 0))
  );
  // check any depends_on fields and remove them if unneeded
  let allRelevantRequiredEmptyValueFields = [];
  for (let j = 0; j < allRequiredEmptyValueFields.length; j++) {
    const emptyField = allRequiredEmptyValueFields[j];
    if (emptyField.depends_on) {
      const dependsOnField = allSectionFields.find(
        (obj: any) => obj.key === emptyField.depends_on.key
      ) as DataField;
      if (dependsOnField) {
        if (dependsOnField.value === emptyField.depends_on.value) {
          allRelevantRequiredEmptyValueFields.push(emptyField);
        }
      }
    } else {
      allRelevantRequiredEmptyValueFields.push(emptyField);
    }
  }
  return allRelevantRequiredEmptyValueFields;
}

export const PreloaderSpinner = (props: {
  spinnerSize: string;
  spinnerStatus: boolean | null;
  spinnerPosition: string;
  spinnerColour: string; // only custom colour is blue else always orange
}) => {
  return (
    <>
      {props.spinnerStatus === null ? (
        <div
          className={`preloader-wrapper ${props.spinnerPosition} ${props.spinnerSize} active`}>
          <div
            className={`spinner-layer ${
              props.spinnerColour === "blue"
                ? "spinner-blue-only"
                : "spinner-orange-only"
            }`}>
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>
            <div className="gap-patch">
              <div className="circle"></div>
            </div>
            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      ) : (
        <i
          className={`material-icons ${props.spinnerPosition} status_spinner ${
            props.spinnerStatus ? "success" : "failure"
          }`}>
          {props.spinnerStatus ? "check_circle" : "error"}
        </i>
      )}
    </>
  );
};

export function dependencyCallback(
  fieldKey: string,
  fieldValue: string,
  hideField: boolean | null = null
) {
  const dependentFields = document.querySelectorAll(`[js-depends-on-key=${fieldKey}]`);
  if (dependentFields.length > 0) {
    dependentFields.forEach((dependentField) => {
      const dependantValue = dependentField.getAttribute("js-depends-on-value");
      let wasHidden = null;
      if (dependantValue === fieldValue && !hideField) {
        dependentField.removeAttribute("hidden");
        wasHidden = false;
      } else {
        dependentField.setAttribute("hidden", "");
        wasHidden = true;
      }
      disableInputToCorrectFormSubmission(dependentField as HTMLDivElement, wasHidden);
      dependencyCallback(
        dependentField.getAttribute("js-field-key") as string,
        dependentField.getAttribute("js-value") as string,
        wasHidden
      );
    });
  }
}

// On load, check if the field/group should be shown based on its dependency (if it has one)
export function checkDependencyOnLoad(
  dependsOn: FieldDependency | undefined,
  elementContainer: HTMLDivElement | null
) {
  let wasHidden = null;
  if (dependsOn) {
    const dependencyField = document.querySelector(`[js-field-key=${dependsOn.key}]`);
    const dependencyFieldValue = dependencyField?.getAttribute("js-value");
    const dependencyFieldAttributes = dependencyField?.getAttributeNames();
    if (
      dependencyFieldValue !== `${dependsOn.value}` ||
      dependencyFieldAttributes?.includes("hidden")
    ) {
      elementContainer?.setAttribute("hidden", "");
      wasHidden = true;
    } else {
      elementContainer?.removeAttribute("hidden");
      wasHidden = false;
    }
  } else {
    // Since React may re-use the same 'element' for different fields/groups,
    // we need to make sure to remove the hidden attribute if the current field/group doesn't have a depends_on
    elementContainer?.removeAttribute("hidden");
    wasHidden = false;
  }
  disableInputToCorrectFormSubmission(elementContainer, wasHidden);
}

function disableInputToCorrectFormSubmission(
  elementContainer: HTMLDivElement | null,
  disable: boolean
) {
  // Don't trigger this for groups as they will alter the fields below them whom can do this operation themselves
  if (!elementContainer?.classList.contains("onboarding_checklist__group_wrapper")) {
    // timeout to allow React to render the input fields
    setTimeout(() => {
      const inputFields = elementContainer?.querySelectorAll("input");
      inputFields?.forEach((inputField) => {
        if (disable) {
          inputField.setAttribute("disabled", "");
        } else {
          inputField.removeAttribute("disabled");
        }
      });
    }, 100);
  }
}

export function checkSectionForEmptyRequiredFields(sections: any, intl: any) {
  let allSectionErrors = {} as any;

  let allRelevantFields = [] as any;
  sections.forEach((section: any) => {
    allRelevantFields = allRelevantFields.concat(
      getAllRelevantRequiredEmptyValueFields(section)
    );
  });
  // Add field errors for all fields that are required and empty (and relevant aka not irrelevant via being nested in a depends_on)
  if (allRelevantFields.length > 0) {
    allRelevantFields.forEach((field: any) => {
      allSectionErrors[field.key] = intl.formatMessage({
        id: "dynamic_onboarding.default_no_value_required_error",
        defaultMessage: "This field cannot be empty.",
      });
    });

    return allSectionErrors;
  }

  return null;
}
