import React from "react";
import { DatePartLocalised } from "../utility/date_part_localised";
import {
  getDayOfWeek,
  getEndOfPreviousMonth,
  getBeginOfNextMonth,
  getMonthRange,
  getDay,
} from "./dates";
/**
 * @param {int} 'The month number, 0 based'
 * @param {int} 'The year, not zero based, required to account for leap years'
 * @return {Date[]} 'List with date objects for each day of the month'
 **/

export function getDaysInMonth(month, year) {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push({
      week_day: getDayOfWeek(new Date(date)),
      date: new Date(date),
    });
    date.setDate(date.getDate() + 1);
  }
  return days;
}

export function addExtraDays(date, day, start) {
  let extra_days = [];
  if (start) {
    var previousMonthDate = getEndOfPreviousMonth(date);
    for (let i = day; i >= 0; i--) {
      if (i === day) {
        continue;
      }
      extra_days.push({
        week_day: i,
        date: new Date(previousMonthDate),
        extra_day: true,
      });
      previousMonthDate.setDate(previousMonthDate.getDate() - 1);
    }
  } else {
    var nextMonthDate = getBeginOfNextMonth(date);
    for (let i = day; i < 7; i++) {
      if (i === day) {
        continue;
      }
      extra_days.push({
        week_day: i,
        date: new Date(nextMonthDate),
        extra_day: true,
      });
      nextMonthDate.setDate(nextMonthDate.getDate() + 1);
    }
  }
  return extra_days.sort((a, b) =>
    a.week_day > b.week_day ? 1 : a.week_day < b.week_day ? -1 : 0
  );
}

export function listDays(date) {
  let listDays = [];
  let monthRange = getMonthRange(date);
  let monthDayStart = getDayOfWeek(monthRange[0]);
  let monthDayEnd = getDayOfWeek(monthRange[1]);
  if (monthDayStart !== 0) {
    listDays.push(addExtraDays(date, monthDayStart, true));
  }
  listDays.push(getDaysInMonth(date.getMonth(), date.getFullYear()));
  if (monthDayEnd !== 6) {
    listDays.push(addExtraDays(date, monthDayEnd, false));
  }
  return [].concat.apply([], listDays);
}

export function currentViewDays(date) {
  var arrDays = listDays(date);
  arrDays.map((d) => {
    let day_num = getDay(d.date);
    d["day"] =
      day_num === 1 ? (
        <>
          <DatePartLocalised date={d.date} options={{ month: "short" }} /> {day_num}
        </>
      ) : (
        day_num
      );
    return d;
  });
  return arrDays;
}
