import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import SmallSpinner from "../utility/small_spinner";
import { FormattedMessage } from "react-intl";
import { PropertyIDContext } from "../../global_contexts";
import { rGET } from "../../actions/api";
import { resizePhotos } from "../../common/util";

const PhotosDashboard = (props) => {
  const { propertyID } = useContext(PropertyIDContext);
  const [photos, setPhotos] = useState(null);

  useEffect(() => {
    if (!props.testMode) {
      setPhotos(null);
      getDashboardPhotos();
    }
  }, [propertyID]);

  function getDashboardPhotos() {
    const dashboardPhotosWidth = 200;
    rGET(`/propertyphotos/${propertyID}/Original/`).then((resp) => {
      const photosDashboard = resp && resp.data.results;
      setPhotos(resizePhotos(photosDashboard, dashboardPhotosWidth));
    });
  }

  return (
    <div className="col s12 higher-card photos">
      <NavLink exact to="/photos/">
        <div className="card">
          <div className="cardHeader">
            <div className="title">
              <div className="hide-on-med-and-up mr-15">
                <Icon
                  icon={ICONS.PHOTOS.d}
                  width={ICONS.PHOTOS.width}
                  height={ICONS.PHOTOS.height}
                />
              </div>
              <div>
                <FormattedMessage id="dashboard.photos.title" defaultMessage="Photos" />
              </div>
            </div>
            <span className="chevron hide-on-small-only">
              <Icon
                icon={ICONS.CHEVRON.d}
                width={ICONS.CHEVRON.width}
                height={ICONS.CHEVRON.height}
              />
            </span>
          </div>
          <div className="card-content-img-container">
            {photos ? (
              <div>
                <div className="card-image hide-on-med-and-up">
                  {photos.length > 0 ? (
                    <img
                      src={photos[0].link}
                      onError={(e) =>
                        (e.target.src = photos[0].link.replace(/\?.+/g, ""))
                      }
                      height="156"
                      alt=""
                    />
                  ) : (
                    <div className="card-content">
                      <FormattedMessage
                        id="dashboard.photos.no_photos"
                        defaultMessage="There are no photos yet!"
                      />
                    </div>
                  )}
                </div>
                <div className="card-content img-container hide-on-small-only">
                  {photos.length > 0 ? (
                    photos.map((photo, id) => {
                      if (id === 3) {
                        return (
                          <div key={photo.id} className="last-img-box">
                            <p className="center-align cobalt-blue">
                              <FormattedMessage
                                id="dashboard.photos.view_all"
                                defaultMessage="View all"
                              />
                            </p>
                            <img
                              className="img-box"
                              src={photo.link}
                              onError={(e) =>
                                (e.target.src = photo.link.replace(/\?.+/g, ""))
                              }
                              alt=""
                            />
                          </div>
                        );
                      } else if (id < 3) {
                        return (
                          <img
                            key={photo.id}
                            className="img-box"
                            src={photo.link}
                            onError={(e) =>
                              (e.target.src = photo.link.replace(/\?.+/g, ""))
                            }
                            alt=""
                          />
                        );
                      }
                      return null;
                    })
                  ) : (
                    <div>
                      <FormattedMessage
                        id="dashboard.photos.no_photos"
                        defaultMessage="There are no photos yet!"
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <SmallSpinner />
            )}
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default PhotosDashboard;
