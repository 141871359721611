import { rGET, rPUT } from "./api";
import {
  PROPERTY_CHECKLIST,
  PROPERTY_AMENITIES,
  ACCOUNT_CHECKLIST,
  GUEST_CHECKLIST,
  ONBOARDING_CHECKLIST,
  BEDS,
  CHECKLIST_STATUS,
  DIRECT_DEBIT_STATUS,
} from "./types";
import { SECTIONS } from "../components/checklist/fields";

function splitChecklistData(checklist) {
  const currency = localStorage.getItem("currency");
  let arr = [];
  for (let key in SECTIONS) {
    let _arr = {};
    arr.push(
      Object.assign(
        _arr,
        ...SECTIONS[key].map((val) => {
          let obj = {};
          let nameValues = val["names"];
          if (!Array.isArray(nameValues)) {
            nameValues = nameValues[currency];
          }
          for (let i in nameValues) {
            if (typeof nameValues[i] === "string") {
              let entry = {};
              if (checklist[nameValues[i]] === null) {
                entry[nameValues[i]] = "";
              } else {
                entry[nameValues[i]] = checklist[nameValues[i]];
              }
              Object.assign(obj, entry);
            }
          }
          return obj;
        })
      )
    );
  }
  return arr;
}

export function getChecklistInfo(property_id) {
  return (dispatch) => {
    rGET(`/property_checklist/${property_id}/`)
      .then((resp) => {
        const load_checklist = resp.data;
        const checklist_status = load_checklist.status;
        delete load_checklist.property;
        let arr = splitChecklistData(load_checklist);
        let account_checklist = arr[0];
        let property_checklist = arr[1];
        let amenities_checklist = arr[2];
        let guest_checklist = arr[3];
        let onboarding_checklist = arr[4];
        const direct_debit_status = load_checklist.direct_debit_status;
        let beds = load_checklist.beds_info.beds;
        if (beds) {
          beds.map(
            (bed) =>
              (bed["fixed"] = bed["fixed"]
                ? "yes"
                : bed["fixed"] !== null
                ? "no"
                : bed["fixed"])
          );
          property_checklist["beds"] = beds;
        } else {
          beds = [{ fixed: null, id: null, size: null }];
          property_checklist["beds"] = beds;
        }
        delete property_checklist.beds_info;
        dispatch({
          type: CHECKLIST_STATUS,
          payload: checklist_status,
        });
        dispatch({
          type: DIRECT_DEBIT_STATUS,
          payload: direct_debit_status,
        });
        dispatch({
          type: BEDS,
          payload: beds,
        });
        dispatch({
          type: PROPERTY_CHECKLIST,
          payload: property_checklist,
        });
        dispatch({
          type: PROPERTY_AMENITIES,
          payload: amenities_checklist,
        });
        dispatch({
          type: ACCOUNT_CHECKLIST,
          payload: account_checklist,
        });
        dispatch({
          type: GUEST_CHECKLIST,
          payload: guest_checklist,
        });
        dispatch({
          type: ONBOARDING_CHECKLIST,
          payload: onboarding_checklist,
        });
      })
      .catch(() => {});
  };
}

export function updateChecklistInfo(property_id, data) {
  return () => rPUT(`/property_checklist/${property_id}/`, data);
}
