import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../../actions";
import { NavLink } from "react-router-dom";

class GreatHostGuide extends Component {
  componentDidMount() {
    this.props.checkAuthState(this.props.history);
  }

  render() {
    const CARDS_INFO = [
      {
        title: "Guest Communication",
        img: "guest-communication-min.jpg",
        link: "/guest-communication/",
      },
      {
        title: "Key Management",
        img: "key-management-min.jpg",
        link: "/key-management/",
      },
      {
        title: "Cleaning",
        img: "cleaning-min.jpg",
        link: "/cleaning/",
      },
      {
        title: "Linen, Laundry & Toiletries",
        img: "linen-min.jpg",
        link: "/linen-laundry-and-toiletries/",
      },
      {
        title: "Maintenance",
        img: "maintenance-min.jpg",
        link: "/maintenance/",
      },
      {
        title: "Possessions & Valuables",
        img: "possessions-min.jpg",
        link: "/possessions-and-valuables/",
      },
      {
        title: "Money Matters",
        img: "money-min.jpg",
        link: "/money-matters/",
      },
      {
        title: "Airbnb, You & Us",
        img: "airbnb-min.jpg",
        link: "/airbnb-you-and-us/",
      },
      {
        title: "A True Partnership",
        img: "partnership-min.jpg",
        link: "/a-true-partnership/",
      },
    ];

    const CARDS = CARDS_INFO.map((card) => {
      return (
        <div key={card.title} className="col s12 m6 xl4">
          <NavLink exact to={card.link}>
            <div className="card great-host-guide-card">
              <div className="card-image">
                <img alt={card.title} src={require(`../../styles/img/${card.img}`)} />
              </div>
              <div className="card-action">{card.title}</div>
            </div>
          </NavLink>
        </div>
      );
    });

    return (
      <div className="great-host-guide-pages">
        <div className="row">
          <div className="col s12">
            <h4>Great Host Guide</h4>
          </div>
        </div>
        <div className="row">{CARDS}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
  };
}

export default connect(mapStateToProps, actions)(GreatHostGuide);
