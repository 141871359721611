import React from "react";

export function renderLogoutMessage(msg) {
  return (
    <div className="row logout valign-wrapper">
      <div className="col s12">
        <p className="cobalt-blue">{msg}</p>
      </div>
    </div>
  );
}
