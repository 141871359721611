import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { numberFormat } from "../utility/currency_format";
import { moneyRates } from "../utility/moneyRates";
import { LocaleContext } from "../../global_contexts";

const Settings = (props) => {
  const { locale } = useContext(LocaleContext);
  const [currency, setCurrency] = useState(localStorage.getItem("currency"));
  const [involvement, setInvolvement] = useState("");

  useEffect(() => {
    if (localStorage.getItem("currency")) {
      setCurrency(localStorage.getItem("currency"));
    }
  }, [localStorage.getItem("currency")]);

  useEffect(() => {
    if (props.fields && props.fields.involvement !== involvement) {
      setInvolvement(props.fields.involvement);
    }
  }, [props.fields]);

  return (
    <div className="row">
      <div className="col s12">
        <h5 className="title">
          <FormattedMessage id="preferences.settings.title" defaultMessage="Settings" />
        </h5>
      </div>
      <div className="col s12 m6 split fix-height">
        <div
          id="hasslefree"
          className={
            "option-container " + (involvement === "Hassle Free" ? "selected-opt" : "")
          }>
          <span className={"checked " + (involvement === "Involved" ? "hide" : "")}>
            <Icon
              icon={ICONS.CHECKED.d}
              width={ICONS.CHECKED.width}
              height={ICONS.CHECKED.height}
            />
          </span>
          <label htmlFor="hassle_free">
            <div className="height-txt">
              <h5 className="title">
                <FormattedMessage
                  id="preferences.settings.subtitle_hassle_free"
                  defaultMessage="Hassle Free"
                />
              </h5>
              <ul>
                <li>
                  <FormattedMessage
                    id="preferences.settings.hassle_free_one"
                    defaultMessage="We solve problems immediately"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="preferences.settings.hassle_free_two"
                    defaultMessage="We let you know when the problem is solved"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="preferences.settings.hassle_free_three"
                    defaultMessage="We make decisions up to"
                  />{" "}
                  {numberFormat(locale, moneyRates(250, currency), currency, 0)}
                </li>
              </ul>
            </div>
            <div className="col s12 center-align">
              <input
                id="hassle_free"
                data-testid="hasslefree"
                type="radio"
                name="involvement"
                onClick={() => props.updateField("involvement", "Hassle Free")}
              />
              <span />
            </div>
          </label>
        </div>
      </div>
      <div className="col s12 m6 split fix-height">
        <div
          id="getinvolved"
          className={
            "option-container get_involved " +
            (involvement === "Involved" ? "selected-opt" : "")
          }>
          <span className={"checked " + (involvement === "Hassle Free" ? "hide" : "")}>
            <Icon
              icon={ICONS.CHECKED.d}
              width={ICONS.CHECKED.width}
              height={ICONS.CHECKED.height}
            />
          </span>
          <label htmlFor="get_involved">
            <div className="height-txt">
              <h5 className="title">
                <FormattedMessage
                  id="preferences.settings.subtitle_wants_involvement"
                  defaultMessage="I want to be involved"
                />
              </h5>
              <ul>
                <li>
                  <FormattedMessage
                    id="preferences.settings.wants_involvement_one"
                    defaultMessage="We contact you about any issue (apart from life threatening situations)"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="preferences.settings.wants_involvement_two"
                    defaultMessage="We don't act without your consent"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="preferences.settings.wants_involvement_three"
                    defaultMessage="We wait for 3 hours before we relocate the guest, however please be aware that the guest may decide to relocate by themself before this"
                  />
                </li>
              </ul>
            </div>
            <div className="col s12 center-align">
              <input
                id="get_involved"
                data-testid="involved"
                type="radio"
                name="involvement"
                onClick={() => props.updateField("involvement", "Involved")}
              />
              <span />
            </div>
          </label>
        </div>
      </div>
      <div className="col s12">
        <h5 className="title">
          <FormattedMessage
            id="preferences.settings.subtitle_replace_items"
            defaultMessage="Would you like us to replace basic items?"
          />
        </h5>

        <div className="switch-container">
          <div>
            <FormattedMessage
              id="preferences.settings.replace_items_vacuum"
              defaultMessage="Vacuum (up to"
            />{" "}
            {numberFormat(locale, moneyRates(100, currency), currency, 0)})
          </div>
          <div className="switch">
            <label>
              <input
                type="checkbox"
                name="replace_vacuum"
                readOnly
                checked={props.fields.replace_vacuum}
                value={props.fields.replace_vacuum}
                onClick={() =>
                  props.updateField("replace_vacuum", !props.fields.replace_vacuum)
                }
              />
              <span className="lever" />
            </label>
          </div>
        </div>

        <div className="switch-container">
          <div>
            <FormattedMessage
              id="preferences.settings.replace_items_kettle"
              defaultMessage="Kettle (up to"
            />{" "}
            {numberFormat(locale, moneyRates(25, currency), currency, 0)})
          </div>
          <div className="switch">
            <label>
              <input
                type="checkbox"
                name="replace_kettle"
                readOnly
                checked={props.fields.replace_kettle}
                value={props.fields.replace_kettle}
                onClick={() =>
                  props.updateField("replace_kettle", !props.fields.replace_kettle)
                }
              />
              <span className="lever" />
            </label>
          </div>
        </div>

        <div className="switch-container">
          <div>
            <FormattedMessage
              id="preferences.settings.replace_items_toaster"
              defaultMessage="Toaster (up to"
            />{" "}
            {numberFormat(locale, moneyRates(25, currency), currency, 0)})
          </div>
          <div className="switch">
            <label>
              <input
                type="checkbox"
                name="replace_toaster"
                readOnly
                checked={props.fields.replace_toaster}
                value={props.fields.replace_toaster}
                onClick={() =>
                  props.updateField("replace_toaster", !props.fields.replace_toaster)
                }
              />
              <span className="lever" />
            </label>
          </div>
        </div>

        <div className="switch-container">
          <div>
            <FormattedMessage
              id="preferences.settings.replace_items_iron"
              defaultMessage="Iron (up to"
            />{" "}
            {numberFormat(locale, moneyRates(25, currency), currency, 0)})
          </div>
          <div className="switch">
            <label>
              <input
                type="checkbox"
                name="replace_iron"
                readOnly
                checked={props.fields.replace_iron}
                value={props.fields.replace_iron}
                onClick={() =>
                  props.updateField("replace_iron", !props.fields.replace_iron)
                }
              />
              <span className="lever" />
            </label>
          </div>
        </div>

        <div className="switch-container">
          <div>
            <FormattedMessage
              id="preferences.settings.replace_items_basic_items"
              defaultMessage="Basic Items (up to"
            />{" "}
            {numberFormat(locale, moneyRates(25, currency), currency, 0)})
          </div>
          <div className="switch">
            <label>
              <input
                type="checkbox"
                name="replace_basic_items"
                readOnly
                checked={props.fields.replace_basic_items}
                value={props.fields.replace_basic_items}
                onClick={() =>
                  props.updateField(
                    "replace_basic_items",
                    !props.fields.replace_basic_items
                  )
                }
              />
              <span className="lever" />
            </label>
          </div>
        </div>

        <div className="switch-container">
          <div>
            <FormattedMessage
              id="preferences.settings.replace_items_hairdryer"
              defaultMessage="Hairdryer (up to"
            />{" "}
            {numberFormat(locale, moneyRates(25, currency), currency, 0)})
          </div>
          <div className="switch">
            <label>
              <input
                type="checkbox"
                name="replace_hairdryer"
                readOnly
                checked={props.fields.replace_hairdryer}
                value={props.fields.replace_hairdryer}
                onClick={() =>
                  props.updateField(
                    "replace_hairdryer",
                    !props.fields.replace_hairdryer
                  )
                }
              />
              <span className="lever" />
            </label>
          </div>
        </div>

        <div className="switch-container">
          <div>
            <FormattedMessage
              id="preferences.settings.replace_items_duvet"
              defaultMessage="Duvet (up to"
            />{" "}
            {numberFormat(locale, moneyRates(50, currency), currency, 0)})
          </div>
          <div className="switch">
            <label>
              <input
                type="checkbox"
                name="replace_duvet"
                readOnly
                checked={props.fields.replace_duvet}
                value={props.fields.replace_duvet}
                onClick={() =>
                  props.updateField("replace_duvet", !props.fields.replace_duvet)
                }
              />
              <span className="lever" />
            </label>
          </div>
        </div>

        <div className="switch-container">
          <div>
            <FormattedMessage
              id="preferences.settings.replace_items_kitchen_utensils"
              defaultMessage="Kitchen Utensils (up to"
            />{" "}
            {numberFormat(locale, moneyRates(50, currency), currency, 0)})
          </div>
          <div className="switch">
            <label>
              <input
                type="checkbox"
                name="replace_kitchen_utensils"
                readOnly
                checked={props.fields.replace_kitchen_utensils}
                value={props.fields.replace_kitchen_utensils}
                onClick={() =>
                  props.updateField(
                    "replace_kitchen_utensils",
                    !props.fields.replace_kitchen_utensils
                  )
                }
              />
              <span className="lever" />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
