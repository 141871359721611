import axios from "axios";
import config from "../config";
import { isTokenAboutToExpire } from "../common/util";
import { rGET } from "./api";
import { AUTH_USER, UNAUTH_USER, AUTH_ERROR } from "./types";

export const API_AUTH_ROOT = config.API_AUTH_ROOT;

export function initCallHandler(
  resp,
  propertyID,
  setPreferredLanguage,
  setPropertyCountry
) {
  // If no propertyID is provided, we will use the first property in the list
  const requestedPropertyResult = propertyID
    ? resp.data.results.find((result) => result.id === propertyID)
    : resp.data.results[0];
  const IS_DYNAMIC_ONBOARDING_ENABLED =
    requestedPropertyResult.dynamic_onboarding || false;
  localStorage.setItem("dynamic_onboarding", IS_DYNAMIC_ONBOARDING_ENABLED);
  localStorage.setItem("properties", JSON.stringify(resp.data.results));
  localStorage.setItem(
    "feature_toggles",
    JSON.stringify(resp.data.client_feature_toggles)
  );
  setPreferredLanguage(resp.data.client_preferred_language);
  let currency = requestedPropertyResult.currency || "GBP";
  localStorage.setItem("currency", currency);
  let country = requestedPropertyResult.country || "GB";
  localStorage.setItem("country", country);
  setPropertyCountry(country);
  return resp.data;
}

export async function initCall(
  { setPreferredLanguage, setPropertyCountry },
  propertyID
) {
  const url = propertyID ? `/init/?property_id=${propertyID}` : "/init/";
  try {
    const response = await rGET(url);
    return initCallHandler(
      response,
      propertyID,
      setPreferredLanguage,
      setPropertyCountry
    );
  } catch (error) {
    localStorage.clear();
    return "Unexpected Error";
  }
}

export function loginUser(
  { email, password },
  history,
  { intl },
  { setPropertyID },
  { setPreferredLanguage, setPropertyCountry }
) {
  delete axios.defaults.headers.common["Authorization"];
  return function (dispatch) {
    axios
      .post(`${API_AUTH_ROOT}/api-token-auth/`, { email, password })
      .then(async (response) => {
        // Remove auth error so it does not show up if the user returns to the login screen
        dispatch(authError(""));

        localStorage.setItem("token", response.data.token);
        axios.defaults.headers.common["Authorization"] = `JWT ${response.data.token}`;
        const user_info = response.data["user"];
        localStorage.setItem("user", JSON.stringify(user_info));
        let init_res = await initCall({ setPreferredLanguage, setPropertyCountry });
        let results = init_res.results;
        if (results !== "Unexpected Error") {
          if (results.length > 0) {
            localStorage.setItem("property_id", JSON.stringify(results[0].id));
            setPropertyID(results[0].id);
            dispatch({ type: AUTH_USER, payload: user_info });
            if (!results[0].checklist_complete) {
              history.push("/checklist/");
            }
          } else {
            dispatch(
              authError(
                intl.formatMessage({
                  id: "login.no_active_properties",
                  defaultMessage:
                    "It seems you do not have active properties. Please contact your account manager if you think this is an error.",
                })
              )
            );
            localStorage.clear();
          }
        } else {
          dispatch(
            authError(
              intl.formatMessage({
                id: "login.unexpected_error",
                defaultMessage: "Unexpected Error",
              })
            )
          );
        }
      })
      .catch((error) => {
        /* If we have a server error warning on the login page then don't show the the login credentials
           Error message */
        if (error.response === undefined || error.response.status !== 400) {
          dispatch(authError(""));
          return;
        }

        localStorage.clear();
        dispatch(
          authError(
            intl.formatMessage({
              id: "login.incorrect_credentials_error_message",
              defaultMessage: `Incorrect credentials - ensure you are using the email address you signed up with, or that you have entered the correct password. If you cannot remember your password click "Forgotten password?" below.`,
            })
          )
        );
      });
  };
}

export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error,
  };
}

export function logoutUser(logout_msg, { setPropertyID }) {
  localStorage.clear();
  setPropertyID(null);
  return {
    type: UNAUTH_USER,
    payload: logout_msg,
  };
}

export function checkAuthState(history) {
  const token = localStorage.getItem("token");
  if (token) {
    if (isTokenAboutToExpire(token)) {
      axios.post(`${API_AUTH_ROOT}/api-token-refresh/`, { token }).then((response) => {
        localStorage.setItem("token", response.data.token);
        axios.defaults.headers.common["Authorization"] = `JWT ${response.data.token}`;
      });
    }
    return { type: AUTH_USER };
  } else {
    var match_path = /\/(password-reset-confirm|register)\/\w+\/.+/.test(
      window.location.pathname
    );
    if (!match_path) {
      localStorage.clear();
      if (history) history.replace("/login/");
    }
    return { type: UNAUTH_USER };
  }
}
