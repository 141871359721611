import { createSelector } from "reselect";
import { UPDATE_SERVICEWORKER } from "../actions/types";

// TODO: Once no longer needed with Redux being removed via modernisation, remove the "reselect" package

export default function updateReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_SERVICEWORKER:
      return { ...state, update_serviceworker: action.payload };
    default:
      return state;
  }
}

const serviceWorker = (state) => state.serviceWorker;

export const getServiceWorker = createSelector(
  [serviceWorker],
  (serviceWorker) => serviceWorker
);
