import React from "react";

const ErrorPage = () => {
  return (
    <div className="row not-found">
      <div className="col s12 logo-container mt-25" />
      <div className="col s12">
        <h1>Something went wrong.</h1>
      </div>
      <div className="col s12">
        <h3>Please contact us if the problem persists.</h3>
      </div>
    </div>
  );
};

export default ErrorPage;
