import React, { useContext, useEffect, useRef, useState } from "react";
import { rGET, rPUT } from "../../actions/api";
import { PropertyIDContext } from "../../global_contexts";
import {
  DYNAMIC_DATA_CONTEXT,
  SECTION_DISPLAY_CONTEXTS,
  FORM_CONTEXTS,
  MATERIALIZE_REF,
} from "./contexts";
import { ScrollableSectionsDisplay } from "./scrollable_sections_display";
import OnboardingChecklistSection from "./section";
import { DataField, DataFieldGroup } from "./schema";
import { store } from "../../store";
import { CHECKLIST_STATUS } from "../../actions/types";
import {
  checkSectionForEmptyRequiredFields,
  getAllRelevantRequiredEmptyValueFields,
  getAllSectionFields,
} from "./utility";
import { useIntl } from "react-intl";

export const DynamicOnboardingForm = () => {
  const INTL = useIntl();
  const { propertyID } = useContext(PropertyIDContext);
  const M = useContext(MATERIALIZE_REF);
  const [activeSection, setActiveSection] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const DYNAMIC_DATA = useContext(DYNAMIC_DATA_CONTEXT);
  const [sections, setSections] = useState(DYNAMIC_DATA && DYNAMIC_DATA.sections);
  const [fieldErrors, setFieldErrors] = useState({});
  const [nonFieldErrors, setNonFieldErrors] = useState([] as any);
  let PUTData = useRef({}) as any;
  const loadSubmitRef = useRef<any>(null);

  useEffect(() => {
    M.Modal.init(document.getElementById("modalChecklistComplete"));
  }, [M.Modal]);

  useEffect(() => {
    runImplicitStatusDetection();
    // The only reason the sections would update is when the api call is made to get the data, so we can presume this is also only updated when the api call for a locale change is made (important as we need to re-run error detection only after the sections data is recieved)
    // Check if there are any existing fieldErrors, if so re-run frontend error detection for all sections to ensure the correct localisation is applied
    if (Object.entries(fieldErrors).length > 0) {
      const allSectionErrors = checkSectionForEmptyRequiredFields(sections, INTL);
      updateFieldErrorsWithAllSectionsErrors(allSectionErrors);
    }
  }, [sections]);

  useEffect(() => {
    setSections(DYNAMIC_DATA && DYNAMIC_DATA.sections);
  }, [DYNAMIC_DATA]);

  useEffect(() => {
    if (sections) {
      // Update the active section index so we are showing the correct section
      sections.map((section) => {
        if (section.title === activeSection) {
          const sectionIndex = sections.indexOf(section);
          setActiveIndex(sectionIndex);

          // If we are on the last section, we want to trigger error detection for all sections and add to field errors if needed
          if (sectionIndex === sections.length - 1) {
            const allSectionErrors = checkSectionForEmptyRequiredFields(sections, INTL);
            updateFieldErrorsWithAllSectionsErrors(allSectionErrors);
          }
        }
      });
    }
  }, [activeSection]);

  // Used in child to uplift data and update local state
  const updateActiveSection = (selected_section_title: any) => {
    setActiveSection(selected_section_title);
    if (Object.keys(fieldErrors).length) {
      setTimeout(() => {
        loadErrorsForActiveSection(null);
      }, 100);
    }
    runImplicitStatusDetection();
  };

  const putData = async () => {
    const submitButton = document.querySelector(".section_submit_button>button") as any;
    if (submitButton) {
      submitButton.disabled = true;
    }
    setNonFieldErrors([] as any);
    await rPUT(`/onboarding/${propertyID}/stages/checklist/`, PUTData)
      .then((response) => {
        // Hide confirmation modal
        closeConfirmationModal("success", 13000); // shown for 13 seconds
        // update internal checklist status to hide tab & screen
        let checklist_status = "requires_check";
        store.dispatch({
          type: CHECKLIST_STATUS,
          payload: checklist_status,
        });
        // redirect to dashboard
        const dashboardTabElement = document.getElementById("Dashboard");
        if (dashboardTabElement) {
          // We click the tab because the window.location.href method causes the toast message to instantly disappear
          dashboardTabElement.click();
        }
      })
      .catch((error) => {
        if (error.response.data) {
          // Hide confirmation modal
          closeConfirmationModal("error", 2000); // shown for 2 seconds
          // Handle & display non-field errors
          if (error.response.data.non_field_errors) {
            setNonFieldErrors(error.response.data.non_field_errors);
          }
          // Handle field errors
          setFieldErrors(error.response.data);
          loadErrorsForActiveSection(error.response.data);
          // Implicit status detection logic
          updateImplicitErrorStatus(error.response.data);
          // Navigate to the first error
          navigateToFirstErrorTab();
        }
        if (submitButton) {
          submitButton.disabled = false;
        }
      });
  };

  function closeConfirmationModal(messageToShow: string, displayLength: number) {
    const successToastHTML = `<div style="position: relative">
      <div>
        <p>${INTL.formatMessage({
          id: "dynamic_onboarding.confirmation_modal.success_line_one",
          defaultMessage: "You are all done!",
        })}</p>
        <p>${INTL.formatMessage({
          id: "dynamic_onboarding.confirmation_modal.success_line_two",
          defaultMessage:
            "Thank you for all your hard work. We can’t wait to get you that first booking!",
        })}</p>
        <p className="mt-0">${INTL.formatMessage({
          id: "dynamic_onboarding.confirmation_modal.success_line_three",
          defaultMessage:
            "Now you can start exploring your Client Portal, where you can monitor all your bookings and much more.",
        })}</p>
        <p className="mt-0">${INTL.formatMessage({
          id: "dynamic_onboarding.confirmation_modal.success_line_four",
          defaultMessage:
            "The important thing to do at this stage is to check your calendar availability and update it as necessary. Also, make sure to go to the ‘Preferences’ tab and add all your providers’ contact details, which we will use in case of emergency.",
        })}</p>
        <p className="mt-0">${INTL.formatMessage({
          id: "dynamic_onboarding.confirmation_modal.success_line_five",
          defaultMessage:
            "Please also check out the Great Host Guide section to learn everything about our service.",
        })}</p>
      </div>
    </div>`;
    const errorToastHTML = `<div style="position: relative">
    <div>
    <p>${INTL.formatMessage({
      id: "dynamic_onboarding.confirmation_modal.error",
      defaultMessage: "There were some errors, please resolve these and try again.",
    })}</p>
  </div>
  </div>`;
    if (loadSubmitRef.current) {
      loadSubmitRef.current.classList.add("hide");
    }
    let instance = M.Modal.getInstance(
      document.getElementById("modalChecklistComplete")
    );
    instance.close();
    let toastHTML = "";
    if (messageToShow === "success") {
      toastHTML = successToastHTML;
    } else if (messageToShow === "error") {
      toastHTML = errorToastHTML;
    }
    M.toast({
      html: toastHTML,
      classes: "centered",
      displayLength: displayLength,
    });
  }

  const runImplicitStatusDetection = () => {
    if (sections) {
      for (let i = 0; i < sections.length; i++) {
        const SECTION = sections[i];
        const sectionToUpdate = document.getElementById(`section_${i}`);
        const allSectionFields = getAllSectionFields(SECTION);
        // grab all required fields excluding depends_on fields to check if the section is not started
        const allRequiredFieldsExcludingDependsOn = allSectionFields.filter(
          (obj: any) => obj.required === true && !obj.depends_on
        );
        const allRelevantRequiredEmptyValueFields = getAllRelevantRequiredEmptyValueFields(
          SECTION
        );
        // update section status
        if (sectionToUpdate) {
          if (allRelevantRequiredEmptyValueFields.length > 0) {
            if (
              allRelevantRequiredEmptyValueFields.length ===
              allRequiredFieldsExcludingDependsOn.length
            ) {
              sectionToUpdate.setAttribute("data-status", "not_started");
            } else {
              sectionToUpdate.setAttribute("data-status", "incomplete");
            }
          } else {
            sectionToUpdate.setAttribute("data-status", "complete");
          }
        }
      }
      // Ensure errors are visible
      updateImplicitErrorStatus(fieldErrors);
    }
  };

  const updateImplicitErrorStatus = (errors: any) => {
    if (sections) {
      Object.entries(errors).forEach((err: any) => {
        const [key, value] = err;
        for (let i = 0; i < sections.length; i++) {
          const SECTION = sections[i];
          const allSectionFields = getAllSectionFields(SECTION);
          // check if error key is in the section
          const findResult = allSectionFields.find((obj: any) => obj.key === key);
          if (findResult) {
            const sectionToUpdate = document.getElementById(`section_${i}`);
            if (sectionToUpdate) {
              sectionToUpdate.setAttribute("data-status", "errors");
            }
            break;
          }
        }
      });
    }
  };

  const loadErrorsForActiveSection = (errors: any) => {
    // First check if there are any persisting errors (if so there will always be something to display)
    // Second check if errors exists and has a length (as an array or object)
    if (
      Object.keys(fieldErrors).length > 0 ||
      (errors && (errors.length > 0 || Object.keys(errors).length > 0))
    ) {
      resetErrorTexts();
      let fieldErrorsData = null;
      if (errors !== null) {
        fieldErrorsData = errors;
      } else {
        fieldErrorsData = fieldErrors;
      }
      if (fieldErrorsData !== null) {
        Object.entries(fieldErrorsData).map((errorObj: any) => {
          const [KEY, ERRORTXT] = errorObj;
          const fieldTarget = document.getElementById(`${KEY}`) as any;
          const fieldTargetSpan = document.getElementById(`${KEY}_span`) as any;
          if (fieldTarget) {
            fieldTarget.classList.add("invalid");
          }
          if (fieldTargetSpan) {
            fieldTargetSpan.textContent = ERRORTXT;
            fieldTargetSpan.classList.add("error_text");
            fieldTargetSpan.setAttribute("data-error", ERRORTXT);
          }
        });
      }
    }
  };

  function resetErrorTexts() {
    const existingErrors = document.querySelectorAll(".error_text");
    existingErrors.forEach((el) => {
      el.classList.remove("error_text");
      if (!el.classList.contains("helper-text")) {
        el.textContent = "";
      }
    });
  }

  const handleSectionSubmission = (gotoNextSection: boolean) => {
    if (!gotoNextSection) {
      let instance = M.Tabs.getInstance(document.querySelector("ul.tabs"));
      const activeTab = document.querySelector("ul.tabs>li>a.active");

      // Query all required fields
      const allRequiredFields = Array.from(
        document.querySelectorAll("input[required]:not([disabled])")
      );
      // Filter out all required fields that have a value
      let allRequiredEmptyFields = allRequiredFields.filter(
        (field: any) => !field.value
      );

      // Check rich text fields for empty values
      allRequiredEmptyFields = allRequiredEmptyFields.filter((field: any) => {
        // Check for the rich text identifier else ignore
        const splitFieldID = field.id.split("#");
        const lastSplitString = splitFieldID[splitFieldID.length - 1];
        if (lastSplitString !== "rich_text") {
          return field;
        }
        // If the last string in the splitFieldID is "rich_text" then we need to check the 'actual' rich text field for a value
        // Get rid of the identifier so we can target the rich text element directly via id
        splitFieldID.pop();
        const combinedFieldIDExcludingIdentifier = splitFieldID.join("#");
        const actualRichTextField = document.getElementById(
          combinedFieldIDExcludingIdentifier
        );
        if (actualRichTextField && actualRichTextField.textContent !== "") {
          // Rich text field has a value so no error
          return null;
        }
        return field;
      });

      // Check for any radio fields that are required
      const allRequiredRadioFields = Array.from(
        document.querySelectorAll("input[type=radio][required]:not([disabled])")
      );
      // Compile unique radio field names (which are their keys)
      const uniqueRadioFieldNames = Array.from(
        new Set(allRequiredRadioFields.map((field: any) => field.name))
      );
      // Check these radio fields for any that don't have a single checked option
      const allRequiredEmptyRadioFields = uniqueRadioFieldNames.filter((name: any) => {
        const radioField = document.querySelector(
          `input[type=radio][name=${name}]:checked`
        );
        return !radioField;
      });
      if (allRequiredEmptyRadioFields.length > 0) {
        allRequiredEmptyRadioFields.forEach((name: any) => {
          const psuedoRadioFieldForErrorGeneration = { id: name } as any;
          allRequiredEmptyFields = allRequiredEmptyFields.concat(
            psuedoRadioFieldForErrorGeneration
          );
        });
      }

      // Check if there are any required fields that don't have a value
      if (allRequiredEmptyFields.length > 0) {
        const emptyRequiredFields = {} as any;
        allRequiredEmptyFields.map((field: any) => {
          // Strip any hash data from the field ID (currently only used for rich text psuedo-inputs (otherwise there is issues with Trumbowyg))
          const fieldID = field.id.split("#")[0];
          emptyRequiredFields[fieldID] = INTL.formatMessage({
            id: "dynamic_onboarding.default_no_value_required_error",
            defaultMessage: "This field cannot be empty.",
          });
        });

        // Ensure errors persist until delt with
        setFieldErrors({ ...fieldErrors, ...emptyRequiredFields });
        updateImplicitErrorStatus(emptyRequiredFields);

        // Include errors in active session so they are instantly visible
        loadErrorsForActiveSection(emptyRequiredFields);

        // Stop the section from advancing
        return;
      }

      // Check for errors in the DOM
      checkForDataErrors();

      const nextTabID =
        activeTab &&
        activeTab.parentElement &&
        activeTab.parentElement.nextElementSibling &&
        activeTab.parentElement.nextElementSibling.id.split("_")[1];
      instance.select(nextTabID);
    } else {
      // Only show confirmation modal if there are no errors in the DOM
      checkForDataErrors();

      // Show confirmation popup
      let instance = M.Modal.getInstance(
        document.getElementById("modalChecklistComplete")
      );
      instance.open();
    }
  };

  async function submitStage() {
    const user = JSON.parse(localStorage.getItem("user") as string);
    if (user && user.is_manager === true) {
      M.toast({
        html: INTL.formatMessage({
          id: "dynamic_onboarding.confirmation_modal.manager_cant_submit_message",
          defaultMessage: "Managers can't submit the stage for a client.",
        }),
        classes: "centered",
        displayLength: 2000,
      });
      return null; // user is manager, and therefore can't submit the stage
    }
    // show loading bar inside of modal
    if (loadSubmitRef.current) {
      loadSubmitRef.current.classList.remove("hide");
    }
    const DirectDebitStatus = await getDirectDebitStatus();
    if (!verifyDirectDebitStatus(DirectDebitStatus)) {
      // set direct debit field error
      let allFields = [] as any;
      if (sections) {
        for (let i = 0; i < sections.length; i++) {
          const SECTION = sections[i];
          const fields = getAllSectionFields(SECTION);
          allFields = allFields.concat(fields);
        }
      }
      const directDebitFields = allFields.filter(
        (obj: any) => obj.type === "direct_debit_status"
      );
      let directDebitFieldErrors = {} as any;
      directDebitFields.forEach((field: DataField) => {
        directDebitFieldErrors[field.key] = INTL.formatMessage({
          id: "dynamic_onboarding.confirmation_modal.direct_debit_not_set_up",
          defaultMessage:
            "You must set up the Direct Debit before you can submit the checklist.",
        });
      });
      setFieldErrors({ ...fieldErrors, ...directDebitFieldErrors });
      updateImplicitErrorStatus(directDebitFieldErrors);
      closeConfirmationModal("error", 2000); // shown for 2 seconds
      return null; // user has not setup the DD, and therefore can't submit the stage
    }
    // Reset PUTData reference
    PUTData = {};
    // Send PUT data
    extractStageData();
    putData();
  }

  async function getDirectDebitStatus() {
    return rGET(`/mandate_status/${propertyID}/`).then((resp) => {
      const DirectDebitStatus = resp.data.mandate_status;
      return DirectDebitStatus;
    });
  }

  function verifyDirectDebitStatus(DirectDebitStatus: string) {
    switch (DirectDebitStatus) {
      case "active":
      case "pending_customer_approval":
      case "pending_submission":
      case "submitted":
        return true;
      case "failed":
      case "cancelled":
      case "expired":
        return false;
      default:
        return null;
    }
  }

  function extractStageData() {
    if (sections) {
      for (let i = 0; i < sections.length; i++) {
        const SECTION = sections[i];
        // We can't just use the extractGroupData function from the get go here
        // because the Sections have a different JSON structure, it would work, but TypeScript doesn't like it
        for (let j = 0; j < SECTION.fields.length; j++) {
          const FIELD = SECTION.fields[j];
          if (FIELD.type === "group") {
            extractGroupData(FIELD as DataFieldGroup);
          } else {
            const newKeyValue = { [FIELD.key]: FIELD.value };
            PUTData = { ...PUTData, ...newKeyValue };
          }
        }
      }
    }
  }

  function extractGroupData(fieldGroup: DataFieldGroup): any {
    for (let i = 0; i < fieldGroup.fields.length; i++) {
      const FIELD = fieldGroup.fields[i];
      if (FIELD.type === "group") {
        extractGroupData(FIELD as DataFieldGroup);
      } else {
        const newKeyValue = { [FIELD.key]: FIELD.value };
        PUTData = { ...PUTData, ...newKeyValue };
      }
    }
  }

  // When a field is modified we update the locally stored copy of the stage data
  // (If we don't then when the user switches tabs back and forth, they will see old data, and this way we don't have to endlessly call the API endpoint)
  const updateSectionData = (modifiedFieldData: any, toUpdate: string) => {
    const keyToFind = Object.keys(modifiedFieldData)[0];
    let modifiedSections = sections;
    if (modifiedSections) {
      const activeTab = document.querySelector("ul.tabs>li>a.active");
      const activeTabName = activeTab && activeTab.textContent;
      for (let i = 0; i < modifiedSections.length; i++) {
        const SECTION = modifiedSections[i];
        if (SECTION.title === activeTabName) {
          for (let j = 0; j < SECTION.fields.length; j++) {
            const FIELD = SECTION.fields[j];
            if (FIELD.type === "group") {
              searchThroughGroupField(
                FIELD as DataFieldGroup,
                modifiedFieldData,
                keyToFind,
                toUpdate
              );
            } else {
              // Not a group field
              updateField(FIELD as DataField, modifiedFieldData, keyToFind, toUpdate);
            }
          }
          break;
        }
      }
    }

    // remove associated field error from fieldErrors to ensure the error doesn't re-appear if user re-clicks the section
    let tempFieldErrors = fieldErrors;
    delete (tempFieldErrors as any)[keyToFind];
    setFieldErrors(tempFieldErrors);
  };

  function searchThroughGroupField(
    fieldGroup: DataFieldGroup,
    modifiedFieldData: any,
    keyToFind: string,
    toUpdate: string
  ) {
    for (let i = 0; i < fieldGroup.fields.length; i++) {
      const FIELD = fieldGroup.fields[i];
      if (FIELD.type === "group") {
        searchThroughGroupField(
          FIELD as DataFieldGroup,
          modifiedFieldData,
          keyToFind,
          toUpdate
        );
      } else {
        updateField(FIELD as DataField, modifiedFieldData, keyToFind, toUpdate);
      }
    }
  }

  function updateField(
    FIELD: DataField,
    modifiedFieldData: any,
    keyToFind: string,
    toUpdate: string
  ) {
    if (FIELD.key === keyToFind) {
      // Left in this structure rather than condensing usage of "value" in case we need to maintain other data locally too
      if (toUpdate === "value") {
        FIELD.value = modifiedFieldData[keyToFind];
      }
    }
  }

  function navigateToFirstErrorTab() {
    // find first tab with errors
    const firstErrorTab = document.querySelector(
      '#onboarding_checklist_header li[data-status="errors"]'
    );
    if (firstErrorTab) {
      let instance = M.Tabs.getInstance(document.querySelector("ul.tabs"));
      const firstErrorTabID = firstErrorTab.id.split("_")[1];
      instance.select(firstErrorTabID);
      // scroll tabs to ensure the active tab is fully visible
      firstErrorTab.scrollIntoView({ behavior: "smooth" });
    }
    // find first error field in the active section
    const firstErrorField = document.querySelector(".invalid");
    if (firstErrorField) {
      firstErrorField.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  function updateFieldErrorsWithAllSectionsErrors(allSectionErrors: any) {
    if (allSectionErrors !== null) {
      // Ensure errors persist until delt with
      setFieldErrors({ ...fieldErrors, ...allSectionErrors });
      updateImplicitErrorStatus(allSectionErrors);

      // Delay to ensure fields are rendered first
      setTimeout(() => {
        // Include errors in active session so they are instantly visible
        loadErrorsForActiveSection(allSectionErrors);
      }, 100);
    }
  }

  function checkForDataErrors() {
    // Check if there are any data-errors present in the DOM, if so, scroll to error and don't advance to the next section
    const allDataErrors = document.querySelectorAll("[data-error]");
    // Check if any of the detected date-errors have a value (aka error text), if they don't then they are leftover from a previous error and should be ignored
    let allDataErrorsWithValues = [] as any;
    allDataErrors.forEach((el) => {
      const dataErrorAttribute = el.getAttribute("data-error");
      // Check for the error_text class to ensure it's a shown field error
      const isFieldError = el.classList.contains("error_text");
      if (dataErrorAttribute && isFieldError) {
        allDataErrorsWithValues.push(el);
      }
    });
    if (allDataErrorsWithValues.length > 0) {
      const firstDataError = allDataErrorsWithValues[0] as HTMLElement;
      firstDataError.scrollIntoView({ behavior: "smooth", block: "center" });
      M.toast({
        html: INTL.formatMessage({
          id: "dynamic_onboarding.confirmation_modal.errors_still_present_in_section",
          defaultMessage: "There are still errors for you to resolve.",
        }),
        classes: "centered",
        displayLength: 2000,
      });
      return;
    }
  }

  return (
    <>
      <div id="modalChecklistComplete" className="modal">
        <div className="col s12 right-align mt-10">
          <i className="material-icons close modal-close">close</i>
        </div>
        <div className="col s12">
          <p>
            {INTL.formatMessage({
              id: "dynamic_onboarding.confirmation_modal.message_one",
              defaultMessage: "Please confirm that you are happy with your responses.",
            })}{" "}
            <br />
            {INTL.formatMessage({
              id: "dynamic_onboarding.confirmation_modal.message_two",
              defaultMessage:
                "Once you submit you will no longer be able to change them.",
            })}
          </p>
          <div className="valign-wrapper">
            <button className="btn cancelS modal-close">
              {INTL.formatMessage({
                id: "dynamic_onboarding.confirmation_modal.cancel",
                defaultMessage: "Cancel",
              })}
            </button>
            <button className="btn checklistSubmit" onClick={() => submitStage()}>
              {INTL.formatMessage({
                id: "dynamic_onboarding.confirmation_modal.submit",
                defaultMessage: "Submit",
              })}
            </button>
          </div>
          <div ref={loadSubmitRef} id="loadSubmit" className="progress hide">
            <div className="indeterminate" />
          </div>
        </div>
      </div>
      <div id="onboarding_checklist_header">
        <SECTION_DISPLAY_CONTEXTS.Provider value={{ updateActiveSection }}>
          <ScrollableSectionsDisplay sections={sections} />
        </SECTION_DISPLAY_CONTEXTS.Provider>
      </div>
      <div id="onboarding_checklist_body">
        {sections ? (
          <FORM_CONTEXTS.Provider
            value={{ handleSectionSubmission, sections, updateSectionData }}>
            <OnboardingChecklistSection
              section={sections[activeIndex]}
              nonFieldErrors={nonFieldErrors}
            />
          </FORM_CONTEXTS.Provider>
        ) : null}
      </div>
    </>
  );
};
