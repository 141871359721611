import React from "react";
import { DatePartLocalised } from "../utility/date_part_localised";

export const MonthYearDropdownULEl = (props) => {
  return (
    <ul id={props.id} className={props.classes}>
      {props.listMonths.map((date) => (
        <li key={date}>
          <span value={date} onClick={() => props.updateOnClick(date)}>
            <DatePartLocalised
              date={date}
              options={{ month: "long", year: "numeric" }}
            />
          </span>
        </li>
      ))}
    </ul>
  );
};
