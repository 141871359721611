import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import InputSelect from "./select";
import * as actions from "../../actions";
import { FormattedMessage } from "react-intl";
import { LocaleContext } from "../../global_contexts";

const FilterPhotos = (props) => {
  const { locale } = useContext(LocaleContext);
  const [listRooms, setListRooms] = useState(
    JSON.parse(localStorage.getItem("listRooms"))
  );

  useEffect(() => {
    setListRooms(JSON.parse(localStorage.getItem("listRooms")));
  }, [locale]);

  function getSelected(filter, value) {
    props.sendData(filter, value);
    switch (true) {
      case filter === "selectedRoom":
        const roomList = [
          ...document.querySelectorAll("#container-photos > div > div"),
        ];
        if (value.value !== "all") {
          roomList.map((el) => {
            if (el.classList[0] !== value.value) {
              el.classList.add("hide");
            } else {
              el.classList.remove("hide");
            }
            return null;
          });
        } else {
          roomList.map((el) => el.classList.remove("hide"));
        }
        break;
      case filter === "selectedPastDate":
        const arrTextPast = [...document.querySelectorAll(".originalFilter")];
        arrTextPast.map((el) => (el.innerHTML = value.label));
        localStorage.setItem("originalFilter", value.value);
        props.filterOriginalPhotos(value.value);
        break;
      case filter === "selectedRecentDate":
        const arrTextRecent = [...document.querySelectorAll(".recentFilter")];
        arrTextRecent.map((el) => (el.innerHTML = value.label));
        localStorage.setItem("recentFilter", value.value);
        props.filterRecentPhotos(value.value);
        break;
      default:
        break;
    }
  }

  return (
    <div id="filter-photos">
      <div className="row">
        {listRooms && (
          <div className="col s12 m4">
            <InputSelect
              sendSelected={getSelected}
              handleChange="selectRoom"
              defaultOption={listRooms[0]}
              options={listRooms}
            />
          </div>
        )}
        <div className="col s12 m8">
          {props.list_dates && (
            <div className="inline-input">
              <span>
                <FormattedMessage
                  id="photos.filter_photos.compare"
                  defaultMessage="Compare"
                />
              </span>
              <InputSelect
                sendSelected={getSelected}
                handleChange="selectPastDate"
                className="custom-select"
                defaultOption={props.list_dates[0]}
                options={props.list_dates}
              />
              <span>
                <FormattedMessage id="photos.filter_photos.vs" defaultMessage="vs" />
              </span>
              <InputSelect
                sendSelected={getSelected}
                handleChange="selectRecentDate"
                className="custom-select"
                defaultOption={
                  props.list_dates.length > 1
                    ? props.list_dates[1]
                    : props.list_dates[0]
                }
                options={props.list_dates}
              />
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <hr className="split-line" />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    list_dates: JSON.parse(localStorage.getItem("listDates")),
    original_filter: state.photos.original_filter,
    recent_filter: state.photos.recent_filter,
  };
}

export default connect(mapStateToProps, actions)(FilterPhotos);
