// support for ie
import "nodelist-foreach-polyfill";
import "react-app-polyfill/ie9";
import "core-js/stable";
import "regenerator-runtime/runtime";
import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { unregister } from "./registerServiceWorker";
import { BrowserRouter } from "react-router-dom";
import { GlobalHistory } from "../src/components/utility/history";
import { store } from "./store";
import config from "./config";
import IncludeSentry from "./sentry";
import Analytics from "react-router-ga";
// todo - fix eslint warning properly
// eslint-disable-next-line
import M from "materialize-css/dist/js/materialize.js";
import ScrollToTop from "../src/components/utility/scroll_to_top";
import App from "./App";
import { IntlProvider } from "react-intl";
import messages_es from "./translations/es.json";
import {
  AvailabilityWindowContext,
  HostContext,
  LocaleContext,
  PreferredLanguageContext,
  PropertyContext,
  PropertyIDContext,
} from "./global_contexts";

if (config.ENABLE_SENTRY) {
  IncludeSentry();
}

// All available translations
const messages = {
  es: messages_es,
};

export const AnalyticsRoute = () => {
  // Host Context States
  const [hostID, setHostID] = useState("");
  const [hostName, setHostName] = useState("");
  const [locale, setLocale] = useState("en");
  const [preferredLanguage, setPreferredLanguage] = useState("en");
  // Property Context States
  const [propertyID, setPropertyID] = useState(null);
  const [propertyName, setPropertyName] = useState("");
  const [propertyCountry, setPropertyCountry] = useState(
    localStorage.getItem("country")
  );
  // Other Context States
  const [availabilityWindow, setAvailabilityWindow] = useState("");

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const splitPathName = window.location.pathname.split("/");
    if (user && user.is_manager && splitPathName.includes("access")) {
      // manager is logged in and access path is present
      const accessID = splitPathName[2];
      localStorage.setItem("property_id", accessID);
      setPropertyID(parseInt(accessID));
      // In case manager user is logged in but an error occurs and they are redirected to the login page
      // save access ID to local storage for later usage
      localStorage.setItem("access_id", splitPathName[2]);
    } else if (user && !propertyID) {
      // user is logged in but propertyID hook is not set
      // Set default property ID
      setPropertyID(localStorage.getItem("property_id"));
    }
  });

  useEffect(() => {
    setLocale(preferredLanguage);
  }, [preferredLanguage]);

  return (
    <HostContext.Provider value={{ hostID, setHostID, hostName, setHostName }}>
      <PreferredLanguageContext.Provider
        value={{ preferredLanguage, setPreferredLanguage }}>
        <LocaleContext.Provider value={{ locale, setLocale }}>
          <PropertyContext.Provider
            value={{
              propertyName,
              setPropertyName,
              propertyCountry,
              setPropertyCountry,
            }}>
            <PropertyIDContext.Provider value={{ propertyID, setPropertyID }}>
              <AvailabilityWindowContext.Provider
                value={{ availabilityWindow, setAvailabilityWindow }}>
                <IntlProvider locale={locale} messages={messages[locale]}>
                  {config.GA_ID ? (
                    <Analytics id={`${config.GA_ID}`}>
                      <div>
                        <GlobalHistory />
                        <ScrollToTop>
                          <App />
                        </ScrollToTop>
                      </div>
                    </Analytics>
                  ) : (
                    <div>
                      <GlobalHistory />
                      <ScrollToTop>
                        <App />
                      </ScrollToTop>
                    </div>
                  )}
                </IntlProvider>
              </AvailabilityWindowContext.Provider>
            </PropertyIDContext.Provider>
          </PropertyContext.Provider>
        </LocaleContext.Provider>
      </PreferredLanguageContext.Provider>
    </HostContext.Provider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AnalyticsRoute />
    </BrowserRouter>
  </Provider>
);
unregister();
