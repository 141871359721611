import React, { useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import "react-month-picker-input/dist/react-month-picker-input.css";
import { PropertyIDContext } from "../../global_contexts";
import { getCalendarRange } from "../calendar/calendar_service";
import { getISOLocalDate } from "../calendar/dates";
import { MonthYearDropdownULEl } from "../calendar/month_year_dropdown";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { DatePartLocalised } from "./date_part_localised";

const M = window.M;

const MonthFromToSelector = (props) => {
  const { propertyID } = useContext(PropertyIDContext);
  const [fromDate, setFromDate] = useState(
    getISOLocalDate(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1))
  );
  const [toDate, setToDate] = useState(
    getISOLocalDate(new Date(new Date().getFullYear(), new Date().getMonth(), 0))
  );
  const [fromListMonths, setFromListMonths] = useState([]);
  const [toListMonths, setToListMonths] = useState([]);
  const monthYearFromDropdownRef = useRef(null);
  const monthYearToDropdownRef = useRef(null);

  useEffect(() => {
    // Update parent component
    compareDates();
    props.onDateChange(fromDate, toDate);
  }, [fromDate, toDate]);

  useEffect(() => {
    getMonthLists();
  }, [fromDate]);

  useEffect(() => {
    getMonthLists();
    M.Dropdown.init(monthYearFromDropdownRef.current);
    M.Dropdown.init(monthYearToDropdownRef.current);
  }, [propertyID]);

  function compareDates() {
    if (fromDate > toDate) {
      let temp_from_date = fromDate;
      handleFromMonthUpdate(toDate);
      handleToMonthUpdate(temp_from_date);
    }
  }

  async function getMonthLists() {
    let list = await getCalendarRange(propertyID);
    list = list.reverse();
    const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1); // ensure we are getting to first of the month for the current date to avoid comparison issues below

    // remove unneeded for FromList
    let fromList = Array.of(...list); // creates a new copy of original list
    let i = fromList.length;
    while (i--) {
      // going downards so when we remove items from the array, it doesn't interfere with the loop
      const DATE = fromList[i];
      if (DATE > currentDate) {
        fromList.splice(i, 1);
      }
    }
    setFromListMonths(fromList);

    // remove unneeded for ToList
    let toList = Array.of(...fromList); // copies current state of fromList
    let j = toList.length;
    const fromDateAsDate = new Date(fromDate); // will always use the first of the month and the list array will always contain the 25th, so this is a safe comparison.
    while (j--) {
      // going downards so when we remove items from the array, it doesn't interfere with the loop
      const DATE = toList[j];
      if (DATE < fromDateAsDate) {
        toList.splice(i, 1);
      }
    }
    setToListMonths(toList);
  }

  function handleFromMonthUpdate(date) {
    const passedDate = new Date(date);
    // gets first day of the month
    const newFromDate = getISOLocalDate(
      new Date(passedDate.getFullYear(), passedDate.getMonth(), 1)
    );
    setFromDate(newFromDate);
  }

  function handleToMonthUpdate(date) {
    const passedDate = new Date(date);
    // gets last day of the month
    const newToDate = getISOLocalDate(
      new Date(passedDate.getFullYear(), passedDate.getMonth() + 1, 0)
    );
    setToDate(newToDate);
  }

  return (
    <div id="month_from_to_selector" className="col s9 m9">
      <MonthYearDropdownULEl
        id="fromMonthDropdown"
        classes="dropdown-content"
        listMonths={fromListMonths}
        updateOnClick={handleFromMonthUpdate}
      />
      <div
        className="form-container"
        data-target="fromMonthDropdown"
        ref={monthYearFromDropdownRef}>
        <span className="p-calendar">
          <Icon icon={ICONS.CALENDAR.d} width={21} height={22} viewBox="0 0 29 30" />
        </span>
        <label className="label-container">
          <span className="text-label">
            <FormattedMessage
              id="month_from_to_selector.from_month"
              defaultMessage="From Month"
            />
          </span>
        </label>
        <button className="month_year_dropdown">
          <DatePartLocalised
            date={fromDate}
            options={{ month: "long", year: "numeric" }}
          />
        </button>
        <span className="dropdown">
          <Icon
            icon={ICONS.DOWN_ARROW.d}
            width={ICONS.DOWN_ARROW.width}
            height={ICONS.DOWN_ARROW.height}
          />
        </span>
      </div>
      <MonthYearDropdownULEl
        id="toMonthDropdown"
        classes="dropdown-content"
        listMonths={toListMonths}
        updateOnClick={handleToMonthUpdate}
      />
      <div
        className="form-container"
        data-target="toMonthDropdown"
        ref={monthYearToDropdownRef}>
        <span className="p-calendar">
          <Icon icon={ICONS.CALENDAR.d} width={21} height={22} viewBox="0 0 29 30" />
        </span>
        <label className="label-container">
          <span className="text-label">
            <FormattedMessage
              id="month_from_to_selector.to_month"
              defaultMessage="To Month"
            />
          </span>
        </label>
        <button className="month_year_dropdown">
          <DatePartLocalised
            date={toDate}
            options={{ month: "long", year: "numeric" }}
          />
        </button>
        <span className="dropdown">
          <Icon
            icon={ICONS.DOWN_ARROW.d}
            width={ICONS.DOWN_ARROW.width}
            height={ICONS.DOWN_ARROW.height}
          />
        </span>
      </div>
    </div>
  );
};

export default MonthFromToSelector;
