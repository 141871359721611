/* Update Types */
export const UPDATE_SERVICEWORKER = "update_serviceworker";
/* -------- */

/* Auth Types */
export const AUTH_USER = "auth_user";
export const UNAUTH_USER = "unauth_user";
export const AUTH_ERROR = "auth_error";
/* -------- */

/* Property Types */
export const SELECTED_PROPERTY = "selected_property";
export const CHARGE_TYPE = "charge_type";
/* -------------- */

/* Calendar Types */
export const CALENDAR_DASHBOARD = "calendar_dashboard";
export const CALENDAR_DATA = "calendar_data";
/* -------------- */

/* User Types */
export const PROFILE = "profile";
export const FEEDBACK = "feedback";
/* ---------- */

/* Photos Types */
export const LIST_PHOTOS = "list_photos";
export const LIST_DATES = "list_dates";
export const ORIGINAL_FILTER = "original_filter";
export const RECENT_FILTER = "recent_filter";
/* ---------- */

/* Checklist Types */
export const PROPERTY_CHECKLIST = "property_checklist";
export const PROPERTY_AMENITIES = "amenities_checklist";
export const ACCOUNT_CHECKLIST = "account_checklist";
export const GUEST_CHECKLIST = "guest_checklist";
export const ONBOARDING_CHECKLIST = "onboarding_checklist";
export const UPDATE_CHECKLIST = "update_checklist";
export const BEDS = "beds";
export const NUMBER_BEDS = "number_beds";
export const CHECKLIST_STATUS = "checklist_status";
export const DIRECT_DEBIT_STATUS = "direct_debit_status";

export const PROPERTY_DETAILS_STATUS = "property_details_status";
export const PROPERTY_AMENITIES_STATUS = "property_amenities_status";
export const ONBOARDING_DETAILS_STATUS = "onboarding_details_status";
export const GUEST_INFO_STATUS = "guest_info_status";
export const ACCOUNT_INFO_STATUS = "account_info_status";
/* ---------- */

/* Direct Debit Types */
export const GENERATE_REDIRECT = "generate_redirect";
export const GENERATE_REDIRECT_ERROR = "generate_redirect_error";
export const CURRENT_STATUS = "current_status";
/* ---------- */
