import React, { useContext, useEffect, useState } from "react";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { backendValidation } from "./backend-validation";
import { rPOST } from "../../actions/api";
import config from "../../config";
import { MATERIALIZE_REF } from "../dynamic_onboarding/contexts";
import SmallSpinner from "../utility/small_spinner";

const ChangePassword = () => {
  const M = useContext(MATERIALIZE_REF);
  const [error, setError] = useState("");
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    M.Modal.init(document.querySelector("#changePWModal"), {
      onCloseEnd: () => {
        setPasswordChanged(false);
      },
    });
  }, []);

  useEffect(() => {
    setError(""); // reset error when modal is closed
    if (passwordChanged) {
      var instance = M.Modal.getInstance(document.querySelector("#changePWModal"));
      instance.close();
      M.toast({
        html: `<div style="position: relative"><div>${passwordChanged}</div></div>`,
        classes: "centered",
      });
    }
  }, [passwordChanged]);

  function handleFormSubmit({ old_password, new_password1, new_password2 }) {
    setShowSpinner(true);
    rPOST(`${config.API_AUTH_ROOT}/password-change/`, {
      old_password,
      new_password1,
      new_password2,
    })
      .then((resp) => {
        setPasswordChanged(resp.data.detail);
        setShowSpinner(false);
      })
      .catch((err) => {
        const passwordChangedError = backendValidation(err.response.data);
        setError(passwordChangedError);
        setShowSpinner(false);
      });
  }

  return (
    <div>
      {/* Modal */}
      <div id="changePWModal" className="modal bottom-sheet">
        <div className="modal-content">
          <div className="change_pw_form login row m-auto">
            <div className="col s12 split-line">
              <h5 className="title">
                <FormattedMessage
                  id="change_password.title"
                  defaultMessage="Change Password"
                />
              </h5>
              <div className="password-criteria">
                <p className="password-criteria-text">
                  <FormattedMessage
                    id="reset_password.criteria.desription"
                    defaultMessage="The criteria for a new password is as follows:"
                  />
                </p>
                <ul className="password-criteria-list">
                  <FormattedMessage
                    id="reset_password.criterias"
                    defaultMessage="
                      <li>Must be more than 8 characters</li>
                      <li>Cannot be purely numeric</li>
                      <li>Must be unique</li>
                      <li>Cannot be related to your personal details</li>"
                    values={{
                      li: (...chunks) => <li>{chunks}</li>,
                    }}
                  />
                </ul>
              </div>
            </div>
            <form
              className="col s12 change_password"
              onSubmit={handleSubmit(handleFormSubmit.bind(this))}>
              <div className="row">
                <div className="input-field col s12">
                  <FormattedMessage
                    id="change_password.current_password"
                    defaultMessage="Current Password">
                    {(placeholder_text) => (
                      <input
                        placeholder={placeholder_text}
                        type="password"
                        autoComplete="current-password"
                        {...register("old_password", { required: true })}
                      />
                    )}
                  </FormattedMessage>
                </div>
                {error && error.old_password && (
                  <div className="col s12 error">{error.old_password[0]}</div>
                )}
                <div className="input-field col s12">
                  <FormattedMessage
                    id="change_password.new_password"
                    defaultMessage="New Password">
                    {(placeholder_text) => (
                      <input
                        placeholder={placeholder_text}
                        type="password"
                        autoComplete="new-password"
                        {...register("new_password1", { required: true })}
                      />
                    )}
                  </FormattedMessage>
                </div>
                <div className="input-field col s12">
                  <FormattedMessage
                    id="change_password.confirm_new_password"
                    defaultMessage="Confirm New Password">
                    {(placeholder_text) => (
                      <input
                        placeholder={placeholder_text}
                        type="password"
                        autoComplete="new-password"
                        {...register("new_password2", { required: true })}
                      />
                    )}
                  </FormattedMessage>
                </div>

                {/* Some errors can only be provided by the server, which will show here */}
                {error && (
                  <div className="col s12 pb-20 error">
                    {error.map((item) => (
                      <p className="text-center">{item}</p>
                    ))}
                  </div>
                )}
                <div className="modal-footer col s12">
                  <button
                    id="changePW"
                    className="btn blue darken-4"
                    action="submit"
                    disabled={showSpinner ? true : false}>
                    {showSpinner ? (
                      <SmallSpinner />
                    ) : (
                      <FormattedMessage
                        id="change_password.submit"
                        defaultMessage="Submit"
                      />
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* end Modal */}
      <a
        className="secondary-button modal-trigger cobalt-blue changepwlink"
        href="#changePWModal">
        <span className="mr-10">
          <Icon icon={ICONS.LOCK_PW.d} width={20} height={20} viewBox="0 0 24 24" />
        </span>
        <FormattedMessage
          id="change_password.change_password"
          defaultMessage="Change Password"
        />
      </a>
    </div>
  );
};

export default ChangePassword;
