import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { numberFormat } from "../utility/currency_format";
import { moneyRates } from "../utility/moneyRates";
import { LocaleContext } from "../../global_contexts";

const GuestCommunication = () => {
  const { locale } = useContext(LocaleContext);
  const [currency, setCurrency] = useState(localStorage.getItem("currency"));

  useEffect(() => {
    if (currency !== localStorage.getItem("currency")) {
      setCurrency(localStorage.getItem("currency"));
    }
  }, [localStorage.getItem("currency")]);

  useEffect(() => {
    document.querySelector(".main-container .container").classList.add("full-width");

    // returned function will be called on component unmount
    return () => {
      document
        .querySelector(".main-container .container")
        .classList.remove("full-width");
    };
  }, []);

  return (
    <div className="great-host-guide-pages">
      <div className="row">
        <div className="col s12">
          <NavLink exact to="/great-host-guide/" className="left-align">
            <span className="mr-10">
              <Icon
                icon={ICONS.BACK_ARROW.d}
                width={ICONS.BACK_ARROW.width}
                height={12}
                viewBox="0 0 10 20"
              />
            </span>
            <span className="cobalt-blue">Back to Guide summary</span>
          </NavLink>
          <h4 className="center-align">Guest Communication</h4>
        </div>
      </div>
      <div className="row top-img-container guest-communication">
        <img
          className="top-img"
          alt="Guest Communication"
          src={require(`../../styles/img/guest-communication@2x-min.jpg`)}
        />
      </div>

      <div className="row">
        <div className="col s12">
          <h5>Guest Messaging</h5>
          <p>
            We aim to respond to all guests as soon as possible and to the best of our
            abilities.
          </p>
          <ul>
            <li>
              The guest communication service on the message board operates between
              9:30am and 11pm. We always aim to respond to all messages within an hour,
              but some more complex enquiries may take longer to resolve.
            </li>
            <li>
              We provide an emergency phone number for in-stay guests to contact us
              outside of normal operating hours. In our experience, urgent issues are
              generally related to problems with check-ins, maintenance requests, and
              cleaning.
            </li>
          </ul>

          <h5>Vetting guests</h5>
          <p>
            As we want to connect you with the best guests, we require potential
            visitors to be ID-verified and also inquire about their general travel
            plans. We prioritise guests who are responsive and forthcoming with their
            answers, or who already have positive reviews. Please note that, due to the
            nature of certain platforms such as Booking.com, it can be tricky to vet
            guests to the same degree. It is for this reason that we have partnered with
            GuardHog to provide you with specialised insurance designed specifically to
            cover short lets via our service.
          </p>

          <h5>Cancellation Policy for Guests</h5>
          <p>
            We apply a "Strict” cancellation policy to all guests on Airbnb, and this is
            non-negotiable. A Strict cancellation policy implies that if a guest were to
            cancel with more than 7 days advance notice, they would get a 50% refund. We
            - and you, too - work hard to attract bookings: Should a guest cancel very
            close to their check in date and get 100% of their booking value back, we
            may not be able to prevent a loss of revenue by securing another booking
            within the available time frame. Please note, that Airbnb has recently added
            a grace period of 48 hours after a booking has been made. Guests are free to
            cancel without penalty during this time.
          </p>

          <h5>Emergency 24/7 Guest Support</h5>
          <p>
            We have a dedicated guest line for emergencies, and it is available 24/7.
          </p>

          <h5>Price negotiation</h5>
          <p>
            Depending on the season, we offer a standardised weekly discount of 15% and
            a standardised monthly discount of 25%. We also aim to leverage booking
            platform discount schemes to maximise occupancy for your property to meet
            local market trends, to help your property attract longer-term bookings for
            the best possible overall revenue. These discounts will also increase your
            visibility on various platforms and provide a boost in relevancy whenever a
            potential guest searches for a property.
          </p>

          <h5>Organising viewings for guests</h5>

          <p>
            Guests wishing to book a property for a longer stay may ask to view it
            first. We can arrange viewings based on our teams' availability, when we use
            a service provider for this task we will disburse the resource cost,
            chargeable at {numberFormat(locale, moneyRates(25, currency), currency, 0)}{" "}
            +VAT.
          </p>

          <h5>Check-ins and Check-outs</h5>

          <p>
            As we input key safes and smart locks, guests can check in at any time after
            the local check in window. We ask all guests to ensure the property is fully
            secured including a check of all windows and doors when they leave.
          </p>

          <h5>Late Check-ins</h5>

          <p>
            Because we utilise portable or wall mounted key safes in conjunction with
            smart locks, guests can check in at any time of the day and night. Our key
            safe is a police approved lockbox and will give you and your guests peace of
            mind.
          </p>

          <h5>Late Check-outs</h5>

          <p>
            We do not allow late check-outs under any circumstances, as this would
            prevent the cleaners from having the access they need. Our cleaning sessions
            are booked in advance from the moment a booking is confirmed. If guests are
            still inside the property, the cleaners will not be able to complete their
            turn-around in time for the next guests to check in.
          </p>
          <p>
            Guests sometimes ask whether they can leave their luggage in the property
            and collect it after it has been cleaned. We don’t allow this, as we’ve had
            cases in the past where the guests come back to collect their luggage and
            then use the bathrooms, leaving them unsanitary for the next guests.
          </p>
          <p>
            When guests ask us whether they can store their luggage in the property
            while it is cleaned, we instead suggest alternative solutions such as the
            luggage services at main train stations or in the city centre.
          </p>

          <h5>We Write The Reviews For You</h5>

          <p>
            Guests are much more likely to leave you a review if you review them first.
            To make this much easier we have developed an automated system to leave
            reviews for your guests a few days after the end of their stay.
          </p>
        </div>
      </div>
    </div>
  );
};

export default GuestCommunication;
