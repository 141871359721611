import { CALENDAR_DASHBOARD, CALENDAR_DATA } from "../actions/types";

export default function calendarReducer(state = {}, action) {
  switch (action.type) {
    case CALENDAR_DASHBOARD:
      return { ...state, calendar_dashboard: action.payload };
    case CALENDAR_DATA:
      return { ...state, calendar_data: action.payload };
    default:
      return state;
  }
}
