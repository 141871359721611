import React, { Component } from "react";
import { Fields, reduxForm, getFormValues, formValueSelector } from "redux-form";
import { connect } from "react-redux";

import { store } from "../../store";
import { NUMBER_BEDS } from "../../actions/types";
import * as actions from "../../actions";
import { SECTIONS } from "./fields";
import FormComponent from "./forms/form_component";
import { saveCheck } from "./forms/common";
import { validatePropertyDetails } from "./forms/validation";
import { compose } from "redux";

const M = (window as any).M;

interface Props {
  checklistValues: any;
  beds: any;
  checklist: any;
  initialValues: any;
  property: any;
  check_status: any;
}

class PropertyDetails extends Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      property_details: {},
      error_beds: {},
      propertyID: localStorage.getItem("property_id")
        ? localStorage.getItem("property_id")
        : undefined,
    };
  }

  displayFields(name: string, selected_val: number | string) {
    if (name === "number_of_beds") {
      let number_beds = selected_val;
      store.dispatch({
        type: NUMBER_BEDS,
        payload: number_beds,
      });
    }
  }

  componentDidUpdate(prevProps: any) {
    Object.keys(this.props.checklistValues).forEach((key) => {
      if (this.props.checklistValues[key] !== prevProps.checklistValues[key]) {
        this.displayFields(key, this.props.checklistValues[key]);
        if (key === "floor" && this.props.checklistValues[key] === "") {
          this.props.checklistValues[key] = undefined;
        }
        if (key === "elevator" && this.props.checklistValues[key] === "") {
          this.props.checklistValues[key] = null;
        }
        this.setState(() => ({ property_details: this.props.checklistValues }));
      }
    });
    if (prevProps.beds !== this.props.beds) {
      this.setState(() => ({ beds: this.props.beds }));
    }
    if (prevProps.checklist !== this.props.checklist) {
      document.querySelectorAll(".materialize-textarea").forEach((el) => {
        M.textareaAutoResize(el);
      });
    }
    if (this.props.initialValues) {
      Object.keys(this.props.initialValues).forEach((key) => {
        var elem = document.querySelector(`select.${key}`);
        if (elem) {
          const optEl = document.querySelector(
            `option[value="${this.props.initialValues[key]}"]`
          );
          if (this.props.initialValues[key] && optEl) {
            optEl.setAttribute("selected", "true");
          } else if (this.props.initialValues[key] === "") {
            const noOptEl = document.querySelector(`option[value=""]`);
            if (noOptEl) {
              noOptEl.setAttribute("selected", "true");
              noOptEl.setAttribute("disabled", "true");
            }
          }
          M.FormSelect.init(elem);
        }
      });
    }
    if (prevProps.property !== this.props.property) {
      if (prevProps.property.id !== this.props.property.id && this.props.property.id) {
        this.setState(() => ({ propertyID: this.props.property.id }));
        validatePropertyDetails(this.state.property_details);
      }
    }
  }

  render() {
    const {
      propertySizeValue,
      floorValue,
      numberBedsValue,
      bedsValue,
    }: any = this.props;
    if (this.state.beds) {
      SECTIONS["Property Details"][1].details[2] = this.state.beds;
    }
    return (
      <form
        id="property_details"
        className={`${
          this.props.check_status && this.props.check_status[0].stage
        } fields-cont`}
        onChange={(e) =>
          saveCheck(e, this.state.property_details, validatePropertyDetails)
        }
        onBlur={(e) =>
          saveCheck(e, this.state.property_details, validatePropertyDetails)
        }>
        <p>
          In this section we are collecting general information about your property.
        </p>
        {this.props &&
          this.props.beds &&
          SECTIONS["Property Details"].map((section, index) => (
            <Fields
              names={section.names}
              component={FormComponent}
              details={section.details}
              key={section.names[index]}
              conditionalValue={[
                {
                  property_size: propertySizeValue,
                  floor: floorValue,
                  number_of_beds: numberBedsValue,
                },
              ]}
              chargeType={undefined}
              propertyID={this.state.propertyID}
              section="property_details"
            />
          ))}
      </form>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    property: state.property.property_info,
    property_checklist: state.checklist.property_checklist,
    update_checklist: state.checklist.update_checklist,
    beds: state.checklist.beds,
    check_status: state.checklist.check_status,
  };
}

const PropertyDetailsForm = reduxForm({
  form: "property_details",
  enableReinitialize: true,
  touchOnChange: true,
  // reset,
  validate: validatePropertyDetails,
});

// Decorate with connect to read form values
const selector = formValueSelector("property_details");

const PropertyDetailsConnect = connect(
  (state: any) => {
    const propertySizeValue = selector(state, "property_size");
    const floorValue = selector(state, "floor");
    const numberBedsValue = selector(state, "number_of_beds");
    return {
      propertySizeValue,
      floorValue,
      numberBedsValue,
      initialValues: state.checklist.property_checklist,
      checklistValues: getFormValues("property_details")(state) || {},
    };
  },
  { actions }
);

const withConnect = connect(mapStateToProps, actions);

export default compose<any>(
  withConnect,
  PropertyDetailsConnect,
  PropertyDetailsForm
)(PropertyDetails);
