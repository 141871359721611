import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../actions";
import { isMobile } from "../common/util";

class Feedback extends Component {
  componentDidMount() {
    this.props.checkAuthState(this.props.history);
    document.querySelector(".main-container .container").classList.add("full-width");
    document.querySelector(".main-container").classList.add("full-height");
  }

  componentWillUnmount() {
    document.querySelector(".main-container .container").classList.remove("full-width");
    document.querySelector(".main-container").classList.remove("full-height");
  }

  render() {
    return (
      <div className="center-align">
        <iframe
          title="Feedback report"
          src="https://docs.google.com/forms/d/e/1FAIpQLScsiBr0Qk4HiLyxenApfcdgzdeP9cXq5wXHDUHXYSW2iYdh5Q/viewform?embedded=true"
          width={window.screen.availWidth - (isMobile() ? 21 : 300)}
          height={window.screen.availHeight - (isMobile() ? 30 : 0)}
          frameBorder="0"
          marginHeight="0"
          marginWidth="0">
          Loading...
        </iframe>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
  };
}

export default connect(mapStateToProps, actions)(Feedback);
