import React, { useEffect, useState } from "react";
import FeedBackForm from "./feedback_form";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { FormattedMessage } from "react-intl";

const ContactUs = () => {
  // Only uses: account_manager, contact_host_phone_number, & contact_host_email
  const [accountManager, setAccountManager] = useState(
    JSON.parse(localStorage.getItem("accountManager"))
  );

  useEffect(() => {
    setAccountManager(JSON.parse(localStorage.getItem("accountManager")));
  }, [localStorage.getItem("accountManager")]);

  return (
    <div>
      <div className="row contact-us mb-0">
        <div className="row">
          <div className="col s12">
            <h4>
              <FormattedMessage id="contact_us.title" defaultMessage="Contact Us" />
            </h4>
          </div>
        </div>
        {accountManager && (
          <div className="row">
            <div className="col s8 m5 l6">
              <div className="img-container">
                {accountManager.account_manager &&
                (accountManager.account_manager.split(" ")[0] === "Angela" ||
                  accountManager.account_manager.split(" ")[0] === "Richard") ? (
                  <img
                    alt="Account manager"
                    src={require(`../../styles/account_manager_photos/${
                      accountManager.account_manager.split(" ")[0]
                    }-min.jpg`)}
                    width="178px"
                    height="178px"
                  />
                ) : (
                  <img
                    alt="Default"
                    src={require(`../../styles/img/default-user-min.png`)}
                    width="178px"
                    height="178px"
                  />
                )}
              </div>
            </div>
            <div className="col s4 m7 l6 right-align">
              <h6>
                <FormattedMessage
                  id="contact_us.your_account_manager"
                  defaultMessage="Your Account Manager"
                />
              </h6>
              <h5 className="pb-10">{accountManager.account_manager}</h5>
              <div className="show-on-medium-and-up hide-on-med-and-down">
                <a
                  href={`tel:${accountManager.contact_host_phone_number}`}
                  className="col s12 fix-display pb-10 pt-10 call px-0">
                  <div>
                    <span className="mr-15">
                      <Icon
                        icon={ICONS.PHONE.d}
                        width={ICONS.PHONE.width}
                        height={ICONS.PHONE.height}
                      />
                    </span>
                    <span className="hide-on-small-only dark-blue">
                      <FormattedMessage id="contact_us.call" defaultMessage="Call" />
                    </span>
                  </div>
                  {accountManager.contact_host_phone_number}
                </a>
                <a
                  href={`mailto:${accountManager.contact_host_email}`}
                  className="col s12 fix-display pt-10 pb-10 px-0 email">
                  <div>
                    <span className="mr-15">
                      <Icon
                        icon={ICONS.EMAIL.d}
                        width={ICONS.EMAIL.width}
                        height={ICONS.EMAIL.height}
                      />
                    </span>
                    <span className="hide-on-small-only dark-blue">
                      <FormattedMessage id="contact_us.email" defaultMessage="E-mail" />
                    </span>
                  </div>
                  <p className="mt-0 mb-0">{accountManager.contact_host_email}</p>
                </a>
              </div>
            </div>
          </div>
        )}
        <div className="row hide-on-med-and-up">
          <a href="tel:442038287523" className="col s12 fix-display pb-10 pt-10 call">
            <div>
              <span className="mr-15">
                <Icon
                  icon={ICONS.PHONE.d}
                  width={ICONS.PHONE.width}
                  height={ICONS.PHONE.height}
                />
              </span>
              <span className="hide-on-small-only dark-blue">
                <FormattedMessage id="contact_us.call" defaultMessage="Call" />
              </span>
            </div>
            {accountManager.contact_host_phone_number}
          </a>
          <a
            href={`mailto:${accountManager.contact_host_email}`}
            className="col s12 fix-display pt-10 pb-10 email">
            <div>
              <span className="mr-15">
                <Icon
                  icon={ICONS.EMAIL.d}
                  width={ICONS.EMAIL.width}
                  height={ICONS.EMAIL.height}
                />
              </span>
              <span className="hide-on-small-only dark-blue">
                <FormattedMessage id="contact_us.email" defaultMessage="E-mail" />
              </span>
            </div>
            <p className="mt-0 mb-0">{accountManager.contact_host_email}</p>
          </a>
        </div>
      </div>
      <div className="row mb-0">
        <div className="col s12 right-align">
          <p>
            <FormattedMessage
              id="contact_us.opening_hours"
              defaultMessage="Our office opening hours are 9:30 - 18:30, Monday - Friday."
            />
          </p>
          <p>
            <FormattedMessage
              id="contact_us.closed_during"
              defaultMessage="Our offices are closed during weekends and Bank Holidays."
            />
          </p>
          <p>
            <FormattedMessage
              id="contact_us.emergency"
              defaultMessage="In case of emergency, you can reach out to our 24/7 guest support line:"
            />
            <a href="tel:442038932230"> (+44) 020 3893 2230.</a>
          </p>
        </div>
      </div>
      <div className="row feedback">
        <div className="col s12 pb-10">
          <h5>
            <FormattedMessage
              id="contact_us.feedback_prompt"
              defaultMessage="Are you happy with us?"
            />
          </h5>
        </div>
        <FeedBackForm />
      </div>
    </div>
  );
};

export default ContactUs;
