import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { numberFormat } from "../utility/currency_format";
import { moneyRates } from "../utility/moneyRates";
import { LocaleContext } from "../../global_contexts";

const MoneyMatters = () => {
  const { locale } = useContext(LocaleContext);
  const [currency, setCurrency] = useState(localStorage.getItem("currency"));

  useEffect(() => {
    if (currency !== localStorage.getItem("currency")) {
      setCurrency(localStorage.getItem("currency"));
    }
  }, [localStorage.getItem("currency")]);

  useEffect(() => {
    document.querySelector(".main-container .container").classList.add("full-width");

    // returned function will be called on component unmount
    return () => {
      document
        .querySelector(".main-container .container")
        .classList.remove("full-width");
    };
  }, []);

  return (
    <div className="great-host-guide-pages">
      <div className="row">
        <div className="col s12">
          <NavLink exact to="/great-host-guide/" className="left-align">
            <span className="mr-10">
              <Icon
                icon={ICONS.BACK_ARROW.d}
                width={ICONS.BACK_ARROW.width}
                height={12}
                viewBox="0 0 10 20"
              />
            </span>
            <span className="cobalt-blue">Back to Guide summary</span>
          </NavLink>
          <h4 className="center-align">Money Matters - The Reason We're Both Here</h4>
        </div>
      </div>
      <div className="row top-img-container money-matters">
        <img
          className="top-img"
          alt="Money"
          src={require(`../../styles/img/money@2x-min.jpg`)}
        />
      </div>
      <div className="row">
        <div className="col s12">
          <h5>The Flow Of Funds on Airbnb….</h5>
          <p>
            This can be a little confusing, so to simplify, the platform processes the
            payment to you 24 hours after the guest checks in, this will arrive with you
            in the following working days depending on your banking provider.
          </p>
          <p>
            For long bookings, the payment will be divided in instalments. Airbnb will
            charge you and the guest a small fee. We’ll then invoice you for the
            management fee and a transfer of the cleaning fee from the guest to us.
          </p>
          <p>
            Please make sure that your Airbnb payout details are correct and up to date,
            as otherwise, Airbnb won’t know where to send the money!
          </p>
          <p>
            Late pay-outs generally only happen if your payout and payment details are
            incorrect or out of date, or if the booking is for longer than 28 nights.
            For this type of longer booking, Airbnb will break the payment down into
            monthly instalments throughout the stay. In addition, if there is a pending
            resolution case, Airbnb might withhold the payment until the case is
            resolved.
          </p>

          <div className="img-container mt-25">
            <img
              className="top-img"
              alt="Money matters flow"
              src={require(`../../styles/img/money-matters-multiplatform@2x-min.png`)}
            />
          </div>

          <h5>...and On The Other Platforms</h5>
          <p>
            If your property is advertised on other platforms, we will receive the funds
            on your behalf. The money (minus fees) are transferred twice a month, based
            on the booking checkout date.
          </p>

          <h5>Invoicing</h5>
          <p>
            We complete invoicing to all our clients on a twice monthly basis, This
            helps everyone to keep on top of money flow! You will receive an email with
            your attached invoice on the evening of the first working day after 15th,
            and the last day of the month.
          </p>
          <p>
            We then process charges on the following day, and schedule any payments due
            to you twice monthly, two weeks in arrears, to ensure we have first received
            any monies generated from the platforms that we are collecting on your
            behalf.
          </p>

          <h5>Cancellations</h5>
          <p>
            Sometimes things can go wrong but our team works hard to get as many
            bookings as possible for you.
          </p>
          <p>
            We will only cancel a booking under extenuating circumstances, and if this
            request was made by yourself, we will disburse any platform fee penalties
            through our invoices.
          </p>
          <p>
            Of course if something goes wrong at our end, you won't be charged for
            cancellations.
          </p>

          <h5>Revenue Management</h5>
          <p>
            Roughly 60% of your potential income is derived from the intrinsic value of
            your property, its location, and its available amenities. The other 40% is
            dependent on implementing an effective pricing strategy.
          </p>
          <p>
            Our pricing strategy takes into account the fluctuating demand for your
            property throughout the year. Our revenue management system is flexible
            enough to set prices for individual days.
          </p>
          <p>
            Your prices and occupancy rate will be checked regularly to ensure the
            highest possible occupancy for your property. Our revenue management
            software takes into consideration seasonality, days of the week, trends,
            events, holiday peaks, occupancy, and rates in the local area. We set a base
            price according to the characteristics of your property, and adjust the
            offered rate between these figures depending on current conditions.
          </p>
          <p>
            The revenue management team will create an Occupancy rate framework for your
            property and take proactive steps to maximise your income.
          </p>
          <p>
            Please note that the rates quoted to you when you signed the contract may be
            lower or higher in practice depending on the factors mentioned above. It
            will also normally take several months to achieve this ‘ideal’ pricing,
            occupancy, and performance.
          </p>
          <p>
            We might reduce rates for last-minute bookings, or apply a discount for a
            long stay, as it is better to pick up a booking at a lower rate than not to
            pick up at all.
          </p>
          <p>
            Please also note that the number of days that are available will have an
            impact on your chances to attract bookings.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MoneyMatters;
