import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";

class GreatHostGuideDashboard extends Component {
  render() {
    return (
      <div data-testid="great_host_guide_dashboard" className="col s12 higher-card">
        <div className="card">
          <NavLink exact to="/great-host-guide/" className="cardHeader">
            <span className="title hide-on-med-and-up">Great Host Guide</span>
            <span className="title hide-on-small-only">
              Great Host Guide - Everything you need to know
            </span>
            <span className="chevron">
              <Icon
                icon={ICONS.CHEVRON.d}
                width={ICONS.CHEVRON.width}
                height={ICONS.CHEVRON.height}
              />
            </span>
          </NavLink>
          <div className="card-content">
            <div className="great-host-guide my-neg">
              <NavLink
                exact
                to="/guest-communication/"
                className="content-el valign-wrapper">
                <span className="title">Guest Communication</span>
                <span className="chevron">
                  <Icon
                    icon={ICONS.CHEVRON.d}
                    width={ICONS.CHEVRON.width}
                    height={ICONS.CHEVRON.height}
                  />
                </span>
              </NavLink>
              <NavLink
                exact
                to="/key-management/"
                className="content-el valign-wrapper">
                <span className="title">Key Management</span>
                <span className="chevron">
                  <Icon
                    icon={ICONS.CHEVRON.d}
                    width={ICONS.CHEVRON.width}
                    height={ICONS.CHEVRON.height}
                  />
                </span>
              </NavLink>
              <NavLink exact to="/cleaning/" className="content-el valign-wrapper">
                <span className="title">Cleaning</span>
                <span className="chevron">
                  <Icon
                    icon={ICONS.CHEVRON.d}
                    width={ICONS.CHEVRON.width}
                    height={ICONS.CHEVRON.height}
                  />
                </span>
              </NavLink>
              <NavLink
                exact
                to="/linen-laundry-and-toiletries/"
                className="content-el valign-wrapper">
                <span className="title">Linen, Laundry and Toiletries</span>
                <span className="chevron">
                  <Icon
                    icon={ICONS.CHEVRON.d}
                    width={ICONS.CHEVRON.width}
                    height={ICONS.CHEVRON.height}
                  />
                </span>
              </NavLink>
              <NavLink exact to="/maintenance/" className="content-el valign-wrapper">
                <span className="title">Maintenance</span>
                <span className="chevron">
                  <Icon
                    icon={ICONS.CHEVRON.d}
                    width={ICONS.CHEVRON.width}
                    height={ICONS.CHEVRON.height}
                  />
                </span>
              </NavLink>
              <NavLink
                exact
                to="/possessions-and-valuables/"
                className="content-el valign-wrapper">
                <span className="title">Possessions &amp; Valuables</span>
                <span className="chevron">
                  <Icon
                    icon={ICONS.CHEVRON.d}
                    width={ICONS.CHEVRON.width}
                    height={ICONS.CHEVRON.height}
                  />
                </span>
              </NavLink>
              <NavLink exact to="/money-matters/" className="content-el valign-wrapper">
                <span className="title">Money Matters</span>
                <span className="chevron">
                  <Icon
                    icon={ICONS.CHEVRON.d}
                    width={ICONS.CHEVRON.width}
                    height={ICONS.CHEVRON.height}
                  />
                </span>
              </NavLink>
              <NavLink
                exact
                to="/airbnb-you-and-us/"
                className="content-el valign-wrapper">
                <span className="title">Airbnb, You &amp; Us</span>
                <span className="chevron">
                  <Icon
                    icon={ICONS.CHEVRON.d}
                    width={ICONS.CHEVRON.width}
                    height={ICONS.CHEVRON.height}
                  />
                </span>
              </NavLink>
              <NavLink
                exact
                to="/a-true-partnership/"
                className="content-el valign-wrapper">
                <span className="title">A True Partnership</span>
                <span className="chevron">
                  <Icon
                    icon={ICONS.CHEVRON.d}
                    width={ICONS.CHEVRON.width}
                    height={ICONS.CHEVRON.height}
                  />
                </span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GreatHostGuideDashboard;
