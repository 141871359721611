import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { displayFullDate } from "../../common/util";
import SmallSpinner from "../utility/small_spinner";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { FormattedMessage } from "react-intl";
import { PropertyIDContext } from "../../global_contexts";
import { rGET } from "../../actions/api";

const ReviewDashboard = (props) => {
  const { propertyID } = useContext(PropertyIDContext);
  const [dashboardReviews, setDashboardReviews] = useState(null);

  useEffect(() => {
    if (!props.testMode) {
      setDashboardReviews(null);
      getDashboardReviews();
    }
  }, [propertyID]);

  function getDashboardReviews() {
    rGET(`/reviews/${propertyID}/dashboard/`).then((resp) => {
      const reviewsDashboard = resp && resp.data.results;
      setDashboardReviews(reviewsDashboard);
    });
  }

  return (
    <div className="col s6">
      <NavLink exact to="/reviews/">
        <div className="card">
          <div className="cardHeader">
            <span className="title">
              <FormattedMessage id="dashboard.review.title" defaultMessage="Reviews" />
            </span>
            <span className="chevron hide-on-small-only">
              <Icon
                icon={ICONS.CHEVRON.d}
                width={ICONS.CHEVRON.width}
                height={ICONS.CHEVRON.height}
              />
            </span>
          </div>
          <div className="card-content">
            {dashboardReviews ? (
              <div className="reviews">
                {dashboardReviews.length > 0 ? (
                  <div>
                    <div className="row">
                      <div className="col s6 m8 name">
                        {dashboardReviews[dashboardReviews.length - 1].guest_name}
                      </div>
                      <div className="col s6 m4 right-align">
                        {dashboardReviews[dashboardReviews.length - 1].platform}
                      </div>
                    </div>
                    <div className="stars hide-on-small-only">
                      <Icon
                        icon={ICONS.STAR.d}
                        width={ICONS.STAR.width}
                        height={ICONS.STAR.height}
                        color="#ec9a3a"
                      />
                      <Icon
                        icon={ICONS.STAR.d}
                        width={ICONS.STAR.width}
                        height={ICONS.STAR.height}
                        color="#ec9a3a"
                      />
                      <Icon
                        icon={ICONS.STAR.d}
                        width={ICONS.STAR.width}
                        height={ICONS.STAR.height}
                        color="#ec9a3a"
                      />
                      <Icon
                        icon={ICONS.STAR.d}
                        width={ICONS.STAR.width}
                        height={ICONS.STAR.height}
                        color="#ec9a3a"
                      />
                      <Icon
                        icon={ICONS.STAR.d}
                        width={ICONS.STAR.width}
                        height={ICONS.STAR.height}
                        color="#ec9a3a"
                      />
                    </div>
                    <div className="stars hide-on-med-and-up">
                      <Icon
                        icon={ICONS.STAR.d}
                        width={23}
                        height={19}
                        viewBox="0 0 30 26"
                        color="#ec9a3a"
                      />
                      <Icon
                        icon={ICONS.STAR.d}
                        width={23}
                        height={19}
                        viewBox="0 0 30 26"
                        color="#ec9a3a"
                      />
                      <Icon
                        icon={ICONS.STAR.d}
                        width={23}
                        height={19}
                        viewBox="0 0 30 26"
                        color="#ec9a3a"
                      />
                      <Icon
                        icon={ICONS.STAR.d}
                        width={23}
                        height={19}
                        viewBox="0 0 30 26"
                        color="#ec9a3a"
                      />
                      <Icon
                        icon={ICONS.STAR.d}
                        width={23}
                        height={19}
                        viewBox="0 0 30 26"
                        color="#ec9a3a"
                      />
                    </div>
                    <div className="date-review hide-on-small-only">
                      <FormattedMessage
                        id="dashboard.review.reviewed_on"
                        defaultMessage="Reviewed on"
                      />{" "}
                      {displayFullDate(
                        dashboardReviews[dashboardReviews.length - 1].created.slice(
                          0,
                          10
                        )
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <FormattedMessage
                      id="dashboard.review.no_reviews"
                      defaultMessage="There have been no reviews yet!"
                    />
                  </div>
                )}
              </div>
            ) : (
              <SmallSpinner />
            )}
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default ReviewDashboard;
