import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import * as actions from "../../actions";
import SignUpComponent from "./sign_up";
import {
  LocaleContext,
  PreferredLanguageContext,
  PropertyContext,
  PropertyIDContext,
} from "../../global_contexts";
import { useForm } from "react-hook-form";
import { backendValidation } from "./backend-validation";
import { rPOST } from "../../actions/api";
import config from "../../config";

export const ResetPasswordConfirm = (props) => {
  const INTL = useIntl();
  const { setPreferredLanguage } = useContext(PreferredLanguageContext);
  const { setPropertyCountry } = useContext(PropertyContext);
  const { setPropertyID } = useContext(PropertyIDContext);
  const [arrURL, setArrURL] = useState(
    props.history.location.pathname.split("/").slice(1, 4)
  );
  const [passwordResetConfirmError, setPasswordResetConfirmError] = useState(null);
  const resetPWConfirmRef = useRef(null);
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    resetPWConfirmRef.current.disabled = false;
  }, [resetPWConfirmRef, passwordResetConfirmError]);

  useEffect(() => {
    setArrURL(props.history.location.pathname.split("/").slice(1, 4));
  }, [props.history.location.pathname]);

  function handleFormSubmit({ new_password1, new_password2 }) {
    var uid = arrURL[1];
    var token = arrURL[2];
    if (new_password1 === undefined) {
      new_password1 = document.querySelector("[name='new_password1']").value;
    }
    if (new_password2 === undefined) {
      new_password2 = document.querySelector("[name='new_password2']").value;
    }
    resetPasswordConfirm({ uid, token, new_password1, new_password2 });
  }

  // reset password confirm
  function resetPasswordConfirm(data) {
    rPOST(`${config.API_AUTH_ROOT}/password-reset-confirm/`, data)
      .then((resp) => {
        // Below not used, but kept for reference
        // const password_reset_confirm = resp.data.detail;
        const password = data.new_password1;
        const email = resp.data.email;
        props.loginUser(
          { email, password },
          props.history,
          { INTL },
          { setPropertyID },
          { setPreferredLanguage, setPropertyCountry }
        );
      })
      .catch((err) => {
        setPasswordResetConfirmError(backendValidation(err.response.data));
      });
  }

  return (
    <div className="row login">
      {arrURL[0] === "register" && <SignUpComponent />}
      <div className="col s12 split-line">
        <h5 className="title">
          <FormattedMessage id="reset_password.title" defaultMessage="Set Password" />
        </h5>
        <div className="password-criteria">
          <p className="password-criteria-text">
            <FormattedMessage
              id="reset_password.criteria.desription"
              defaultMessage="The criteria for a new password is as follows:"
            />
          </p>
          <ul className="password-criteria-list">
            <FormattedMessage
              id="reset_password.criterias"
              defaultMessage="
                <li>Must be more than 8 characters</li>
                <li>Cannot be purely numeric</li>
                <li>Must be unique</li>
                <li>Cannot be related to your personal details</li>"
              values={{
                li: (...chunks) => <li>{chunks}</li>,
              }}
            />
          </ul>
        </div>
      </div>
      <form
        className="col s12 reset-password-confirm"
        onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="row">
          <div className="input-field col s12">
            <FormattedMessage
              id="reset_password.new_password1.placeholder"
              defaultMessage="Password">
              {(placeholder) => (
                <input
                  placeholder={placeholder}
                  type="password"
                  {...register("new_password1", { required: true })}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="input-field col s12">
            <FormattedMessage
              id="reset_password.new_password2.placeholder"
              defaultMessage="Confirm Password">
              {(placeholder) => (
                <input
                  placeholder={placeholder}
                  type="password"
                  {...register("new_password2", { required: true })}
                />
              )}
            </FormattedMessage>
          </div>

          {/* Some errors can only be provided by the server, which will show here */}
          {passwordResetConfirmError?.length > 0 ? (
            <div className="col s12 pb-20 error">
              {passwordResetConfirmError.map((item) => (
                <p className="text-center">{item}</p>
              ))}
            </div>
          ) : null}

          <div className="col s12">
            <button
              ref={resetPWConfirmRef}
              id="resetPWConfirm"
              className="btn"
              action="submit">
              <FormattedMessage id="reset_password.submit" defaultMessage="Submit" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default withRouter(connect(null, actions)(ResetPasswordConfirm));
