import React, { Key, useContext, useEffect, useRef, useState } from "react";
import { rPATCH } from "../../../actions/api";
import { PropertyIDContext } from "../../../global_contexts";
import { FORM_CONTEXTS, MATERIALIZE_REF } from "../contexts";
import {
  BedsField,
  ChoiceField,
  DataField,
  DataFieldType,
  DocumentsField,
  MultiSelectField,
  NumericField,
  TextField,
} from "../schema";
import { InputAccountNumberComponent } from "./inputs/account_number";
import { InputBICComponent } from "./inputs/bic";
import { InputButtonComponent } from "./inputs/button";
import { InputCheckboxComponent } from "./inputs/checkbox";
import { DirectDebitComponent } from "./inputs/direct_debit";
import { InputEmailComponent } from "./inputs/email";
import { InputIBANComponent } from "./inputs/iban";
import { InputNIFComponent } from "./inputs/nif";
import { InputPasswordComponent } from "./inputs/password";
import { InputRadioComponent } from "./inputs/radio";
import { InputRichTextComponent } from "./inputs/rich_text";
import { InputSelectComponent } from "./inputs/select";
import { InputSortCodeComponent } from "./inputs/sort_code";
import { InputTelephoneComponent } from "./inputs/telephone";
import { InputTextComponent } from "./inputs/text";
import { InputDateComponent } from "./inputs/date";
import { BespokeBedsComponent } from "./bespoke/beds";
import { InputMultiSelectComponent } from "./inputs/select_multiple";
import { PreloaderSpinner, checkDependencyOnLoad } from "../utility";
import { InputNumericComponent } from "./inputs/numeric";
import { BespokeDocumentsComponent } from "./bespoke/documents";

export const OnboardingChecklistField = (props: { field: DataField }) => {
  const { propertyID } = useContext(PropertyIDContext);
  const mData = useContext(MATERIALIZE_REF);
  const [componentToRender, setComponentToRender] = useState<any>(null);
  const [helpText, setHelpText] = useState<any>(null);
  const [fieldError, setFieldError] = useState<any>(null);
  const { updateSectionData } = useContext(FORM_CONTEXTS);
  const [showPreloaderSpinner, setShowPreloaderSpinner] = useState<boolean>(false);
  const [spinnerStatus, setSpinnerStatus] = useState<boolean | null>(null);
  const fieldContainer = useRef<HTMLDivElement | null>(null);

  const onValueChange = (e: any, patchValue: any) => {
    const PATCH_URL = `/onboarding/${propertyID}/stages/checklist/`;
    const fieldKey = props.field.key as Key;
    updateSectionData({ [`${fieldKey}`]: patchValue }, "value");
    setShowPreloaderSpinner(true);
    rPATCH(PATCH_URL, { [`${fieldKey}`]: patchValue })
      .then((resp) => {
        if (e.classList && !e.classList.contains("valid")) {
          e.classList.remove("invalid");
          e.classList.add("validate");
          e.classList.add("valid");
        }
        setFieldError(null);
        const fieldTargetSpan = document.getElementById(
          `${props.field.key}_span`
        ) as any;
        if (fieldTargetSpan.classList.contains("error_text")) {
          fieldTargetSpan.textContent = props.field.help;
          fieldTargetSpan.classList.remove("error_text");
        }
        fieldTargetSpan.setAttribute("data-error", "");
        setSpinnerStatus(true);
        // The time in this timeout should match the animation length for the status_spinner
        setTimeout(() => {
          setShowPreloaderSpinner(false);
          setSpinnerStatus(null);
        }, 1000);
      })
      .catch((error) => {
        if (e.classList && !e.classList.contains("invalid")) {
          e.classList.remove("valid");
          e.classList.remove("validate");
          e.classList.add("invalid");
        }
        const errorMsg =
          error.response &&
          error.response.data &&
          error.response.data[props.field.key][0];
        setFieldError(errorMsg);
        mData.updateTextFields();
        setSpinnerStatus(false);
        setTimeout(() => {
          setShowPreloaderSpinner(false);
          setSpinnerStatus(null);
        }, 1000);
      });
  };

  function getComponentToRender(): JSX.Element {
    switch (props.field.type) {
      case DataFieldType.CHOICE:
        return (
          <InputSelectComponent
            onValueChange={onValueChange}
            field={props.field as ChoiceField}
            key={props.field.key}
          />
        );
      case DataFieldType.SHORT_CHOICE:
        return (
          <InputRadioComponent
            onValueChange={onValueChange}
            field={props.field as ChoiceField}
            key={props.field.key}
          />
        );
      case DataFieldType.BOOLEAN:
        return (
          <InputCheckboxComponent
            onValueChange={onValueChange}
            field={props.field as TextField}
            key={props.field.key}
          />
        );
      case DataFieldType.EMAIL:
        return (
          <InputEmailComponent
            onValueChange={onValueChange}
            setFieldError={setFieldError}
            field={props.field as TextField}
            key={props.field.key}
          />
        );
      case DataFieldType.DIRECT_DEBIT:
        return <DirectDebitComponent key={props.field.key} />;
      case DataFieldType.TELEPHONE:
        return (
          <InputTelephoneComponent
            onValueChange={onValueChange}
            field={props.field as TextField}
            key={props.field.key}
          />
        );
      case DataFieldType.RICH_TEXT:
        return (
          <InputRichTextComponent
            onValueChange={onValueChange}
            field={props.field as TextField}
            key={props.field.key}
          />
        );
      case DataFieldType.GB_ACCOUNT_NUMBER:
        return (
          <InputAccountNumberComponent
            onValueChange={onValueChange}
            setFieldError={setFieldError}
            field={props.field as TextField}
            key={props.field.key}
          />
        );
      case DataFieldType.GB_SORT_CODE:
        return (
          <InputSortCodeComponent
            onValueChange={onValueChange}
            setFieldError={setFieldError}
            field={props.field as TextField}
            key={props.field.key}
          />
        );
      case DataFieldType.IBAN:
        return (
          <InputIBANComponent
            onValueChange={onValueChange}
            setFieldError={setFieldError}
            field={props.field as TextField}
            key={props.field.key}
          />
        );
      case DataFieldType.BIC:
        return (
          <InputBICComponent
            onValueChange={onValueChange}
            setFieldError={setFieldError}
            field={props.field as TextField}
            key={props.field.key}
          />
        );
      case DataFieldType.NIF:
        return (
          <InputNIFComponent
            onValueChange={onValueChange}
            setFieldError={setFieldError}
            field={props.field as TextField}
            key={props.field.key}
          />
        );
      case DataFieldType.DECIMAL:
      case DataFieldType.INTEGER:
      case DataFieldType.NUMERIC:
        return (
          <InputNumericComponent
            onValueChange={onValueChange}
            field={props.field as NumericField}
            key={props.field.key}
          />
        );
      case DataFieldType.PASSWORD:
        return (
          <InputPasswordComponent
            onValueChange={onValueChange}
            field={props.field as TextField}
            key={props.field.key}
          />
        );
      case DataFieldType.DATE:
        return (
          <InputDateComponent
            onValueChange={onValueChange}
            field={props.field as TextField}
            key={props.field.key}
          />
        );
      case DataFieldType.BEDS:
        return (
          <BespokeBedsComponent
            field={props.field as BedsField}
            key={props.field.key}
          />
        );
      case DataFieldType.MULTI_SELECT_CHOICE:
        return (
          <InputMultiSelectComponent
            onValueChange={onValueChange}
            field={props.field as MultiSelectField}
            key={props.field.key}
          />
        );
      case DataFieldType.DOCUMENT:
        return (
          <BespokeDocumentsComponent
            field={props.field as DocumentsField}
            key={props.field.key}
          />
        );
      default:
        return (
          <InputTextComponent
            onValueChange={onValueChange}
            field={props.field as TextField}
            key={props.field.key}
          />
        );
    }
  }

  useEffect(() => {
    setComponentToRender(getComponentToRender());

    setTimeout(() => {
      mData.updateTextFields();
    }, 100);
  }, [props]);

  useEffect(() => {
    if (props.field.help !== helpText) {
      setHelpText(props.field.help);
    }
  }, [props.field.help]);

  useEffect(() => {
    checkDependencyOnLoad(props.field.depends_on, fieldContainer.current);
  }, [props.field.depends_on]);

  return (
    <div
      ref={fieldContainer}
      className={"row"}
      js-value={`${props.field.value}`}
      js-field-key={props.field.key}
      js-depends-on-key={props.field.depends_on?.key}
      js-depends-on-value={
        props.field.depends_on ? `${props.field.depends_on?.value}` : null
      }>
      <div
        className={`input-field ${
          props.field.type === DataFieldType.RICH_TEXT ? "rich_text_field" : ""
        } ${
          [
            DataFieldType.SHORT_CHOICE,
            DataFieldType.BEDS,
            DataFieldType.DOCUMENT,
          ].includes(props.field.type)
            ? "mt-0"
            : ""
        } ${
          [DataFieldType.PASSWORD].includes(props.field.type) ? "has_suffix_icon" : ""
        } col s12`}>
        {props.field.label && [DataFieldType.RICH_TEXT].includes(props.field.type) && (
          <label htmlFor={props.field.key} className="font_active_label">
            {props.field.label}
            {props.field.required && "*"}
          </label>
        )}
        {componentToRender}
        {props.field.label &&
          ![
            DataFieldType.BOOLEAN,
            DataFieldType.SHORT_CHOICE,
            DataFieldType.DIRECT_DEBIT,
            DataFieldType.BEDS,
            DataFieldType.RICH_TEXT,
            DataFieldType.DOCUMENT,
          ].includes(props.field.type) && (
            <label
              htmlFor={props.field.key}
              className={
                [DataFieldType.CHOICE, DataFieldType.MULTI_SELECT_CHOICE].includes(
                  props.field.type
                )
                  ? "font_active_label"
                  : ""
              }>
              {props.field.label}
              {props.field.required && "*"}
            </label>
          )}
        <span
          id={`${props.field.key}_span`}
          className={helpText || fieldError ? "helper-text" : ""}
          data-error={fieldError}>
          {helpText}
        </span>
        {showPreloaderSpinner ? (
          <PreloaderSpinner
            spinnerSize="small"
            spinnerStatus={spinnerStatus}
            spinnerPosition="suffix"
            spinnerColour="orange"
          />
        ) : null}
      </div>
    </div>
  );
};
