import React, { useContext, useState } from "react";
import { rPATCH } from "../../../../actions/api";
import { PropertyIDContext } from "../../../../global_contexts";
import { FORM_CONTEXTS } from "../../contexts";
import { Bed, BedsField } from "../../schema";
import { BespokeBedComponent } from "./bed";

export const BespokeBedsComponent = (props: { field: BedsField }) => {
  const { propertyID } = useContext(PropertyIDContext);
  const [beds, setBeds] = useState(props.field.value || []);
  const { updateSectionData } = useContext(FORM_CONTEXTS);

  const PATCHBedsData = (bedsArr: any) => {
    rPATCH(`/onboarding/${propertyID}/stages/checklist/`, {
      property_beds: bedsArr,
    }).then((response) => {
      setBeds(response.data);
      updateSectionData({ [props.field.key]: response.data }, "value");
    });
  };

  const addBed = (fixed: boolean) => {
    let newBed = { fixed: fixed, size: props.field.options.size_choices[0].value };
    const newBedsArr = [...beds, newBed];
    PATCHBedsData(newBedsArr);
  };

  const removeBed = (bedToRemove: Bed) => {
    const newBedsArr = beds.map((bed: Bed) => {
      if (bed.id === bedToRemove.id) {
        return;
      }
      return bed;
    });

    const newSetOfBeds = newBedsArr.filter((item: any) => item !== undefined);

    PATCHBedsData(newSetOfBeds);
  };

  const updateBed = (bedToUpdate: Bed, newBedSize: string) => {
    bedToUpdate.size = newBedSize;
    let newBedsArr = [...beds];
    newBedsArr.forEach((bed) => {
      if (bed.id === bedToUpdate.id) {
        bed = bedToUpdate;
      }
    });
    PATCHBedsData(newBedsArr);
  };

  return (
    <div className="beds">
      <h6>
        {props.field.label}
        {props.field.required && "*"}
      </h6>
      <span className="scale_active_label">{props.field.options.label_fixed_beds}</span>
      {beds &&
        beds.map((existing_bed_data: Bed) => {
          if (existing_bed_data.fixed) {
            return (
              <BespokeBedComponent
                key={`bed_${existing_bed_data.id}`}
                key_prop={`bed_${existing_bed_data.id}`}
                field={existing_bed_data}
                size_choices={props.field.options.size_choices}
                removeBed={removeBed}
                updateBed={updateBed}
              />
            );
          }
        })}
      <button
        type="button"
        className="waves-effect waves-light btn"
        onClick={() => addBed(true)}>
        {props.field.options.label_add_button}
      </button>
      <span className="scale_active_label">
        {props.field.options.label_non_fixed_beds}
      </span>
      {beds &&
        beds.map((existing_bed_data: Bed) => {
          if (!existing_bed_data.fixed) {
            return (
              <BespokeBedComponent
                key={`bed_${existing_bed_data.id}`}
                key_prop={`bed_${existing_bed_data.id}`}
                field={existing_bed_data}
                size_choices={props.field.options.size_choices}
                removeBed={removeBed}
                updateBed={updateBed}
              />
            );
          }
        })}
      <button
        type="button"
        className="waves-effect waves-light btn"
        onClick={() => addBed(false)}>
        {props.field.options.label_add_button}
      </button>
    </div>
  );
};
