import { AUTH_USER, UNAUTH_USER, AUTH_ERROR } from "../actions/types";

export default function authReducer(state = {}, action) {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        authenticated: true,
        init: true,
        user_info: action.payload,
      };
    case UNAUTH_USER:
      return { ...state, authenticated: false, logout_msg: action.payload };
    case AUTH_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
