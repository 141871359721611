import { createContext } from "react";

export const CalendarFormDataContext = createContext({
  calendarFormData: {},
  setCalendarFormData: () => {},
});

export const BlockingReasonErrorContext = createContext({
  blockingReasonError: {},
  setBlockingReasonError: () => {},
});

export const CalendarDatesContext = createContext({
  calendarDates: {},
  setCalendarDates: () => {},
});
