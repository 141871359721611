import React, { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { formatDateDDMMYYYY, getNextDate } from "../../common/util";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { numberFormat } from "../utility/currency_format";
import { LocaleContext } from "../../global_contexts";

let currency = localStorage.getItem("currency");
export const calculateDate = (date) => {
  const d = date && formatDateDDMMYYYY(date);
  return d ? d : "-";
};

export const calculateFee = (locale, fee, vat, cleaning_fee = false) => {
  if (cleaning_fee) {
    const clean_fee = parseFloat(fee);
    return isNaN(clean_fee) ? " - " : numberFormat(locale, clean_fee, currency, 0);
  } else {
    const man_charge = parseFloat(fee);
    const incl_vat = +vat !== 0 ? "(incl. VAT)" : "";
    return isNaN(man_charge) ? " - " : `${man_charge}% ${incl_vat}`;
  }
};

const feeComponent = (locale, chargeBlock, vat, regular_fee, cleaning = false) => {
  const noChargeblockFee = cleaning
    ? calculateFee(locale, regular_fee, vat, true)
    : calculateFee(locale, regular_fee, vat);
  if (chargeBlock) {
    const untilDate = calculateDate(chargeBlock.end_date);
    const fromDate = formatDateDDMMYYYY(
      getNextDate(1, false, chargeBlock.end_date).toISOString()
    );
    if (cleaning) {
      return (
        <td>
          <p className="mt-0 mb-0">
            {calculateFee(locale, chargeBlock.cleaning_fee, 0, true)} discounted until{" "}
            {untilDate}
          </p>
          <p className="mb-0">
            {noChargeblockFee} from {fromDate}
          </p>
        </td>
      );
    } else {
      return (
        <td>
          <p className="mt-0 mb-0">
            {calculateFee(locale, chargeBlock.management_charge, vat)} discounted until{" "}
            {untilDate}
          </p>
          <p className="mb-0">
            {noChargeblockFee} from {fromDate}
          </p>
        </td>
      );
    }
  } else {
    return <td>{noChargeblockFee}</td>;
  }
};

const ServiceDetails = (prop) => {
  const { locale } = useContext(LocaleContext);
  useEffect(() => {
    currency = localStorage.getItem("currency");
  });
  return (
    <table className={`table-box striped ${prop.single && "one"}`}>
      <thead className="thead-box">
        <tr>
          <th colSpan="2">
            <div className="icon-agreement valign-wrapper">
              <Icon
                icon={ICONS.AGREEMENT.d}
                width={75}
                height={38}
                viewBox="50 18 165 80"
                color="#fff"
              />
              <span>{prop.property.property_name}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <FormattedMessage
              id="profile.service_details.package"
              defaultMessage="Package"
            />
          </td>
          <td>{prop.property.package}</td>
        </tr>
        <tr>
          <td className={prop.property.management_charge_block && "top"}>
            <FormattedMessage
              id="profile.service_details.management_fee"
              defaultMessage="Management fee"
            />
          </td>
          {feeComponent(
            locale,
            prop.property.management_charge_block,
            prop.property.vat_amount,
            prop.property.management_charge
          )}
        </tr>
        <tr>
          <td className={prop.property.cleaning_charge_block && "top"}>
            <FormattedMessage
              id="profile.service_details.cleaning_fee"
              defaultMessage="Cleaning fee"
            />
          </td>
          {feeComponent(
            locale,
            prop.property.cleaning_charge_block,
            0,
            prop.property.cleaning_fee,
            true
          )}
        </tr>
        <tr>
          <td>
            <FormattedMessage
              id="profile.service_details.service_provider"
              defaultMessage="Service Provider"
            />
          </td>
          <td>{prop.property.service_provider}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default ServiceDetails;
