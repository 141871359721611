import React, { useContext, useState } from "react";
import Select from "react-select";
import "react-select/dist/react-select.css";
import { connect } from "react-redux";
import * as actions from "../actions";
import { rGET } from "../actions/api";
import { FormattedMessage } from "react-intl";
import { PropertyIDContext } from "../global_contexts";

const BackDoor = (props) => {
  const { setPropertyID } = useContext(PropertyIDContext);
  const [height, setHeight] = useState({ height: "150px" });

  function createList(results) {
    let json = [];
    results.map((result, index) => {
      json[index] = { value: result.property_id, label: result.value };
      return json[index];
    });
    return json;
  }

  const getOptions = (input, callback) => {
    let json = [];
    if (input.length > 3) {
      return rGET(`/search-auto-complete/${input}/`)
        .then((response) => {
          json = createList(response.data.results);
          const height = json.length * 35 + "px";
          setHeight({ height: height });
          return { options: json };
        })
        .catch(() => {
          json = [{ value: "not_found", label: "Not Found" }];
          return { options: json };
        });
    }
    setTimeout(function () {
      callback(null, json);
    }, 5000);
  };

  const handleChange = (selectedOption) => {
    if (selectedOption && selectedOption !== "Search for a property or a client") {
      localStorage.setItem("property_id", JSON.stringify(selectedOption.value));
      setPropertyID(selectedOption.value);
      props.history.push("/");
    }
  };

  return (
    <div className="row">
      <div className="col s12 mt-20">
        <div className="row">
          <div className="col s12" style={height}>
            <FormattedMessage
              id="backdoor.search"
              defaultMessage="Search for a property or a client">
              {(placeholder_text) => (
                <Select.Async
                  name="form-field-name"
                  autoload={false}
                  placeholder={placeholder_text}
                  loadOptions={getOptions}
                  onChange={handleChange}
                  resetValue={""}
                />
              )}
            </FormattedMessage>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    property: state.property.property_info,
    profile: state.user.profile,
  };
}

export default connect(mapStateToProps, actions)(BackDoor);
