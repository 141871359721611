import React, { Component } from "react";
import { Field, FieldArray, reduxForm, formValueSelector, reset } from "redux-form";
import { connect } from "react-redux";

import * as actions from "../../actions";
import { debounce } from "../../common/util";
import { store } from "../../store";
import { BEDS } from "../../actions/types";
import { compose } from "redux";

var fields_info: any;

interface Props {
  updateChecklistInfo: any;
  bedsValues: any;
  number_beds: any;
  reset: Function;
  beds: any;
}

class Beds extends Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.updateChecklist = debounce(this.updateChecklist, 4000);
  }

  updateChecklist(data: any) {
    const propertyID = localStorage.getItem("property_id");
    data["beds_info"]["beds"].map((bed: any) => {
      bed.fixed = bed.fixed === "yes" ? true : bed.fixed === "no" ? false : bed.fixed;
      bed.size = bed.size === null ? "" : bed.size;
      return bed;
    });
    this.props.updateChecklistInfo(propertyID, data);
  }

  componentDidUpdate(prevProps: any) {
    this.props.bedsValues &&
      Object.keys(this.props.bedsValues).forEach((key) => {
        if (
          prevProps.bedsValues &&
          this.props.bedsValues[key] !== prevProps.bedsValues[key]
        ) {
          let bed = this.props.bedsValues[key];
          if (bed.id) {
            bed.id = null;
          }
          if (fields_info) {
            let beds = fields_info.getAll();
            store.dispatch({
              type: BEDS,
              payload: beds,
            });
            this.updateChecklist({ beds_info: { beds: beds } });
          }
        }
      });
    if (fields_info && this.props.number_beds) {
      let fields_num = fields_info.getAll().length;
      while (fields_num < this.props.number_beds) {
        fields_info.push({ id: "null", fixed: null, size: "null" });
        fields_num++;
      }
      while (fields_num > this.props.number_beds) {
        fields_info.remove(fields_info.getAll().length - 1);
        fields_num--;
      }
      if (this.props.number_beds === "" || this.props.number_beds === 0) {
        if (fields_info.getAll()) {
          fields_info
            .getAll()
            .map((field: any, index: number) => fields_info.remove(index));
        }
        fields_info = undefined;
      }
    }
  }

  componentWillUnmount() {
    reset("beds");
  }

  render() {
    const renderInputField = (field: any) => {
      let valueField =
        field.input.value === true
          ? "yes"
          : field.input.value === false
          ? "no"
          : field.input.value;
      return (
        <div id={field.input.name}>
          <p className="title">{field.title}</p>
          <p className="radio-cont">
            {this.props.beds &&
              field.options.map((option: any, i: number) => (
                <label key={i} htmlFor={`${option.value}_${field.id}`}>
                  <input
                    {...field.input}
                    id={`${option.value}_${field.id}`}
                    type="radio"
                    name={field.input.name}
                    value={option.value}
                    defaultChecked={valueField === option.value}
                  />
                  <span>{option.name}</span>
                </label>
              ))}
          </p>
        </div>
      );
    };

    let Bed = ({ bed, index, className }: any) => (
      <li key={index} id={bed} className={"beds_info_container " + className}>
        <div className="bed-title">Bed {index + 1}</div>
        <Field
          id={index}
          name={`${bed}.fixed`}
          title="Permanent"
          options={[
            { name: "Yes", value: "yes" },
            { name: "No", value: "no" },
          ]}
          component={renderInputField}
        />
        <Field
          id={index}
          name={`${bed}.size`}
          title="Size"
          options={[
            { name: "Single", value: "Single" },
            { name: "Queen", value: "Queen" },
            { name: "Double", value: "Double" },
            { name: "King", value: "King" },
            { name: "Super King", value: "Super King" },
          ]}
          component={renderInputField}
        />
      </li>
    );

    const renderBeds = ({ fields }: any) => {
      fields_info = fields;
      return (
        <ul id="beds">
          {fields && fields.length > 0 && (
            <p className="helper-text">
              A "permanent" bed is one that is fixed in place, whereas a "non-permanent"
              bed would be a sofa-bed, air-matress, or similar.
            </p>
          )}
          {fields.map((bed: any, index: number) => {
            let classSkipped;
            if (this.props.beds[index]) {
              if (
                this.props.beds[index].fixed === null ||
                this.props.beds[index].fixed === "null"
              )
                classSkipped = "skippedBed";
              if (
                this.props.beds[index].size === null ||
                this.props.beds[index].size === "null"
              )
                classSkipped = "skippedBed";
            }
            return (
              <Bed
                bed={bed}
                fields={fields}
                index={index}
                key={index}
                className={classSkipped}
              />
            );
          })}
        </ul>
      );
    };

    return <FieldArray name="beds" component={renderBeds} />;
  }
}

const selector = formValueSelector("beds");

function mapStateToProps(state: any, props: any) {
  return {
    initialValues: props.info,
    beds: state.checklist.beds,
    number_beds: state.checklist.number_beds,
  };
}

const FormBeds = reduxForm({
  form: "beds",
  enableReinitialize: true,
});

const connectBeds = connect(
  (state) => {
    return {
      bedsValues: selector(state, "beds"),
    };
  },
  { actions }
);

const withConnect = connect(mapStateToProps, actions);

export default compose<any>(withConnect, connectBeds, FormBeds)(Beds);
