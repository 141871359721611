import { getNextDate, validateEmail, toTitleCase } from "../../../common/util";

const validateOnboardingDates = function validate(fields) {
  var errors = {};
  const after_3_days = getNextDate(3).toISOString().slice(0, 10);

  // Iterate only if the fields object actually has keys (there is a 'preload' where validation is triggered with an empty object)
  if (Object.keys(fields).length > 0) {
    Object.keys(fields).forEach((key) => {
      switch (key) {
        case "start_hosting_date":
          if (fields[key] < getNextDate(10).toISOString().slice(0, 10)) {
            errors[key] =
              "The date you start hosting must be at least 10 working days from today";
          }
          break;
        case "handover_date":
          if (fields[key] < after_3_days) {
            errors[key] =
              "The keys handover date must be at least 3 working days from today";
          }
          if (fields["start_hosting_date"] < fields[key]) {
            errors[key] = "The keys handover must take place before you start hosting";
          }
          break;
        case "early_photography_date":
          if (fields[key] < after_3_days) {
            errors[key] =
              "The photography session must be at least 3 working days from today";
          }
          if (
            fields["photography_timing"] === "before_handover_date" &&
            fields[key] >= fields["handover_date"]
          ) {
            errors[key] =
              "The photography session must take place before the keys handover";
          }
          break;
        case "photography_required":
          if (
            !fields["photography_timing"] &&
            [true, "yes_photography_required"].includes(fields[key])
          ) {
            errors["photography_timing"] = "This field is required.";
          }
          break;
        case "first_hosting_period_end_date":
          if (fields["start_hosting_date"]) {
            const noHostingIndefinitely = [false, "no_hosting_indefinitely"].includes(
              fields["hosting_indefinitely"]
            );
            if (
              fields[key] <
                getNextDate(21, false, fields["start_hosting_date"])
                  .toISOString()
                  .slice(0, 10) &&
              noHostingIndefinitely
            ) {
              errors[key] = "Your first hosting period must last at least 3 weeks";
            }
          } else {
            errors[key] = "Please select the date you'd like to start hosting first!";
          }
          break;
        case "own_keysafe":
          const keySafeMounts = ["rail_mounted", "wall_mounted"];
          const yesOwnKeysafe = [true, "yes_own_keysafe"];
          const noOwnKeysafe = [false, "no_own_keysafe"];
          const ownedKeysafeType = fields["owned_keysafe_type"];

          if (
            (noOwnKeysafe.includes(fields[key]) &&
              keySafeMounts.includes(ownedKeysafeType)) ||
            (yesOwnKeysafe.includes(fields[key]) &&
              keySafeMounts.includes(fields["keysafe_type"]))
          ) {
            if (fields["key_sets"] === false) {
              errors["key_sets"] = "Please confirm you have 3 sets of keys for us";
            }
          }
          if (
            yesOwnKeysafe.includes(fields[key]) &&
            keySafeMounts.includes(ownedKeysafeType)
          ) {
            ["keysafe_code", "keysafe_location"].forEach((k) => {
              errors = checkingRequiredField(fields[k], k, errors);
              errors = checkingRequiredFieldMax255Chars(fields[k], k, errors);
            });
          } else if (
            yesOwnKeysafe.includes(fields[key]) &&
            ownedKeysafeType === "smart_lock"
          ) {
            ["smartlock_faq", "smartlock_code"].forEach((k) => {
              errors = checkingRequiredField(fields[k], k, errors);
              errors = checkingRequiredFieldMax255Chars(fields[k], k, errors);
            });
          }
          break;
        case "access_faq":
        case "back_door_faq":
          errors = checkingRequiredField(fields[key], key, errors);
          errors = checkingRequiredFieldMax255Chars(fields[key], key, errors);
          break;
        default:
          break;
      }
    });
  }
  return errors;
};

const validateAccountInfo = function validate(formProps) {
  var errors = {};
  const airbnb_account_exists =
    formProps["airbnb_account_exists"] === true ||
    formProps["airbnb_account_exists"] === "yes_airbnb_account_exists";
  const currency = localStorage.getItem("currency") || "GBP";
  const country = localStorage.getItem("country") || "GB";

  Object.keys(formProps).forEach((key) => {
    switch (key) {
      case "account_number":
        if (currency === "GBP") {
          const accountNumber = formProps[key];
          if (accountNumber.length > 0 && !accountNumber.match(/^\d{7,8}$/)) {
            errors[key] =
              "Min length is 7 digits and max is 8. If your account number is 7 digits long then 0 will be added to the front.";
          }
          errors = checkingRequiredField(accountNumber, key, errors);
        }
        break;
      case "sort_code":
        if (currency === "GBP") {
          const sortCode = formProps[key];
          if (sortCode.length > 0 && !sortCode.match(/^\d{6}$|^(\d{2}-){2}\d{2}$/)) {
            errors[key] = "Please enter you sort code in the format 000000 or 00-00-00";
          }
          errors = checkingRequiredField(sortCode, key, errors);
        }
        break;
      case "iban":
        if (currency !== "GBP") {
          const iban = formProps[key];
          const ibanRegex = new RegExp(
            /(?:((?:IT|SM)\d{2}[A-Z]{1}\d{22})|(NL\d{2}[A-Z]{4}\d{10})|(LV\d{2}[A-Z]{4}\d{13})|((?:BG|GB|IE)\d{2}[A-Z]{4}\d{14})|(GI\d{2}[A-Z]{4}\d{15})|(RO\d{2}[A-Z]{4}\d{16})|(MT\d{2}[A-Z]{4}\d{23})|(NO\d{13})|((?:DK|FI)\d{16})|((?:SI)\d{17})|((?:AT|EE|LU|LT)\d{18})|((?:HR|LI|CH)\d{19})|((?:DE|VA)\d{20})|((?:AD|CZ|ES|MD|SK|SE)\d{22})|(PT\d{23})|((?:IS)\d{24})|((?:BE)\d{14})|((?:FR|MC|GR)\d{25})|((?:PL|HU|CY)\d{26}))$/
          );
          if (iban.length > 0 && !iban.match(ibanRegex)) {
            errors[key] =
              "Please provide a valid IBAN number with the country code at the beginning";
          }
          errors = checkingRequiredField(iban, key, errors);
        }
        break;
      case "bic":
        if (currency !== "GBP") {
          const bic = formProps[key];
          if (
            bic.length > 0 &&
            !bic.match(/^[A-Z0-9]{4}[A-Z]{2}[A-Z0-9]{2}(?:[A-Z0-9]{3})?$/)
          ) {
            errors[key] = "Please enter you BIC code in the format XXXXXXXX";
          }
          errors = checkingRequiredField(bic, key, errors);
        }
        break;
      case "long_let_value":
        const longLetValue = formProps[key];
        if (
          longLetValue !== 0 &&
          longLetValue !== null &&
          longLetValue.length > 0 &&
          (longLetValue < 1 || longLetValue > 32766)
        ) {
          errors[key] = "Please type a valid number.";
        }
        errors = checkingRequiredField(longLetValue, key, errors);
        break;
      case "nif":
        if (country === "ES") {
          const nif = formProps[key];
          if (!nif.match(/^([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])$/)) {
            errors[key] = "Please enter you NIF number in the format XXXXXXXXX";
          }
          errors = checkingRequiredField(nif, key, errors);
        }
        break;
      case "airbnb_email":
      case "airbnb_password":
        if (airbnb_account_exists) {
          if (key === "airbnb_email" && !validateEmail(formProps[key])) {
            errors[key] = "Please type a valid email.";
          }
          errors = checkingRequiredField(formProps[key], key, errors);
          errors = checkingRequiredFieldMax255Chars(formProps[key], key, errors);
        }
        break;
      case "online_id_verified":
      case "offline_id_verified":
      case "profile_picture_added":
        if (formProps[key] === false && airbnb_account_exists) {
          if (key === "online_id_verified") {
            errors[key] = "You must verify your online identity with Airbnb.";
          }
          if (key === "offline_id_verified") {
            errors[key] = "You must verify your offline identity with Airbnb.";
          }
          if (key === "profile_picture_added") {
            errors[key] = "You must have added your Airbnb profile picture.";
          }
        }
        break;
      default:
        break;
    }
  });
  return errors;
};

const validateGuestInfo = function validate(formProps) {
  var errors = {};
  Object.keys(formProps).forEach((key) => {
    switch (key) {
      case "nearest_train_faq":
      case "nearest_bus_faq":
      case "lightbulb_location":
      case "wifi_location":
      case "storage_space_for_guests_location":
        errors = checkingRequiredField(formProps[key], key, errors);
        errors = checkingRequiredFieldMax255Chars(formProps[key], key, errors);
        break;
      case "utensils":
        if (formProps[key] === false) {
          errors[key] = "Please confirm you have cutlery & utensils for guests";
        }
        break;
      default:
        errors = checkingRequiredField(formProps[key], key, errors);
    }
  });
  return errors;
};

const validatePropertyAmenities = function validate(formProps) {
  var errors = {};
  Object.keys(formProps).forEach((key) => {
    switch (key) {
      case "wifi_network_name":
      case "wifi_password":
      case "iron_and_ironing_board_location":
      case "hairdryer_location":
      case "vacuum_cleaner_location":
      case "lightbulb_location":
      case "additional_amenities_faq":
        errors = checkingRequiredField(formProps[key], key, errors);
        errors = checkingRequiredFieldMax255Chars(formProps[key], key, errors);
        break;
      case "fridge":
      case "oven":
      case "smoke_detector":
      case "wifi":
      case "carbon_monoxide_detector":
      case "iron_and_ironing_board":
      case "hairdryer":
      case "vacuum_cleaner":
        if (formProps[key] === false) {
          let err_msg = ["wifi"].includes(key)
            ? "Wifi"
            : ["oven", "iron_and_ironing_board"].includes(key)
            ? `an ${toTitleCase(key.replace(/_/g, " "))}`
            : `a ${toTitleCase(key.replace(/_/g, " "))}`;
          errors[key] = `Your property must have ${err_msg}.`;
        }
        break;
      case "tv":
        if (formProps[key] === "yes_tv" || formProps[key] === true) {
          errors = checkingRequiredField(formProps["tv_faq"], "tv_faq", errors);
          errors = checkingRequiredFieldMax255Chars(
            formProps["tv_faq"],
            "tv_faq",
            errors
          );
        }
        break;
      case "desk":
        if (![false, true, "no_desk", "yes_desk"].includes(formProps[key])) {
          errors[key] = "This field is required.";
        }
        break;
      case "washing_machine_type":
        if (!formProps[key]) {
          errors[key] = "This field is required.";
        }
        break;
      case "electricity_prepaid":
        if ([true, "yes_electricity_prepaid"].includes(formProps[key])) {
          errors[
            key
          ] = `Please contact host@passthekeys.co.uk if you have a pre-paid electricity/ gas meter as we may not be able to manage your property.`;
        }
        break;
      case "utensils":
        if (formProps[key] === false) {
          errors[key] = "Please confirm you have cutlery & utensils for guests";
        }
        break;
      default:
        break;
    }
  });
  return errors;
};

const validatePropertyDetails = function validate(formProps) {
  var errors = {};
  Object.keys(formProps).forEach((key) => {
    switch (key) {
      case "long_let_value":
      case "guest_capacity":
      case "number_of_bedrooms":
      case "number_of_beds":
        if (formProps[key] > 32766 || formProps[key] < 1) {
          errors[key] = "Insert a valid number";
        } else if (!formProps[key]) {
          errors[key] = "This field is required.";
        }
        break;
      case "property_size":
        if (formProps[key] === "apartment") {
          if (!formProps["floor"] || !(-2 < +formProps["floor"] < 500)) {
            errors["floor"] = "This field is required.";
          }
          if (
            formProps["floor"] &&
            (+formProps["floor"] < -1 || +formProps["floor"] > 500)
          ) {
            errors["floor"] = "Please enter a valid number.";
          }
          if (
            ![false, true, "no_elevator", "yes_elevator"].includes(
              formProps["elevator"]
            )
          ) {
            errors["elevator"] = "This field is required.";
          }
        } else if (!formProps[key]) {
          errors[key] = "This field is required.";
        }
        break;
      case "number_of_bathrooms":
        if (
          !formProps[key] ||
          +formProps[key] % 0.5 !== 0 ||
          +formProps[key] === 0 ||
          !/^\d{1,2}(\.5)?$/.test(formProps[key])
        ) {
          errors[key] = "Insert a valid number";
        }
        break;
      default:
        errors = checkingRequiredField(formProps[key], key, errors);
    }
  });
  return errors;
};

function returnTextLengthWithoutWhitespace(text) {
  let textWithoutWhitespace = text.toString().replace(/\s/g, "");
  return textWithoutWhitespace.length;
}

function checkingRequiredField(text, key, errors) {
  if ((text && returnTextLengthWithoutWhitespace(text) === 0) || !text) {
    errors[key] = "This field is required.";
  }
  return errors;
}

function checkingRequiredFieldMax255Chars(text, key, errors) {
  if (text && text.length > 255) {
    errors[key] = "Maximum characters are 255.";
  }
  return errors;
}

export {
  validateGuestInfo,
  validateAccountInfo,
  validateOnboardingDates,
  validatePropertyAmenities,
  validatePropertyDetails,
};
