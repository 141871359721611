import React from "react";
import { FormattedMessage } from "react-intl";
import { ICONS } from "../components/svg/icons";
import Icon from "../components/svg/svg_sprite";
import { DatePartLocalised } from "../components/utility/date_part_localised";

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

function isMobile() {
  let mobMax = 768;
  return window.screen.availWidth <= mobMax;
}

function isSmallScreen() {
  let mobMax = 600;
  return window.screen.availWidth <= mobMax;
}

function greetingForTimeOfDay() {
  var today = new Date();
  var curHr = today.getHours();
  var time =
    curHr < 12 ? (
      <FormattedMessage id="dashboard.title_morning" defaultMessage="Good Morning" />
    ) : curHr < 18 ? (
      <FormattedMessage id="dashboard.title_afternon" defaultMessage="Good Afternoon" />
    ) : (
      <FormattedMessage id="dashboard.title_evening" defaultMessage="Good Evening" />
    );
  return time;
}

function monthDates(...args) {
  var date = new Date();
  var y;
  var m;
  if (args.length === 0) {
    y = date.getFullYear();
    m = date.getMonth();
  } else {
    y = args[0].y;
    m = args[0].m;
  }
  const firstDay = new Date(y, m, 1, 11); //add 11 to avoid ISOString to get previous day
  const lastDay = new Date(y, m + 1, 0, 11);
  return {
    date_start: firstDay.toISOString().slice(0, 10),
    date_end: lastDay.toISOString().slice(0, 10),
  };
}

function displayFullDate(date) {
  return <DatePartLocalised date={date} options={{ dateStyle: "long" }} />;
}

//need to move to a new folder services
function listTypes(name, i) {
  switch (name) {
    case "Supplying Linen":
      return (
        <span key={i} className="responsive-mr">
          <Icon
            icon={ICONS.LINEN.d}
            width={35}
            height={35}
            viewBox="0 0 38 38"
            color="#01095a"
          />
        </span>
      );
    case "Stocking Linen":
      return (
        <span key={i} className="responsive-mr">
          <Icon
            icon={ICONS.LINEN_DELIVERY.d}
            width={35}
            height={35}
            viewBox="0 0 24 24"
            color="#01095a"
          />
        </span>
      );
    case "Cleaning":
      return (
        <span key={i} className="responsive-mr">
          <Icon
            icon={ICONS.CLEANING.d}
            width={35}
            height={35}
            viewBox="0 0 38 38"
            color="#01095a"
          />
        </span>
      );
    case "Consumables":
      return (
        <span key={i} className="responsive-mr">
          <Icon
            icon={ICONS.CONSUMABLES.d}
            width={35}
            height={35}
            viewBox="0 0 38 30"
            color="#01095a"
          />
        </span>
      );
    case "Maintenance":
      return (
        <span key={i} className="responsive-mr">
          <Icon
            icon={ICONS.MAINTENANCE.d}
            width={35}
            height={35}
            viewBox="0 0 38 38"
            color="#01095a"
          />
        </span>
      );
    case "Photography":
      return (
        <span key={i} className="responsive-mr">
          <Icon
            icon={ICONS.PHOTOS.d}
            width={35}
            height={35}
            viewBox="0 0 38 38"
            color="#01095a"
          />
        </span>
      );
    case "Key Safe Code Change":
      return (
        <span key={i} className="responsive-mr">
          <Icon
            icon={ICONS.CODE_CHANGE.d}
            width={35}
            height={35}
            viewBox="0 0 24 24"
            color="#01095a"
          />
        </span>
      );
    case "Greeting":
      return (
        <span key={i} className="responsive-mr">
          <Icon
            icon={ICONS.GREETING.d}
            width={35}
            height={35}
            viewBox="0 0 24 24"
            color="#01095a"
          />
        </span>
      );
    case "Tasking":
      return (
        <span key={i} className="responsive-mr">
          <Icon
            icon={ICONS.TASKING.d}
            width={35}
            height={35}
            viewBox="0 0 24 24"
            color="#01095a"
          />
        </span>
      );
    case "Spot Checking":
      return (
        <span key={i} className="responsive-mr">
          <Icon
            icon={ICONS.SPOT_CHECKING.d}
            width={35}
            height={35}
            viewBox="0 0 24 24"
            color="#01095a"
          />
        </span>
      );
    case "Offboarding":
      return (
        <span key={i} className="responsive-mr">
          <Icon
            icon={ICONS.OFFBOARDING.d}
            width={35}
            height={35}
            viewBox="0 0 24 24"
            color="#01095a"
          />
        </span>
      );
    case "Onboarding":
      return (
        <div key={i} className="responsive-mr">
          <Icon
            icon={ICONS.ONBOARDING.d}
            width={35}
            height={35}
            viewBox="0 0 24 24"
            color="#01095a"
          />
        </div>
      );
    case "Sales":
      return (
        <div key={i} className="responsive-mr">
          <Icon
            icon={ICONS.SALES.d}
            width={35}
            height={35}
            viewBox="0 0 24 24"
            color="#01095a"
          />
        </div>
      );
    case "Storage":
      return (
        <div key={i} className="responsive-mr">
          <Icon
            icon={ICONS.STORAGE.d}
            width={35}
            height={35}
            viewBox="0 0 24 24"
            color="#01095a"
          />
        </div>
      );
    default:
      return null;
  }
}

function changeFormatDate(date) {
  const d = date;
  return d.replace(/-/g, "/");
}

function formatDateDDMMYYYY(date) {
  if (date) {
    const d = new Date(date.slice(0, 10).replace(/-/g, "/"));
    return d.toString() !== "Invalid Date"
      ? `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear().toString().slice(2)}`
      : null;
  } else {
    return "-";
  }
}

function isTokenAboutToExpire(token) {
  var mins = Math.floor(new Date(parseJwt(token).exp * 1000 - Date.now()) / 60000);
  return mins < 5 && mins > 1 ? true : false;
}

function parseJwt(token) {
  return JSON.parse(atob(token.split(".")[1]));
}

//temporary create obj photos
function groupArrBy(arr, key) {
  let result = arr.reduce(function (r, a) {
    r[a[key]] = r[a[key]] || [];
    r[a[key]].push(a);
    return r;
  }, Object.create(null));
  return result;
}

function photosObj(original_photos, recent_photos, group) {
  recent_photos = recent_photos.length > 0 ? recent_photos : original_photos;
  for (let i = 0; i < original_photos.length; i++) {
    if (original_photos[i] && recent_photos[i]) {
      if (original_photos[i].group === recent_photos[i].group) {
        original_photos[i]["recentLink"] = recent_photos[i].link;
      }
    }
  }
  return groupArrBy(original_photos, group);
}

function findElIndex(arr, key, val) {
  return arr.findIndex((el) => el[key] === val);
}

function arrayMoveEl(arr, fromIndex, toIndex) {
  let element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
}

function resizePhotos(photos, width, height) {
  // FIXME: config url
  photos &&
    photos.map((photo) => {
      /* Make it working for both -- production
      'https://s3-eu-west-1.amazonaws.com/ptk-connect' and
       staging 'https://s3-eu-west-1.amazonaws.com/ptk-connect-staging' */
      var match_path_st = /\/ptk-connect-staging\/\w+\/.+/.test(photo["link"]);
      var match_path_pr = /\/ptk-connect\/\w+\/.+/.test(photo["link"]);
      if (match_path_st) {
        photo["link"] = photo["link"].replace(
          "https://s3-eu-west-1.amazonaws.com/ptk-connect-staging",
          "https://t1si3y0rn6.execute-api.eu-west-1.amazonaws.com/production"
        );
      } else if (match_path_pr) {
        photo["link"] = photo["link"].replace(
          "https://s3-eu-west-1.amazonaws.com/ptk-connect",
          "https://t1si3y0rn6.execute-api.eu-west-1.amazonaws.com/production"
        );
      }
      photo["link"] += !height ? `?width=${width}` : `?width=${width}&height=${height}`;
      return photo["link"];
    });
  return photos;
}

function debounce(fn, delay) {
  var timer = null;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function getNextDate(n, workingDate = true, d) {
  let compareDate = d ? new Date(d) : new Date(Date.now());
  if (workingDate) {
    let currDay = compareDate.getDay();
    switch (true) {
      case currDay === 1 || currDay === 2:
        n += n === 3 ? 0 : 4;
        break;
      case currDay === 3 || currDay === 4 || currDay === 5:
        n += n === 3 ? 2 : 4;
        break;
      case currDay === 6:
        n += n === 3 ? 1 : 4;
        break;
      default:
        break;
    }
  }
  let after_n_days = new Date(
    compareDate.getFullYear(),
    compareDate.getMonth(),
    compareDate.getDate() + n,
    compareDate.getHours(),
    compareDate.getMinutes(),
    compareDate.getSeconds()
  );
  return after_n_days;
}

export {
  isMobile,
  monthDates,
  listTypes,
  changeFormatDate,
  displayFullDate,
  formatDateDDMMYYYY,
  isTokenAboutToExpire,
  parseJwt,
  greetingForTimeOfDay,
  toTitleCase,
  isSmallScreen,
  photosObj,
  groupArrBy,
  findElIndex,
  arrayMoveEl,
  resizePhotos,
  getRandomInt,
  debounce,
  validateEmail,
  getNextDate,
};
