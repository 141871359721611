import React, { useState } from "react";
import { TextField } from "../../schema";

export const InputCheckboxComponent = (props: {
  onValueChange: any;
  field: TextField;
}) => {
  const [value, setValue] = useState(props.field.value || "");

  return (
    <div>
      <label>
        <input
          id={props.field.key}
          className="filled-in"
          type="checkbox"
          required={props.field.required}
          checked={value === true ? true : false}
          onChange={(e) => {
            setValue(e.currentTarget.checked);
            props.onValueChange(e.currentTarget, e.currentTarget.checked);
          }}
        />
        <span>
          {props.field.label}
          {props.field.required && "*"}
        </span>
      </label>
    </div>
  );
};
