import React, { useContext, useEffect, useRef, useState } from "react";
import { PreloaderSpinner } from "../../utility";
import { FormattedMessage } from "react-intl";
import { API_URL, rDELETE, rGET, rPOST } from "../../../../actions/api";
import DocViewer, {
  DocViewerRenderers,
  IHeaderOverride,
} from "@cyntler/react-doc-viewer";
import { MATERIALIZE_REF } from "../../contexts";

export const BespokeFileUploaderComponent = (props: any) => {
  const M = useContext(MATERIALIZE_REF);
  const [files, setFiles] = useState([] as any);
  const [iconToShow, setIconToShow] = useState("add");
  const [viewerDocs, setViewerDocs] = useState([] as any);
  const docViewerModalRef = useRef(null);
  const [modalInstance, setModalInstance] = useState({} as any);

  useEffect(() => {
    if (docViewerModalRef.current) {
      const tempModalInstance = M.Modal.init(docViewerModalRef.current);
      setModalInstance(tempModalInstance);
    }
  }, [docViewerModalRef]);

  useEffect(() => {
    if (props.documentValues) {
      const FILES = props.documentValues.files;
      if (FILES) {
        setFiles(FILES);
      }
    }
  }, [props.documentValues]);

  function targetHiddenFileInput(e: any) {
    const newFileInput = e.currentTarget.querySelector(
      'input[type="file"]'
    ) as HTMLElement;
    if (newFileInput) {
      newFileInput.click();
    }
  }

  function changeFileEvent(e: any) {
    setIconToShow("spinner");
    uploadFile(e);
  }

  function removeFile(fileURL: string) {
    const splitBaseURL = API_URL.split("/");
    const splitFileURL = fileURL.split("/");
    const filteredFileURL = splitFileURL.filter(
      (item: string) => !splitBaseURL.includes(item)
    ); // remove base url from file url
    const requiredFileURL = filteredFileURL.join("/");
    rDELETE(`/${requiredFileURL}/`);
    const newFiles = files.filter((file: any) => file.url !== fileURL);
    setFiles(newFiles);
  }

  function uploadFile(e: any) {
    if (e.target.files.length > 0) {
      const splitBaseURL = API_URL.split("/");
      const splitNewFileURL = props.documentValues.new_file.split("/");
      const filteredNewFileURL = splitNewFileURL.filter(
        (item: string) => !splitBaseURL.includes(item)
      ); // remove base url from file url
      const requiredNewFileURL = filteredNewFileURL.join("/");
      const fileToUpload = e.target.files[0];
      rPOST(`/${requiredNewFileURL}/`, { file: fileToUpload.name })
        .then((response: any) => {
          const fileData = response.data;
          const AWSFields = fileData.file.fields;
          let formData = new FormData();
          Object.keys(AWSFields).forEach((key) => {
            formData.append(key, AWSFields[key]);
          });
          // Append file data to form data
          formData.append("file", fileToUpload);

          // Upload file to S3
          rPOST(fileData.file.url, formData, true)
            .then(() => {
              setIconToShow("add");
              // Remove axios base url from file url
              const splitFileURL = fileData.url.split("/");
              const filteredFileURL = splitFileURL.filter(
                (item: string) => !splitBaseURL.includes(item)
              );
              const fileURL = filteredFileURL.join("/");
              // If this S3 upload is successful, we directly GET the file so that the AWS url is compiled and we can view the document immediately after uploading
              rGET(`/${fileURL}/`)
                .then((response: any) => {
                  setFiles([...files, response.data as any]);
                })
                .catch((error) => {
                  console.error("error", error);
                });
            })
            .catch((error) => {
              console.error("error", error);
              setIconToShow("add");
              // Trigger DELETE request to remove the file from Connect since S3 upload failed
              removeFile(fileData.url);
            });
        })
        .catch((error) => {
          console.error("error", error);
          setIconToShow("add");
        });
    }
  }

  // custom header for document viewer plugin
  const customDocViewerHeader: IHeaderOverride = (
    state,
    previousDocument,
    nextDocument
  ) => {
    if (!state.currentDocument || state.config?.header?.disableFileName) {
      return null;
    }

    return (
      <div className="modal__header">
        <button
          className="left-arrow"
          onClick={previousDocument}
          disabled={state.currentFileNo === 0}>
          <i className={`material-icons`}>chevron_left</i>
        </button>
        <div className="file-name">
          {/* This is how the original header gets the file name */}
          {
            decodeURI(state.currentDocument.uri).split("?")[0].split("/")[
              decodeURI(state.currentDocument.uri).split("?")[0].split("/").length - 1
            ]
          }
        </div>
        <button
          className="right-arrow"
          onClick={nextDocument}
          disabled={state.currentFileNo >= state.documents.length - 1}>
          <i className={`material-icons`}>chevron_right</i>
        </button>
      </div>
    );
  };

  return (
    <div id="file_uploader" className="documents__files">
      {files.map((file: any, index: number) => (
        <div
          className="btn-floating documents__files_done"
          style={{ order: file.order }}
          key={index}>
          <img src={`${file.thumbnail}&width=150&height=150`}></img>
          <i className={`material-icons`} onClick={() => removeFile(file.url)}>
            delete_forever
          </i>
          <a
            onClick={() => {
              setViewerDocs([{ uri: file.file }]);
              modalInstance?.open();
            }}>
            <i className={`material-icons`}>search</i>
          </a>
        </div>
      ))}
      {files.length < props.options.max_file_count ? (
        <div
          className={`btn-floating documents__files_new`}
          onClick={(e) => targetHiddenFileInput(e)}>
          <input
            type="file"
            accept={props.options.permitted_file_types}
            hidden
            onChange={(e) => changeFileEvent(e)}
          />
          <i
            className={`material-icons ${
              iconToShow === "spinner" ? "icon_centered" : ""
            }`}>
            {iconToShow === "spinner" ? (
              <PreloaderSpinner
                spinnerSize="small"
                spinnerStatus={null}
                spinnerPosition=""
                spinnerColour="orange"
              />
            ) : (
              iconToShow
            )}
          </i>
          {iconToShow === "spinner" ? (
            <p>
              <FormattedMessage
                id="dynamic_onboarding.documents.uploading"
                defaultMessage={"Uploading"}
              />
            </p>
          ) : (
            <p>
              <FormattedMessage
                id="dynamic_onboarding.documents.add_file"
                defaultMessage={"Add File"}
              />
            </p>
          )}
        </div>
      ) : null}
      <div ref={docViewerModalRef} className="modal">
        <div className="modal-content">
          <DocViewer
            documents={viewerDocs}
            pluginRenderers={DocViewerRenderers}
            prefetchMethod="GET"
            config={{ header: { overrideComponent: customDocViewerHeader } }}
          />
        </div>
      </div>
    </div>
  );
};
