import React from "react";

class Days {
  constructor(date1, date2, intl) {
    this.intl = intl;
    this.date1 = typeof date1 !== "object" ? new Date(date1) : date1;
    this.date2 = new Date(date2);
    this.today = new Date(Date.now());
  }

  day_s(d) {
    return d === 1
      ? `${d} ${this.intl.formatMessage({
          id: "dashboard.common.day",
          defaultMessage: "day",
        })}`
      : `${d} ${this.intl.formatMessage({
          id: "dashboard.common.days",
          defaultMessage: "days",
        })}`;
  }

  difference(d2, d1) {
    return Math.ceil((d2 - d1) / (1000 * 60 * 60 * 24));
  }

  getGapDays() {
    let diff = { days: null, msg: undefined };
    if (this.date1.available !== undefined) {
      //calendar dashboard
      let diffCal = this.difference(this.date2, this.today);
      diff.days = this.day_s(diffCal);
      if (this.date1.available === true) {
        diff.msg = this.intl.formatMessage({
          id: "dashboard.common.availability_window",
          defaultMessage: "remain of your current availability window",
        });
      } else if (this.date1.available === false) {
        diff.msg = this.intl.formatMessage({
          id: "dashboard.common.block",
          defaultMessage: "remain of your current block",
        });
      }
    } else {
      //bookings dashboard
      if (this.difference(this.date1, this.today) > 0) {
        diff.days = this.day_s(this.difference(this.date1, this.today));
        diff.msg = this.intl.formatMessage({
          id: "dashboard.common.next_booking",
          defaultMessage: "until next booking",
        });
      } else {
        if (this.difference(this.date2, this.today) > 0) {
          diff.days = this.day_s(this.difference(this.date2, this.today));
          diff.msg = this.intl.formatMessage({
            id: "dashboard.common.current_booking",
            defaultMessage: "remaining of current booking",
          });
        } else {
          diff.days = this.day_s(this.difference(this.today, this.date2));
          diff.msg = this.intl.formatMessage({
            id: "dashboard.common.previous_booking",
            defaultMessage: "since last booking ended",
          });
        }
      }
    }
    return diff;
  }
}

export default Days;
