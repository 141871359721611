import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { numberFormat } from "../utility/currency_format";
import { moneyRates } from "../utility/moneyRates";
import { LocaleContext } from "../../global_contexts";

const Possessions = () => {
  const { locale } = useContext(LocaleContext);
  const [currency, setCurrency] = useState(localStorage.getItem("currency"));

  useEffect(() => {
    if (currency !== localStorage.getItem("currency")) {
      setCurrency(localStorage.getItem("currency"));
    }
  }, [localStorage.getItem("currency")]);

  useEffect(() => {
    document.querySelector(".main-container .container").classList.add("full-width");

    // returned function will be called on component unmount
    return () => {
      document
        .querySelector(".main-container .container")
        .classList.remove("full-width");
    };
  }, []);

  return (
    <div className="great-host-guide-pages">
      <div className="row">
        <div className="col s12">
          <NavLink exact to="/great-host-guide/" className="left-align">
            <span className="mr-10">
              <Icon
                icon={ICONS.BACK_ARROW.d}
                width={ICONS.BACK_ARROW.width}
                height={12}
                viewBox="0 0 10 20"
              />
            </span>
            <span className="cobalt-blue">Back to Guide summary</span>
          </NavLink>
          <h4 className="center-align">
            Possessions and Valuables - Let's keep your treasures safe
          </h4>
        </div>
      </div>
      <div className="row top-img-container">
        <img
          className="top-img"
          alt="Possessions"
          src={require(`../../styles/img/possessions@2x-min.jpg`)}
        />
      </div>
      <div className="row">
        <div className="col s12">
          <h5>Items That You Love</h5>
          <p>
            We love them too, and would really hate to see anything happen to them! It’s
            best to be over-cautious, so locking or taking them away is always the best
            idea.
          </p>

          <h5>Finding Alternatives</h5>
          <p>
            Sometimes replacing expensive items with a cheaper alternative can make the
            difference. Swap that expensive china and cutlery with a standard dining
            set, or that 10 setting steamer with an inexpensive hotel standard iron!
          </p>

          <h5>Big and Precious</h5>
          <p>
            Please ensure that you lock away or remove any items that are expensive or
            hold sentimental value. We’d be upset too if anything were to happen to such
            items, therefore storing them safely will prevent any damage or loss.
          </p>
          <p>
            Please also protect any sensitive surfaces, such as marble worktops or
            antique dining tables, covering them in some way to prevent guests from
            accidentally scratching them. It can be hard to claim wear and tear as
            guests may deny any wrongdoing.
          </p>
          <p>
            Guests may accidentally break something small such as a plate or mug.
            Although we do not perform inventory checks, we will do our best to bring
            such occurrences to your attention should we notice them.
          </p>

          <h5>Food and Fridge</h5>
          <p>
            We’ll empty your fridge and freezer for you ahead of a booking, as guests
            prefer to buy products during their stay. Although guests do appreciate some
            salt or pepper in the cupboard, they may not want to use any items stored in
            the fridge, for example, ketchup or frozen food. We’ll also empty your
            fridge and freezer after each check-out.
          </p>
          <p>
            If you have items of food that you do not wish for guests to consume or a
            cleaner to throw away, such as special tea or luxury chocolate, please store
            them in secure cupboards and label them as we cannot replace these goods or
            cover the cost of their replacement.
          </p>

          <h5>Insurance</h5>
          <p>
            Whilst the primary booking platforms we work with provide public liability
            insurance and respective damage guarantee schemes for Hosts & Guests, we
            strongly advise that you seek additional insurance to protect yourself as a
            Host against any libel claims if the worst should happen.
          </p>
          <p>
            Our Terms of service request that adequate insurance is in place prior to
            our agreement to host on your behalf. Please reach out to your local
            management team for more information on our recommended short let insurance
            providers.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Possessions;
