import { createContext } from "react";
import { DataEditor } from "./schema";

export const DYNAMIC_DATA_CONTEXT = createContext<DataEditor | null>(null);

export const SECTION_DISPLAY_CONTEXTS = createContext({
  updateActiveSection: (...args: any[]) => {},
});

export const FORM_CONTEXTS = createContext({
  handleSectionSubmission: (...args: any[]) => {},
  sections: [] as any,
  updateSectionData: (...args: any[]) => {},
});

export const MATERIALIZE_REF = createContext((window as any).M);
