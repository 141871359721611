import config from "./config";
const Sentry = window.Sentry;

function IncludeSentry(errorMsg) {
  const user = JSON.parse(localStorage.getItem("user"));

  const toTitleCase = (str) =>
    str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());

  Sentry.init({
    dsn: "https://0d51d2b31e454c5dbfb554c929719352@sentry.io/1282250",
    environment: toTitleCase(config.ENV),
  });

  if (user) {
    // Set user information
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: user.uid,
        username: user.name,
        email: user.email,
      });
    });
  }

  if (errorMsg) {
    Sentry.captureMessage(errorMsg);
  }
}

export default IncludeSentry;
