import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { FormattedMessage } from "react-intl";
import { PropertySelectorContext } from "./navbar";
import { PropertyIDContext } from "../../global_contexts";

const OnboardingChecklistTab = (props) => {
  const { propertyID } = useContext(PropertyIDContext);
  const { propertySelectorOpen } = useContext(PropertySelectorContext);
  const [showChecklist, setShowChecklist] = useState(true);
  const [properties, setProperties] = useState(
    JSON.parse(localStorage.getItem("properties"))
  );
  const [multipleProperties, setMultipleProperties] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("dynamic_onboarding")) {
      props.getChecklistInfo(propertyID);
    }
  }, []);

  useEffect(() => {
    if (
      props.checklist_status === "complete" ||
      props.checklist_status === "requires_check"
    ) {
      setShowChecklist(false);
    } else {
      setShowChecklist(true);
    }
  }, [props.checklist_status, propertyID]);

  useEffect(() => {
    if (properties !== JSON.parse(localStorage.getItem("properties"))) {
      setProperties(JSON.parse(localStorage.getItem("properties")));
    }
  }, [localStorage.getItem("properties")]);

  useEffect(() => {
    if (properties) {
      setMultipleProperties(properties.length > 1 ? true : false);
    }
  }, [properties]);

  return (
    <li
      className={`listOpt ${propertySelectorOpen ? "addOpacity" : ""} ${
        multipleProperties ? "nav-item-inset" : ""
      } ${showChecklist ? "" : "hide"}`}
      key="Onboarding Checklist">
      <NavLink id="Onboarding Checklist" exact to="/checklist/">
        <Icon icon={ICONS.CHECKLIST.d} width={29} height={30} />
        <span>
          <FormattedMessage
            id="onboarding_checklist.text"
            defaultMessage="Onboarding Checklist"
          />
        </span>
      </NavLink>
    </li>
  );
};

function mapStateToProps(state) {
  return {
    checklist_status: state.checklist.checklist_status,
  };
}

export default withRouter(connect(mapStateToProps, actions)(OnboardingChecklistTab));
