import {
  PROPERTY_DETAILS_STATUS,
  PROPERTY_AMENITIES_STATUS,
  ONBOARDING_DETAILS_STATUS,
  GUEST_INFO_STATUS,
  ACCOUNT_INFO_STATUS,
} from "../../../actions/types";

interface fieldInterface {
  meta: any;
  input: any;
}

const filterFields = function (section_name: string, fields: fieldInterface[]): object {
  let filterFields: any = {};
  Object.keys(fields).forEach((name: any) => {
    if (fields[name].meta && fields[name].meta.form === section_name)
      filterFields[name] = fields[name];
  });
  return filterFields;
};

const checklistStatus = function (
  section_name: string,
  fields: fieldInterface[]
): object {
  let section: any = {};
  switch (section_name) {
    case "account_info":
      section = { type: ACCOUNT_INFO_STATUS, index: 0 };
      break;
    case "property_details":
      section = { type: PROPERTY_DETAILS_STATUS, index: 1 };
      break;
    case "property_amenities":
      section = { type: PROPERTY_AMENITIES_STATUS, index: 2 };
      break;
    case "guest_info":
      section = { type: GUEST_INFO_STATUS, index: 3 };
      break;
    case "onboarding_details":
      section = { type: ONBOARDING_DETAILS_STATUS, index: 4 };
      break;
    default:
      break;
  }
  section.fields = filterFields(section_name, fields);
  return section;
};

export default checklistStatus;
