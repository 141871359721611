import React, { useState } from "react";
import { TextField } from "../../schema";

export const InputTelephoneComponent = (props: {
  onValueChange: any;
  field: TextField;
}) => {
  const [value, setValue] = useState(props.field.value || "");

  return (
    <input
      id={props.field.key}
      type="tel"
      className="validate input-field--medium"
      required={props.field.required}
      placeholder="00000000000"
      value={value}
      onChange={(e) => {
        setValue(e.currentTarget.value);
      }}
      onBlur={(e) => {
        props.onValueChange(e.currentTarget, e.currentTarget.value);
      }}
    />
  );
};
