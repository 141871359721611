import { rGET } from "./api";
import { CALENDAR_DATA, CALENDAR_DASHBOARD } from "./types";

//Get calendar data
export function calendarData(property_id, ...args) {
  return function (dispatch) {
    const url =
      args.length > 0
        ? `/calendar/${property_id}/${args[0]["start_date"]}/to/${args[0]["end_date"]}/`
        : `/calendar/${property_id}/dashboard/`;
    rGET(url).then((resp) => {
      if (args.length > 0) {
        const calendar_data = resp && resp.data.results;
        dispatch({
          type: CALENDAR_DATA,
          payload: calendar_data,
        });
      } else {
        const calendar_dashboard = resp && resp.data.results;
        dispatch({
          type: CALENDAR_DASHBOARD,
          payload: calendar_dashboard,
        });
      }
    });
  };
}
