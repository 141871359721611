import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { rGET } from "../actions/api";
import SmallSpinner from "./utility/small_spinner";
import { PropertyIDContext } from "../global_contexts";
import { MATERIALIZE_REF } from "./dynamic_onboarding/contexts";

const Listings = () => {
  const M = useContext(MATERIALIZE_REF);
  const { propertyID } = useContext(PropertyIDContext);
  const [displayPage, setDisplayPage] = useState(undefined);
  const [listingsData, setListingsData] = useState(undefined);
  const [directListingData, setDirectListingData] = useState(undefined);
  const [displayDirectBookingBox, setDisplayDirectBookingBox] = useState(undefined);
  const [displayDirectPropertyBox, setDisplayDirectPropertyBox] = useState(undefined);
  const [displayOtherListingsBox, setDisplayOtherListingsBox] = useState(undefined);
  const [noListings, setNoListings] = useState(undefined);
  const DIRECT = "Direct";

  useEffect(() => {
    setDisplayPage(undefined);
    setListingsData(undefined);
    setDirectListingData(undefined);
    setDisplayDirectBookingBox(undefined);
    setDisplayDirectPropertyBox(undefined);
    setDisplayOtherListingsBox(undefined);
    setNoListings(undefined);
    loadListingsTab();
  }, [propertyID]);

  useEffect(() => {
    if (listingsData) {
      if (listingsData.length > 0) {
        setDisplayOtherListingsBox(true);
        // Check if there is a Direct listing
        listingsData.forEach((listing) => {
          if (listing.platform === DIRECT) {
            // Direct listing exists
            setDisplayDirectBookingBox(true);
            if (listingsData.length === 1) {
              setDisplayOtherListingsBox(false);
            }
          }
        });
      } else {
        setNoListings(true);
      }
    }
  }, [listingsData]);

  useEffect(() => {
    if (directListingData) {
      if (parseInt(directListingData.baths) >= 0) {
        // Direct property details loaded successfully
        setDisplayDirectPropertyBox(true);
      }
    }
  }, [directListingData]);

  function loadListingsTab() {
    getListingsData();
    getDirectListingData();
    setDisplayPage(true);
  }

  function generateOtherListings() {
    if (listingsData) {
      let limited_listings = {};
      return (
        <div className="other_listings__links">
          {listingsData.map((listing, key) =>
            listing.platform !== DIRECT
              ? loadListing(listing, key, limited_listings)
              : null
          )}
        </div>
      );
    }
  }

  function loadDirectListing() {
    if (listingsData) {
      let l_url = null;
      listingsData.forEach((listing) => {
        if (listing.platform === DIRECT) {
          l_url = listing.url;
        }
      });
      return (
        <div className="listing__link">
          <a
            href={`${l_url}?utm_source=host&utm_medium=client_portal&utm_campaign=view_listing`}
            target="_blank"
            rel="noopener noreferrer">
            {stripPrefix(l_url)}
          </a>
          <FormattedMessage
            id="listings.copied_toast"
            defaultMessage="Copied to clipboard.">
            {(Localised_toast_message) => (
              <button
                className="listing__button btn"
                onClick={() => {
                  copyLink(
                    `${l_url}?utm_source=host&utm_medium=client_portal&utm_campaign=view_listing`
                  );
                  M.toast({
                    html: `<div style="position: relative"><div>${Localised_toast_message}</div></div>`,
                  });
                }}>
                <FormattedMessage id="listings.copy_link" defaultMessage="Copy Link" />
              </button>
            )}
          </FormattedMessage>
        </div>
      );
    }
  }

  function loadListing(listing, key, limited_listings) {
    if (checkListingLoadable(listing, limited_listings)) {
      return (
        <div key={key} className="other_listing_link listing__link">
          <img src={listing.image} alt={listing.platform}></img>
          <a href={listing.url} target="_blank" rel="noopener noreferrer">
            {stripPrefix(listing.url)}
          </a>
          <FormattedMessage
            id="listings.copied_toast"
            defaultMessage="Copied to clipboard.">
            {(Localised_toast_message) => (
              <button
                className="listing__button btn"
                onClick={() => {
                  copyLink(listing.url);
                  M.toast({
                    html: `<div style="position: relative"><div>${Localised_toast_message}</div></div>`,
                  });
                }}>
                <FormattedMessage id="listings.copy_link" defaultMessage="Copy Link" />
              </button>
            )}
          </FormattedMessage>
        </div>
      );
    }
  }

  function stripPrefix(url) {
    return url.replace(/https?:\/\/(?:www\.)?/gm, "");
  }

  function loadDirectProperty() {
    const TITLE = directListingData ? directListingData.title : undefined;
    const TYPE = directListingData ? directListingData.property_type : undefined;
    const TOTAL_GUESTS = directListingData ? directListingData.total_guests : undefined;
    const ROOMS = directListingData ? directListingData.rooms : undefined;
    const BATHS = directListingData ? directListingData.baths : undefined;
    const THUMBNAIL_IMG = directListingData
      ? directListingData.thumbnail_image
      : undefined;
    const CITY_NAME = directListingData ? directListingData.city_name : undefined;
    return (
      <div id="direct_property_details" className="direct_property">
        <img src={THUMBNAIL_IMG} alt="Direct Property Thumbnail"></img>
        <h5 className="direct_property__title">{TITLE}</h5>
        {directListingData && directListingData.city_name ? (
          <div className="direct_property__city_name">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 11">
              <path
                d="M5.00464 -0.25C3.911 -0.248759 2.86251 0.186236 2.08919 0.959555C1.31587 1.73287 0.880879 2.78136 0.879639 3.875C0.879639 7.40469 4.62964 10.0719 4.78901 10.1844C4.85288 10.2269 4.92791 10.2496 5.00464 10.2496C5.08137 10.2496 5.15639 10.2269 5.22026 10.1844C5.37964 10.0719 9.12964 7.40469 9.12964 3.875C9.1284 2.78136 8.6934 1.73287 7.92008 0.959555C7.14677 0.186236 6.09828 -0.248759 5.00464 -0.25ZM5.00464 2.375C5.30131 2.375 5.59132 2.46297 5.83799 2.6278C6.08467 2.79262 6.27693 3.02689 6.39046 3.30097C6.50399 3.57506 6.53369 3.87666 6.47582 4.16763C6.41794 4.45861 6.27508 4.72588 6.0653 4.93566C5.85552 5.14544 5.58825 5.2883 5.29727 5.34618C5.0063 5.40406 4.7047 5.37435 4.43061 5.26082C4.15652 5.14729 3.92226 4.95503 3.75743 4.70835C3.59261 4.46168 3.50464 4.17167 3.50464 3.875C3.50464 3.47717 3.66267 3.09564 3.94398 2.81434C4.22528 2.53304 4.60681 2.375 5.00464 2.375Z"
                fill="#0826AE"
              />
            </svg>
            <p>{CITY_NAME}</p>
          </div>
        ) : null}
        <div className="direct_property__info">
          <div className="direct_property__info_section">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              <path d="M511.8 287.6L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L416 100.7V64C416 46.33 430.3 32 448 32H480C497.7 32 512 46.33 512 64V185L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6L511.8 287.6z" />
            </svg>
            <p>
              <FormattedMessage id="listings.property.type" defaultMessage="Type" />
            </p>
            <p>{TYPE}</p>
          </div>
          <div className="direct_property__info_section">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
              <path d="M623.5 164C638.1 172.6 644.6 192.1 635.1 207.5C627.4 222.1 607.9 228.6 592.5 219.1L319.1 68.61L47.54 219.1C32.09 228.6 12.61 222.1 4.025 207.5C-4.558 192.1 1.008 172.6 16.46 164L304.5 4.027C314.1-1.342 325.9-1.342 335.5 4.027L623.5 164zM279.1 200C279.1 177.9 297.9 160 319.1 160C342.1 160 359.1 177.9 359.1 200C359.1 222.1 342.1 240 319.1 240C297.9 240 279.1 222.1 279.1 200zM103.1 296C103.1 273.9 121.9 256 143.1 256C166.1 256 183.1 273.9 183.1 296C183.1 318.1 166.1 336 143.1 336C121.9 336 103.1 318.1 103.1 296V296zM535.1 296C535.1 318.1 518.1 336 495.1 336C473.9 336 455.1 318.1 455.1 296C455.1 273.9 473.9 256 495.1 256C518.1 256 535.1 273.9 535.1 296zM226.9 491.4L199.1 441.5V480C199.1 497.7 185.7 512 167.1 512H119.1C102.3 512 87.1 497.7 87.1 480V441.5L61.13 491.4C54.84 503 40.29 507.4 28.62 501.1C16.95 494.8 12.58 480.3 18.87 468.6L56.74 398.3C72.09 369.8 101.9 352 134.2 352H153.8C170.1 352 185.7 356.5 199.2 364.6L232.7 302.3C248.1 273.8 277.9 255.1 310.2 255.1H329.8C362.1 255.1 391.9 273.8 407.3 302.3L440.8 364.6C454.3 356.5 469.9 352 486.2 352H505.8C538.1 352 567.9 369.8 583.3 398.3L621.1 468.6C627.4 480.3 623 494.8 611.4 501.1C599.7 507.4 585.2 503 578.9 491.4L551.1 441.5V480C551.1 497.7 537.7 512 519.1 512H471.1C454.3 512 439.1 497.7 439.1 480V441.5L413.1 491.4C406.8 503 392.3 507.4 380.6 501.1C368.1 494.8 364.6 480.3 370.9 468.6L407.2 401.1C405.5 399.5 404 397.6 402.9 395.4L375.1 345.5V400C375.1 417.7 361.7 432 343.1 432H295.1C278.3 432 263.1 417.7 263.1 400V345.5L237.1 395.4C235.1 397.6 234.5 399.5 232.8 401.1L269.1 468.6C275.4 480.3 271 494.8 259.4 501.1C247.7 507.4 233.2 503 226.9 491.4H226.9z" />
            </svg>{" "}
            <p>
              <FormattedMessage
                id="listings.property.accommodation"
                defaultMessage="Accommodation"
              />
            </p>
            <p>
              {TOTAL_GUESTS}{" "}
              <FormattedMessage id="listings.property.guests" defaultMessage="Guests" />
            </p>
          </div>
          <div className="direct_property__info_section">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
              <path d="M176 288C220.1 288 256 252.1 256 208S220.1 128 176 128S96 163.9 96 208S131.9 288 176 288zM544 128H304C295.2 128 288 135.2 288 144V320H64V48C64 39.16 56.84 32 48 32h-32C7.163 32 0 39.16 0 48v416C0 472.8 7.163 480 16 480h32C56.84 480 64 472.8 64 464V416h512v48c0 8.837 7.163 16 16 16h32c8.837 0 16-7.163 16-16V224C640 170.1 597 128 544 128z" />
            </svg>
            <p>
              <FormattedMessage
                id="listings.property.bedrooms"
                defaultMessage="Bedrooms"
              />
            </p>
            <p>
              {ROOMS}{" "}
              <FormattedMessage
                id="listings.property.beds"
                defaultMessage="Bedrooms / Beds"
              />
            </p>
          </div>
          <div className="direct_property__info_section">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M32 384c0 28.32 12.49 53.52 32 71.09V496C64 504.8 71.16 512 80 512h32C120.8 512 128 504.8 128 496v-15.1h256V496c0 8.836 7.164 16 16 16h32c8.836 0 16-7.164 16-16v-40.9c19.51-17.57 32-42.77 32-71.09V352H32V384zM496 256H96V77.25C95.97 66.45 111 60.23 118.6 67.88L132.4 81.66C123.6 108.6 129.4 134.5 144.2 153.2C137.9 159.5 137.8 169.8 144 176l11.31 11.31c6.248 6.248 16.38 6.248 22.63 0l105.4-105.4c6.248-6.248 6.248-16.38 0-22.63l-11.31-11.31c-6.248-6.248-16.38-6.248-22.63 0C230.7 33.26 204.7 27.55 177.7 36.41L163.9 22.64C149.5 8.25 129.6 0 109.3 0C66.66 0 32 34.66 32 77.25v178.8L16 256C7.164 256 0 263.2 0 272v32C0 312.8 7.164 320 16 320h480c8.836 0 16-7.164 16-16v-32C512 263.2 504.8 256 496 256z" />
            </svg>
            <p>
              <FormattedMessage
                id="listings.property.bathrooms"
                defaultMessage="Bathrooms"
              />
            </p>
            <p>
              {BATHS}{" "}
              <FormattedMessage id="listings.property.full" defaultMessage="Full" />
            </p>
          </div>
        </div>
      </div>
    );
  }

  function getListingsData() {
    const url = `/listings/${propertyID}/`;
    rGET(url)
      .then((resp) => {
        let listings_data = resp && resp.data.results;
        setListingsData(listings_data);
      })
      .catch((err) => {
        setListingsData(null);
      });
  }

  function getDirectListingData() {
    const url = `/direct_listing/${propertyID}/`;
    rGET(url)
      .then((resp) => {
        let direct_listing_data = resp
          ? resp.status === 200
            ? resp.data.data
            : null
          : null;
        setDirectListingData(direct_listing_data);
      })
      .catch((err) => {
        setDirectListingData(null);
      });
  }

  function copyLink(url) {
    let link = document.querySelector(`[href='${url}']`).href;
    navigator.clipboard.writeText(link);
  }

  // This checks if we are loading a duplicated listing because we know there may be mutliple,
  // but we only want to load the first one into the Listings tab
  function checkListingLoadable(listing, limited_listings) {
    let load = true;
    if (Object.keys(limited_listings).length > 0) {
      for (const LOADED_LISTING in limited_listings) {
        if (listing.platform === LOADED_LISTING) {
          load = false;
        }
      }
    }
    // We want to hide all the Google listigns for now
    switch (listing.platform) {
      case "Google":
        load = false;
        break;
      default:
        break;
    }
    if (load === true) {
      limited_listings[listing.platform] = listing.platform;
    }
    return load;
  }

  return (
    <div className="row">
      <div className="col s12">
        <div className="row mb-0">
          <div className="col s12">
            <h4>
              <FormattedMessage
                id="listings.title"
                defaultMessage="Property Listings"
              />
            </h4>
          </div>
        </div>
      </div>
      {displayPage === true ? (
        noListings !== true ? (
          <div className="row">
            <div className="col s12">
              <FormattedMessage
                id="listings.description"
                defaultMessage="These are all the platforms where a page advertising your property has
              been created. Please use these links to share with friends, family and
              prospective guests, and to check property information and details are
              displayed correctly."
              />
            </div>
            <div className="col s12">
              <div className="listings_container">
                {displayDirectPropertyBox === true ? (
                  <div className="listings_section">{loadDirectProperty()}</div>
                ) : null}
                <div
                  className={
                    displayDirectPropertyBox !== true
                      ? "listings_section listings_section--single"
                      : "listings_section"
                  }>
                  {displayDirectBookingBox === true ? (
                    <div id="direct_booking_box" className="direct_booking_listing">
                      <div className="direct_booking_listing__ptk_logo">
                        <img
                          src={
                            require(`../components/svg/ptk-logo-website.svg`).default
                          }
                          alt="Pass the Keys"
                        />
                      </div>
                      <div className="direct_booking_listing__title">
                        <FormattedMessage
                          id="listings.share_link_title"
                          defaultMessage="Share your Direct Booking link"
                        />
                      </div>
                      <div className="direct_booking_listing__text">
                        <FormattedMessage
                          id="listings.share_link_description"
                          defaultMessage="Where possible please share the Direct Booking link with
                        interested parties. Guests who book directly are more likely
                        to become repeat customers which will lead to more bookings
                        for your property."
                        />
                      </div>
                      {loadDirectListing()}
                    </div>
                  ) : (
                    <div id="direct_booking_box" className="direct_booking_listing">
                      <div className="direct_booking_listing__ptk_logo">
                        <img
                          src={
                            require(`../components/svg/ptk-logo-website.svg`).default
                          }
                          alt="Pass the Keys"
                        />
                      </div>
                      <div className="direct_booking_listing__title">
                        <FormattedMessage
                          id="listings.setup_db_title"
                          defaultMessage="Setup your Direct Booking link"
                        />
                      </div>
                      <div className="direct_booking_listing__text">
                        <FormattedMessage
                          id="listings.setup_db_description"
                          defaultMessage="Your property isn't currently listed on the Pass the Keys®
                        booking website. You could be missing out on loyal guests who
                        book direct. <a>Contact your account manager</a> to request a listing."
                          values={{
                            a: (...chunks) => (
                              <NavLink exact to="/contact-us/">
                                {chunks}
                              </NavLink>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {displayOtherListingsBox === true ? (
                    <div id="other_listings_box" className="other_listings">
                      <div className="other_listings__title">
                        <FormattedMessage
                          id="listings.other_platforms_title"
                          defaultMessage="Other platforms"
                        />
                      </div>
                      <div className="other_listings__text">
                        {displayDirectBookingBox === true ? (
                          <FormattedMessage
                            id="listings.other_platforms_description_part_one_with_other"
                            defaultMessage="These are the other platforms where your property is available to book. "
                          />
                        ) : (
                          <FormattedMessage
                            id="listings.other_platforms_description_part_one"
                            defaultMessage="These are the platforms where your property is available to book. "
                          />
                        )}
                        <FormattedMessage
                          id="listings.other_platforms_description_part_two"
                          defaultMessage="Please note, your listing may also appear on third party
                        websites which are not displayed below. We can only manage the
                        listings from the platforms shown below. To request a change
                        to a listing, please <a>contact your account manager</a>."
                          values={{
                            a: (...chunks) => (
                              <NavLink exact to="/contact-us/">
                                {chunks}
                              </NavLink>
                            ),
                          }}
                        />
                      </div>
                      {generateOtherListings()}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col s12">
              <FormattedMessage
                id="listings.no_listing"
                defaultMessage="Your listings will be live shortly, please check back here soon."
              />
            </div>
          </div>
        )
      ) : (
        <SmallSpinner />
      )}
    </div>
  );
};

export default Listings;
