import React, { Component } from "react";
import { change } from "redux-form";

import { store } from "../../../store";
import { rPUT } from "../../../actions/api";
import checklistStatus from "./factory_checklist_status";
import Beds from "../form_beds";
import { setChecklistStages } from "./common";
import { debounce, getNextDate } from "../../../common/util";
import { getISOLocalDate } from "../../calendar/dates";
import { DATE_FIELDS } from "../fields";
import {
  inputSelect,
  inputRadio,
  inputCheckbox,
  inputTextarea,
  inputDatePicker,
  inputDefault,
} from "../../../common/form_inputs";

const M = (window as any).M;
var sectionStatus: any = [
  { errors: {}, isInProgress: false, isTouched: false, statusInputFilled: null },
  { errors: {}, isInProgress: false, isTouched: false, statusInputFilled: null },
  { errors: {}, isInProgress: false, isTouched: false, statusInputFilled: null },
  { errors: {}, isInProgress: false, isTouched: false, statusInputFilled: null },
  { errors: {}, isInProgress: false, isTouched: false, statusInputFilled: null },
];
var fieldsToCheck: any = {};
var newData: any = {};

interface Props {
  name: any;
  n: string;
  propertyID: number;
}

interface State {}

class FormComponent extends Component<Props | any, State | any> {
  constructor(props: Props) {
    super(props);
    this.saveData = this.saveData.bind(this);
    this.updateChecklist = debounce(this.updateChecklist, 3000);
    this.state = {};
  }

  initDateInputFields(inputs: any) {
    let instancesDate = [];
    inputs.forEach((name: string) => {
      let minSelectableDate = getNextDate(3);
      if (name === "start_hosting_date") minSelectableDate = getNextDate(10);
      if (name === "first_hosting_period_end_date") {
        minSelectableDate = getNextDate(21, false);
      }
      var date_picker_opt = {
        autoClose: true,
        disableWeekends: ![
          "first_hosting_period_end_date",
          "start_hosting_date",
        ].includes(name),
        disableDayFn: (date: Date) => {
          if (date < minSelectableDate) {
            return true;
          }
        },
        minDate: minSelectableDate,
        format: "dd/mm/yyyy",
        onSelect: (date: Date) => {
          let obj_date: any = {};
          obj_date[name] = getISOLocalDate(date);
          this.setState(() => obj_date);
        },
      };
      M.Datepicker.init(
        document.querySelector(`#${name} .datepicker`),
        date_picker_opt
      );
      instancesDate.push(
        M.Datepicker.getInstance(document.querySelector(`#${name} .datepicker`))
      );
    });
  }

  componentDidMount() {
    this.initDateInputFields(DATE_FIELDS);
    M.FormSelect.init(document.querySelectorAll("select"));
    this.forceUpdate();
  }

  componentDidUpdate(prevProps: Props | any, prevState: State | any) {
    DATE_FIELDS.forEach((n: string) => {
      const prev_n = prevState[n];
      const state_n = this.state[n];
      const props_n = this.props[n];
      if (prev_n !== state_n) {
        store.dispatch(change("onboarding_details", n, state_n));
      }
      if (props_n) {
        if (this.state && prev_n !== state_n) {
          props_n.input.value = state_n;
          props_n.meta.dispatch(change("onboarding_details", n, state_n));
        }
      }
    });
    if (this.props) {
      let direct_debit = null;
      Object.keys(this.props).forEach((name, index) => {
        const prop_name = this.props && this.props[name];
        if (prop_name) {
          let propsNameVal = prop_name.input && prop_name.input.value;
          if (propsNameVal) {
            if (prop_name.meta && prop_name.meta.valid) {
              this.saveData(
                prop_name.input,
                prop_name.meta,
                this.props.details[index].type
              );
            }
          }
        }
        if (prop_name) {
          if (prevProps.propertyID !== this.props.propertyID) {
            newData = {};
            fieldsToCheck = {};
            sectionStatus = [
              {
                errors: {},
                isInProgress: false,
                isTouched: false,
                statusInputFilled: null,
              },
              {
                errors: {},
                isInProgress: false,
                isTouched: false,
                statusInputFilled: null,
              },
              {
                errors: {},
                isInProgress: false,
                isTouched: false,
                statusInputFilled: null,
              },
              {
                errors: {},
                isInProgress: false,
                isTouched: false,
                statusInputFilled: null,
              },
              {
                errors: {},
                isInProgress: false,
                isTouched: false,
                statusInputFilled: null,
              },
            ];
          }
          if (prop_name.meta && prop_name.input) {
            fieldsToCheck[name] = {
              meta: prop_name.meta,
              input: prop_name.input,
            };
          }
        }
      });
      if (this.props.section === "account_info") {
        direct_debit = this.props.conditionalValue[1];
      }
      const section: any = checklistStatus(this.props.section, fieldsToCheck);
      const status = `${setChecklistStages(
        store,
        sectionStatus,
        section.fields,
        section.index,
        direct_debit
      )}_${section.index}`;

      store.dispatch({
        type: section.type,
        payload: status,
      });
    }
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.forceUpdate();
    }
  }

  saveData(input: any, meta: any, type: any) {
    if (
      ((meta.touched || meta.dirty) && !meta.error && meta.valid) ||
      (typeof input.value === "object" && input.value.length > 0)
    ) {
      if (input.name !== "beds") {
        let key = input.name;
        newData[key] = input.value;
        if ((/date/.test(key) || type === "number") && newData[key].length === 0) {
          newData[key] = null;
        }
        if (!["no_washer", "no_garden"].includes(input.value)) {
          newData[key] = /^no_.+/i.test(String(input.value))
            ? false
            : /^yes_.+/i.test(String(input.value))
            ? true
            : input.value;
        }
        if (type === "number" && typeof input.value === "string") {
          newData[key] = +input.value;
        }
        if (Array.isArray(input.value) && input.value[0] === "-1") {
          if (input.value.length > 1) {
            input.value.shift();
          }
        }
      }
    }
    if (input.name === "rubbish_dates") {
      if (input.ishidden) {
        newData["rubbish_dates"] = null;
      }
    }
  }

  updateChecklist(data: any) {
    const propertyID = localStorage.getItem("property_id");
    if (data.account_number && data.account_number.length === 7) {
      data.account_number = `0${data.account_number}`;
    }
    rPUT(`/property_checklist/${propertyID}/`, data);
  }

  errorHandler(name: string, error: any, touched: boolean) {
    if (Object.keys(newData).includes(name)) {
      if (error) {
        delete newData[name];
        return;
      } else {
        this.updateChecklist(newData);
        return;
      }
    }
    if ((/date/.test(name) && error) || (error && touched)) {
      return error;
    }
  }

  render() {
    let arrFields = Object.values(this.props);
    let names = arrFields[arrFields.length - 6];
    let details = arrFields[arrFields.length - 5];
    let conditionalFields = arrFields[arrFields.length - 4];
    let chargeType = arrFields[arrFields.length - 3];
    const formBeds = store.getState() && store.getState().form.beds;
    if (formBeds && formBeds.values) {
      var beds = formBeds.values.beds;
    }

    return (
      arrFields &&
      names.map((name: string, i: number) => {
        const props_name = this.props[name];
        let error = this.errorHandler(
          props_name.input.name,
          props_name.meta.error,
          props_name.meta.touched
        );
        const yes_own_keysafe =
          conditionalFields[0] &&
          (String(conditionalFields[0]["own_keysafe"]).match(/^yes_.+/) ||
            conditionalFields[0]["own_keysafe"] === true);
        const no_own_keysafe =
          conditionalFields[0] &&
          (String(conditionalFields[0]["own_keysafe"]).match(/^no_.+/) ||
            conditionalFields[0]["own_keysafe"] === false);
        switch (true) {
          // property details
          case name === "beds":
            if (conditionalFields[0]["number_of_beds"] > 0) {
              this.props[name].input["ishidden"] = false;
              return (
                <Beds
                  key={`${this.state.skippedBeds}_${i}`}
                  info={{ beds: this.props[name].input.value }}
                />
              );
            } else {
              return (this.props[name].input["ishidden"] = true);
            }
          case name === "floor":
            if (conditionalFields[0]["property_size"] === "apartment") {
              this.props[name].input["ishidden"] = false;
              return inputDefault(name, error, details[i], this.props[name].input);
            } else {
              return (this.props[name].input["ishidden"] = true);
            }
          case name === "beds_faq":
            if (beds) {
              for (let k = 0; k < beds.length; k++) {
                if (beds[k].fixed === false || beds[k].fixed === "no") {
                  this.props[name].input["ishidden"] = false;
                  return inputDefault(name, error, details[i], this.props[name].input);
                } else if (beds[k].fixed === true || beds[k].fixed === "yes") {
                  this.props[name].input["ishidden"] = true;
                }
              }
            }
            break;
          case name === "elevator":
            if (conditionalFields[0]["property_size"] === "apartment") {
              this.props[name].input["ishidden"] = false;
              return inputRadio(name, error, details[i], this.props[name].input);
            } else {
              return (this.props[name].input["ishidden"] = true);
            }
          case details[i].type === "select":
            return inputSelect(
              name,
              error,
              details[i],
              this.props[name].input,
              name === "rubbish_dates"
            );
          // property amenities
          case ["wifi_network_name", "wifi_password"].includes(name):
            if (conditionalFields[0]["wifi"] === true) {
              this.props[name].input["ishidden"] = false;
              return inputDefault(
                name,
                error,
                details[i],
                this.props[name].input,
                "255"
              );
            } else {
              return (this.props[name].input["ishidden"] = true);
            }
          case [
            "iron_and_ironing_board_location",
            "hairdryer_location",
            "vacuum_cleaner_location",
            "tv_faq",
          ].includes(name):
            let fieldName = !["tv_faq"].includes(name)
              ? name.replace("_location", "")
              : name.replace("_faq", "");
            if (
              String(conditionalFields[0][fieldName]).match(/^yes_.+/) ||
              conditionalFields[0][fieldName] === true
            ) {
              this.props[name].input["ishidden"] = false;
              return inputTextarea(
                name,
                error,
                details[i],
                this.props[name].input,
                "255"
              );
            } else {
              return (this.props[name].input["ishidden"] = true);
            }
          case name === "additional_amenities_faq":
            this.props[name].input["ishidden"] = false;
            return inputTextarea(name, error, details[i], this.props[name].input);
          case ![
            "key_sets",
            "online_id_verified",
            "offline_id_verified",
            "profile_picture_added",
          ].includes(name) && details[i].type === "checkbox":
            return inputCheckbox(name, error, details[i], this.props[name].input);
          case details[i].type === "radio" &&
            !["keysafe_type", "owned_keysafe_type", "photography_timing"].includes(
              name
            ):
            let info: string = "";
            const airbnb_ae_value = this.props[name].input.value;
            const showInfo =
              String(airbnb_ae_value).match(/^no_airbnb_account_exists/) ||
              airbnb_ae_value === false;
            if (showInfo) {
              info = `We'll create an Airbnb account for you!`;
            }
            if (name === "package" && chargeType === "Fee") {
              this.props[name].input["ishidden"] = true;
            } else if (name === "package" && chargeType !== "Fee") {
              this.props[name].input["ishidden"] = false;
            }
            return inputRadio(name, error, details[i], this.props[name].input, info);
          // onboarding details
          case [
            "keysafe_code",
            "keysafe_location",
            "keysafe_type",
            "owned_keysafe_type",
          ].includes(name):
            const noSmartLock = ["rail_mounted", "wall_mounted"].includes(
              this.props["owned_keysafe_type"].input.value
            );

            const yesSmartLock =
              this.props["owned_keysafe_type"].input.value === "smart_lock";
            if (name === "keysafe_type") {
              if (yes_own_keysafe) {
                return (this.props[name].input["ishidden"] = true);
              } else if (no_own_keysafe) {
                this.props[name].input["ishidden"] = false;
                return inputRadio(name, error, details[i], this.props[name].input);
              }
            }
            if (name === "owned_keysafe_type") {
              if (yes_own_keysafe) {
                this.props[name].input["ishidden"] = false;
                return inputRadio(name, error, details[i], this.props[name].input);
              } else if (no_own_keysafe) {
                return (this.props[name].input["ishidden"] = true);
              }
            }
            if (["keysafe_code", "keysafe_location"].includes(name)) {
              if (yes_own_keysafe && noSmartLock) {
                this.props[name].input["ishidden"] = false;
                return inputTextarea(
                  name,
                  error,
                  details[i],
                  this.props[name].input,
                  "255"
                );
              } else if (no_own_keysafe || yesSmartLock) {
                return (this.props[name].input["ishidden"] = true);
              }
            }
            break;
          case ["smartlock_faq", "smartlock_code"].includes(name):
            const isSmartLock =
              this.props["owned_keysafe_type"].input.value === "smart_lock";
            if (isSmartLock && yes_own_keysafe) {
              this.props[name].input["ishidden"] = false;
              const countChar = name === "smartlock_faq" ? "" : "255";
              return inputTextarea(
                name,
                error,
                details[i],
                this.props[name].input,
                countChar
              );
            } else {
              return (this.props[name].input["ishidden"] = true);
            }
          case name === "access_faq":
            return inputTextarea(name, error, details[i], this.props[name].input);
          case details[i].type === "textarea" &&
            !["keysafe_location", "smartlock_faq", "smartlock_code"].includes(name):
            let charCount = [
              "nearest_train_faq",
              "nearest_bus_faq",
              "lightbulb_location",
              "wifi_location",
            ].includes(name)
              ? "255"
              : "";
            return inputTextarea(
              name,
              error,
              details[i],
              this.props[name].input,
              charCount
            );
          case name === "key_sets":
            const arr_test = yes_own_keysafe
              ? conditionalFields[0]["owned_keysafe_type"]
              : conditionalFields[0]["keysafe_type"];
            if (["rail_mounted", "wall_mounted"].includes(arr_test)) {
              this.props[name].input["ishidden"] = false;
              return inputCheckbox(name, error, details[i], this.props[name].input);
            } else {
              return (this.props[name].input["ishidden"] = true);
            }
          case name === "first_hosting_period_end_date":
            if (
              String(conditionalFields[0]["hosting_indefinitely"]).match(/^no_.+/) ||
              conditionalFields[0]["hosting_indefinitely"] === false
            ) {
              this.props[name].input["ishidden"] = false;
            } else {
              this.props[name].input["ishidden"] = true;
            }
            return inputDatePicker(name, error, details[i], this.props[name].input);
          case name === "photography_timing":
            let f = name.replace("_timing", "_required");
            if (
              String(conditionalFields[0][f]).match(/^yes_.+/) ||
              conditionalFields[0][f] === true
            ) {
              this.props[name].input["ishidden"] = false;
              return inputRadio(name, error, details[i], this.props[name].input);
            } else {
              return (this.props[name].input["ishidden"] = true);
            }
          case name === "early_photography_date":
            if (conditionalFields[0]["photography_timing"] === "before_handover_date") {
              this.props[name].input["ishidden"] = false;
            } else {
              this.props[name].input["ishidden"] = true;
            }
            return inputDatePicker(name, error, details[i], this.props[name].input);
          case details[i].type === "date_picker" &&
            !["first_hosting_period_end_date", "early_photography_date"].includes(name):
            return inputDatePicker(name, error, details[i], this.props[name].input);
          case [
            "online_id_verified",
            "offline_id_verified",
            "profile_picture_added",
          ].includes(name):
            if (
              String(conditionalFields[0]["airbnb_account_exists"]).match(/^yes_.+/) ||
              conditionalFields[0]["airbnb_account_exists"] === true
            ) {
              this.props[name].input["ishidden"] = false;
              return inputCheckbox(name, error, details[i], this.props[name].input);
            } else return (this.props[name].input["ishidden"] = true);
          case ["airbnb_email", "airbnb_password"].includes(name):
            if (
              String(conditionalFields[0]["airbnb_account_exists"]).match(/^yes_.+/) ||
              conditionalFields[0]["airbnb_account_exists"] === true
            ) {
              this.props[name].input["ishidden"] = false;
              return inputDefault(
                name,
                error,
                details[i],
                this.props[name].input,
                "255"
              );
            } else return (this.props[name].input["ishidden"] = true);
          default:
            return inputDefault(name, error, details[i], this.props[name].input);
        }
        return null;
      })
    );
  }
}

export default FormComponent;
