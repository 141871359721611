import React from "react";
import { FormattedMessage } from "react-intl";
import { PreferredLanguageSelector } from "./preferred_language_display";

export const ClientPreferences = () => {
  return (
    <div id="client_preferences">
      <div className="col s12">
        <hr />
      </div>
      <div className="col s12">
        <h5>
          <FormattedMessage id="profile.preferences" defaultMessage="Preferences" />
        </h5>
      </div>
      <div className="col s12">
        <PreferredLanguageSelector />
      </div>
    </div>
  );
};
