import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { listTypes, displayFullDate } from "../../common/util";
import SmallSpinner from "../utility/small_spinner";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { FormattedMessage } from "react-intl";
import { LocaleContext, PropertyIDContext } from "../../global_contexts";
import { rGETwLocale } from "../../actions/api";

const ServiceDashboard = () => {
  const { locale } = useContext(LocaleContext);
  const { propertyID } = useContext(PropertyIDContext);
  const [serviceLog, setServiceLog] = useState();

  useEffect(() => {
    if (propertyID) {
      const url = `/servicelog/${propertyID}/dashboard/`;
      rGETwLocale(url, locale).then((resp) => {
        const service_log = resp && resp.data.results;
        setServiceLog(service_log);
      });
    }
  }, [propertyID, locale]);

  function serviceIcons(service) {
    const services = service.replace(/\s*,\s*/g, ",").split(",");
    const serviceIcon = services.map((service, i) => listTypes(service, i));
    return serviceIcon;
  }

  return (
    <div className="col s12 higher-card">
      <NavLink exact to="/services/">
        <div className="card">
          <div className="cardHeader">
            <span className="title">
              <FormattedMessage
                id="dashboard.service.title"
                defaultMessage="Service Log"
              />
            </span>
            <span className="chevron hide-on-small-only">
              <Icon
                icon={ICONS.CHEVRON.d}
                width={ICONS.CHEVRON.width}
                height={ICONS.CHEVRON.height}
              />
            </span>
          </div>
          <div className="card-content">
            {serviceLog ? (
              <div>
                {serviceLog.length === 0 ? (
                  <div>
                    <FormattedMessage
                      id="dashboard.service.no_services"
                      defaultMessage="There have been no services yet!"
                    />
                  </div>
                ) : (
                  <div className="service-log">
                    <div className="content-el">
                      <div className="service-txt">
                        <FormattedMessage
                          id="dashboard.service.last_service"
                          defaultMessage="Last Service on"
                        />{" "}
                        {serviceLog &&
                          displayFullDate(
                            serviceLog[serviceLog.length - 1]["date"].slice(0, 10)
                          )}
                      </div>
                      <div>
                        {serviceLog && (
                          <div className="display-start">
                            {serviceIcons(
                              serviceLog[serviceLog.length - 1]["services_icon_string"]
                            )}
                            <p className="mt-10">
                              {serviceLog[serviceLog.length - 1]["services_string"]}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <SmallSpinner />
            )}
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default ServiceDashboard;
