export interface DataEditor {
  sections: DataSection[];
}

export interface DataSection {
  title: string;
  fields: FieldList;
}

export type Integer = number & { __integer: true };
export type RegexString = string & { __regex: true };
export type Key = string;
export type FieldList = Array<DataField | DataFieldGroup>;
export interface FieldDependency {
  key: Key;
  value: any;
}

export enum DataFieldType {
  GROUP = "group",
  TEXT = "text",
  EMAIL = "email",
  BOOLEAN = "boolean",
  TELEPHONE = "telephone",
  INTEGER = "integer",
  DECIMAL = "decimal",
  NUMERIC = "numeric",
  DURATION = "duration",
  CHOICE = "choice",
  SHORT_CHOICE = "short_choice",
  RICH_TEXT = "rich_text",
  GB_POSTCODE = "gb_postcode",
  ADDRESS = "address",
  SHORT_ADDRESS = "short_address",
  GB_ACCOUNT_NUMBER = "gb_account_number",
  GB_SORT_CODE = "gb_sort_code",
  IBAN = "iban",
  BIC = "bic",
  NIF = "nif",
  PASSWORD = "password",
  DIRECT_DEBIT = "direct_debit_status",
  DATE = "date",
  BEDS = "beds",
  MULTI_SELECT_CHOICE = "multi_select_choice",
  DOCUMENT = "document",
}

export interface BaseDataField {
  type: DataFieldType;
  label?: string;
  depends_on?: FieldDependency;
}

export interface DataFieldGroup extends BaseDataField {
  type: DataFieldType.GROUP;
  description?: string;
  fields: FieldList;
}

export interface DataField extends BaseDataField {
  key: Key;
  required?: boolean;
  help?: string;
  value?: any;
  options?: any; // be more speicific later
}

export interface TextField extends DataField {
  type: DataFieldType.TEXT;
  min_length?: Integer;
  max_length?: Integer;
  pattern?: RegexString;
  prompt?: string;
  mask?: string;
}

export interface NumericField extends DataField {
  type: DataFieldType.INTEGER | DataFieldType.DECIMAL;
  min_value?: Integer;
  max_value?: Integer;
  step?: number;
}

export type ChoiceList = Array<{ label: string; value: string }>;

export interface ChoiceField extends DataField {
  type:
    | DataFieldType.CHOICE
    | DataFieldType.SHORT_CHOICE
    | DataFieldType.MULTI_SELECT_CHOICE;
  choices: ChoiceList;
}

export type Bed = {
  id?: number;
  fixed: boolean;
  size: string;
};

export interface BedsField extends DataField {
  options: {
    label_add_button: string;
    label_fixed_beds: string;
    label_non_fixed_beds: string;
    label_size: string;
    size_choices: ChoiceList;
  };
}

export interface MultiSelectField extends DataField {
  type: DataFieldType.MULTI_SELECT_CHOICE;
  choices: Array<{
    value: number;
    label: string;
  }>;
  value: Array<number>;
}

export interface PatchResponse {
  field_errors?: { [key: string]: string[] };
  non_field_errors?: string[];
}

export interface DocumentsField extends DataField {
  value: {
    id: number;
    files: Array<any>;
    document_number: string;
    start_date: string;
    end_date: string;
  };
  options: {
    label_start_date?: string;
    label_end_date?: string;
    label_document_number?: string;
    min_file_count?: number;
    max_file_count?: number;
    permitted_file_types?: string;
  };
}
