import React, { Component } from "react";
import { Fields, reduxForm, getFormValues } from "redux-form";
import { connect } from "react-redux";

import * as actions from "../../actions";
import { SECTIONS } from "./fields";
import FormComponent from "./forms/form_component";
import { validateGuestInfo } from "./forms/validation";
import { saveCheck } from "./forms/common";
import { compose } from "redux";

const M = (window as any).M;
var initChar = 0;

interface Props {
  checklistValues: any;
  initialValues: any;
  property: any;
  check_status: any;
}

class GuestInfo extends Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      guest_info: {},
      propertyID: localStorage.getItem("property_id")
        ? localStorage.getItem("property_id")
        : undefined,
    };
  }

  componentDidUpdate(prevProps: any) {
    Object.keys(this.props.checklistValues).forEach((key) => {
      if (this.props.checklistValues[key] !== prevProps.checklistValues[key]) {
        if (key === "rubbish_dates" && this.props.checklistValues[key] === "") {
          this.props.checklistValues[key] = null;
        }
        this.setState(() => ({ guest_info: this.props.checklistValues }));
        M.FormSelect.init(document.querySelector("select.rubbish_dates"));
        M.FormSelect.init(document.querySelector("select.keysafe_type"));
        document.querySelectorAll(".materialize-textarea").forEach((el) => {
          M.textareaAutoResize(el);
        });
      }
    });
    if (this.props.initialValues) {
      document.querySelectorAll(".materialize-textarea").forEach((el) => {
        M.textareaAutoResize(el);
      });
      M.FormSelect.init(document.querySelector("select.rubbish_dates"));
      M.FormSelect.init(document.querySelector("select.keysafe_type"));
    }
    if (prevProps.property !== this.props.property) {
      if (prevProps.property.id !== this.props.property.id && this.props.property.id) {
        this.setState(() => ({ propertyID: this.props.property.id }));
        validateGuestInfo(this.state.guest_info);
        M.CharacterCounter.init(document.querySelectorAll(".countChar"));
        initChar = M.CharacterCounter.init(document.querySelectorAll(".countChar"))
          .length;
      }
    }
    if (
      document.querySelectorAll(".countChar").length > 0 &&
      document.querySelectorAll(".countChar").length !== initChar
    ) {
      M.CharacterCounter.init(document.querySelectorAll(".countChar"));
      initChar = M.CharacterCounter.init(document.querySelectorAll(".countChar"))
        .length;
    }
  }

  render() {
    return (
      <form
        id="guest_info"
        className={`${
          this.props.check_status && this.props.check_status[3].stage
        } fields-cont`}
        onChange={(e) => saveCheck(e, this.state.guest_info, validateGuestInfo)}
        onBlur={(e) => saveCheck(e, this.state.guest_info, validateGuestInfo)}>
        {this.props &&
          SECTIONS["Guest Info"].map((section, index) => (
            <Fields
              names={section.names}
              component={FormComponent}
              details={section.details}
              key={section.names[index]}
              conditionalValue={[]}
              chargeType={undefined}
              propertyID={this.state.propertyID}
              section="guest_info"
            />
          ))}
      </form>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    property: state.property.property_info,
    checklist: state.checklist.guest_checklist,
    update_checklist: state.checklist.update_checklist,
  };
}

const GuestInfoForm = reduxForm({
  form: "guest_info",
  validate: validateGuestInfo,
  enableReinitialize: true,
  // reset
});

const GuestInfoConnect = connect(
  (state: any) => {
    return {
      initialValues: state.checklist.guest_checklist,
      checklistValues: getFormValues("guest_info")(state) || {},
    };
  },
  { actions }
);

const withConnect = connect(mapStateToProps, actions);

export default compose<any>(withConnect, GuestInfoConnect, GuestInfoForm)(GuestInfo);
