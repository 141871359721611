import React, { useState, useEffect, useContext } from "react";
import { rGETwLocale } from "../../actions/api";
import { LocaleContext, PropertyIDContext } from "../../global_contexts";
import { DYNAMIC_DATA_CONTEXT, MATERIALIZE_REF } from "./contexts";

export const DynamicOnboardingChecklistDataProvider = ({ children }: any) => {
  const { locale } = useContext(LocaleContext);
  const [data, setData] = useState<any>(null);
  const [mData, setMData] = useState((window as any).M);
  const { propertyID } = useContext(PropertyIDContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await rGETwLocale(
          `/onboarding/${propertyID}/stages/checklist/`,
          locale
        );
        const data = response.data;
        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [propertyID, locale]);

  return (
    <MATERIALIZE_REF.Provider value={mData}>
      <DYNAMIC_DATA_CONTEXT.Provider value={data}>
        {children}
      </DYNAMIC_DATA_CONTEXT.Provider>
    </MATERIALIZE_REF.Provider>
  );
};
