import {
  PROPERTY_CHECKLIST,
  PROPERTY_AMENITIES,
  ACCOUNT_CHECKLIST,
  GUEST_CHECKLIST,
  ONBOARDING_CHECKLIST,
  UPDATE_CHECKLIST,
  BEDS,
  NUMBER_BEDS,
  CHECKLIST_STATUS,
  DIRECT_DEBIT_STATUS,
  PROPERTY_DETAILS_STATUS,
  PROPERTY_AMENITIES_STATUS,
  ONBOARDING_DETAILS_STATUS,
  GUEST_INFO_STATUS,
  ACCOUNT_INFO_STATUS,
} from "../actions/types";

export default function checklistReducer(state = {}, action) {
  switch (action.type) {
    case PROPERTY_CHECKLIST:
      return { ...state, property_checklist: action.payload };
    case PROPERTY_AMENITIES:
      return { ...state, amenities_checklist: action.payload };
    case ACCOUNT_CHECKLIST:
      return { ...state, account_checklist: action.payload };
    case GUEST_CHECKLIST:
      return { ...state, guest_checklist: action.payload };
    case ONBOARDING_CHECKLIST:
      return { ...state, onboarding_checklist: action.payload };
    case UPDATE_CHECKLIST:
      return { ...state, update_checklist: action.payload };
    case BEDS:
      return { ...state, beds: action.payload };
    case NUMBER_BEDS:
      return { ...state, number_beds: action.payload };
    case CHECKLIST_STATUS:
      return { ...state, checklist_status: action.payload };
    case DIRECT_DEBIT_STATUS:
      return { ...state, direct_debit_status: action.payload };
    case PROPERTY_DETAILS_STATUS:
      return { ...state, property_details_status: action.payload };
    case PROPERTY_AMENITIES_STATUS:
      return { ...state, property_amenities_status: action.payload };
    case ONBOARDING_DETAILS_STATUS:
      return { ...state, onboarding_details_status: action.payload };
    case GUEST_INFO_STATUS:
      return { ...state, guest_info_status: action.payload };
    case ACCOUNT_INFO_STATUS:
      return { ...state, account_info_status: action.payload };
    default:
      return state;
  }
}
