import React, { useState, useEffect, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { rGET, rPUT } from "../../actions/api";
import OptionPanel from "./option-panel";
import { HostContext } from "../../global_contexts";

const Notifications = () => {
  const { hostID } = useContext(HostContext);
  const [isManager, setIsManager] = useState(
    JSON.parse(localStorage.getItem("user")).is_manager
  );
  const [saveChanges, setSaveChanges] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [data, setData] = useState({
    global_notification_preference: false,
    notification_preferences: [],
  });

  useEffect(() => {
    if (hostID) {
      getInitialValues();
    }
  }, [hostID]);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      setIsManager(JSON.parse(localStorage.getItem("user")).is_manager);
    }
  }, [localStorage.getItem("user")]);

  // Handles the change event of a given toggle and update the state
  function checkboxHandler(e) {
    e.persist();
    if (e.target.id === "global_notification_preference") {
      setData({
        ...data,
        [e.target.id]: e.target.checked,
      });
    } else {
      const selectedIndex = data.notification_preferences.findIndex(
        (obj) => obj.type === e.target.name
      );

      let items = [...data.notification_preferences];
      let item = { ...items[selectedIndex] };

      item.preference = e.target.checked;

      items[selectedIndex] = item;

      setData({
        ...data,
        notification_preferences: items,
      });
    }
  }

  // Get's the initial values of the users settings
  function getInitialValues() {
    rGET(`/preferences/${hostID}/`).then((response) => {
      setData(response.data);
      setInitialValues(response.data);
    });
  }

  function getToggleLabel(type) {
    const toggleItems = [
      {
        type: "Payout",
        label:
          "Airbnb Payout - You will receive a confirmation before Airbnb releases funds to your bank account.",
      },
      {
        type: "Booking_Emails",
        label:
          "Booking Emails - You will receive an email whenever a booking is created, changed or cancelled.",
      },
    ];

    const result = toggleItems.find((x) => x.type === type);

    if (result) {
      return result.label;
    } else {
      return "Toggle info not found";
    }
  }

  // Helper method - radio buttons values are always returned as strings, and we want a boolean
  function stringToBool(value) {
    if (value === "true") {
      return true;
    } else {
      return false;
    }
  }

  // Handles an update from the radio buttons (the option panels)
  function radioHandler(e) {
    e.persist();
    setData({
      ...data,
      global_notification_preference: stringToBool(e.target.value),
    });
  }

  function submitHandler(e, data) {
    e.preventDefault();
    rPUT(`/preferences/${hostID}/`, data)
      .then((response) => {
        setSaveChanges(false);
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function onFormChange(e) {
    setSaveChanges(true);
  }

  function clearFormSelections(e) {
    setData(initialValues);
    setSaveChanges(false);
  }

  // The list of options provided by the API
  function toggleList() {
    if (data.global_notification_preference) {
      return data.notification_preferences
        .sort((a, b) => a.type.localeCompare(b.type))
        .map((item) => {
          return (
            <div key={item.type} className="notification-switch-container">
              <p>{getToggleLabel(item.type)}</p>
              <div className="switch">
                <label>
                  <input
                    disabled={isManager}
                    name={item.type}
                    id={item.type}
                    component="input"
                    type="checkbox"
                    checked={item.preference}
                    onChange={(e) => {
                      checkboxHandler(e);
                    }}
                  />
                  <span className="lever" />
                </label>
              </div>
            </div>
          );
        });
    }
  }

  return (
    <div className="email-notifications">
      <div>
        <div className="col s12">
          <hr />
        </div>

        <form
          onSubmit={(e) => submitHandler(e, data)}
          onChange={(e) => onFormChange(e)}>
          {data.notification_preferences.length > 0 && (
            <div className="preferences">
              <div className="row">
                <div className="col s12">
                  <h5>
                    <FormattedMessage
                      id="profile.notifications.title"
                      defaultMessage="Email Notifications"
                    />
                  </h5>
                  {isManager && (
                    <p className="manager-message">
                      <FormattedMessage
                        id="profile.notifications.manager_message"
                        defaultMessage="You are currently logged in as a manager, therefore the below options are read-only."
                      />
                    </p>
                  )}
                </div>
              </div>
              <div className="option-panel-container">
                <OptionPanel
                  active={data.global_notification_preference}
                  isManager={isManager}
                  containerId="emails-enabled"
                  inputId="emails-enabled-input"
                  title="Notifications Enabled"
                  description="You will receive the notifications below via email if they have been specifically enabled."
                  value={true}
                  onChange={(e) => {
                    radioHandler(e);
                  }}
                />
                <OptionPanel
                  active={!data.global_notification_preference}
                  isManager={isManager}
                  containerId="emails-disabled"
                  inputId="emails-disabled-input"
                  title="Notifications Disabled"
                  description="You will not receive email notifications. Important account updates will still continue to be sent."
                  value={false}
                  onChange={(e) => {
                    radioHandler(e);
                  }}
                />
              </div>
              <div className="col s12">{toggleList()}</div>
              {saveChanges && (
                <div className="col s12">
                  <div className="notification-button-container">
                    <button className="btn" onClick={(e) => clearFormSelections(e)}>
                      Clear
                    </button>
                    <button className="btn" type="submit">
                      Save
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Notifications;
