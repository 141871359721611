import React, { Component, DOMElement } from "react";
import { Fields, reduxForm, getFormValues, formValueSelector } from "redux-form";
import { connect } from "react-redux";

import * as actions from "../../actions";
import { SECTIONS } from "./fields";
import { validateAccountInfo } from "./forms/validation";
import FormComponent from "./forms/form_component";
import { saveCheck } from "./forms/common";
import { compose } from "redux";

const M = (window as any).M;
var initChar = 0;

interface Props {
  checklistValues: any;
  property: any;
  charge_type: any;
  direct_debit_status: any;
  check_status: any;
  checkAirbnbAccountExists: any;
}

class AccountInfo extends Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      account_info: {},
      propertyID: localStorage.getItem("property_id")
        ? localStorage.getItem("property_id")
        : undefined,
      chargeType: "",
    };
  }

  componentDidUpdate(prevProps: any) {
    Object.keys(this.props.checklistValues).forEach((key) => {
      if (this.props.checklistValues[key] !== prevProps.checklistValues[key]) {
        this.setState(() => ({ account_info: this.props.checklistValues }));
      }
    });
    if (prevProps.property !== this.props.property) {
      if (prevProps.property.id !== this.props.property.id && this.props.property.id) {
        this.setState(() => ({ propertyID: this.props.property.id }));
        validateAccountInfo(this.state.account_info);
        M.CharacterCounter.init(document.querySelectorAll(".countChar"));
        initChar = M.CharacterCounter.init(document.querySelectorAll(".countChar"))
          .length;
      }
    }
    if (
      document.querySelectorAll(".countChar").length > 0 &&
      document.querySelectorAll(".countChar").length !== initChar
    ) {
      M.CharacterCounter.init(document.querySelectorAll(".countChar"));
      initChar = M.CharacterCounter.init(document.querySelectorAll(".countChar"))
        .length;
    }
    if (prevProps.charge_type !== this.props.charge_type) {
      this.setState(() => ({ chargeType: this.props.charge_type }));
    }
  }

  getairbnbAccountExistsStatus(airbnbAccountExistsValue: any) {
    this.props.checkAirbnbAccountExists(airbnbAccountExistsValue);
  }

  render() {
    const { airbnbAccountExistsValue }: any = this.props;
    let arrconditionalValue: any[] = [
      { airbnb_account_exists: airbnbAccountExistsValue },
    ];
    if (this.props.direct_debit_status !== "organization_disabled") {
      arrconditionalValue[1] = { direct_debit_status: this.props.direct_debit_status };
    }

    const currency = localStorage.getItem("currency") || "GBP";
    const country = localStorage.getItem("country") || "GB";

    return (
      <form
        id="account_info"
        className={`${
          this.props.check_status && this.props.check_status[2].stage
        } fields-cont`}
        onChange={(e) => {
          const el: any = e.target;
          if (el.getAttribute("name") === "airbnb_account_exists") {
            this.getairbnbAccountExistsStatus(airbnbAccountExistsValue);
          }
          saveCheck(e, this.state.account_info, validateAccountInfo);
        }}
        onBlur={(e) => {
          saveCheck(e, this.state.account_info, validateAccountInfo);
        }}>
        {this.props &&
          SECTIONS["Account Info"].map((section, index) => {
            var section_names =
              currency === "GBP"
                ? (section.names["GBP"] as string[])
                : (section.names["EUR"] as string[]);
            var section_details =
              currency === "GBP"
                ? (section.details["GBP"] as any[])
                : (section.details["EUR"] as any[]);

            // index 0 since this check is only needed for the accounting section
            if (index === 0 && country === "ES" && !section_names.includes("nif")) {
              section_names.splice(1, 0, "nif");
              section_details.splice(1, 0, {
                type: "text",
                label: "NIF",
                helper:
                  "We need your identification number to display on your invoices, as required by Spanish tax law. Your ID number will either be Documento Nacional de Identidad if you are a Spanish national, or Número de Identificación de Extranjero if you are a foreign national. If you are registering as a business, you need to supply the Certificado de Identificación Fiscal.",
                icon: "account_balance",
                section: 0,
              });
            }

            return (
              <Fields
                key={index}
                names={section_names}
                component={FormComponent}
                details={section_details}
                conditionalValue={arrconditionalValue}
                chargeType={this.state.chargeType}
                propertyID={this.state.propertyID}
                section="account_info"
              />
            );
          })}
      </form>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    property: state.property.property_info,
    charge_type: state.property.charge_type,
    checklist: state.checklist.account_checklist,
    update_checklist: state.checklist.update_checklist,
    direct_debit_status: state.checklist.direct_debit_status,
    current_status: state.direct_debit.current_status,
  };
}

const AccountInfoForm = reduxForm({
  form: "account_info",
  validate: validateAccountInfo,
  enableReinitialize: true,
});

// Decorate with connect to read form values
const selector = formValueSelector("account_info");

const AccountInfoConnect = connect(
  (state: any) => {
    const airbnbAccountExistsValue: any = selector(state, "airbnb_account_exists");
    return {
      airbnbAccountExistsValue,
      initialValues: state.checklist.account_checklist,
      checklistValues: getFormValues("account_info")(state) || {},
    };
  },
  { actions }
);

const withConnect = connect(mapStateToProps, actions);

export default compose<any>(
  withConnect,
  AccountInfoConnect,
  AccountInfoForm
)(AccountInfo);
