import { createContext } from "react";

export const HostContext = createContext({
  hostID: "",
  setHostID: () => {},
  hostName: "",
  setHostName: () => {},
});

// TODO: Consolidate PreferredLanguageContext & LocaleContext with the above 'HostContext'
export const PreferredLanguageContext = createContext({
  preferredLanguage: "",
  setPreferredLanguage: () => {},
});

export const LocaleContext = createContext({
  locale: "",
  setLocale: () => {},
});

export const PropertyContext = createContext({
  propertyName: "",
  setPropertyName: () => {},
  propertyCountry: "",
  setPropertyCountry: () => {},
});

// TODO: Consolidate with the above property context
export const PropertyIDContext = createContext({
  propertyID: "",
  setPropertyID: () => {},
});

// TODO: Consolidated with the above property context
export const AvailabilityWindowContext = createContext({
  availabilityWindow: "",
  setAvailabilityWindow: () => {},
});
