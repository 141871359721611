import React from "react";
import { FormattedMessage } from "react-intl";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import LanguageSelector from "./language_selector";

const Header = () => {
  return (
    <nav className="header white">
      <div className="nav-wrapper row">
        <a className="brand-logo center" href="/">
          <div className="hide-on-large-only valign-wrapper">
            <Icon
              width={22}
              height={55}
              viewBox="0 0 156 194"
              multiplePaths={[
                { d: ICONS.LOGO_MOBILE_3.d, color: "#F5A423" },
                { d: ICONS.LOGO_MOBILE_2.d, color: "#87AF4B" },
                { d: ICONS.LOGO_MOBILE_1.d, color: "#020CB9" },
              ]}
            />
          </div>
          <div className="hide-on-med-and-down valign-wrapper">
            <img
              src={require(`../../components/svg/ptk-logo-website.svg`).default}
              alt="Pass the Keys"
            />
          </div>
        </a>
        <ul>
          <li>
            <a
              className="left cobalt-blue valign-wrapper"
              href="http://www.passthekeys.com/">
              <Icon
                icon={ICONS.BACK_ARROW.d}
                width={ICONS.BACK_ARROW.width}
                height={ICONS.BACK_ARROW.height}
              />
              <div className="hide-on-small-only ml-5 mb-3">passthekeys.com</div>
              <span className="hide-on-med-and-up ml-5">
                <FormattedMessage
                  id="no_auth_header.marketing_link"
                  defaultMessage="Website"
                />
              </span>
            </a>
          </li>
        </ul>
        <div className="language_switcher col s4 right">
          <LanguageSelector showPopup={false} />
        </div>
      </div>
    </nav>
  );
};

export default Header;
