import {
  LIST_PHOTOS,
  LIST_DATES,
  ORIGINAL_FILTER,
  RECENT_FILTER,
} from "../actions/types";

export default function photosReducer(state = {}, action) {
  switch (action.type) {
    case LIST_PHOTOS:
      return { ...state, list_photos: action.payload };
    case LIST_DATES:
      return { ...state, list_dates: action.payload };
    case ORIGINAL_FILTER:
      return { ...state, original_filter: action.payload };
    case RECENT_FILTER:
      return { ...state, recent_filter: action.payload };
    default:
      return state;
  }
}
