import React from "react";
import { NavLink } from "react-router-dom";
import LanguageSelector from "./language_selector";

const DesktopNavbar = () => {
  return (
    <>
      <ul className="left hide-on-med-and-down logo_container">
        <li>
          <NavLink exact to="" className="nav-logo desktop">
            <div className="hide-on-med-and-down valign-wrapper">
              <img
                src={require(`../../components/svg/ptk-logo-website.svg`).default}
                alt="Pass the Keys"
                className="logo_container__logo"
              />
            </div>
          </NavLink>
        </li>
      </ul>
      <div className={`language_switcher hide-on-med-and-down right col s4`}>
        <LanguageSelector />
      </div>
    </>
  );
};

export default DesktopNavbar;
