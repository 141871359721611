import { rPOST, rGETwLocale } from "./api";
import { PROFILE, FEEDBACK } from "./types";

//profile
export function profileData(id, locale, { setHostID, setHostName }) {
  return function (dispatch) {
    rGETwLocale(`/profile/${id}/?language_code=${locale}`, locale)
      .then((resp) => {
        const profile = resp && resp.data.results[0];
        if (profile) {
          setHostID(profile.id);
          setHostName(profile.name);
          localStorage.setItem("profile", JSON.stringify(profile));
          dispatch({
            type: PROFILE,
            payload: profile,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

// user feedback
export function feedbackProperty(property_id, data) {
  return function (dispatch) {
    rPOST(`/feedback/${property_id}/`, data).then((resp) => {
      const feedback = resp && resp.data;
      dispatch({
        type: FEEDBACK,
        payload: feedback,
      });
    });
  };
}
