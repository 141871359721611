import { rGET } from "../../actions/api";
import { setToolTip } from "./selector";

const currentDate = new Date();
currentDate.setHours(0, 0, 0, 0);

function addNotes(note, start_date, end_date) {
  let startDay = document.querySelector(`#group_${start_date}`);
  checkAddNote(note, startDay.children[0]);
  let next_el = startDay.nextElementSibling;
  while (next_el && next_el.id.slice(6) <= end_date) {
    checkAddNote(note, next_el.children[0]);
    next_el = next_el.nextElementSibling;
  }
}

function checkAddNote(note, el) {
  let note_el = document.querySelector(`#${el.id} > .note`);
  if (note && note.length > 0) {
    note_el.classList.remove("hide");
    note_el.setAttribute("data-tooltip", note);
  } else {
    note_el.classList.add("hide");
    note_el.setAttribute("data-tooltip", "");
  }
}

const hideErrorMsgBox = () => {
  const ErrorMsgBox = document.querySelectorAll("#CPCalendarErrorMsg");
  // hide error box
  ErrorMsgBox.forEach((box) => box.classList.add("hide"));
};

function getCalendarData(property_id, start_date, end_date) {
  const url = `/calendar/${property_id}/${start_date}/to/${end_date}/`;
  return rGET(url).then((resp) => {
    let calendar_data = resp ? resp.data : undefined;
    return calendar_data;
  });
}

async function getCalendarRange(property_id) {
  const url = `/calendar/${property_id}/range/`;
  return rGET(url).then((resp) => {
    let list = resp && resp.data.results;
    let listMonths = [];
    list &&
      list.forEach((d, i) => {
        listMonths[i] = new Date(d);
      });
    return listMonths;
  });
}

function getCalendarWorms(property_id, start_date, end_date) {
  const url = `/calendar/${property_id}/worms/${start_date}/to/${end_date}/`;
  return rGET(url).then((resp) => {
    let calendar_worms = resp && resp.data.results;
    return calendar_worms;
  });
}
function getCalendarCleaningWorms(property_id, start_date, end_date) {
  const url = `/servicelog/${property_id}/${start_date}/to/${end_date}/`;
  return rGET(url).then((resp) => {
    let all_services = resp && resp.data.results;
    let calendar_clean_worms = [];
    all_services.forEach((service) => {
      let split_services = service.services_string.split(", ");
      for (let i = 0; i < split_services.length; i++) {
        const s = split_services[i];
        if (s === "Cleaning") {
          calendar_clean_worms.push(service);
          break;
        }
      }
    });
    return calendar_clean_worms;
  });
}

function spinner(remove) {
  if (!remove) {
    if (document.querySelector("#spinner")) {
      document.querySelector("#spinner").classList.remove("hide");
    }
    if (
      document.querySelector(".left-arrow") &&
      document.querySelector(".right-arrow")
    ) {
      document.querySelector(".left-arrow").setAttribute("disabled", true);
      document.querySelector(".right-arrow").setAttribute("disabled", true);
    }
  } else {
    if (document.querySelector("#spinner")) {
      document.querySelector("#spinner").classList.add("hide");
    }
    if (
      document.querySelector(".left-arrow") &&
      document.querySelector(".right-arrow")
    ) {
      document.querySelector(".left-arrow").removeAttribute("disabled");
      document.querySelector(".right-arrow").removeAttribute("disabled");
    }
  }
}

async function fillCellCalendar(
  property_id,
  start_date,
  end_date,
  locale,
  intl,
  { setCalendarDates }
) {
  spinner(false);
  let calendar_data = await getCalendarData(property_id, start_date, end_date);
  let calendar_days = calendar_data.results;
  let listDays = document.querySelectorAll(".calendar_tiles_row > div > li");
  clearCalendarWorms();
  listDays.forEach(function (d, index) {
    // Remove any previous instance of past class
    d.parentNode.classList.remove("is_past");
    // If date is in the past add past class
    if (new Date(d.getAttribute("value")) < currentDate) {
      d.parentNode.classList.add("is_past");
    }
    // Remove modal trigger from past dates
    if (d.parentNode.classList.contains("is_past")) {
      if (d.attributes.getNamedItem("data-target")) {
        d.attributes.removeNamedItem("data-target");
      }
      d.classList.remove("modal-trigger");
    }

    if (
      !calendar_days.find((el) =>
        el && el["date"] ? el["date"] === d.id.slice(5) : undefined
      )
    ) {
      calendar_days.unshift(undefined);
      calendar_days[index] = {
        available: false,
      };
    }
    addNotes(calendar_days[index].notes, d.id.slice(5), d.id.slice(5));
    let class_availability = calendar_days[index].available
      ? "available"
      : "unavailable";
    d.classList.remove("available");
    d.classList.remove("unavailable");
    d.classList.add(class_availability);
    d.setAttribute("requires_clean", d.classList.contains("available") ? "on" : "off");
  });
  let calendar_worms = await getCalendarWorms(property_id, start_date, end_date);
  let calendar_cleaning_worms = await getCalendarCleaningWorms(
    property_id,
    start_date,
    end_date
  );
  if (calendar_worms && calendar_worms.length > 0) {
    generateWormLayer(listDays, calendar_worms, calendar_cleaning_worms, intl, {
      setCalendarDates,
    });
  }

  // applies z-index dynamically to cells (required due to the fact that the worms need to be )
  calculateDisplayLayers(listDays);

  // check for pre-selected cells (meaning a worm is selected)
  const checkSelectedEls = Array.from(document.querySelectorAll(".selected"));
  if (checkSelectedEls.length > 0) {
    checkSelectedEls.forEach((el) => {
      Array.from(el.children).forEach((c) => {
        if (c.classList.contains("worm")) {
          c.classList.add("selected_worm");
        }
      });
    });
  }

  // calendar loading complete
  spinner(true);

  // ensure hob worms are localised appropriately
  translateWorms(locale);

  // extract the calendar_read_only flag from the payload
  return "calendar_read_only" in calendar_data
    ? calendar_data.calendar_read_only
    : false;
}

function clearCalendarWorms() {
  let allWorms = document.querySelectorAll(".worm");
  allWorms.forEach((worm) => {
    worm.remove();
  });
  let allGroups = document.querySelectorAll('*[id^="group_"]');
  allGroups.forEach((group) => {
    if (group.hasAttribute("worm_start")) {
      group.removeAttribute("worm_start");
      group.removeAttribute("worm_end");
    }
  });
}

function calculateDisplayLayers(listDays) {
  // find all non is_past cells
  let validDays = [];
  let inValidDays = [];
  listDays.forEach((d) => {
    if (!d.parentNode.classList.contains("is_past")) {
      validDays.push(d);
    } else {
      inValidDays.push(d);
    }
    // clear old z-index's
    d.parentNode.style.zIndex = "";
  });

  // iterate through the found cells to generate the z-index calculation for the layer
  // (this is done because a cell with a worm needs to at least be 1 above its subsequent cells & 2 if in its end cell another worm starts)
  let calendarZIndexArray = [];
  validDays.forEach((d) => {
    let tracker =
      d.nextElementSibling && d.nextElementSibling.classList.contains("worm") ? 3 : 2;
    if (tracker === 3) {
      calendarZIndexArray = calendarZIndexArray.map((zindex) => {
        return zindex > 2 ? (zindex += 1) : zindex;
      });
    }
    calendarZIndexArray.push(tracker);
  });

  // apply z-index's to calendar
  validDays.forEach((d, i) => {
    d.parentNode.style.zIndex = calendarZIndexArray[i];
  });
  inValidDays.forEach((d, i) => {
    const childrenArr = d.parentNode.children;
    for (let i = 0; i < childrenArr.length; i++) {
      const child = childrenArr[i];
      const child_worm_end = child.getAttribute("worm_end");
      if (child_worm_end && new Date(child_worm_end) > new Date()) {
        child.style.zIndex = 100;
      }
    }
  });
}

function generateWormLayer(
  listDays,
  calendar_worms,
  calendar_cleaning_worms,
  intl,
  { setCalendarDates }
) {
  let endWeekElements = getEndWeekElements(listDays);
  let wormDivs = constructWormDivsArray(calendar_worms, endWeekElements, {
    setCalendarDates,
  });

  // populated first so the objects exist and can be used for reference in the generation of the booking & superblock worms
  let cleaning_worms = populateCalendarWithCleaningWorms(
    listDays,
    calendar_cleaning_worms,
    intl
  );

  populateCalendarWithWorms(wormDivs, listDays);
  checkForActivelyHostedWorm(wormDivs, listDays);
  generateWormClickEvents(wormDivs, listDays, cleaning_worms);
}

// finds the last day element for each week
function getEndWeekElements(listDays) {
  let tempArr = [];
  listDays.forEach(function (d) {
    if (d.getAttribute("weekday") === "6") {
      tempArr.push(d);
    }
  });
  return tempArr;
}

function populateCalendarWithCleaningWorms(listDays, calendar_cleaning_worms, intl) {
  let cleaning_worms = [];
  listDays.forEach((d) => {
    if (calendar_cleaning_worms && calendar_cleaning_worms.length > 0) {
      calendar_cleaning_worms.forEach((cleaning_worm) => {
        let date_worm_clean_day = cleaning_worm["date"];
        let worm_cleaning_time = cleaning_worm["start_time"];
        if (d.id.slice(5) === date_worm_clean_day) {
          if (worm_cleaning_time.length > 5) {
            let splitCT = worm_cleaning_time.split(":");
            splitCT.pop();
            worm_cleaning_time = splitCT.join(":");
          }
          let new_el = document.createElement("div");
          new_el.classList.add(["worm"], ["worm--clean"]);
          new_el.setAttribute("clean_date", date_worm_clean_day);
          new_el.setAttribute("cleaning_time", worm_cleaning_time);
          let placeholder_icon = document.querySelector(
            '[data-js="cleaning_icon_to_be_used_in_cleaning_worms"]'
          );
          if (placeholder_icon) {
            let new_clean_svg = placeholder_icon.children[0].cloneNode(true);
            d.parentElement.appendChild(new_el);
            // Add cleaning time tooltip to cleaning worm
            let clean_tooltip = document.createElement("small");
            clean_tooltip.id = "cleaning-info-tooltip";
            clean_tooltip.classList.add(["tooltipped"]);
            clean_tooltip.setAttribute("data-position", "top");
            clean_tooltip.setAttribute(
              "data-tooltip",
              `${intl.formatMessage({
                id: "tooltip.cleaning_from",
                defaultMessage: "Cleaning From",
              })}: ${worm_cleaning_time}`
            );
            clean_tooltip.appendChild(new_clean_svg);
            new_el.appendChild(clean_tooltip);
            // Ensure newly added tooltip is triggerable
            var elems = document.querySelectorAll(".tooltipped");
            setToolTip(elems);

            cleaning_worms.push(new_el);
          }
        }
      });
    }
  });
  return cleaning_worms;
}

function populateCalendarWithWorms(wormDivs, listDays) {
  wormDivs.forEach((wormDiv) => {
    listDays.forEach(function (d) {
      let group = d.parentElement;
      if (group.id.slice(6) === wormDiv.getAttribute("worm_placement")) {
        group.append(wormDiv);
      }
    });
  });
}

function checkForActivelyHostedWorm(wormDivs, listDays) {
  let lastFoundWorm = undefined;
  // iterate through all worms in the past, will result in the above variable being set to the latest one
  listDays.forEach((d) => {
    let g = d.parentElement;
    if (g.classList.contains("is_past") && g.children.length > 1) {
      Array.from(g.children).forEach((child) => {
        if (child.classList.contains("worm")) {
          lastFoundWorm = child;
        }
      });
    }
  });
  // check if the worm's end date is equal to or greater than the current date, and if so set the zindex so the worm displays correctly
  if (
    lastFoundWorm &&
    new Date(lastFoundWorm.getAttribute("worm_end")) >= currentDate
  ) {
    let logWorm = false;
    let connected_worms = [lastFoundWorm];
    for (let i = wormDivs.length - 1; i >= 0; i--) {
      if (wormDivs[i] === lastFoundWorm || logWorm) {
        logWorm = true;
        if (
          connected_worms[0].classList.contains("worm--end") ||
          connected_worms[0].classList.contains("worm--middle")
        ) {
          if (wormDivs[i].classList.contains("worm--middle")) {
            connected_worms.push(wormDivs[i]);
          } else if (wormDivs[i].classList.contains("worm--start")) {
            connected_worms.push(wormDivs[i]);
            logWorm = false;
          }
        }
      }
    }
    connected_worms.forEach((worm) => {
      // z-index given needs to be high enough to compensate for the scenarios where the calendar is full of one-day worms
      worm.style.zIndex = "50";
    });
  }
}

function generateWormClickEvents(wormDivs, listDays, cleaning_worms) {
  let recentlyFoundWormData = [];

  listDays.forEach((d) => {
    let group = d.parentElement;
    if (
      recentlyFoundWormData.length === 0 ||
      d.id.slice(5) === recentlyFoundWormData[1]
    ) {
      if (group.children.length > 1 || d.id.slice(5) === recentlyFoundWormData[1]) {
        let groupChildrenArr = Array.from(group.children);
        groupChildrenArr.forEach((child) => {
          if (
            child.classList.contains("worm") &&
            !child.classList.contains("worm--clean")
          ) {
            // make & add array containing each related worm to this one
            let logWorm = false;
            let loggedWorms = [];

            wormDivs.forEach((wormDiv) => {
              if (!logWorm) {
                if (wormDiv === child) {
                  if (
                    wormDiv.classList.contains("worm--start") ||
                    wormDiv.classList.contains("worm--middle")
                  ) {
                    logWorm = true;
                    loggedWorms.push(wormDiv);
                  }
                }
              } else {
                if (wormDiv.classList.contains("worm--end")) {
                  logWorm = false;
                }
                loggedWorms.push(wormDiv);
              }
            });

            if (loggedWorms.length === 0) {
              loggedWorms.push(child);
            }

            cleaning_worms.forEach((cleanWorm) => {
              if (
                cleanWorm.getAttribute("clean_date") === child.getAttribute("worm_end")
              ) {
                if (!loggedWorms.includes(cleanWorm)) {
                  loggedWorms.push(cleanWorm);
                }
              }
            });

            if (
              recentlyFoundWormData.length > 0 &&
              recentlyFoundWormData[2].includes(child)
            ) {
              // Do nothing since this will be a worm--end whom already has an event
            } else {
              recentlyFoundWormData = [
                child.getAttribute("worm_start"),
                child.getAttribute("worm_end"),
                loggedWorms,
              ];

              applySelectWormEventToGroup(group, recentlyFoundWormData);
            }

            if (d.id.slice(5) === recentlyFoundWormData[1]) {
              recentlyFoundWormData = [];
            }
          } else if (
            group.children.length === 1 ||
            child.classList.contains("worm--clean")
          ) {
            if (d.id.slice(5) === recentlyFoundWormData[1]) {
              recentlyFoundWormData = [];
            }
          }
        });
      }
    } else if (d.id.slice(5) < recentlyFoundWormData[1]) {
      applySelectWormEventToGroup(group, recentlyFoundWormData);
    }
  });
}
function applySelectWormEventToGroup(group, recentlyFoundWormData) {
  group.setAttribute("worm_start", recentlyFoundWormData[0]);
  group.setAttribute("worm_end", recentlyFoundWormData[1]);
}

function constructWormDivsArray(calendar_worms, endWeekElements, { setCalendarDates }) {
  let tempWormDivs = [];
  calendar_worms.forEach(function (worm) {
    let date_worm_start = worm["start_date"];
    let date_worm_end = worm["end_date"];
    let worm_type = calculateWormReference(worm);
    let name = worm["name"];
    let id = worm["id"];

    // Check if worm is superblock or homeowner booking and correct the name for the worm if so
    if (worm_type === "superblock") {
      name = "";
      // WARNING: If Connect is ever updated and superblocks get given an 'extra day' for their end date, then these lines will need to be removed from here
      // If the worm is a sueprblock, then we know that Connect doesn't give us it with the end_date on the checkout date, so we compensate for this
      let actualEndDate = new Date(date_worm_end);
      actualEndDate.setDate(actualEndDate.getDate() + 1);
      let checkoutDate = actualEndDate.toISOString().split("T")[0];
      date_worm_end = checkoutDate;
    } else if (worm_type === "host_use") {
      name = "With cleaning";
    }

    let wormStartCell = document.querySelector(`#group_${date_worm_start}`)
      ? document.querySelector(`#group_${date_worm_start}`).children[0]
      : "no start cell";
    let wormEndCell = document.querySelector(`#group_${date_worm_end}`)
      ? document.querySelector(`#group_${date_worm_end}`).children[0]
      : "no end cell";

    const start_day =
      wormStartCell !== "no start cell"
        ? new Date(wormStartCell.id.slice(5)).getDate()
        : false;
    const end_day =
      wormEndCell !== "no end cell"
        ? new Date(wormEndCell.id.slice(5)).getDate()
        : false;

    let tempAllWormsRequired = identifyWormDivStartPoints(
      start_day,
      end_day,
      wormStartCell,
      wormEndCell,
      endWeekElements
    );

    tempWormDivs = constructIdentifiedWormDivs(
      { date_worm_start, date_worm_end, worm_type, name, id },
      start_day,
      end_day,
      wormEndCell,
      wormStartCell,
      tempAllWormsRequired,
      tempWormDivs,
      { setCalendarDates }
    );
  });
  return tempWormDivs;
}

function calculateWormReference(wormData) {
  switch (wormData["type"]) {
    case "booking":
      if (wormData["is_homeowner"]) {
        return "host_use";
      }
      if (wormData["platform"] === "BringYourOwnBooking") {
        return "byo_booking";
      }
      return "booking";
    case "superblock":
      return "superblock";
    default:
      // Included to ensure code works with the old version of the api call from Connect
      if (wormData["platform"] === "BringYourOwnBooking") {
        return "byo_booking";
      }
      return "booking";
  }
}

function identifyWormDivStartPoints(
  start_day,
  end_day,
  wormStartCell,
  wormEndCell,
  endWeekElements
) {
  const CALENDAR_TILES_ROW = document.querySelector(`.calendar_tiles_row`);
  let tempAllWormsRequired = start_day
    ? [wormStartCell]
    : CALENDAR_TILES_ROW
    ? [CALENDAR_TILES_ROW.firstChild.children[0]]
    : [];

  if (start_day && end_day) {
    endWeekElements.forEach((el) => {
      if (
        el.id.slice(5) >= wormStartCell.id.slice(5) &&
        el.id.slice(5) < wormEndCell.id.slice(5)
      ) {
        tempAllWormsRequired.push(requiredEndWeek(el));
      }
    });
    return tempAllWormsRequired;
  }

  for (let i = 0; i < endWeekElements.length; i++) {
    let el = endWeekElements[i];

    // triggered when the worm goes off into another unseen month
    if (start_day && !end_day) {
      if (
        el.id.slice(5) >= wormStartCell.id.slice(5) &&
        endWeekElements[endWeekElements.length - 1] !== el
      ) {
        tempAllWormsRequired.push(requiredEndWeek(el));
      }
      // triggered when the worm comes from another unseen month
    } else if (!start_day && end_day) {
      if (el.id.slice(5) < wormEndCell.id.slice(5)) {
        tempAllWormsRequired.push(requiredEndWeek(el));
      }
      // triggered when the worm comes from & goes off into other unseen months
    } else if (!start_day && !end_day) {
      // stops the first line being duplicated (since it exists by default in the array);
      if (i !== 0 && CALENDAR_TILES_ROW) {
        tempAllWormsRequired.push(CALENDAR_TILES_ROW.children[i * 7].children[0]);
      }
    }
  }
  return tempAllWormsRequired;
}

function requiredEndWeek(el) {
  let targetCellDate = el.id.slice(0, 13);
  let targetCellDayNumCalc = parseInt(el.id.slice(13)) + 1;
  targetCellDate +=
    targetCellDayNumCalc > 9 ? targetCellDayNumCalc : "0" + targetCellDayNumCalc;
  return document.querySelector(`#${targetCellDate}`);
}

function constructIdentifiedWormDivs(
  wormData,
  start_day,
  end_day,
  wormEndCell,
  wormStartCell,
  tempAllWormsRequired,
  tempWormDivs,
  { setCalendarDates }
) {
  if (tempAllWormsRequired.length > 1) {
    for (let i = 0; i < tempAllWormsRequired.length; i++) {
      let weekStartDayNumCalculation = parseInt(
        tempAllWormsRequired[i].getAttribute("weekday")
      );

      // since identified worm div start points will always start from the beginning of the week, we know that if it doesn't, then this was the initial wormStartCell
      if (weekStartDayNumCalculation !== 0) {
        tempWormDivs.push(
          constructWormDivElement(
            { setCalendarDates },
            wormData.date_worm_start,
            wormData.date_worm_end,
            wormData.date_worm_start,
            6 - weekStartDayNumCalculation,
            wormData.worm_type,
            wormData.name,
            wormData.id,
            true,
            "worm--start"
          )
        );
        continue;
      }

      if (tempAllWormsRequired.length - 1 > i || !end_day) {
        if (start_day === new Date(tempAllWormsRequired[i].id.slice(5)).getDate()) {
          // worm div starts on the first day of the displayed calendar
          tempWormDivs.push(
            constructWormDivElement(
              { setCalendarDates },
              wormData.date_worm_start,
              wormData.date_worm_end,
              wormData.date_worm_start,
              6 - weekStartDayNumCalculation,
              wormData.worm_type,
              wormData.name,
              wormData.id,
              true,
              "worm--start"
            )
          );
          continue;
        }

        if (!tempWormDivs[0]) {
          // ensures the name is placed on the first 'middle' worm
          tempWormDivs.push(
            constructWormDivElement(
              { setCalendarDates },
              wormData.date_worm_start,
              wormData.date_worm_end,
              tempAllWormsRequired[i].id.slice(5),
              6,
              wormData.worm_type,
              wormData.name,
              wormData.id,
              true,
              "worm--middle"
            )
          );
          continue;
        }

        // if none of the above are true, then we know this is a 'blank' middle worm
        tempWormDivs.push(
          constructWormDivElement(
            { setCalendarDates },
            wormData.date_worm_start,
            wormData.date_worm_end,
            tempAllWormsRequired[i].id.slice(5),
            6,
            wormData.worm_type,
            "",
            wormData.id,
            true,
            "worm--middle"
          )
        );
        continue;
      }

      // if none of the above are true, then we know that this must be true because:
      // --weekStartDayNumCalculation will be equal to 0 (first day in the week)
      // --it is only possible with end_day set
      // --& will be the last item in the array hence "tempAllWormsRequired.length - 1" will be equal to i
      tempWormDivs.push(
        constructWormDivElement(
          { setCalendarDates },
          wormData.date_worm_start,
          wormData.date_worm_end,
          tempAllWormsRequired[i].id.slice(5),
          1 + parseInt(wormEndCell.getAttribute("weekday")),
          wormData.worm_type,
          "",
          wormData.id,
          true,
          "worm--end"
        )
      );
    }
    return tempWormDivs;
  }

  if (end_day) {
    if (start_day) {
      // if both are present then we know this worm is contained within a single row
      tempWormDivs.push(
        constructWormDivElement(
          { setCalendarDates },
          wormData.date_worm_start,
          wormData.date_worm_end,
          wormData.date_worm_start,
          parseInt(wormEndCell.getAttribute("weekday")) -
            parseInt(wormStartCell.getAttribute("weekday")),
          wormData.worm_type,
          wormData.name,
          wormData.id
        )
      );
      return tempWormDivs;
    }
    // if only the end_day is present then we know this worm comes from a previous offscreen month
    tempWormDivs.push(
      constructWormDivElement(
        { setCalendarDates },
        wormData.date_worm_start,
        wormData.date_worm_end,
        tempAllWormsRequired[0].id.slice(5),
        1 + parseInt(wormEndCell.getAttribute("weekday")),
        wormData.worm_type,
        wormData.name,
        wormData.id,
        true,
        "worm--end"
      )
    );
    return tempWormDivs;
  }

  // reached when the worm is only present in the last row and extends into the next month
  if (tempAllWormsRequired.length > 0) {
    tempWormDivs.push(
      constructWormDivElement(
        { setCalendarDates },
        wormData.date_worm_start,
        wormData.date_worm_end,
        wormData.date_worm_start,
        6 - parseInt(tempAllWormsRequired[0].getAttribute("weekday")),
        wormData.worm_type,
        wormData.name,
        wormData.id,
        true,
        "worm--start"
      )
    );
  }
  return tempWormDivs;
}

function constructWormDivElement(
  { setCalendarDates },
  worm_start_date,
  worm_end_date,
  worm_placement_date,
  worm_numDays,
  worm_type,
  worm_name,
  worm_id,
  divided = false,
  worm_position = undefined
) {
  let tempWormDiv = document.createElement("div");
  let tempWormSpan = document.createElement("span");
  tempWormSpan.classList.add("name");
  tempWormSpan.textContent = worm_name;
  tempWormDiv.append(tempWormSpan);

  // add defaults
  tempWormDiv.classList.add("worm");
  tempWormDiv.classList.add(worm_type);
  tempWormDiv.setAttribute("worm_start", worm_start_date);
  tempWormDiv.setAttribute("worm_end", worm_end_date);
  tempWormDiv.setAttribute("worm_placement", worm_placement_date);
  tempWormDiv.setAttribute("worm_id", worm_id);

  // add click event
  tempWormDiv.onmousedown = (e) => {
    setCalendarDates({
      start_date: e.target.getAttribute("worm_start"),
      end_date: e.target.getAttribute("worm_end"),
    });
  };

  let widthCalcuation = undefined;
  if (!divided) {
    // Calculates percentage width for worm (the first day is always 60% since 30% in start & end)
    widthCalcuation = 60 + (worm_numDays - 1) * 100;

    tempWormDiv.style.right = "-" + (widthCalcuation - 30) + "%";
  } else {
    tempWormDiv.classList.add(worm_position);

    if (worm_position === "worm--start") {
      widthCalcuation = 30 + worm_numDays * 100;
      // adds days to px calculation; to compensate for grid borders
      widthCalcuation += worm_numDays;

      tempWormDiv.style.right = "-" + (widthCalcuation - 30) + "%";
    } else if (worm_position === "worm--end") {
      widthCalcuation = 30 + (worm_numDays - 1) * 100;
    } else if (worm_position === "worm--middle") {
      // adds 6px to compensate for the grid borders
      widthCalcuation = 6 + (worm_numDays + 1) * 100;
    }
  }
  tempWormDiv.style.width = widthCalcuation + "%";

  return tempWormDiv;
}

function translateWorms(locale) {
  const translations = {
    en: {
      wormName: "With cleaning",
    },
    es: {
      wormName: "Con limpieza",
    },
  };

  const translatedText = translations[locale] && translations[locale]["wormName"]; // Only has one use atm, but good to leave like this in case we need to expand capability in future

  const hostuseWorms = document.querySelectorAll(".worm.host_use>span");
  hostuseWorms.forEach((worm) => {
    if (worm.innerHTML !== translations[locale] && worm.innerHTML !== "") {
      worm.innerHTML = translatedText;
    }
  });
}

export { getCalendarRange, fillCellCalendar, hideErrorMsgBox, translateWorms, spinner };
