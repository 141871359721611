import React from "react";
import { FormattedMessage } from "react-intl";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";

const SignUpComponent = () => {
  return (
    <div data-testid="signUpComponent" className="col s12 signed">
      <Icon
        icon={ICONS.HANDSHAKE.d}
        width={70}
        height={70}
        color="#f5a423"
        viewBox="0 -30 512 512"
      />
      <h5 className="title">
        <FormattedMessage
          id="signup.title"
          defaultMessage="You've completed signing up to Pass the Keys!"
        />
      </h5>
      <FormattedMessage
        id="signup.description"
        defaultMessage="<p>Welcome to your Client Portal, please set a password so you can access this
          space in the future.
          {br}
          {br}
          After setting your password, you can complete the Onboarding Checklist so that
          we can continue with your onboarding.
          {br}
          {br}
          Once that is complete, the Client Portal will allow you to control many aspects
          of how your property is managed by Pass the Keys.</p>"
        values={{
          p: (...chunks) => <p>{chunks}</p>,
          br: <br />,
        }}
      />
    </div>
  );
};

export default SignUpComponent;
