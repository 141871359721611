import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { rGET } from "../../actions/api";
import { isMobile, resizePhotos } from "../../common/util";
import SmallSpinner from "../utility/small_spinner";
import FilterPhotos from "./filter_photos";
import ContainerPhotos from "./container_photos";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { FormattedMessage, injectIntl } from "react-intl";
import { LocaleContext, PropertyIDContext } from "../../global_contexts";
import { listRooms } from "../../actions";

const M = window.M;

const Photos = (props) => {
  const { locale } = useContext(LocaleContext);
  const { propertyID } = useContext(PropertyIDContext);
  const [mostRecentPhotos, setMostRecentPhotos] = useState(null);
  const [listPhotos, setListPhotos] = useState(null);
  const photosTooltipRef = useRef(null);

  useEffect(() => {
    const photosTooltipInstance = M.Tooltip.init(photosTooltipRef.current, {
      position: "left",
      html: props.intl.formatMessage({
        id: "photos.info_popup",
        defaultMessage:
          "Please note that not all our suppliers have a smartphone which is compatible with our App. In this case, unfortunately the photos won't be available in the Client Portal. We are working to improve the compatibility, so over time you'll be able to view the photos from all the cleanings we performed.",
      }),
    });
    if (photosTooltipInstance) {
      photosTooltipInstance.tooltipEl.classList.add("right_arrow");
    }
  }, [locale]);

  useEffect(() => {
    props.listPhotos(propertyID, "Original", props.intl);
    listDates();
  }, [propertyID]);

  useEffect(() => {
    props.listPhotos(propertyID, "Original", props.intl);
    if (!props.list_dates) {
      listDates();
    } else {
      if (props.list_dates.length > 1) {
        localStorage.setItem("recentFilter", props.list_dates[1].value);
        getRecentPhotos(props.list_dates[1].value);
      } else {
        if (props.list_photos && props.list_photos.length > 0) {
          localStorage.setItem("originalFilter", props.list_dates[0].value);
          localStorage.setItem("recentFilter", props.list_dates[0].value);
          getRecentPhotos(props.list_dates[0].value);
        }
      }
    }
  }, []);

  useEffect(() => {
    setListPhotos(props.list_photos);
  }, [props.list_photos]);

  useEffect(() => {
    if (props.list_dates > 0) {
      localStorage.setItem("originalFilter", props.list_dates[0].value);
      if (props.list_dates.length > 1) {
        localStorage.setItem("recentFilter", props.list_dates[1].value);
        getRecentPhotos(props.list_dates[1].value);
      } else {
        localStorage.setItem("recentFilter", props.list_dates[0].value);
        localStorage.setItem("listRecentPhotos", JSON.stringify(props.list_photos));
        setMostRecentPhotos(props.list_photos);
      }
    }
  }, [props.list_dates]);

  async function listDates() {
    let list_dates = await getlistDates();
    if (!list_dates || !list_dates[0]) {
      return;
    }
    return list_dates.length > 1
      ? getRecentPhotos(list_dates[1].value)
      : getRecentPhotos(list_dates[0].value);
  }

  async function getlistDates() {
    return rGET(`propertyphotos/${propertyID}/set-list/`).then(async (resp) => {
      const list_dates = resp && resp.data.results;
      list_dates &&
        list_dates.forEach(function (date, i) {
          list_dates[i] = { value: date, label: date.split("-").join("/") };
        });
      localStorage.setItem("listDates", JSON.stringify(list_dates));
      return list_dates;
    });
  }

  function getRecentPhotos(filter) {
    rGET(`/propertyphotos/${propertyID}/${filter}/`).then((resp) => {
      const recentPhotos = resp.data.results;
      const width = isMobile() ? 370 : 630;
      resizePhotos(recentPhotos, width);
      localStorage.setItem("listRecentPhotos", JSON.stringify(recentPhotos));
      setMostRecentPhotos(recentPhotos);
    });
  }

  function getData(filter, val) {
    switch (true) {
      case filter === "selectedPastDate":
        props.listPhotos(propertyID, val.value, props.intl);
        break;
      case filter === "selectedRecentDate":
        getRecentPhotos(val.value);
        break;
      default:
        break;
    }
  }

  return (
    <div className="row photos">
      <div className="col s12">
        <div className="row">
          <div className="col s12">
            <div className="container-title">
              <h4>
                <FormattedMessage id="photos.title" defaultMessage="Photos" />
              </h4>
              <span className="info_btn" ref={photosTooltipRef}>
                <Icon
                  icon={ICONS.INFO.d}
                  width={ICONS.INFO.width}
                  height={ICONS.INFO.height}
                />
              </span>
            </div>
          </div>
        </div>
        {props.list_photos ? (
          props.list_photos.length > 0 ? (
            <div>
              {listRooms(props.list_photos, props.intl)}
              <FilterPhotos sendData={getData} />
              <ContainerPhotos
                photos={listPhotos}
                mostRecentPhotos={mostRecentPhotos}
              />
            </div>
          ) : (
            <div className="row">
              <div className="col s12">
                <FormattedMessage
                  id="photos.no_photos"
                  defaultMessage="There are no photos yet!"
                />
              </div>
            </div>
          )
        ) : (
          <SmallSpinner />
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    list_dates: JSON.parse(localStorage.getItem("listDates")),
    list_photos: state.photos.list_photos,
  };
}

export default connect(mapStateToProps, actions)(injectIntl(Photos));
