import React, { useEffect, useState } from "react";
import { NumericField } from "../../schema";

export const InputNumericComponent = (props: {
  onValueChange: any;
  field: NumericField;
}) => {
  const [value, setValue] = useState(props.field.value || "");

  // Since 0 counts as false when checking for value, we need to handle it separately
  useEffect(() => {
    if (value === "" && props.field.value === 0) {
      setValue("0");
    }
  }, [props.field.value]);

  return (
    <input
      id={props.field.key}
      type="number"
      className="validate input-field--small"
      min={props.field.min_value}
      max={props.field.max_value}
      step={props.field.step}
      required={props.field.required}
      value={value}
      placeholder="0"
      onChange={(e) => {
        setValue(e.currentTarget.value);
      }}
      onBlur={(e) => {
        let realValue = value;
        if (realValue === "") {
          realValue = null;
        }
        props.onValueChange(e.currentTarget, realValue);
      }}
    />
  );
};
