import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import * as actions from "../../actions";
import { rPUT } from "../../actions/api";
import { CHECKLIST_STATUS } from "../../actions/types";
import { store } from "../../store";
import { HELPER_TXT } from "./fields";
import PropertyDetails from "./property_details";
import PropertyAmenities from "./property_amenities";
import AccountInfo from "./account_info";
import GuestInfo from "./guest_info";
import OnboardingDetails from "./onboarding_details";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";

const M = (window as any).M;
const sections = [
  "Account Info",
  "Property Details",
  "Property Amenities",
  "Guest Info",
  "Onboarding",
];

interface RefObject<T> {
  readonly current: T | null;
}

interface Props extends RouteComponentProps<any> {
  checkAuthState: Function;
  getChecklistInfo: Function;
  history: any;
  location: any;
  property: any;
  onboarding_details_status: any;
  account_info_status: any;
  checklist_status: any;
  direct_debit_status: any;
  property_amenities_status: any;
  property_details_status: any;
  guest_info_status: any;
  unlistenHistory: any;
}

class OnboardingChecklist extends Component<Props, any> {
  private InfoIconRef: RefObject<HTMLElement> = React.createRef();
  private btnSendChecklistRef: RefObject<any> = React.createRef();
  private loadSubmitRef: RefObject<any> = React.createRef();
  private collapsibleHeaderRef: any[] = [];
  private collapsibleBodyRef: any[] = [];
  private dwRef: any[] = [];
  private upRef: any[] = [];
  private unlistenHistory: any;
  private historyChanged: boolean = false;
  constructor(props: any) {
    super(props);
    this.changeInfo = this.changeInfo.bind(this);
    this.toggleSection = this.toggleSection.bind(this);
    this.redirectUser = this.redirectUser.bind(this);
    this.checkAirbnbAccountExists = this.checkAirbnbAccountExists.bind(this);
    this.state = {
      section: undefined,
      charge_type: "",
      checklistSections: [],
      propertyID: localStorage.getItem("property_id"),
      openSection: undefined,
      airbnb_account_exists: false,
    };
  }

  showRightSection(index: number) {
    const el_info_icon = this.InfoIconRef && this.InfoIconRef.current;
    // const collapsible_body = this.collapsibleBodyRef[index];
    const collapsible_body_i = document.querySelector(
      `#el_${index} > .collapsible-body`
    );

    if (collapsible_body_i && collapsible_body_i.classList.contains("show")) {
      collapsible_body_i && collapsible_body_i.classList.remove("show");
      el_info_icon && el_info_icon.classList.add("hide");
      this.showDownIcon(index);
      this.setState(() => ({ section: undefined }));
    } else {
      collapsible_body_i && collapsible_body_i.classList.add("show");
      el_info_icon && el_info_icon.classList.remove("hide");
      this.setState(() => ({ section: sections[index] }));
      this.showUpIcon(index);
      document.querySelectorAll(".materialize-textarea").forEach((el) => {
        M.textareaAutoResize(el);
      });
      [0, 1, 2, 3, 4].map((num) => {
        if (num !== index) {
          const collapsible_body_num = document.querySelector(
            `#el_${num} > .collapsible-body`
          );
          collapsible_body_num && collapsible_body_num.classList.remove("show");
          this.showDownIcon(num);
        }
        return null;
      });
      window.scrollTo(0, 0);
    }
    if ([0, 1, 3].includes(index)) {
      el_info_icon && el_info_icon.classList.add("hide");
    }
  }

  componentDidMount() {
    this.props.checkAuthState(this.props.history);
    this.props.getChecklistInfo(this.state.propertyID);
    let sectionComp = localStorage.getItem(`sectionCompleted_${this.state.propertyID}`)
      ? localStorage.getItem(`sectionCompleted_${this.state.propertyID}`)
      : 0;
    let sectionCompleted = `sectionCompleted_${this.state.propertyID}`;
    this.setState(() => ({ [sectionCompleted]: sectionComp }));
    M.Modal.init(document.querySelector("#modalInfoSection"));
    M.Modal.init(document.querySelector("#modalChecklistComplete"));
    document.querySelectorAll(".materialize-textarea").forEach((el) => {
      M.textareaAutoResize(el);
    });
    if (this.props.location.state && this.props.location.state.mandate_complete) {
      this.showRightSection(0);
      setTimeout(
        () =>
          window.scrollTo(
            0,
            this.collapsibleBodyRef[0].querySelector("#direct-debit").offsetTop
          ),
        1000
      );
    }
    if (
      !this.collapsibleHeaderRef.includes(null) &&
      this.props.history.location.pathname
    ) {
      this.checkComplete();
    }
  }

  getIncompleteSection(sectionEl: any, onLoad: boolean = false) {
    let index_section: number = -1;
    let num_complete = 0;
    sectionEl.forEach((sec: any, i: number) => {
      const inProgress = /(initial|inprogress|error)/.test(sec.className);
      if (inProgress) {
        // Ensures the first section encountered is the one that gets shown
        if (index_section < 0) {
          index_section = i;
        }
      } else {
        num_complete += 1;
      }
    });
    if (index_section !== -1 && onLoad) this.showRightSection(index_section);
    return num_complete;
  }

  showDownIcon(index: number) {
    this.dwRef[index].classList.remove("hide");
    this.upRef[index].classList.add("hide");
  }

  showUpIcon(index: number) {
    this.dwRef[index].classList.add("hide");
    this.upRef[index].classList.remove("hide");
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.checkComplete();
    }
    if (JSON.stringify(prevProps.property) !== JSON.stringify(this.props.property)) {
      if (this.props.property.charge_type === "Fee") {
        this.setState(() => ({ charge_type: "Fee" }));
      } else {
        this.setState(() => ({ charge_type: "" }));
      }
      if (prevProps.property.id !== this.props.property.id && this.props.property.id) {
        window.scrollTo(0, 0);
        this.props.getChecklistInfo(this.props.property.id);
        if (this.state.checklistSections) {
          this.setState(() => ({ propertyID: this.props.property.id }));
        }
      }
    }
    if (
      prevState.sectionCompleted !== this.state.sectionCompleted &&
      this.btnSendChecklistRef.current
    ) {
      this.checkComplete(true);
      if (this.state.sectionCompleted === 5) {
        this.btnSendChecklistRef.current.disabled = false;
      } else {
        this.btnSendChecklistRef.current.disabled = true;
      }
    }
    if (["requires_check", "complete"].includes(this.props.checklist_status)) {
      let checklist_status = this.props.checklist_status;
      store.dispatch({
        type: CHECKLIST_STATUS,
        payload: checklist_status,
      });
      this.props.history.push("/");
    }
  }

  checkComplete(onLoad: boolean = false) {
    const num_completed = this.getIncompleteSection(this.collapsibleHeaderRef, onLoad);
    this.setState(() => ({ sectionCompleted: num_completed }));
  }

  changeInfo(value: any) {
    this.setState(() => ({ section: value }));
    M.Modal.getInstance(document.querySelector("#modalInfoSection")).open();
  }

  toggleSection(e: any, index: number) {
    e.stopPropagation();
    if (
      e.target.classList.contains("collapsible-header") ||
      e.target.parentElement.classList.contains("collapsible-header")
    ) {
      this.showRightSection(index);
    }
  }

  checkAirbnbAccountExists(airbnb_account_exists: boolean | string) {
    const exist =
      airbnb_account_exists === false ||
      airbnb_account_exists === "no_airbnb_account_exists";
    this.setState(() => ({ airbnb_account_exists: exist }));
  }

  infoSection(
    sec: any,
    charge_type: any,
    direct_debit_status: string,
    airbnb_account_exists: boolean
  ) {
    const accountSection = sec === "Account Info";
    const infoSection = document.getElementById("info-section");
    if (accountSection && !airbnb_account_exists) {
      infoSection && infoSection.classList.add("hide");
    } else {
      infoSection && infoSection.classList.remove("hide");
    }
    return HELPER_TXT[sec].map((section: any, i: number) => {
      if (accountSection && i === 0 && direct_debit_status !== "organization_disabled")
        return "";
      return (
        <div key={i} className={i === 0 && charge_type === "Fee" ? "hide" : ""}>
          <h6>{section.subject}</h6>
          {section["info"].map((info: any, i: number) => (
            <div key={i} className="i-info">
              {info.icon && (
                <span className="mr-10">
                  {ICONS[info.icon] ? (
                    <Icon
                      icon={ICONS[info.icon].d}
                      width={ICONS[info.icon].width}
                      height={ICONS[info.icon].height}
                      viewBox={ICONS[info.icon].viewBox}
                    />
                  ) : (
                    <i className="material-icons prefix">{info.icon}</i>
                  )}
                </span>
              )}
              {info.link ? (
                <p>
                  <strong>{info.strong} </strong>
                  Make sure you verify your profile to show potential guests this is not
                  a fraudulent account. It only takes a couple of minutes to complete,{" "}
                  <a
                    className="link"
                    href="https://www.passthekeys.com/static/pdf/Onboarding%20Manual.pdf"
                    target="_blank"
                    rel="noopener noreferrer">
                    click here
                  </a>{" "}
                  to check out this step-by-step guide we have created for you.
                </p>
              ) : (
                <p>
                  {info.strong && <strong>{info.strong}</strong>} {info.text}
                </p>
              )}
            </div>
          ))}
        </div>
      );
    });
  }

  redirectUser() {
    const propertyID = localStorage.getItem("property_id");
    this.loadSubmitRef.current.classList.remove("hide");
    rPUT(`/property_checklist/${propertyID}/`, { client_submitted: true }).then(() => {
      let checklist_status = "requires_check";
      M.toast({
        html: `<div style="position: relative">
              <div>
              <p>You are all done!</p>
              <p>Thank you for all your hard work. We can’t wait to get you that first booking! </p>
              <p className="mt-0">Now you can start exploring your Client Portal, where you can monitor all your bookings and much more.</p>
              <p className="mt-0">The important thing to do at this stage is to check your calendar availability and update it as necessary. Also, make sure to go to the ‘Preferences’ tab and add all your providers’ contact details, which we will use in case of emergency.</p>
              <p className="mt-0">Please also check out the Great Host Guide section to learn everything about our service.</p>
            </div>
            </div>`,
        classes: "centered",
        displayLength: 13000,
      });
      store.dispatch({
        type: CHECKLIST_STATUS,
        payload: checklist_status,
      });
    });
  }

  componentWillUnmount() {
    let instance = M.Modal.getInstance(
      document.querySelector("#modalChecklistComplete")
    );
    instance.destroy();
  }

  render() {
    return (
      <div className="row checklist">
        {/* modal checklist complete */}
        <div id="modalChecklistComplete" className="modal">
          <div className="col s12 right-align mt-10">
            <i className="material-icons close modal-close">close</i>
          </div>
          <div className="col s12">
            <p>
              Please confirm that you are happy with your responses. <br />
              Once you submit you will no longer be able to change them.
            </p>
            <div className="valign-wrapper">
              <button className="btn cancelS modal-close">Cancel</button>
              <button
                className="btn checklistSubmit"
                onClick={() => this.redirectUser()}>
                Submit
              </button>
            </div>
            <div ref={this.loadSubmitRef} id="loadSubmit" className="progress hide">
              <div className="indeterminate" />
            </div>
          </div>
        </div>
        {/* **** */}

        {/* modal info section */}
        <div id="modalInfoSection" className="modal">
          <div className="col s12 right-align mt-10">
            <i className="material-icons close modal-close">close</i>
          </div>
          <div className="col s12">
            {this.state.section &&
              !["Property Details", "Guest Info"].includes(this.state.section) &&
              this.infoSection(
                this.state.section,
                this.state.charge_type,
                this.props.direct_debit_status,
                this.state.airbnb_account_exists
              )}
          </div>
        </div>
        {/* **** */}

        <div className="col s12">
          <div className="row">
            <div className="col s12">
              <div className="container-title">
                <h4>Onboarding Checklist</h4>
              </div>
              <p>
                Please complete the questions below to the best of your knowledge.
                <br />
                We cannot proceed to keys handover or manage any bookings without a
                completed checklist.
                <br />
                Once you have completed all sections, please confirm by clicking
                ‘Submit’.
              </p>
              {this.state.sectionCompleted === 5 ? (
                <p>
                  You have completed all sections. Please remember to submit all info by
                  clicking{" "}
                  <a
                    href="/"
                    data-target="modalChecklistComplete"
                    className="modal-trigger">
                    here
                  </a>
                  .
                </p>
              ) : (
                <p>
                  You have completed{" "}
                  <strong className="cobalt-blue">{this.state.sectionCompleted}</strong>{" "}
                  of 5 sections.
                </p>
              )}
              <i
                ref={this.InfoIconRef}
                data-target="modalInfoSection"
                className={
                  this.state.airbnb_account_exists &&
                  this.state.section === "Account Info"
                    ? "material-icons fixed-info-icon modal-trigger hide-on-large-only "
                    : "material-icons fixed-info-icon modal-trigger hide-on-large-only hide"
                }>
                info
              </i>
            </div>
          </div>
        </div>

        <div className="col s12 l6">
          <ul className="collapsible check-sect">
            <li id="el_0" onClick={(e) => this.toggleSection(e, 0)}>
              {this.props.account_info_status && (
                <div
                  ref={(ref) => (this.collapsibleHeaderRef[0] = ref)}
                  className={`collapsible-header ${this.props.account_info_status.replace(
                    /_.+/,
                    ""
                  )}`}>
                  <div className="leftItm">
                    <i className="material-icons">
                      {this.props.account_info_status.replace(/_.+/, "") === "complete"
                        ? "check_box"
                        : "check_box_outline_blank"}
                    </i>
                    Account Info
                  </div>
                  <i
                    ref={(ref) => (this.dwRef[0] = ref)}
                    className="material-icons dropdown dw">
                    arrow_drop_down
                  </i>
                  <i
                    ref={(ref) => (this.upRef[0] = ref)}
                    className="material-icons dropdown up hide">
                    arrow_drop_up
                  </i>
                </div>
              )}
              <div
                className="collapsible-body"
                ref={(ref) => (this.collapsibleBodyRef[0] = ref)}>
                <AccountInfo checkAirbnbAccountExists={this.checkAirbnbAccountExists} />
              </div>
            </li>

            <li id="el_1" onClick={(e) => this.toggleSection(e, 1)}>
              {this.props.property_details_status && (
                <div
                  ref={(ref) => (this.collapsibleHeaderRef[1] = ref)}
                  className={`collapsible-header ${this.props.property_details_status.replace(
                    /_.+/,
                    ""
                  )}`}>
                  <div className="leftItm">
                    <i className="material-icons">
                      {this.props.property_details_status.replace(/_.+/, "") ===
                      "complete"
                        ? "check_box"
                        : "check_box_outline_blank"}
                    </i>
                    Property Details
                  </div>
                  <i
                    ref={(ref) => (this.dwRef[1] = ref)}
                    className="material-icons dropdown dw">
                    arrow_drop_down
                  </i>
                  <i
                    ref={(ref) => (this.upRef[1] = ref)}
                    className="material-icons dropdown up hide">
                    arrow_drop_up
                  </i>
                </div>
              )}
              <div
                className="collapsible-body"
                ref={(ref) => (this.collapsibleBodyRef[1] = ref)}>
                <PropertyDetails />
              </div>
            </li>

            <li id="el_2" onClick={(e) => this.toggleSection(e, 2)}>
              {this.props.property_amenities_status && (
                <div
                  ref={(ref) => (this.collapsibleHeaderRef[2] = ref)}
                  className={`collapsible-header ${this.props.property_amenities_status.replace(
                    /_.+/,
                    ""
                  )}`}>
                  <div className="leftItm">
                    <i className="material-icons">
                      {this.props.property_amenities_status.replace(/_.+/, "") ===
                      "complete"
                        ? "check_box"
                        : "check_box_outline_blank"}
                    </i>
                    Property Amenities
                  </div>
                  <i
                    ref={(ref) => (this.dwRef[2] = ref)}
                    className="material-icons dropdown dw">
                    arrow_drop_down
                  </i>
                  <i
                    ref={(ref) => (this.upRef[2] = ref)}
                    className="material-icons dropdown up hide">
                    arrow_drop_up
                  </i>
                </div>
              )}
              <div
                className="collapsible-body"
                ref={(ref) => (this.collapsibleBodyRef[2] = ref)}>
                <PropertyAmenities />
              </div>
            </li>

            <li id="el_3" onClick={(e) => this.toggleSection(e, 3)}>
              {this.props.guest_info_status && (
                <div
                  ref={(ref) => (this.collapsibleHeaderRef[3] = ref)}
                  className={`collapsible-header ${this.props.guest_info_status.replace(
                    /_.+/,
                    ""
                  )}`}>
                  <div className="leftItm">
                    <i className="material-icons">
                      {this.props.guest_info_status.replace(/_.+/, "") === "complete"
                        ? "check_box"
                        : "check_box_outline_blank"}
                    </i>
                    Guest Info
                  </div>
                  <i
                    ref={(ref) => (this.dwRef[3] = ref)}
                    className="material-icons dropdown dw">
                    arrow_drop_down
                  </i>
                  <i
                    ref={(ref) => (this.upRef[3] = ref)}
                    className="material-icons dropdown up hide">
                    arrow_drop_up
                  </i>
                </div>
              )}
              <div
                className="collapsible-body"
                ref={(ref) => (this.collapsibleBodyRef[3] = ref)}>
                <GuestInfo />
              </div>
            </li>

            <li id="el_4" onClick={(e) => this.toggleSection(e, 4)}>
              {this.props.onboarding_details_status && (
                <div
                  ref={(ref) => (this.collapsibleHeaderRef[4] = ref)}
                  className={`collapsible-header ${this.props.onboarding_details_status.replace(
                    /_.+/,
                    ""
                  )}`}>
                  <div className="leftItm">
                    <i className="material-icons">
                      {this.props.onboarding_details_status.replace(/_.+/, "") ===
                      "complete"
                        ? "check_box"
                        : "check_box_outline_blank"}
                    </i>
                    Onboarding
                  </div>
                  <i
                    ref={(ref) => (this.dwRef[4] = ref)}
                    className="material-icons dropdown dw">
                    arrow_drop_down
                  </i>
                  <i
                    ref={(ref) => (this.upRef[4] = ref)}
                    className="material-icons dropdown up hide">
                    arrow_drop_up
                  </i>
                </div>
              )}
              <div
                className="collapsible-body"
                ref={(ref) => (this.collapsibleBodyRef[4] = ref)}>
                <OnboardingDetails />
              </div>
            </li>
          </ul>
          <div className="saveChecklist">
            <button
              ref={this.btnSendChecklistRef}
              id="btnSendChecklist"
              data-target="modalChecklistComplete"
              className="btn checklistSubmit modal-trigger"
              disabled>
              Submit
            </button>
          </div>
        </div>

        {this.state.section &&
          !["Property Details", "Guest Info"].includes(this.state.section) && (
            <div className="hide-on-med-and-down col l6 xl5 offset-xl1">
              <div id="info-section" className="col s12">
                {this.infoSection(
                  this.state.section,
                  this.state.charge_type,
                  this.props.direct_debit_status,
                  this.state.airbnb_account_exists
                )}
              </div>
            </div>
          )}

        <div className="col s12 m6 xl5 offset-xl1">
          <div id="keySections" className="col s12">
            <h6>Checklist Status Key</h6>
            <div>
              <div className="keyInfo">
                <i className="material-icons">check_box_outline_blank</i>
                <p>Not started</p>
              </div>
              <div className="keyInfo">
                <i className="material-icons squash">check_box_outline_blank</i>
                <p>Incomplete answers</p>
              </div>
            </div>
            <div>
              <div className="keyInfo">
                <i className="material-icons rusty-red">check_box_outline_blank</i>
                <p>Errors exist in answers</p>
              </div>

              <div className="keyInfo">
                <i className="material-icons asparagus">check_box</i>
                <p>All answers completed</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    authenticated: state.auth.authenticated,
    property: state.property.property_info,
    checklist_status: state.checklist.checklist_status,
    direct_debit_status: state.checklist.direct_debit_status,
    property_details_status: state.checklist.property_details_status || "initial",
    property_amenities_status: state.checklist.property_amenities_status || "initial",
    onboarding_details_status: state.checklist.onboarding_details_status || "initial",
    guest_info_status: state.checklist.guest_info_status || "initial",
    account_info_status: state.checklist.account_info_status || "initial",
  };
}

export default connect(mapStateToProps, actions)(OnboardingChecklist);
