import React, { useEffect, useRef } from "react";
import { ICONS } from "../../svg/icons";
import Icon from "../../svg/svg_sprite";
import { FieldListComponent } from "../field_list";
import { DataFieldGroup } from "../schema";
import { checkDependencyOnLoad } from "../utility";

export const OnboardingChecklistGroup = (props: { field: DataFieldGroup }) => {
  const dropdownContent = useRef<any>(null);
  const groupContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    checkDependencyOnLoad(props.field.depends_on, groupContainer.current);
  }, [props.field.depends_on]);

  const handleDropdownClick = () => {
    if (dropdownContent.current) {
      if (!dropdownContent.current.classList.contains("hide")) {
        dropdownContent.current.classList.add("hide");
        dropdownContent.current.parentNode.classList.remove(
          "onboarding_checklist__group_wrapper--active"
        );
      } else {
        dropdownContent.current.classList.remove("hide");
        dropdownContent.current.parentNode.classList.add(
          "onboarding_checklist__group_wrapper--active"
        );
      }
    }
  };

  return (
    <div
      ref={groupContainer}
      className={
        "onboarding_checklist__group_wrapper onboarding_checklist__group_wrapper--active"
      }
      js-depends-on-key={props.field.depends_on?.key}
      js-depends-on-value={
        props.field.depends_on ? `${props.field.depends_on?.value}` : null
      }>
      <div className="dropdown" onClick={handleDropdownClick}>
        <span>{props.field.label}</span>
        <Icon
          icon={ICONS.CHEVRON.d}
          width={ICONS.CHEVRON.width}
          height={ICONS.CHEVRON.height}
        />
      </div>
      <div ref={dropdownContent} className="onboarding_checklist__group">
        {props.field.description ? <p>{props.field.description}</p> : null}
        <FieldListComponent fieldList={props.field.fields} />
      </div>
    </div>
  );
};
