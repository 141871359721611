import React from "react";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import PaymentAccount from "../direct_debit/direct_debit";
import { FormattedMessage } from "react-intl";

const MandateStatus = (props) => {
  const updateDD = ["Failed", "Cancelled", "Expired"].includes(
    props.customer.mandate_status
  );
  let cssClasses = "dd right-align";
  cssClasses += !updateDD ? " collapsible" : "";
  cssClasses += props.mobile ? " col s12" : "";
  return (
    <ul className={cssClasses}>
      {updateDD ? (
        <li className="fix-display">
          <PaymentAccount profile={true} />
        </li>
      ) : (
        <li>
          <div className="collapsible-header fix-display">
            <p>
              <span className="mr-15">
                <Icon
                  icon={ICONS.DIRECT_DEBIT.d}
                  width={28}
                  height={28}
                  viewBox="0 0 472.001 472.001"
                />
              </span>
              {!props.mobile && (
                <FormattedMessage
                  id="profile.mandate_status"
                  defaultMessage="Direct Debit status"
                />
              )}
            </p>
            <p className="mt-0">
              <FormattedMessage
                id={`profile.mandate_status.${props.customer.mandate_status}`}
                defaultMessage={props.customer.mandate_status}
              />
            </p>
          </div>
          <div className="collapsible-body">
            <div className="fix-display">
              <p>
                <FormattedMessage
                  id="profile.mandate.bank_name"
                  defaultMessage="Bank name:"
                />
              </p>
              <p>{props.customer.bank_name}</p>
            </div>
            <div className="fix-display">
              <p>
                <FormattedMessage
                  id="profile.mandate.account_number_end"
                  defaultMessage="Account number ending:"
                />
              </p>
              <p className="acc-end">
                <span>* * * * * *</span> {props.customer.account_number_ending}
              </p>
            </div>
          </div>
        </li>
      )}
    </ul>
  );
};

export default MandateStatus;
