import { Component } from "react";
import { withRouter } from "react-router";

const { detect } = require("detect-browser");
const browser = detect();

// TODO: Modernise this and remove need for 'detect-browser' package
class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (document.querySelector("#mobile-navbar")) {
        if (browser && ["chrome", "firefox", "safari"].includes(browser.name)) {
          try {
            document.querySelector("#mobile-navbar").scrollTo(0, 0);
          } catch (err) {
            console.log(err);
          }
        }
      }
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
