import React, { useContext } from "react";
import { LocaleContext } from "../../global_contexts";

// Expects a date prop and options prop
// For example: date={'2023-05-09'} options={{ month: 'long' }}
// When "en" locale returns: "May"
// When "es" locale returns: "mayo"

export const DatePartLocalised = (props) => {
  const { locale } = useContext(LocaleContext);
  let dateTimeFormat = null;
  if (locale === "en") {
    // required as the locale is defaulting to en-US (whom display dates differently to en-GB)
    dateTimeFormat = new Intl.DateTimeFormat(`${locale}-GB`, props.options);
  } else {
    dateTimeFormat = new Intl.DateTimeFormat(locale, props.options);
  }

  let date = new Date(props.date);
  let userTimezoneOffset = date.getTimezoneOffset() * 60000;
  // Will offset the effect of timezone and return the date to it's original state (time wise)
  let correct_date = new Date(
    date.getTime() + userTimezoneOffset * Math.sign(userTimezoneOffset)
  );

  const parts = dateTimeFormat.formatToParts(correct_date);
  const partValues = parts.map((p) => p.value);

  return <>{partValues}</>;
};
