import React, { useContext } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions";
import ResetPassword from "./reset_password";
import { renderLogoutMessage } from "./logout_message";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  LocaleContext,
  PreferredLanguageContext,
  PropertyContext,
  PropertyIDContext,
} from "../../global_contexts";
import { useForm } from "react-hook-form";

const Login = (props) => {
  const { setPropertyID } = useContext(PropertyIDContext);
  const { setPreferredLanguage } = useContext(PreferredLanguageContext);
  const { setPropertyCountry } = useContext(PropertyContext);
  const { register, handleSubmit } = useForm();
  const intl = props.intl;

  function handleFormSubmit(values) {
    props.loginUser(
      values,
      props.history,
      { intl },
      { setPropertyID },
      { setPreferredLanguage, setPropertyCountry }
    );
    document.getElementById("loginBtn").disabled = true;
  }

  function clickForm() {
    document.getElementById("loginBtn").disabled = false;
  }

  function renderAlert() {
    // Show an error when there is one.
    return props.errorMessage ? (
      <div id="error" className="col s12">
        {props.errorMessage}
      </div>
    ) : (
      ""
    );
  }

  return (
    <div>
      {props.logout_msg && renderLogoutMessage(props.logout_msg)}
      {props.location.state &&
        props.location.state.detail &&
        renderLogoutMessage(props.location.state.detail)}
      <div className="row login">
        <div className="col s12 split-line">
          <h5 className="title">
            <FormattedMessage
              id="login.title"
              defaultMessage="Log in to manage your property"
            />
          </h5>
        </div>
        <form
          className="col s12 login-form"
          onSubmit={handleSubmit(handleFormSubmit)}
          onClick={() => clickForm()}
          history={props.history}>
          <div className="row">
            <div className="input-field col s12">
              <FormattedMessage id="login.email" defaultMessage="Email">
                {(placeholder_text) => (
                  <input
                    className="mb-0"
                    type="email"
                    placeholder={placeholder_text}
                    {...register("email", { required: true })}
                  />
                )}
              </FormattedMessage>
              <div />
            </div>
            <div className="input-field col s12">
              <FormattedMessage id="login.password" defaultMessage="Password">
                {(placeholder_text) => (
                  <input
                    type="password"
                    placeholder={placeholder_text}
                    {...register("password", { required: true })}
                  />
                )}
              </FormattedMessage>
            </div>
            {renderAlert()}
            <div className="col s12">
              <button
                id="loginBtn"
                data-testid="loginBtn"
                className="btn"
                action="submit">
                <FormattedMessage id="login.button" defaultMessage="Log in now" />
              </button>
            </div>
          </div>
        </form>
        <div className="row">
          <ResetPassword />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    errorMessage: state.auth.error,
    logout_msg: state.auth.logout_msg,
  };
}

export default withRouter(connect(mapStateToProps, actions)(injectIntl(Login)));
