import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import * as actions from "../actions";
import Dashboard from "./dashboard/dashboard";
import PerformanceC from "./performance/performance";
import Invoices from "./invoices/invoices";
import Calendar from "./calendar/calendar";
import Bookings from "./bookings";
import Listings from "./listings";
import Reviews from "./reviews";
import Services from "./services";
import Photos from "./photos/photos";
import DetailPhotos from "./photos/detail_photos";
import GreatHostGuide from "./great_host_guide/great_host_guide";
import GuestCommunication from "./great_host_guide/guest_communication";
import KeyManagement from "./great_host_guide/key_management";
import Cleaning from "./great_host_guide/cleaning";
import Linen from "./great_host_guide/linen_laundry_and_toiletries";
import Maintenance from "./great_host_guide/maintenance";
import Possessions from "./great_host_guide/possessions_and_valuables";
import MoneyMatters from "./great_host_guide/money_matters";
import Airbnb from "./great_host_guide/airbnb_you_and_us";
import Partnership from "./great_host_guide/a_true_patnership";
import ContactUs from "./contact_us/contact_us";
import Preferences from "./preferences/preferences";
import Feedback from "./feedback";
import Review from "./review";
import Profile from "./profile/profile";
import NotFound from "./not_found";
import ErrorPage from "./error";
import BackDoor from "./backdoor";
import OnboardingChecklist from "./checklist/onboarding_checklist";
import getHistory from "./utility/history";
import { LocaleContext, PropertyContext, PropertyIDContext } from "../global_contexts";
import DynamicOnboardingChecklist from "./dynamic_onboarding/checklist";
import { useIntl } from "react-intl";

const Main = (props) => {
  const INTL = useIntl();
  const { locale } = useContext(LocaleContext);
  const { propertyCountry } = useContext(PropertyContext);
  const { setPropertyID } = useContext(PropertyIDContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const [useDynamicOnboarding, setUseDynamicOnboarding] = useState(false);

  useEffect(() => {
    setUseDynamicOnboarding(JSON.parse(localStorage.getItem("dynamic_onboarding")));
  }, [localStorage.getItem("dynamic_onboarding")]);

  return (
    <div className="main-container">
      <div className="container">
        <Switch>
          <Route exact path="/performance/" component={PerformanceC} />
          <Route exact path="/invoices/" component={Invoices} />
          <Route exact path="/calendar/" component={Calendar} />
          <Route exact path="/reviews/" component={Reviews} />
          <Route path="/reviews/:id/" render={(props) => <Review {...props} />} />
          <Route exact path="/bookings/" component={Bookings} />
          <Route exact path="/listings/" component={Listings} />
          <Route exact path="/services/" component={Services} />
          <Route exact path="/photos/" component={Photos} />
          <Route
            exact
            path="/photos/:filter"
            render={(props) => <DetailPhotos {...props} />}
          />
          {propertyCountry === "GB" && (
            <Route exact path="/great-host-guide/" component={GreatHostGuide} />
          )}
          {propertyCountry === "GB" && (
            <Route exact path="/guest-communication/" component={GuestCommunication} />
          )}
          {propertyCountry === "GB" && (
            <Route exact path="/key-management/" component={KeyManagement} />
          )}
          {propertyCountry === "GB" && (
            <Route exact path="/cleaning/" component={Cleaning} />
          )}
          {propertyCountry === "GB" && (
            <Route exact path="/linen-laundry-and-toiletries/" component={Linen} />
          )}
          {propertyCountry === "GB" && (
            <Route exact path="/maintenance/" component={Maintenance} />
          )}
          {propertyCountry === "GB" && (
            <Route exact path="/possessions-and-valuables/" component={Possessions} />
          )}
          {propertyCountry === "GB" && (
            <Route exact path="/money-matters/" component={MoneyMatters} />
          )}
          {propertyCountry === "GB" && (
            <Route exact path="/airbnb-you-and-us/" component={Airbnb} />
          )}
          {propertyCountry === "GB" && (
            <Route exact path="/a-true-partnership/" component={Partnership} />
          )}
          <Route exact path="/contact-us/" component={ContactUs} />
          <Route exact path="/preferences/" component={Preferences} />
          <Route exact path="/profile/" component={Profile} />
          <Route
            exact
            path="/profile/mandate_complete/"
            render={() => <Redirect to="/profile/" />}
          />
          {locale === "en" ? (
            <Route exact path="/feedback/" component={Feedback} />
          ) : null}
          <Route
            exact
            path="/checklist/"
            component={
              useDynamicOnboarding === true
                ? DynamicOnboardingChecklist
                : OnboardingChecklist
            }
            history={getHistory()}
          />
          <Route
            exact
            path="/checklist/mandate_complete/"
            render={(_) => (
              <Redirect
                to={{
                  pathname: "/checklist/",
                  state: { mandate_complete: true },
                }}
              />
            )}
          />
          {user && user.is_manager && (
            <Route exact path="/search/" component={BackDoor} />
          )}
          {user && user.is_manager && (
            <Route
              exact
              path="/access/:property_id"
              render={() => <Redirect push to="/" />}
            />
          )}
          <Route exact path="/error/" component={ErrorPage} />
          <Route exact path="/login/" render={() => <Redirect to="/" />} />
          <Route
            exact
            path="/logout/"
            render={() => {
              props.logoutUser(
                INTL.formatMessage({
                  id: "main.logout",
                  defaultMessage: "You've been logged out successfully.",
                }),
                {
                  setPropertyID,
                }
              );
              return <Redirect to="/login/" />;
            }}
          />
          <Route
            path="/password-reset-confirm/*/*"
            render={(_) => <Redirect push to="/" />}
          />
          <Route exact path="/" component={Dashboard} />
          <Route
            path="/register/"
            render={() => {
              props.logoutUser(
                INTL.formatMessage({
                  id: "main.logout_to_register",
                  defaultMessage:
                    "You've been logged out so you can setup your new account.",
                }),
                { setPropertyID }
              ); // message is unseen (may be an improvement to show when hooks are more standardized in the codebase)
              return <Redirect to={`${window.location.pathname}`} />;
            }}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    logout_msg: state.auth.logout_msg,
  };
}

export default withRouter(connect(mapStateToProps, actions)(Main));
