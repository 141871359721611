import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import * as actions from "../../actions";
import {
  AvailabilityWindowContext,
  PropertyContext,
  PropertyIDContext,
} from "../../global_contexts";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { PropertySelectorContext } from "./navbar";
import { MATERIALIZE_REF } from "../dynamic_onboarding/contexts";

const SelectProperty = (props) => {
  const M = useContext(MATERIALIZE_REF);
  const { propertyName, setPropertyName } = useContext(PropertyContext);
  const { propertyID } = useContext(PropertyIDContext);
  const [properties, setProperties] = useState(
    JSON.parse(localStorage.getItem("properties"))
  );
  const [multipleProperties, setMultipleProperties] = useState(false);
  const { setPropertyID } = useContext(PropertyIDContext);
  const { setAvailabilityWindow } = useContext(AvailabilityWindowContext);
  const { propertySelectorOpen, setPropertySelectorOpen } = useContext(
    PropertySelectorContext
  );

  useEffect(() => {
    if (properties) {
      setMultipleProperties(properties.length > 1 ? true : false);
    }
  }, [properties]);

  useEffect(() => {
    initDropdown();
  }, []);

  useEffect(() => {
    if (props.property) {
      var selectedProperty = props.property;
      setPropertyName(selectedProperty.name);
      const newAvailabilityWindow = selectedProperty.availability_window;
      if (newAvailabilityWindow) {
        setAvailabilityWindow(newAvailabilityWindow);
      }
      initDropdown();
    }
  }, [props.property]);

  useEffect(() => {
    if (properties !== JSON.parse(localStorage.getItem("properties"))) {
      setProperties(JSON.parse(localStorage.getItem("properties")));
    }
  }, [localStorage.getItem("properties")]);

  function initDropdown() {
    // Timeout required for now as the document doesn't contain the element required since it hadn't had time to render
    setTimeout(() => {
      document.querySelectorAll(".col-prop.collapsible").forEach(function (elem) {
        new M.Collapsible(elem);
      });
    }, 100);
  }

  const handleClick = (e) => {
    e.stopPropagation();
    setPropertySelectorOpen(!propertySelectorOpen);
  };

  function switchTo(property) {
    localStorage.setItem("property_id", JSON.stringify(property.id));
    setPropertyID(property.id);
    setPropertySelectorOpen(false);
  }

  return multipleProperties ? (
    <ul className="col-prop collapsible expandable">
      <li>
        <div
          className="collapsible-header valign-wrapper"
          onClick={(e) => handleClick(e)}>
          <Icon
            icon={ICONS.PROPERTY.d}
            width={ICONS.PROPERTY.width}
            height={ICONS.PROPERTY.height}
          />
          {propertySelectorOpen ? (
            <div>
              <span>
                <FormattedMessage
                  id="select_property.text"
                  defaultMessage="Select Property"
                />
              </span>
              <span className="arrow-container right-align">
                <Icon
                  icon={ICONS.UP_ARROW.d}
                  width={ICONS.UP_ARROW.width}
                  height={ICONS.UP_ARROW.height}
                />
              </span>
            </div>
          ) : (
            <div>
              <p>{propertyName}</p>
              <span className="arrow-container right-align">
                <Icon
                  icon={ICONS.DOWN_ARROW.d}
                  width={ICONS.DOWN_ARROW.width}
                  height={ICONS.DOWN_ARROW.height}
                />
              </span>
            </div>
          )}
        </div>
        <div className="collapsible-body">
          {properties &&
            properties.map((sub_property) => {
              return (
                <div
                  className="option"
                  key={sub_property.id}
                  value={sub_property.id}
                  onClick={() => switchTo(sub_property)}>
                  <p>{sub_property.name}</p>
                  {parseInt(propertyID) === sub_property.id && (
                    <i className="tick">
                      <Icon
                        icon={ICONS.TICK.d}
                        width={ICONS.TICK.width}
                        height={ICONS.TICK.height}
                      />
                    </i>
                  )}
                </div>
              );
            })}
        </div>
      </li>
    </ul>
  ) : (
    ""
  );
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    property: state.property.property_info,
  };
}

export default connect(mapStateToProps, actions)(SelectProperty);
