import { rGET } from "./api";
import { SELECTED_PROPERTY, CHARGE_TYPE, CHECKLIST_STATUS } from "./types";
import { store } from "../store";
import { initCall } from "../actions";

async function listProperties(property_id) {
  return rGET(`/properties/${property_id}/`).then((resp) => {
    const properties =
      resp && localStorage.setItem("properties", JSON.stringify(resp.data.results));
    return properties;
  });
}

async function checkChecklistComplete(
  selectedProperty,
  { setPreferredLanguage, setPropertyCountry }
) {
  let listProp = await initCall(
    { setPreferredLanguage, setPropertyCountry },
    selectedProperty.id
  );
  let checkProp = listProp && listProp.results;
  if (checkProp) {
    for (let i = 0; i < checkProp.length; i++) {
      if (checkProp[i].id === selectedProperty.id) {
        let checklist_status;
        if (!checkProp[i].checklist_complete) {
          checklist_status = "inprogress";
        } else {
          checklist_status = "complete";
        }
        store.dispatch({
          type: CHECKLIST_STATUS,
          payload: checklist_status,
        });
        return checklist_status;
      }
    }
  }
}

export function propertyInfo(id, locale, { setPreferredLanguage, setPropertyCountry }) {
  return function (dispatch) {
    rGET(`/property/${id}/?language_code=${locale}`).then(async (resp) => {
      const property_info = resp && resp.data.results;
      if (property_info && property_info.length > 0) {
        localStorage.setItem("selectedProperty", JSON.stringify(property_info[0]));
        localStorage.setItem("accountManager", JSON.stringify(property_info[0]));
        await listProperties(property_info[0]["id"]);
        const charge_type = property_info[0]["charge_type"];
        let checklist_status = await checkChecklistComplete(property_info[0], {
          setPreferredLanguage,
          setPropertyCountry,
        });
        dispatch({
          type: CHECKLIST_STATUS,
          payload: checklist_status,
        });
        dispatch({
          type: SELECTED_PROPERTY,
          payload: property_info[0],
        });
        dispatch({
          type: CHARGE_TYPE,
          payload: charge_type,
        });
        // Clear accessID from local storage
        localStorage.removeItem("access_id");
      }
    });
  };
}
