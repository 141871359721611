import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { displayFullDate } from "../common/util";
import SmallSpinner from "./utility/small_spinner";
import { ICONS } from "./svg/icons";
import Icon from "./svg/svg_sprite";
import { FormattedMessage } from "react-intl";
import { PropertyIDContext } from "../global_contexts";
import { rGET } from "../actions/api";

const Reviews = () => {
  const { propertyID } = useContext(PropertyIDContext);
  const [reviews, setReviews] = useState(null);
  const [overallAverage, setOverallAverage] = useState(null);

  useEffect(() => {
    if (propertyID) {
      setReviews(null);
      getReviews();
      getOverallAverageReviews();
    }
  }, [propertyID]);

  function getReviews() {
    rGET(`/reviews/${propertyID}/`).then((resp) => {
      const reviews = resp && resp.data.results;
      setReviews(reviews);
    });
  }

  function getOverallAverageReviews() {
    rGET(`/reviews/${propertyID}/overall-average/`).then((resp) => {
      const overallAverage = resp && resp.data.results[0].reviews_overall_average;
      setOverallAverage(overallAverage);
    });
  }

  function reviewDetails(review) {
    localStorage.setItem("review", JSON.stringify(review));
  }

  function listReviews(reviews) {
    return reviews.map((review) => {
      return (
        <div className="col s12 m6" key={review.id}>
          <NavLink
            exact
            to={`/reviews/${review.id}`}
            onClick={() => reviewDetails(review)}>
            <div className="card">
              <div className="cardHeader">
                <div className="row pt-10 mb-10">
                  <div className="col s8 name">{review.guest_name}</div>
                  <div className="col s4 right-align">{review.platform}</div>
                </div>
              </div>
              <div className="card-content pt-0">
                <div className="reviews">
                  <div className="stars hide-on-small-only hide-on-med-only">
                    <Icon
                      icon={ICONS.STAR.d}
                      width={51}
                      height={47}
                      viewBox="0 0 30 26"
                      color={review.rating >= 1 ? "#ec9a3a" : "rgba(236, 154, 58, 0.2)"}
                    />
                    <Icon
                      icon={ICONS.STAR.d}
                      width={51}
                      height={47}
                      viewBox="0 0 30 26"
                      color={review.rating >= 2 ? "#ec9a3a" : "rgba(236, 154, 58, 0.2)"}
                    />
                    <Icon
                      icon={ICONS.STAR.d}
                      width={51}
                      height={47}
                      viewBox="0 0 30 26"
                      color={review.rating >= 3 ? "#ec9a3a" : "rgba(236, 154, 58, 0.2)"}
                    />
                    <Icon
                      icon={ICONS.STAR.d}
                      width={51}
                      height={47}
                      viewBox="0 0 30 26"
                      color={review.rating >= 4 ? "#ec9a3a" : "rgba(236, 154, 58, 0.2)"}
                    />
                    <Icon
                      icon={ICONS.STAR.d}
                      width={51}
                      height={47}
                      viewBox="0 0 30 26"
                      color={
                        review.rating === 5 ? "#ec9a3a" : "rgba(236, 154, 58, 0.2)"
                      }
                    />
                  </div>
                  <div className="stars hide-on-large-only">
                    <Icon
                      icon={ICONS.STAR.d}
                      width={46}
                      height={42}
                      viewBox="0 0 30 26"
                      color={review.rating >= 1 ? "#ec9a3a" : "rgba(236, 154, 58, 0.2)"}
                    />
                    <Icon
                      icon={ICONS.STAR.d}
                      width={46}
                      height={42}
                      viewBox="0 0 30 26"
                      color={review.rating >= 2 ? "#ec9a3a" : "rgba(236, 154, 58, 0.2)"}
                    />
                    <Icon
                      icon={ICONS.STAR.d}
                      width={46}
                      height={42}
                      viewBox="0 0 30 26"
                      color={review.rating >= 3 ? "#ec9a3a" : "rgba(236, 154, 58, 0.2)"}
                    />
                    <Icon
                      icon={ICONS.STAR.d}
                      width={46}
                      height={42}
                      viewBox="0 0 30 26"
                      color={review.rating >= 4 ? "#ec9a3a" : "rgba(236, 154, 58, 0.2)"}
                    />
                    <Icon
                      icon={ICONS.STAR.d}
                      width={46}
                      height={42}
                      viewBox="0 0 30 26"
                      color={
                        review.rating === 5 ? "#ec9a3a" : "rgba(236, 154, 58, 0.2)"
                      }
                    />
                  </div>
                  <div className="date-review pt-20">
                    <FormattedMessage
                      id="reviews.reviewed_on"
                      defaultMessage="Reviewed on "
                    />
                    <span className="date">
                      {displayFullDate(review.created.slice(0, 10))}
                    </span>
                    <span className="right hide-on-med-only">
                      <Icon
                        icon={ICONS.CHEVRON.d}
                        width={ICONS.CHEVRON.width}
                        height={ICONS.CHEVRON.height}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
        </div>
      );
    });
  }

  return (
    <div className="row reviews">
      <div className="col s12">
        <div className="row">
          <div className="col s12 m6">
            <h4>
              <FormattedMessage id="reviews.title" defaultMessage="Reviews" />
            </h4>
          </div>
          {overallAverage && (
            <div className="col s12 m6">
              <div className="average-rating">
                <h5>
                  <FormattedMessage
                    id="reviews.average_rating"
                    defaultMessage="Average Rating: "
                  />
                  <span>{overallAverage > 0 ? overallAverage.toFixed(1) : "-"}</span>
                </h5>
                {overallAverage > 0 && (
                  <span className="icon-star">
                    <Icon
                      icon={ICONS.STAR.d}
                      width={15}
                      height={11}
                      viewBox="0 0 30 26"
                      color="#ec9a3a"
                    />
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {reviews ? (
        reviews.length > 0 ? (
          listReviews(reviews)
        ) : (
          <div className="col s12">
            <FormattedMessage
              id="reviews.no_reviews"
              defaultMessage="There have been no reviews yet!"
            />
          </div>
        )
      ) : (
        <SmallSpinner />
      )}
    </div>
  );
};

export default Reviews;
