import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import authReducer from "./auth_reducer";
import propertyReducer from "./property_reducer";
import calendarReducer from "./calendar_reducer";
import userReducer from "./user_reducer";
import photosReducer from "./photos_reducer";
import checklistReducer from "./checklist_reducer";
import updateReducer from "./update_reducer";
import directDebitReducer from "./direct-debit_reducer";

const rootReducer = combineReducers({
  form,
  auth: authReducer,
  property: propertyReducer,
  calendar: calendarReducer,
  user: userReducer,
  photos: photosReducer,
  checklist: checklistReducer,
  update_app: updateReducer,
  direct_debit: directDebitReducer,
});

export default rootReducer;
