import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { monthDates } from "../../common/util";
import SmallSpinner from "../utility/small_spinner";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { FormattedMessage } from "react-intl";
import { PropertyIDContext } from "../../global_contexts";
import { rGET } from "../../actions/api";

const PerformanceDashboard = (props) => {
  const { propertyID } = useContext(PropertyIDContext);
  const [performance, setPerformance] = useState(null);

  useEffect(() => {
    const start_date = monthDates()["date_start"];
    const end_date = monthDates()["date_end"];
    performanceData(propertyID, start_date, end_date);
  }, [propertyID]);

  function performanceData(property_id, start_date, end_date) {
    if (!props.testMode) {
      rGET(`/occupancy/${property_id}/${start_date}/to/${end_date}/`).then((resp) => {
        const performance = resp && resp.data.results;
        if (performance) {
          setPerformance(performance[0]);
        }
      });
    }
  }

  return (
    <div className="col s6">
      <NavLink exact to="/performance/">
        <div className="card">
          <div className="cardHeader">
            <span className="title">
              <FormattedMessage
                id="dashboard.performance.title"
                defaultMessage="Performance"
              />
            </span>
            <span className="chevron hide-on-small-only">
              <Icon
                icon={ICONS.CHEVRON.d}
                width={ICONS.CHEVRON.width}
                height={ICONS.CHEVRON.height}
              />
            </span>
          </div>
          <div className="card-content">
            {performance ? (
              <div className="performance row">
                <div className="col hide-on-small-only m5 xl4">
                  <div className="graph-box">
                    <Icon
                      icon={ICONS.PERFORMANCE_GRAPH_BOX.d}
                      width={ICONS.PERFORMANCE_GRAPH_BOX.width}
                      height={ICONS.PERFORMANCE_GRAPH_BOX.height}
                      viewBox="0 0 355 355"
                      color="#071db5"
                    />
                  </div>
                  <div className="graph">
                    <Icon
                      icon={ICONS.PERFORMANCE_GRAPH.d}
                      width={ICONS.PERFORMANCE_GRAPH.width}
                      height={ICONS.PERFORMANCE_GRAPH.height}
                      viewBox="0 0 355 355"
                      color="#071db5"
                    />
                  </div>
                </div>
                <div className="col s12 m7 xl8 percentage">
                  <div>
                    {performance !== null
                      ? `${Math.floor(performance["occupancy_rate"])}%`
                      : "-"}
                  </div>
                  <p>
                    <FormattedMessage
                      id="dashboard.performance.percentage_text"
                      defaultMessage="available dates booked this month"
                    />
                  </p>
                </div>
              </div>
            ) : (
              <SmallSpinner />
            )}
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default PerformanceDashboard;
