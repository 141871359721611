import { rGET } from "./api";
import { GENERATE_REDIRECT, CURRENT_STATUS, GENERATE_REDIRECT_ERROR } from "./types";

//Get redirect url
export function generateRedirect(property_id, redirectPage) {
  return function (dispatch) {
    const url = `/generate_redirect/${property_id}/?&next=/${redirectPage}/mandate_complete/`;
    rGET(url).then(
      (resp) => {
        const generate_redirect = resp && resp.data.redirect_url;
        dispatch({
          type: GENERATE_REDIRECT,
          payload: generate_redirect,
        });
      },
      (err) => {
        const generate_redirect_error = err;
        dispatch({
          type: GENERATE_REDIRECT_ERROR,
          payload: generate_redirect_error,
        });
      }
    );
  };
}

// Get direct debit current status
export function getDirectDebitCurrentStatus(property_id) {
  return function (dispatch) {
    const url = `/mandate_status/${property_id}/`;
    rGET(url).then(
      (resp) => {
        const current_status = {
          status: resp && resp.data.mandate_status,
          checkN: Math.random(0, 100),
        };
        dispatch({
          type: CURRENT_STATUS,
          payload: current_status,
        });
      },
      (err) => {
        console.log(err);
      }
    );
  };
}
