import React, { useEffect, useRef, useState } from "react";
import { DataField, DataFieldType, DocumentsField, TextField } from "../../schema";
import { InputDateComponent } from "../inputs/date";
import { InputTextComponent } from "../inputs/text";
import { BespokeFileUploaderComponent } from "./file_uploader";
import { rPATCH } from "../../../../actions/api";
import { PreloaderSpinner } from "../../utility";

export const BespokeDocumentsComponent = (props: { field: DocumentsField }) => {
  const documentID = useRef(props.field.value.id);
  const [psuedoStartDateField, setPsuedoStartDateField] = useState({} as DataField);
  const [psuedoEndDateField, setPsuedoEndDateField] = useState({} as DataField);
  const [psuedoDocumentNumberField, setPsuedoDocumentNumberField] = useState(
    {} as TextField
  );
  const [showPreloaderSpinner, setShowPreloaderSpinner] = useState<boolean>(false);
  const [spinnerStatus, setSpinnerStatus] = useState<boolean | null>(null);

  useEffect(() => {
    if (props.field.options.label_start_date) {
      setPsuedoStartDateField({
        type: DataFieldType.DATE,
        key: "documents_start_date",
        required: false,
        value: psuedoStartDateField.value || props.field.value.start_date,
        label: props.field.options.label_start_date,
      });
    }
  }, [props.field.options.label_start_date]);

  useEffect(() => {
    if (props.field.options.label_end_date) {
      setPsuedoEndDateField({
        type: DataFieldType.DATE,
        key: "documents_end_date",
        required: false,
        value: psuedoEndDateField.value || props.field.value.end_date,
        label: props.field.options.label_end_date,
      });
    }
  }, [props.field.options.label_end_date]);

  useEffect(() => {
    if (props.field.options.label_document_number) {
      setPsuedoDocumentNumberField({
        type: DataFieldType.TEXT,
        key: "documents_document_number",
        required: false,
        value: psuedoDocumentNumberField.value || props.field.value.document_number,
        label: props.field.options.label_document_number,
      });
    }
  }, [props.field.options.label_document_number]);

  function onValueChange(element: any, value: any) {
    setShowPreloaderSpinner(true);
    let dataToPatch = {} as any;
    switch (element.id) {
      case "documents_start_date":
        setPsuedoStartDateField({ ...psuedoStartDateField, value: value });
        dataToPatch["start_date"] = value;
        break;
      case "documents_end_date":
        setPsuedoEndDateField({ ...psuedoEndDateField, value: value });
        dataToPatch["end_date"] = value;
        break;
      case "documents_document_number":
        setPsuedoDocumentNumberField({ ...psuedoDocumentNumberField, value: value });
        dataToPatch["document_number"] = value;
        break;
      default:
        break;
    }
    if (Object.keys(dataToPatch).length > 0) {
      rPATCH(`/document/${documentID.current}/`, dataToPatch)
        .then((response: any) => {
          setSpinnerStatus(true);
          setTimeout(() => {
            setShowPreloaderSpinner(false);
            setSpinnerStatus(null);
          }, 1000);
        })
        .catch((error) => {
          console.error("error", error);
          setSpinnerStatus(false);
          setTimeout(() => {
            setShowPreloaderSpinner(false);
            setSpinnerStatus(null);
          }, 1000);
        });
    } else {
      // If there is no data to patch, there must've been an error
      setSpinnerStatus(false);
      setTimeout(() => {
        setShowPreloaderSpinner(false);
        setSpinnerStatus(null);
      }, 1000);
    }
  }

  return (
    <div className="documents">
      <label>{props.field.label}</label>
      {showPreloaderSpinner ? (
        <PreloaderSpinner
          spinnerSize="small"
          spinnerStatus={spinnerStatus}
          spinnerPosition="suffix"
          spinnerColour="orange"
        />
      ) : null}
      <BespokeFileUploaderComponent
        documentValues={props.field.value}
        options={props.field.options}
      />
      {psuedoDocumentNumberField.key ? (
        <div id="documents_number" className="documents__number">
          <label>{psuedoDocumentNumberField.label}</label>
          <InputTextComponent
            onValueChange={onValueChange}
            field={psuedoDocumentNumberField}
          />
        </div>
      ) : null}
      {psuedoStartDateField.key || psuedoEndDateField.key ? (
        <div id="documents_dates" className="documents__dates">
          {psuedoStartDateField.key ? (
            <div id="documents_start_date">
              <label>{psuedoStartDateField.label}</label>
              <InputDateComponent
                onValueChange={onValueChange}
                field={psuedoStartDateField}
              />
            </div>
          ) : null}
          {psuedoEndDateField.key ? (
            <div id="documents_end_date">
              <label>{psuedoEndDateField.label}</label>
              <InputDateComponent
                onValueChange={onValueChange}
                field={psuedoEndDateField}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
