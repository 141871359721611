export function backendValidation(errorData) {
  let error;

  for (let key in errorData) {
    if (key === "token") {
      error = "This password reset link has expired.";
      break;
    } else {
      error = errorData[key];
    }
  }

  return error;
}
