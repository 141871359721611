import React, { useContext, useEffect, useState } from "react";
import "react-month-picker-input/dist/react-month-picker-input.css";
import SmallSpinner from "../utility/small_spinner";
import MonthFromToSelector from "../utility/month_from_to_selector";
import { DatePartLocalised } from "../utility/date_part_localised";
import { FormattedMessage } from "react-intl";
import { PropertyIDContext } from "../../global_contexts";
import { rGET } from "../../actions/api";

const Invoices = () => {
  const { propertyID } = useContext(PropertyIDContext);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [allInvoices, setAllInvoices] = useState(null);

  useEffect(() => {
    getAllInvoices();
  }, [propertyID]);

  function ListInvoices() {
    return allInvoices.map((report) => {
      /* Example Invoice: "May 2022 (First Half)" */
      return (
        <li className="report valign-wrapper" key={report}>
          <div>
            <DatePartLocalised
              date={`${report.split("/")[4]}/${report.split("/")[5]}/01`}
              options={{ month: "long", year: "numeric" }}
            />
            {" ("}
            {report.split("/")[6].replace("_", " ") === "first half" ? (
              <FormattedMessage id="invoices.first_half" defaultMessage="First Half" />
            ) : (
              <FormattedMessage
                id="invoices.second_half"
                defaultMessage="Second Half"
              />
            )}
            {")"}
          </div>
          <button
            onClick={() => {
              openMonthlyPropertyReportPDF(report);
            }}
            className="btn-flat">
            PDF
          </button>
        </li>
      );
    });
  }

  const onDateChange = (dateFrom, dateTo) => {
    setDateFrom(dateFrom);
    setDateTo(dateTo);
  };

  // revenue statements - List of URLS that have potential reports in
  function getAllInvoices() {
    rGET(`/propertyreport/${propertyID}/url-list/`).then((resp) => {
      const monthly_property_report_url_list = resp && resp.data.results.reverse();
      setAllInvoices(monthly_property_report_url_list);
    });
  }

  function openMonthlyPropertyReportPDF(reportURL) {
    let splitReportURL = reportURL.split("/");
    // 0 is "api", 1 is "v3", 2 is "propertyreport", 3 is "PROPERTY_ID"
    const year = splitReportURL[4];
    const month = splitReportURL[5];
    const output_type = splitReportURL[6];
    const option = splitReportURL[7];
    rGET(
      `/propertyreport/${propertyID}/${year}/${month}/${output_type}/${option}/`
    ).then((resp) => {
      const downloadLink = `${year}-${month}-${output_type}.pdf`;
      openPDF(resp, downloadLink);
    });
  }

  function openSummaryPDF() {
    rGET(`/propertyreport/${propertyID}/${dateFrom}/to/${dateTo}/`).then((resp) => {
      const downloadLink = `summary-${dateFrom.split("-")[1]}_${
        dateFrom.split("-")[0]
      }-${dateTo.split("-")[1]}_${dateFrom.split("-")[0]}.pdf`;
      openPDF(resp, downloadLink);
    });
  }

  function openPDF(resp, downloadLink) {
    // Create a Blob from the PDF Stream
    const file = new Blob([resp.data], { type: "application/pdf" });
    // Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    // Open the URL on new Window
    window.open(fileURL, "_blank");
    // Check if the device is iOS
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    // If browser cannot open pdf, then download
    if (!navigator.pdfViewerEnabled || isIOS) {
      var link = document.createElement("a");
      link.href = fileURL;
      link.download = downloadLink;
      link.dispatchEvent(new MouseEvent("click"));
    }
  }

  return (
    <div className="performance">
      <div className="row">
        <div className="col s12 m8">
          <h4 data-test="invoices_title">
            <FormattedMessage id="invoices.title" defaultMessage="Invoices" />
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m8">
          <h5 data-test="invoices_subtitle_one">
            <FormattedMessage
              id="invoices.subtitle_one"
              defaultMessage="Summary Invoice Report"
            />
          </h5>
        </div>
      </div>
      <div className="col">
        <div className="month-picker">
          <MonthFromToSelector onDateChange={onDateChange} />
        </div>
        <li className="valign-wrapper summary">
          <button onClick={openSummaryPDF} className="btn-flat">
            <FormattedMessage
              id="invoices.generate_report"
              defaultMessage="Generate Report"
            />
          </button>
        </li>
      </div>
      <div className="row">
        <div className="col s12 m8">
          <h5 data-test="invoices_subtitle_two">
            <FormattedMessage
              id="invoices.subtitle_two"
              defaultMessage="Bi-monthly Invoices"
            />
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <ul className="reports">
            {allInvoices ? (
              allInvoices.length > 0 ? (
                ListInvoices()
              ) : (
                <div className="col s12">
                  <FormattedMessage
                    id="invoices.no_invoices"
                    defaultMessage="There are currently no invoices to be displayed."
                  />
                </div>
              )
            ) : (
              <SmallSpinner />
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Invoices;
