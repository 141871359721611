import React, { Component } from "react";
import { injectIntl } from "react-intl";
import Select from "react-select";
import "react-select/dist/react-select.css";

class InputSelect extends Component {
  state = {
    selectedOption: "",
  };

  handleChange = (selectedOption) => {
    switch (true) {
      case this.props.handleChange === "selectRoom":
        this.setState({ selectedOption });
        this.props.sendSelected("selectedRoom", selectedOption);
        break;
      case this.props.handleChange === "selectPastDate":
        this.setState({ selectedOption });
        this.props.sendSelected("selectedPastDate", selectedOption);
        break;
      case this.props.handleChange === "selectRecentDate":
        this.setState({ selectedOption });
        this.props.sendSelected("selectedRecentDate", selectedOption);
        break;
      default:
        break;
    }
  };

  compareLocalSelectedOptionWithCurrentPropValueState() {
    if (
      this.state.selectedOption &&
      this.props.defaultOption &&
      this.state.selectedOption !== this.props.defaultOption &&
      this.state.selectedOption.value === this.props.defaultOption.value
    ) {
      this.setState({ selectedOption: this.props.defaultOption });
    }
  }

  render() {
    const { selectedOption } = this.state;
    const value = this.props.defaultOption;
    if (selectedOption) {
      this.compareLocalSelectedOptionWithCurrentPropValueState();
    }

    return (
      <>
        {selectedOption ? (
          <Select
            name="form-field-name"
            className={this.props.className}
            value={selectedOption}
            clearable={false}
            searchable={false}
            onChange={this.handleChange}
            options={this.props.options}
          />
        ) : (
          <Select
            name="form-field-name"
            className={this.props.className}
            value={value}
            clearable={false}
            searchable={false}
            onChange={this.handleChange}
            options={this.props.options}
          />
        )}
      </>
    );
  }
}

export default injectIntl(InputSelect);
