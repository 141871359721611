import React from "react";
import { OnboardingChecklistField } from "./fields/generic_field";
import { OnboardingChecklistGroup } from "./groups/generic_group";
import { DataField, DataFieldGroup, DataFieldType, FieldList } from "./schema";

export const FieldListComponent = (props: { fieldList: FieldList }) => {
  return (
    <>
      {props.fieldList &&
        props.fieldList.map((field, index) =>
          field.type === DataFieldType.GROUP ? (
            <OnboardingChecklistGroup
              key={`group_${field.type}_${index}`}
              field={field as DataFieldGroup}
            />
          ) : (
            <OnboardingChecklistField
              key={`field_${field.type}_${index}`}
              field={field as DataField}
            />
          )
        )}
    </>
  );
};
