import { Store } from "redux";
import { SyntheticEvent } from "react";

export interface BedInterface {
  id?: number | string;
  fixed: string | boolean;
  size: string;
}

export interface SectionStatusInterface {
  errors: object;
  isInProgress: boolean;
  isTouched: boolean;
  statusInputFilled: number;
}

export interface FieldSkippedInterface {
  name: string;
  valueId: number;
}

function saveCheck(event: SyntheticEvent, state: object, callback: Function) {
  switch (event.type) {
    case "change":
      const target: any = event.target;
      if (["radio", "checkbox"].includes(target.getAttribute("type"))) {
        callback(state);
      }
      break;
    default:
      callback(state);
  }
}

function getBedValue(bedVal: BedInterface[]) {
  let value = true;
  for (let val of bedVal) {
    if (
      ![true, false, "yes", "no"].includes(val.fixed) ||
      !["Single", "Queen", "Double", "King", "Super King"].includes(val.size)
    ) {
      value = false;
    }
  }
  return value;
}

function checkSkippedFields(
  propsFields: any,
  fieldsSkipped: FieldSkippedInterface[],
  lastFieldFilled: number
) {
  if (propsFields) {
    if (fieldsSkipped.length === 1 && lastFieldFilled > 1) {
      propsFields[fieldsSkipped[0].name].input["skipped"] = true;
    } else if (fieldsSkipped.length > 1) {
      fieldsSkipped.forEach((field: any, i: number) => {
        if (field.valueId < lastFieldFilled) {
          propsFields[fieldsSkipped[i].name].input["skipped"] = true;
        }
      });
    }
  }
}

function setChecklistStages(
  store: Store,
  sectionStatus: SectionStatusInterface[],
  fields: any,
  sectionIndex: number,
  direct_debit: any = null
) {
  let statusInputFilled = null;
  let details_status;
  let status = "";
  let counter = 0;
  let visibleFields = 0;
  let currentStatus: any = sectionStatus[sectionIndex];
  let skippedFields = [];
  let lastFilled = -1;
  let j = 1;
  for (let index in fields) {
    const field: any = fields[index];
    const name: string = field.input.name;
    const touched: boolean = field.meta.touched;
    const error: any = field.meta.error;
    visibleFields += field.input["ishidden"] ? 0 : 1;
    currentStatus.isTouched = touched ? true : currentStatus.isTouched;
    if ((error && touched) || (error && sectionIndex === 4)) {
      currentStatus.errors[name] = true;
    } else {
      delete currentStatus.errors[name];
    }
    if (!field.input["ishidden"]) {
      const storeBedState = store.getState().form && store.getState().form.beds;
      if (/^beds$/.test(name)) {
        if (storeBedState && storeBedState.values) {
          let beds = storeBedState.values.beds;
          counter += getBedValue(beds) ? 1 : 0;
        }
      } else {
        const val = field.input.value;
        counter += val || val === 0 ? 1 : 0;
        if (val) {
          lastFilled = j;
        } else {
          skippedFields.push({ valueId: j, name: index });
        }
      }
    }
    statusInputFilled = visibleFields === counter ? 1 : counter === 0 ? counter : 2;
    currentStatus.statusInputFilled = statusInputFilled;
    details_status = currentStatus;
    j++;
  }
  if (details_status) {
    status = !details_status.statusInputFilled
      ? "error"
      : details_status.statusInputFilled === 1
      ? "complete"
      : "inprogress";
    status = details_status.isTouched
      ? Object.keys(details_status.errors).length
        ? "error"
        : status
      : status === "error"
      ? "initial"
      : status;
    const arr_errors = Object.keys(details_status.errors);
    if (arr_errors.length && sectionIndex === 4) {
      const arr_date_fields = [
        "first_hosting_period_end_date",
        "start_hosting_date",
        "handover_date",
        "early_photography_date",
      ];
      if (arr_date_fields.some((err) => arr_errors.indexOf(err) >= 0)) {
        status = "error";
      }
    }
  }
  if (status === "inprogress") {
    checkSkippedFields(fields, skippedFields, lastFilled);
  }
  if (direct_debit) {
    let dd_status = direct_debit.direct_debit_status;
    switch (true) {
      case dd_status === "not_started" && status === "initial":
        status = "initial";
        break;
      case dd_status === "not_started" && ["inprogress", "complete"].includes(status):
        status = "inprogress";
        break;
      case dd_status === "failed":
        status = "error";
        break;
      case dd_status === "required":
        status = status !== "error" ? "inprogress" : "error";
        break;
      case dd_status === "already_complete" && status === "complete":
        status = "complete";
        break;
      case dd_status === "already_complete":
        status = status === "initial" ? "inprogress" : status;
        break;
      default:
        return status;
    }
  }
  return status;
}

export { saveCheck, setChecklistStages, getBedValue };
