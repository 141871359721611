import React, { useContext, useEffect, useRef, useState } from "react";
import { injectIntl } from "react-intl";
import { FORM_CONTEXTS } from "./contexts";
import { FieldListComponent } from "./field_list";
import { DataSection } from "./schema";
import { dependencyCallback } from "./utility";

const OnboardingChecklistSection = (props: {
  section: DataSection;
  nonFieldErrors: any;
  intl: any;
}) => {
  const { handleSectionSubmission } = useContext(FORM_CONTEXTS);
  const sectionElement = useRef(null);
  const [submitButtonText, setSubmitButtonText] = useState(
    props.intl.formatMessage({
      id: "onboarding.submit_button_advance",
      defaultMessage: "advance to next section",
    })
  );
  const [isLastSection, setIsLastSection] = useState(false);
  const observerConfig = { subtree: true, attributeFilter: ["js-value"] };
  const observer = new MutationObserver(observerCallback);

  // By default checks current section to ensure no pre-existing errors nor required blanks
  const handleFormSubmission = (e: any) => {
    e.preventDefault();
    // Make sure the submit button was clicked
    if (e.nativeEvent.submitter.getAttribute("data-allow-submit") !== "true") {
      return;
    }
    // Update status of current section (tab)
    handleSectionSubmission(isLastSection);

    const firstError = document.querySelector(".invalid");
    if (firstError) {
      // Scroll to first error
      firstError.scrollIntoView({ behavior: "smooth", block: "center" });
      // Return as should prefer to scroll to first error if any present
      return;
    }

    const activeTab = document.querySelector("ul.tabs>li>a.active");
    if (activeTab) {
      // Scroll tabs so active tab is visible
      activeTab?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  // Runs whenever there is a re-render
  useEffect(() => {
    // If on last section, update submit button text and do a PUT when submitted
    const activeTab = document.querySelector("ul.tabs>li>a.active");
    const nextTab =
      activeTab &&
      activeTab.parentElement &&
      activeTab.parentElement.nextElementSibling;
    if (nextTab && nextTab.classList.contains("indicator")) {
      setSubmitButtonText(
        props.intl.formatMessage({
          id: "onboarding.submit_button_last_section",
          defaultMessage: "submit onboarding checklist",
        })
      );
      setIsLastSection(true);
    } else {
      setSubmitButtonText(
        props.intl.formatMessage({
          id: "onboarding.submit_button_advance",
          defaultMessage: "advance to next section",
        })
      );
      setIsLastSection(false);
    }
  });

  function observerCallback(mutationList: MutationRecord[]) {
    mutationList.forEach((mutation) => {
      switch (mutation.type) {
        case "attributes":
          if (mutation.attributeName === "js-value") {
            const targetElement = mutation.target as Element;
            const fieldKey = targetElement.getAttribute("js-field-key") as string;
            const fieldValue = targetElement.getAttribute("js-value") as string;
            dependencyCallback(fieldKey, fieldValue);
          }
          break;
      }
    });
  }

  useEffect(() => {
    if (sectionElement.current) {
      observer.observe(sectionElement.current, observerConfig);
    }
  }, [sectionElement.current]);

  return (
    <form
      ref={sectionElement}
      className="onboarding_checklist__section"
      onSubmit={(e) => handleFormSubmission(e)}>
      {props.section && <FieldListComponent fieldList={props.section.fields} />}
      <div className="section_submit_button">
        {props.nonFieldErrors.length > 0 && (
          <div className="error_container">
            {props.nonFieldErrors.map((error: string, index: number) => (
              <span data-testid="non_field_error" key={index} className="error">
                {error}
              </span>
            ))}
          </div>
        )}
        <button data-allow-submit="true" className="btn" formNoValidate>
          {submitButtonText}
        </button>
      </div>
    </form>
  );
};

export default injectIntl(OnboardingChecklistSection);
