import React from "react";
import { DynamicOnboardingChecklistContent } from "./content";
import { DynamicOnboardingChecklistDataProvider } from "./providers";

export default function DynamicOnboardingChecklist() {
  return (
    <DynamicOnboardingChecklistDataProvider>
      <div className="dynamic_onboarding_checklist row">
        <DynamicOnboardingChecklistContent />
      </div>
    </DynamicOnboardingChecklistDataProvider>
  );
}
