import React, { Component } from "react";
import { Field, reduxForm, reset } from "redux-form";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { FormattedMessage, injectIntl } from "react-intl";

const M = window.M;

class FeedbackForm extends Component {
  componentDidMount() {
    var elem = document.querySelectorAll(".modal");
    M.Modal.init(elem, {
      dismissible: false,
      onCloseEnd: () => this.props.reset("feedback"),
    });
    var cancel_btn = document.querySelectorAll(".cancel-feedback");
    Array.from(cancel_btn).map((cancel) => {
      return cancel.addEventListener("click", () => {
        M.toast({
          html: `<div style="position: relative"><div>${this.props.intl.formatMessage({
            id: "feedback.toast.not_submitted",
            defaultMessage: "You have NOT submitted your feedback.",
          })}</div></div>`,
          classes: "no-feedback",
        });
      });
    });
  }

  handleHappyFormSubmit({ message, positive }) {
    const property_id = localStorage.getItem("property_id");
    const client_id = JSON.parse(localStorage.getItem("user")).uid;
    positive = true;
    this.props.feedbackProperty(property_id, {
      client: client_id,
      positive: true,
      message: message,
    });
    M.toast({
      html: `<div style="position: relative"><div>${this.props.intl.formatMessage({
        id: "feedback.toast.submitted",
        defaultMessage: "Thank you for submitting your feedback.",
      })}</div></div>`,
      classes: "centered",
    });
  }

  handleUnhappyFormSubmit({ message, positive }) {
    const property_id = localStorage.getItem("property_id");
    const client_id = JSON.parse(localStorage.getItem("user")).uid;
    positive = false;
    this.props.feedbackProperty(property_id, {
      client: client_id,
      positive: positive,
      message: message,
    });
    M.toast({
      html: `<div style="position: relative"><div>${this.props.intl.formatMessage({
        id: "feedback.toast.submitted",
        defaultMessage: "Thank you for submitting your feedback.",
      })}</div></div>`,
      classes: "centered",
    });
  }

  render() {
    const { handleSubmit } = this.props;
    const styleMask = {
      display: "block!important",
      margin: "auto!important",
    };

    return (
      <div>
        {/* Modals */}
        <div id="happyModal" className="modal">
          <div className="modal-content">
            <div className="right-align modal-action modal-close cancel-feedback">
              <Icon
                icon={ICONS.CANCEL.d}
                width={ICONS.CANCEL.width}
                height={ICONS.CANCEL.height}
              />
            </div>
            <p className="pb-10 mt-0">
              <FormattedMessage
                id="feedback.title_line_good"
                defaultMessage="That's fantastic. Would you like to leave a message?"
              />
            </p>
            <div className="feedback_form">
              <form
                id="feedback"
                onSubmit={handleSubmit(this.handleHappyFormSubmit.bind(this))}>
                <div>
                  <div className="input-field">
                    <Field
                      id="textarea"
                      className="materialize-textarea"
                      name="message"
                      component="textarea"
                      type="text"
                    />
                    <label htmlFor="textarea">
                      <FormattedMessage
                        id="feedback.message_title"
                        defaultMessage="Message (Optional)"
                      />
                    </label>
                    <Field
                      className="mb-0"
                      name="positive"
                      component="select"
                      type="radio"
                    />
                  </div>
                  <div className="modal-footer">
                    <button
                      id="sendFeedback"
                      className="btn modal-action modal-close blue darken-4 btn-flat"
                      action="submit">
                      <FormattedMessage
                        id="feedback.submit_button"
                        defaultMessage="Submit your feedback"
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div id="unhappyModal" className="modal">
          <div className="modal-content">
            <div className="right-align modal-action modal-close cancel-feedback">
              <Icon
                icon={ICONS.CANCEL.d}
                width={ICONS.CANCEL.width}
                height={ICONS.CANCEL.height}
              />
            </div>
            <p className="pb-10 mt-0">
              <FormattedMessage
                id="feedback.title_line_bad"
                defaultMessage="Sorry to hear that. Would you like to leave a message?"
              />
            </p>
            <div className="feedback_form">
              <form
                id="feedback"
                onSubmit={handleSubmit(this.handleUnhappyFormSubmit.bind(this))}>
                <div>
                  <div className="input-field">
                    <Field
                      id="textarea"
                      className="materialize-textarea"
                      name="message"
                      component="textarea"
                      type="text"
                    />
                    <label htmlFor="textarea">
                      <FormattedMessage
                        id="feedback.message_title"
                        defaultMessage="Message (Optional)"
                      />
                    </label>
                    <Field
                      className="mb-0"
                      name="positive"
                      component="select"
                      type="radio"
                    />
                  </div>
                  <div className="modal-footer">
                    <button
                      id="sendFeedback"
                      className="btn modal-action modal-close blue darken-4 btn-flat"
                      action="submit">
                      <FormattedMessage
                        id="feedback.submit_button"
                        defaultMessage="Submit your feedback"
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* end Modals */}
        <div className="col s6">
          <div className="happy-container modal-trigger" href="#happyModal">
            <div className="face-container">
              <Icon
                icon={ICONS.HAPPY_MASK.d}
                width={ICONS.HAPPY_MASK.width}
                height={ICONS.HAPPY_MASK.height}
                color="#87ad51"
                style={styleMask}
              />
            </div>
            <div className="happy-face">
              <Icon
                icon={ICONS.HAPPY.d}
                width={ICONS.HAPPY.width}
                height={ICONS.HAPPY.height}
                color="#ffffff"
              />
            </div>
            <div className="valign-wrapper">
              <h6>
                <FormattedMessage id="feedback.happy" defaultMessage="I'm happy" />
              </h6>
              <div className="hide-on-small-only">
                <Icon
                  icon={ICONS.CHEVRON.d}
                  width={ICONS.CHEVRON.width}
                  height={ICONS.CHEVRON.height}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col s6">
          <div className="unhappy-container modal-trigger" href="#unhappyModal">
            <div className="face-container">
              <Icon
                icon={ICONS.UNHAPPY_MASK.d}
                width={ICONS.UNHAPPY_MASK.width}
                height={ICONS.UNHAPPY_MASK.height}
                color="#b81c17"
                style={styleMask}
              />
            </div>
            <div className="unhappy-face">
              <Icon
                icon={ICONS.UNHAPPY.d}
                width={ICONS.UNHAPPY.width}
                height={ICONS.UNHAPPY.height}
                color="#ffffff"
              />
            </div>
            <div className="valign-wrapper">
              <h6>
                <FormattedMessage id="feedback.unhappy" defaultMessage="I'm unhappy" />
              </h6>
              <div className="hide-on-small-only">
                <Icon
                  icon={ICONS.CHEVRON.d}
                  width={ICONS.CHEVRON.width}
                  height={ICONS.CHEVRON.height}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    feedback: state.property.feedback,
  };
}

const afterSubmit = (result, dispatch) => dispatch(reset("feedback"));

FeedbackForm = reduxForm(
  {
    form: "feedback",
    fields: ["message", "positive"],
    onSubmitSuccess: afterSubmit,
  },
  mapStateToProps
)(FeedbackForm);

FeedbackForm = connect((state) => ({
  initialValues: { message: "" },
}))(FeedbackForm);

export default connect(mapStateToProps, actions)(injectIntl(FeedbackForm));
