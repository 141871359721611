import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { rGETwLocale } from "../actions/api";
import { listTypes, changeFormatDate, formatDateDDMMYYYY } from "../common/util";
import { LocaleContext, PropertyIDContext } from "../global_contexts";
import SmallSpinner from "./utility/small_spinner";

const Services = () => {
  const { locale } = useContext(LocaleContext);
  const { propertyID } = useContext(PropertyIDContext);
  const [serviceLog, setServiceLog] = useState();

  useEffect(() => {
    if (propertyID) {
      const url = `/servicelog/${propertyID}/past/`;
      rGETwLocale(url, locale).then((resp) => {
        const service_log = resp && resp.data.results;
        setServiceLog(service_log);
      });
    }
  }, [propertyID, locale]);

  return (
    <div className="row">
      <div className="col s12">
        <div className="row">
          <div className="col s12">
            <h4>
              <FormattedMessage id="services.title" defaultMessage="Service Log" />
            </h4>
          </div>
        </div>
      </div>
      {serviceLog ? (
        <div className="col s12">
          {serviceLog.length === 0 ? (
            <div>
              <FormattedMessage
                id="services.no_services"
                defaultMessage="There have been no services yet!"
              />
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th width="">
                    <FormattedMessage id="services.date" defaultMessage="Date" />
                  </th>
                  <th width="">
                    <FormattedMessage id="services.types" defaultMessage="Types" />
                  </th>
                  <th className="show-on-medium-and-up hide-on-med-and-down" width="">
                    <FormattedMessage id="services.info" defaultMessage="Info" />
                  </th>
                </tr>
              </thead>
              {serviceLog &&
                serviceLog.map((service, index) => {
                  const services = service.services_icon_string
                    .replace(/\s*,\s*/g, ",")
                    .split(",");
                  const serviceIcon = services.map((service, i) =>
                    listTypes(service, i)
                  );
                  return (
                    <tbody key={index} className="bodyTable">
                      <tr className="no-border" data-activates={service.date}>
                        <td>{formatDateDDMMYYYY(changeFormatDate(service.date))}</td>
                        <td>{serviceIcon}</td>
                        <td className="show-on-medium-and-up hide-on-med-and-down">
                          <div>{service.services_string}</div>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
            </table>
          )}
        </div>
      ) : (
        <SmallSpinner />
      )}
    </div>
  );
};

export default Services;
