import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { numberFormat } from "../utility/currency_format";
import { moneyRates } from "../utility/moneyRates";
import { LocaleContext } from "../../global_contexts";

const Maintenance = () => {
  const { locale } = useContext(LocaleContext);
  const [currency, setCurrency] = useState(localStorage.getItem("currency"));

  useEffect(() => {
    if (currency !== localStorage.getItem("currency")) {
      setCurrency(localStorage.getItem("currency"));
    }
  }, [localStorage.getItem("currency")]);

  useEffect(() => {
    document.querySelector(".main-container .container").classList.add("full-width");

    // returned function will be called on component unmount
    return () => {
      document
        .querySelector(".main-container .container")
        .classList.remove("full-width");
    };
  }, []);

  return (
    <div className="great-host-guide-pages">
      <div className="row">
        <div className="col s12">
          <NavLink exact to="/great-host-guide/" className="left-align">
            <span className="mr-10">
              <Icon
                icon={ICONS.BACK_ARROW.d}
                width={ICONS.BACK_ARROW.width}
                height={12}
                viewBox="0 0 10 20"
              />
            </span>
            <span className="cobalt-blue">Back to Guide summary</span>
          </NavLink>
          <h4 className="center-align">Maintenance - We'll Fix That For You</h4>
        </div>
      </div>
      <div className="row top-img-container">
        <img
          className="top-img"
          alt="Maintenance"
          src={require(`../../styles/img/maintenance@2x-min.jpg`)}
        />
      </div>
      <div className="row">
        <div className="col s12">
          <h5>Prevention and information</h5>
          <p>
            Things can sometimes go wrong, but we are always here to ensure your
            property is properly taken care of. You can help us tackle any maintenance
            issues by providing us with the following details:
          </p>
          <ul>
            <li>The location, make, and model of your boiler</li>
            <li>The location of the fuse box</li>
            <li>
              Any tips for quick troubleshooting, such as how to repressurise your
              boiler
            </li>
            <li>
              Contact details for your maintenance person. We’ll contact them as the
              first point of call for any maintenance issues requiring a call-out. An
              example of a perfect person to have would be a favourite plumber who knows
              your heating and boiler systems inside out
            </li>
            <li>
              Any special instructions, such as how to activate the hot water for the
              shower, how to lock the door if it has an uncommon mechanism, and so on
            </li>
          </ul>

          <h5>Maintenance and emergency situations</h5>
          <p>
            Most guest-raised problems will be maintenance-related, such as boiler
            breakdowns, faulty locks, or the like. Our staff will also highlight any
            issues they observe whilst at your property.
          </p>
          <p>
            When you complete the onboarding checklist, we ask you to decide your level
            of involvement in case of any emergent issues. Each host can choose between
            two options:
          </p>
          <ul>
            <li>
              A completely hassle free service, providing us with the host's consent to
              make decisions on their behalf when there is an issue to solve, with a
              budget of {numberFormat(locale, moneyRates(250, currency), currency, 0)}.
            </li>
            <li>To be involved in case of any issues.</li>
          </ul>
          <p>
            You can also select which items we can replace if damaged from a list of
            essentials. This will provide a faster resolution to standard situations,
            which will in return improve your guests’ experience.
          </p>
          <p>Please note that the default setting is "Hassle Free."</p>
          <p>
            You can also provide us with a list of “Contacts” that we should reach out
            to in case of maintenance issues or emergencies: a relative, a trusted
            contractor, or so on. You can add as many as you like.
          </p>

          <h5>Maintenance fees</h5>
          <p>
            The management fee covers the day-to-day running of your listing. We’re
            eager to go the extra mile to assist hosts and provide a great experience
            for guests and support your property upkeep.
          </p>
          <p>
            Our local management teams use service providers that best suit the local
            geography. We therefore have provided a guide to support additional service
            charges, (*these are subject to amendment by the local team)
          </p>
          <p className="mb-5">
            Associate | call-out{" "}
            {numberFormat(locale, moneyRates(25, currency), currency)} + VAT (jobs up to
            1.5h + {numberFormat(locale, moneyRates(15, currency), currency, 0)}
            /hour (+ VAT) thereafter)
          </p>
          <ul className="mt-0">
            <li>Changing light bulbs</li>
            <li>Picking up post and sending on to host</li>
            <li>Extra home check</li>
            <li>Furnishing</li>
            <li>Extra key-cutting</li>
            <li>Any similar ad-hoc job request</li>
          </ul>
          <p className="mb-5">
            Provider | call-out{" "}
            {numberFormat(locale, moneyRates(35, currency), currency, 0)}/h + VAT (jobs
            up to 1.5h + {numberFormat(locale, moneyRates(15, currency), currency, 0)}
            /hour (+ VAT) thereafter)
          </p>
          <ul className="mt-0">
            <li>Re-pressurising boiler</li>
            <li>Minor home repairs</li>
            <li>Moving furniture</li>
            <li>Any similar ad-hoc job request</li>
          </ul>
          <p className="mb-5">
            Specialist | call-out charged at cost plus Associate call-out (if
            applicable) and office time management at a rate of{" "}
            {numberFormat(locale, moneyRates(35, currency), currency, 0)} + VAT
          </p>
          <ul className="mt-0">
            <li>Severe plumbing issues</li>
            <li>Boiler failures</li>
            <li>Repairing/changing locks</li>
            <li>Pest control</li>
            <li>Carpet cleaning</li>
            <li>Painting</li>
            <li>Any similar ad-hoc job request</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
