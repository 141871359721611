import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { MATERIALIZE_REF } from "../dynamic_onboarding/contexts";
import { useForm } from "react-hook-form";
import SmallSpinner from "../utility/small_spinner";
import { rPOST } from "../../actions/api";
import config from "../../config";

const ResetPassword = () => {
  const M = useContext(MATERIALIZE_REF);
  const [passwordReset, setPasswordReset] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    var elem = document.querySelector("#resetpwModal.modal");
    M.Modal.init(elem);
  }, []);

  useEffect(() => {
    if (passwordReset) {
      showToast(passwordReset.detail);
    }
  }, [passwordReset]);

  function handleFormSubmit({ email }) {
    // Submit the reset password form
    localStorage.setItem("email", { email }.email);
    submitPOST({ email });
  }

  function submitPOST(data) {
    setShowSpinner(true);
    rPOST(`${config.API_AUTH_ROOT}/password-reset/`, data).then(
      (resp) => {
        setPasswordReset(resp.data);
        setShowSpinner(false);
        var elem = document.querySelector(".modal");
        var instance = M.Modal.getInstance(elem);
        instance.close();
      },
      (err) => {
        setPasswordReset(err);
        setShowSpinner(false);
      }
    );
  }

  function showToast(pwResetMsg) {
    const colour =
      pwResetMsg === "Password reset e-mail has been sent." ? "bg-squash" : "bg-error";
    M.toast({
      html: `<div style="position: relative"><div>${pwResetMsg}</div></div>`,
      classes: `centered ${colour}`,
    });
  }

  return (
    <div>
      {/* Modal */}
      <div id="resetpwModal" className="modal bottom-sheet">
        <div className="modal-content">
          <div className="reset_pw_form login row">
            <div className="col s12 split-line">
              <h5 className="title">
                <FormattedMessage
                  id="no_auth_reset_password.title"
                  defaultMessage="Please enter your email"
                />
              </h5>
            </div>
            <form
              className="col s12 login-form"
              onSubmit={handleSubmit(handleFormSubmit.bind(this))}>
              <div className="row">
                <div className="input-field col s12">
                  <FormattedMessage id="reset_password.email" defaultMessage="Email">
                    {(placeholder_text) => (
                      <input
                        placeholder={placeholder_text}
                        type="email"
                        autoComplete="email"
                        className="validate"
                        {...register("email", { required: true })}
                      />
                    )}
                  </FormattedMessage>
                </div>
                <div className="modal-footer col s12">
                  <button
                    id="resetPW"
                    className="btn blue darken-4"
                    action="submit"
                    disabled={showSpinner ? true : false}>
                    {showSpinner ? (
                      <SmallSpinner />
                    ) : (
                      <FormattedMessage
                        id="reset_password.submit"
                        defaultMessage="Submit"
                      />
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* end Modal */}
      <div className="col s12">
        <h6>
          <u>
            <a className="dark-blue modal-trigger" href="#resetpwModal">
              <FormattedMessage
                id="reset_password.prompt"
                defaultMessage="Forgotten password?"
              />
            </a>
          </u>
        </h6>
      </div>
    </div>
  );
};

export default ResetPassword;
