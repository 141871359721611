import React, { useContext, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { rPUT } from "../../actions/api";
import {
  LocaleContext,
  PreferredLanguageContext,
  PropertyIDContext,
} from "../../global_contexts";

const M = window.M;

export const PreferredLanguageSelector = () => {
  const { preferredLanguage, setPreferredLanguage } = useContext(
    PreferredLanguageContext
  );
  const { setLocale } = useContext(LocaleContext);
  const { propertyID } = useContext(PropertyIDContext);
  const selectRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (
        selectRef.current &&
        preferredLanguage !== (selectRef.current && selectRef.current.value)
      ) {
        selectRef.current.value = preferredLanguage;
      }
    }, 100);
  }, [selectRef.current && selectRef.current.value]);

  useEffect(() => {
    // Check if the select element ref is available
    if (selectRef.current) {
      // Initialize Materialize select
      M.FormSelect.init(selectRef.current);
    }
  }, []);

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    updateClientPreferredLanguage(
      propertyID,
      newValue,
      { setLocale },
      { setPreferredLanguage }
    );
  };

  return (
    <div id="preferred_language" className="select-container col s12">
      <p>
        <FormattedMessage
          id="profile.preferences.preferred_language"
          defaultMessage="Preferred Language:"
        />
      </p>
      <select
        onChange={handleSelectChange}
        ref={selectRef}
        name="language"
        id="language_selector"
        className={`browser-default`}
        value={preferredLanguage}>
        <option value="en">English</option>
        <option value="es">español</option>
      </select>
      <p className="description">
        <FormattedMessage
          id="preferred_language.description"
          defaultMessage="Changing your preferred language will update the default language you view our websites in and the communications we send you"
        />
      </p>
    </div>
  );
};

export function updateClientPreferredLanguage(
  propertyID,
  newPreferredLanguage,
  { setLocale },
  { setPreferredLanguage }
) {
  rPUT(`/profile/language/${propertyID}/`, {
    preferred_language: `${newPreferredLanguage}`,
  }).then(() => {
    setLocale(newPreferredLanguage);
    setPreferredLanguage(newPreferredLanguage);
  });
}
