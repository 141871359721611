import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import ChangePassword from "../auth/change_password";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import SmallSpinner from "../utility/small_spinner";
import ServiceDetails, { calculateDate } from "./service_details";
import MandateStatus from "./mandate_status";
import TermsAndConditions from "../../common/terms_and_conditions";
import Notifications from "./notifications";
import { FormattedMessage } from "react-intl";
import { ClientPreferences } from "./client_preferences";
import { HostContext, LocaleContext, PropertyIDContext } from "../../global_contexts";
import { MATERIALIZE_REF } from "../dynamic_onboarding/contexts";
import HostKeyTerms from "./key_terms";

const Profile = (props) => {
  const M = useContext(MATERIALIZE_REF);
  const { locale } = useContext(LocaleContext);
  const { hostName, setHostName, setHostID } = useContext(HostContext);
  const { propertyID, setPropertyID } = useContext(PropertyIDContext);
  const profileElRef = useRef(null);
  const [instanceTCModal, setInstanceTCModal] = useState(null);
  const [contentHtmlTC, setContentHtmlTC] = useState(
    props.profile &&
      props.profile.terms_and_conditions &&
      props.profile.terms_and_conditions.html_content
  );

  useEffect(() => {
    if (props.profile) {
      if (props.profile.customer.mandate_status) {
        if (profileElRef.current) {
          const elems = profileElRef.current.querySelectorAll(".dd.collapsible");
          M.Collapsible.init(elems);
        }
      }

      if (
        props.profile.terms_and_conditions &&
        props.profile.terms_and_conditions.html_content
      ) {
        setContentHtmlTC(props.profile.terms_and_conditions.html_content);
      }
    }
  }, [props.profile]);

  useEffect(() => {
    // Ensure correct TCs are displayed for locale setting
    if (propertyID) {
      props.profileData(propertyID, locale, { setHostID, setHostName });
    }
  }, [locale]);

  function logOut() {
    props.logoutUser(
      <FormattedMessage
        id="login.logged_out_message"
        defaultMessage="You've been logged out successfully."
      />,
      { setPropertyID }
    );
    return props.history.push("/login/");
  }

  return (
    <div className="row profile mb-0" ref={profileElRef}>
      {contentHtmlTC && (
        <TermsAndConditions
          setInstanceTCModalInProfile={setInstanceTCModal}
          terms_and_conditions_accepted={contentHtmlTC}
        />
      )}
      <div className="col s12">
        <div className="row">
          <div className="col s12">
            <h4>
              <FormattedMessage id="profile.title" defaultMessage="Profile" />
            </h4>
          </div>
        </div>
      </div>
      {props.profile && props.property ? (
        <div>
          <div className="row">
            <div className="col s8 m5">
              <div className="img-container">
                <img
                  alt="User"
                  src={
                    props.profile.client_thumbnail
                      ? props.profile.client_thumbnail
                      : require(`../../styles/img/default-user-min.png`)
                  }
                  width="154px"
                  max-height="183px"
                />
              </div>
            </div>
            <div className="col s4 m7">
              <h5 className="pb-10 mt-0 right-align">{hostName}</h5>
              <h6 className="right-align">
                <FormattedMessage
                  id="profile.renting_out"
                  defaultMessage="Renting out "
                />
                {props.profile && props.profile.properties_managed.length > 1 ? (
                  <FormattedMessage
                    id="profile.properties"
                    defaultMessage={"properties"}>
                    {(text) => `${props.profile.properties_managed.length} ${text}`}
                  </FormattedMessage>
                ) : (
                  <FormattedMessage id="profile.property" defaultMessage={"property"}>
                    {(text) => `${props.profile.properties_managed.length} ${text}`}
                  </FormattedMessage>
                )}{" "}
              </h6>
              <div className="show-on-medium-and-up hide-on-med-and-down">
                <div className="fix-display pb-10 pt-20 call">
                  <p className="dark-blue">
                    <span className="mr-15">
                      <Icon
                        icon={ICONS.PHONE.d}
                        width={ICONS.PHONE.width}
                        height={ICONS.PHONE.height}
                      />
                    </span>
                    <FormattedMessage
                      id="profile.telephone"
                      defaultMessage="Telephone"
                    />
                  </p>
                  <p className="mt-0 mb-0">
                    {props.profile && props.profile.telephone}
                  </p>
                </div>
                <div className="fix-display pt-10 email">
                  <p className="dark-blue">
                    <span className="mr-15">
                      <Icon
                        icon={ICONS.EMAIL.d}
                        width={ICONS.EMAIL.width}
                        height={ICONS.EMAIL.height}
                      />
                    </span>
                    <FormattedMessage id="profile.email" defaultMessage="E-mail" />
                  </p>
                  <p className="mt-0 mb-0">{props.profile && props.profile.email}</p>
                </div>
                <div className="fix-display pt-10 address right-align">
                  <p>
                    <span className="mr-15">
                      <Icon
                        icon={ICONS.PROPERTY.d}
                        width={ICONS.PROPERTY.width}
                        height={ICONS.PROPERTY.height}
                      />
                    </span>
                    <FormattedMessage id="profile.address" defaultMessage="Address" />
                  </p>
                  {props.property && (
                    <p className="mt-0 mb-0">{props.profile.address}</p>
                  )}
                </div>
                <div className="fix-display pt-10 address right-align">
                  <p>
                    <span className="mr-15">
                      <Icon
                        icon={ICONS.BUSINESS.d}
                        width={ICONS.BUSINESS.width}
                        height={ICONS.BUSINESS.height}
                        viewBox={ICONS.BUSINESS.viewBox}
                      />
                    </span>
                    <FormattedMessage
                      id="profile.business_name"
                      defaultMessage="Business name"
                    />
                  </p>
                  {props.property && (
                    <p className="mt-0 mb-0">{props.profile.business_name}</p>
                  )}
                </div>
                {props.profile.nif && (
                  <div className="fix-display pt-10 address right-align">
                    <p>
                      <span className="mr-15">
                        <Icon
                          icon={ICONS.BUSINESS_CARD.d}
                          width={ICONS.BUSINESS_CARD.width}
                          height={ICONS.BUSINESS_CARD.height}
                        />
                      </span>
                      NIF
                    </p>
                    {props.property && (
                      <p className="mt-0 mb-0">{props.profile.nif.number}</p>
                    )}
                  </div>
                )}
                {props.profile.accepted_terms_and_conditions_timestamp && (
                  <button
                    className="fix-display pl-0 pt-10 pb-10 open-tc right-align"
                    onClick={() => instanceTCModal.open()}>
                    <p>
                      <span className="mr-15">
                        <Icon
                          icon={ICONS.CHECKLIST.d}
                          width={28}
                          height={28}
                          viewBox="1 1 22 22"
                        />
                      </span>
                      <FormattedMessage
                        id="profile.tos"
                        defaultMessage="Terms of Service"
                      />
                    </p>
                    <p className="mt-0 mb-0">
                      <FormattedMessage
                        id="profile.last_updated"
                        defaultMessage="last updated "
                      />
                      {calculateDate(props.profile.terms_and_conditions.activated_at)}
                    </p>
                  </button>
                )}
                {props.profile.customer.mandate_status && (
                  <MandateStatus customer={props.profile.customer} mobile={false} />
                )}
              </div>
            </div>
          </div>
          <div className="row hide-on-med-and-up">
            <div className="col s12 fix-display pb-10 pt-10 call">
              <p>
                <span className="mr-15">
                  <Icon
                    icon={ICONS.PHONE.d}
                    width={ICONS.PHONE.width}
                    height={ICONS.PHONE.height}
                  />
                </span>
              </p>
              <p>{props.profile && props.profile.telephone}</p>
            </div>
            <div className="col s12 fix-display pt-10 pb-10 email">
              <p>
                <span className="mr-15">
                  <Icon
                    icon={ICONS.EMAIL.d}
                    width={ICONS.EMAIL.width}
                    height={ICONS.EMAIL.height}
                  />
                </span>
              </p>
              <p className="mt-0 mb-0">{props.profile && props.profile.email}</p>
            </div>
            <div className="col s12 fix-display pt-10 address right-align">
              <p>
                <span className="mr-15">
                  <Icon
                    icon={ICONS.PROPERTY.d}
                    width={ICONS.PROPERTY.width}
                    height={ICONS.PROPERTY.height}
                  />
                </span>
              </p>
              {props.property && <p className="mt-0 mb-0">{props.property.name}</p>}
            </div>
            <div className="col s12 fix-display pt-10 address right-align">
              <p>
                <span className="mr-15">
                  <Icon
                    icon={ICONS.BUSINESS.d}
                    width={ICONS.BUSINESS.width}
                    height={ICONS.BUSINESS.height}
                    viewBox={ICONS.BUSINESS.viewBox}
                  />
                </span>
              </p>
              {props.property && (
                <p className="mt-0 mb-0">{props.profile.business_name}</p>
              )}
            </div>
            {props.profile.nif && (
              <div className="col s12 fix-display pt-10 address right-align">
                <p>
                  <span className="mr-15">
                    <Icon
                      icon={ICONS.BUSINESS_CARD.d}
                      width={ICONS.BUSINESS_CARD.width}
                      height={ICONS.BUSINESS_CARD.height}
                    />
                  </span>
                </p>
                {props.property && (
                  <p className="mt-0 mb-0">{props.profile.nif.number}</p>
                )}
              </div>
            )}
            {props.profile.terms_and_conditions && (
              <button
                className="col s12 fix-display pt-10 open-tc right-align"
                onClick={() => instanceTCModal.open()}>
                <p>
                  <span className="mr-15">
                    <Icon
                      icon={ICONS.CHECKLIST.d}
                      width={28}
                      height={28}
                      viewBox="1 1 22 22"
                    />
                  </span>
                </p>
                <p className="mt-0">
                  <FormattedMessage
                    id="profile.tos_last_updated"
                    defaultMessage="T&Cs last updated "
                  />
                  {calculateDate(props.profile.terms_and_conditions.activated_at)}
                </p>
              </button>
            )}
            {props.profile.customer.mandate_status && (
              <MandateStatus customer={props.profile.customer} mobile={true} />
            )}
          </div>
          <div className="row">
            <div className="col s12 m7 offset-m5">
              <div
                className={`fix-display profile__buttons ${
                  !props.profile.terms_and_conditions?.has_key_terms ? "" : "flex-end"
                }`}>
                {!props.profile.terms_and_conditions?.has_key_terms ? (
                  <button
                    className="secondary-button cobalt-blue viewTC"
                    onClick={() => instanceTCModal.open()}>
                    <span className="mr-10">
                      <Icon
                        icon={ICONS.LENS.d}
                        width={20}
                        height={20}
                        viewBox="3 6 24 24"
                      />
                    </span>
                    <FormattedMessage
                      id="profile.view_tos"
                      defaultMessage="View T&Cs"
                    />
                  </button>
                ) : null}
                <ChangePassword />
              </div>
            </div>
          </div>
          {props.profile.properties_managed && props.profile.properties_managed.length && (
            <div className="row information">
              <ClientPreferences />
              <Notifications />
              <div className="col s12 mt-10">
                <hr />
              </div>
              {props.profile.terms_and_conditions?.has_key_terms ? (
                <div className="col s12 mt-10 pos-rel">
                  <h5>
                    <FormattedMessage
                      id="profile.key_terms"
                      defaultMessage="Your Key Terms"
                    />
                  </h5>
                  <button
                    className="secondary-button cobalt-blue viewTC in_key_terms"
                    onClick={() => instanceTCModal.open()}>
                    <span className="mr-10">
                      <Icon
                        icon={ICONS.LENS.d}
                        width={20}
                        height={20}
                        viewBox="3 6 24 24"
                      />
                    </span>
                    <FormattedMessage
                      id="profile.view_tos_expanded"
                      defaultMessage="view full Terms & Conditions"
                    />
                  </button>
                  <div className="agreement-container">
                    {props.profile.properties_managed.map((property, i) => (
                      <HostKeyTerms
                        key={i}
                        property={property}
                        single={
                          props.profile.properties_managed.length > 1 ? false : true
                        }
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <div className="col s12 mt-10">
                  <h5>
                    <FormattedMessage
                      id="profile.service_details"
                      defaultMessage="Service Details"
                    />
                  </h5>
                  <div className="agreement-container">
                    {props.profile.properties_managed.map((property, i) => (
                      <ServiceDetails
                        key={i}
                        property={property}
                        single={
                          props.profile.properties_managed.length > 1 ? false : true
                        }
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="row information pt-0">
            <div className="col s12 pt-10 right-align hide-on-med-and-up">
              <div className="border-b" />
              <button className="btn logout-btn mt-25" onClick={logOut}>
                <FormattedMessage id="profile.log_out" defaultMessage="Log Out" />
              </button>
            </div>
            <div className="col s12">
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col s12 right-align">
              <div className="row show-on-medium-and-up hide-on-med-and-down">
                <div className="col m12 right-align">
                  <button
                    className="btn waves-effect waves-light logout-btn"
                    onClick={logOut}>
                    <FormattedMessage id="profile.log_out" defaultMessage="Log Out" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SmallSpinner />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    profile: state.user.profile,
    property: state.property.property_info,
    logout_msg: state.auth.logout_msg,
  };
}

export default connect(mapStateToProps, actions)(Profile);
