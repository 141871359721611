import React, { Component } from "react";
import { Fields, reduxForm, getFormValues, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import * as actions from "../../actions";
import { SECTIONS } from "./fields";
import { validateOnboardingDates } from "./forms/validation";
import { saveCheck } from "./forms/common";
import FormComponent from "./forms/form_component";
import { compose } from "redux";

interface Props {
  checklistValues: any;
  check_status: any;
}

class OnboardingDetails extends Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      onboarding_details: {},
      propertyID: localStorage.getItem("property_id")
        ? localStorage.getItem("property_id")
        : undefined,
    };
  }

  displayFields(name: string, selected_val: string | boolean) {
    switch (true) {
      case [
        "start_hosting_date",
        "first_hosting_period_end_date",
        "handover_date",
        "early_photography_date",
      ].includes(name):
        let obj_d: any = {};
        obj_d[name] = selected_val;
        this.setState(() => ({ onboarding_details: obj_d }));
        break;
      case name === "hosting_indefinitely":
        const hosting_end_date = document.querySelector(
          "#first_hosting_period_end_date"
        );
        if (String(selected_val).match(/^no_.+/) || selected_val === false) {
          hosting_end_date && hosting_end_date.classList.remove("hide");
        } else {
          hosting_end_date && hosting_end_date.classList.add("hide");
        }
        break;
      case name === "photography_timing":
        const photo_date = document.querySelector("#early_photography_date");
        if (selected_val === "before_handover_date") {
          photo_date && photo_date.classList.remove("hide");
        } else {
          photo_date && photo_date.classList.add("hide");
        }
        break;
      default:
        break;
    }
  }

  componentDidUpdate(prevProps: any) {
    Object.keys(this.props.checklistValues).forEach((key) => {
      if (this.props.checklistValues[key] !== prevProps.checklistValues[key]) {
        this.displayFields(key, this.props.checklistValues[key]);
        this.setState(() => ({
          onboarding_details: this.props.checklistValues,
        }));
      }
    });
  }

  render() {
    const {
      keysafeTypeValue,
      keysafeTypeOwnValue,
      ownKeysafeValue,
      hostingIndefinitelyValue,
      photographyRequiredValue,
      photographyTimingValue,
    }: any = this.props;
    return (
      <form
        id="onboarding_details"
        className={`${
          this.props.check_status && this.props.check_status[4].stage
        } fields-cont`}
        onChange={(e) =>
          saveCheck(e, this.state.onboarding_details, validateOnboardingDates)
        }
        onBlur={(e) =>
          saveCheck(e, this.state.onboarding_details, validateOnboardingDates)
        }>
        {this.props &&
          SECTIONS["Onboarding"].map((section, index) => (
            <Fields
              names={section.names}
              component={FormComponent}
              details={section.details}
              key={section.names[index]}
              conditionalValue={[
                {
                  keysafe_type: keysafeTypeValue,
                  owned_keysafe_type: keysafeTypeOwnValue,
                  own_keysafe: ownKeysafeValue,
                  hosting_indefinitely: hostingIndefinitelyValue,
                  photography_required: photographyRequiredValue,
                  photography_timing: photographyTimingValue,
                },
              ]}
              chargeType={undefined}
              propertyID={this.state.propertyID}
              section="onboarding_details"
            />
          ))}
      </form>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    property: state.property.property_info,
    checklist: state.checklist.onboarding_checklist,
    update_checklist: state.checklist.update_checklist,
  };
}

const OnboardingDetailsForm = reduxForm<any, any, any>({
  form: "onboarding_details",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
});

// Decorate with connect to read form values
const selector = formValueSelector("onboarding_details");

const OnboardingDetailsConnect = connect(
  (state: any) => {
    const keysafeTypeValue = selector(state, "keysafe_type");
    const keysafeTypeOwnValue = selector(state, "owned_keysafe_type");
    const ownKeysafeValue = selector(state, "own_keysafe");
    const hostingIndefinitelyValue = selector(state, "hosting_indefinitely");
    const photographyRequiredValue = selector(state, "photography_required");
    const photographyTimingValue = selector(state, "photography_timing");
    return {
      keysafeTypeValue,
      keysafeTypeOwnValue,
      ownKeysafeValue,
      hostingIndefinitelyValue,
      photographyRequiredValue,
      photographyTimingValue,
      initialValues: state.checklist.onboarding_checklist,
      checklistValues: getFormValues("onboarding_details")(state) || {},
    };
  },
  { actions }
);

const withConnect = connect(mapStateToProps, actions);

export default compose<any>(
  withRouter,
  withConnect,
  OnboardingDetailsConnect,
  OnboardingDetailsForm
)(OnboardingDetails);
