function getYear(date) {
  if (date instanceof Date) {
    return date.getFullYear();
  }

  if (typeof date === "number") {
    return date;
  }

  var year = parseInt(date, 10);

  if (typeof date === "string" && !Number.isNaN(year)) {
    return year;
  }

  throw new Error("Failed to get year from date: " + date + ".");
}

function getMonth(date) {
  return date.getMonth() + 1;
}

function getMonthIndex(date) {
  return date.getMonth();
}

function getDay(date) {
  return date.getDate();
}

function getDayOfWeek(date) {
  var weekday = date.getDay();
  // Shifts days of the week so that Monday is 0, Sunday is 6
  return (weekday + 6) % 7;
}

function getBeginOfMonth(date) {
  var year = getYear(date);
  var monthIndex = getMonthIndex(date);
  return new Date(year, monthIndex, 1);
}

function getEndOfMonth(date) {
  var year = getYear(date);
  var monthIndex = getMonthIndex(date);
  return new Date(year, monthIndex + 1, 1, 0, 0, 0, -1);
}

/**
 * Returns the beginning of a given week.
 *
 * @param {Date} date Date.
 * @param {String} calendarType Calendar type. Can be ISO 8601 or US.
 */
function getBeginOfWeek(date) {
  var calendarType =
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "ISO 8601";

  var year = getYear(date);
  var monthIndex = getMonthIndex(date);
  var day = date.getDate() - getDayOfWeek(date, calendarType);
  return new Date(year, monthIndex, day);
}

/**
 * Returns the beginning of a given year.
 *
 * @param {Date} date Date.
 */
function getBeginOfYear(date) {
  var year = getYear(date);
  return new Date(year, 0, 1);
}

/**
 * Returns the end of a given year.
 *
 * @param {Date} date Date.
 */
function getEndOfYear(date) {
  var year = getYear(date);
  return new Date(year + 1, 0, 1, 0, 0, 0, -1);
}

function getBeginOfPreviousYear(date) {
  var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

  var previousYear = getYear(date) - offset;
  return getBeginOfYear(previousYear);
}

function getEndOfPreviousYear(date) {
  var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

  var previousYear = getYear(date) - offset;
  return getEndOfYear(previousYear);
}

function getBeginOfNextYear(date) {
  var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

  var nextYear = getYear(date) + offset;
  return getBeginOfYear(nextYear);
}

/**
 * Returns an array with the beginning and the end of a given month.
 *
 * @param {Date} date Date.
 */
function getMonthRange(date) {
  return [getBeginOfMonth(date), getEndOfMonth(date)];
}

function getDifferentMonth(date, offset) {
  var year = getYear(date);
  var previousMonthIndex = getMonthIndex(date) + offset;
  return new Date(year, previousMonthIndex, 1);
}

function getBeginOfPreviousMonth(date) {
  var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

  var previousMonth = getDifferentMonth(date, -offset);
  return getBeginOfMonth(previousMonth);
}

function getEndOfPreviousMonth(date) {
  var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

  var previousMonth = getDifferentMonth(date, -offset);
  return getEndOfMonth(previousMonth);
}

function getBeginOfNextMonth(date) {
  var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

  var nextMonth = getDifferentMonth(date, offset);
  return getBeginOfMonth(nextMonth);
}

function getBeginOfDay(date) {
  var year = getYear(date);
  var monthIndex = getMonthIndex(date);
  var day = getDay(date);
  return new Date(year, monthIndex, day);
}

function getEndOfDay(date) {
  var year = getYear(date);
  var monthIndex = getMonthIndex(date);
  var day = getDay(date);
  return new Date(year, monthIndex, day + 1, 0, 0, 0, -1);
}

/**
 * Returns an array with the beginning and the end of a given day.
 *
 * @param {Date} date Date.
 */
function getDayRange(date) {
  return [getBeginOfDay(date), getEndOfDay(date)];
}

/**
 * Gets week number according to ISO 8601 or US standard.
 * In ISO 8601 week 1 is the one with January 4.
 * In US calendar week 1 is the one with January 1.
 *
 * @param {Date} date Date.
 * @param {String} calendarType Calendar type. Can be ISO 8601 or US.
 */
function getWeekNumber(date) {
  var calendarType =
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "ISO 8601";

  var beginOfWeek = getBeginOfWeek(date, calendarType);
  var year = getYear(date) + 1;
  var dayInWeekOne = void 0;
  var beginOfFirstWeek = void 0;

  // Look for the first week one that does not come after a given date
  do {
    dayInWeekOne = new Date(year, 0, calendarType === "ISO 8601" ? 4 : 1);
    beginOfFirstWeek = getBeginOfWeek(dayInWeekOne, calendarType);
    year -= 1;
  } while (date - beginOfFirstWeek < 0);

  return Math.round((beginOfWeek - beginOfFirstWeek) / (8.64e7 * 7)) + 1;
}

/**
 * Returns the beginning of a given range.
 *
 * @param {String} rangeType Range type (e.g. 'day')
 * @param {Date} date Date.
 */
function getBegin(rangeType, date) {
  switch (rangeType) {
    case "year":
      return getBeginOfYear(date);
    case "month":
      return getBeginOfMonth(date);
    case "day":
      return getBeginOfDay(date);
    default:
      throw new Error("Invalid rangeType: " + rangeType);
  }
}

function getBeginPrevious(rangeType, date) {
  switch (rangeType) {
    case "year":
      return getBeginOfPreviousYear(date);
    case "month":
      return getBeginOfPreviousMonth(date);
    default:
      throw new Error("Invalid rangeType: " + rangeType);
  }
}

function getBeginNext(rangeType, date) {
  switch (rangeType) {
    case "year":
      return getBeginOfNextYear(date);
    case "month":
      return getBeginOfNextMonth(date);
    default:
      throw new Error("Invalid rangeType: " + rangeType);
  }
}

/**
 * Returns the end of a given range.
 *
 * @param {String} rangeType Range type (e.g. 'day')
 * @param {Date} date Date.
 */
function getEnd(rangeType, date) {
  switch (rangeType) {
    case "year":
      return getEndOfYear(date);
    case "month":
      return getEndOfMonth(date);
    case "day":
      return getEndOfDay(date);
    default:
      throw new Error("Invalid rangeType: " + rangeType);
  }
}

function getEndPrevious(rangeType, date) {
  switch (rangeType) {
    case "year":
      return getEndOfPreviousYear(date);
    case "month":
      return getEndOfPreviousMonth(date);
    default:
      throw new Error("Invalid rangeType: " + rangeType);
  }
}

/**
 * Creates a range out of two values, ensuring they are in order and covering entire period ranges.
 *
 * @param {String} rangeType Range type (e.g. 'day')
 * @param {Date} date1 First date.
 * @param {Date} date2 Second date.
 */
function getValueRange(rangeType, date1, date2) {
  var rawNextValue = [date1, date2].sort(function (a, b) {
    return a.getTime() > b.getTime();
  });
  return [getBegin(rangeType, rawNextValue[0]), getEnd(rangeType, rawNextValue[1])];
}

/**
 * Returns a number of days in a month of a given date.
 *
 * @param {Date} date Date.
 */
function getDaysInMonth(date) {
  var year = getYear(date);
  var monthIndex = getMonthIndex(date);
  return new Date(year, monthIndex + 1, 0).getDate();
}

/**
 * Returns a boolean determining whether a given date is on Saturday or Sunday.
 *
 * @param {Date} date Date.
 */
function isWeekend(date) {
  var weekday = getDayOfWeek(date);
  return weekday >= 5;
}

/**
 * Returns local month in ISO-like format (YYYY-MM).
 */
function getISOLocalMonth(value) {
  if (!value) {
    return value;
  }

  var date = new Date(value);

  if (Number.isNaN(date.getTime())) {
    throw new Error("Invalid date: " + value);
  }

  var year = getYear(date);
  var month = ("0" + getMonth(date)).slice(-2);

  return year + "-" + month;
}

/**
 * Returns local date in ISO-like format (YYYY-MM-DD).
 */
function getISOLocalDate(value, slashSplit = false) {
  if (!value) {
    return value;
  }

  if (value.toString().indexOf("/")) {
    const re_exp = new RegExp(/(\d{2})\/(\d{2})\/(\d{4})/);
    value = value.toString().replace(re_exp, "$3-$2-$2");
  }

  var date = new Date(value);

  if (Number.isNaN(date.getTime())) {
    throw new Error("Invalid date: " + value);
  }

  var year = getYear(date);
  var month = ("0" + getMonth(date)).slice(-2);
  var day = ("0" + getDay(date)).slice(-2);

  return !slashSplit ? year + "-" + month + "-" + day : day + "/" + month + "/" + year;
}

function GetDatepickeri18nData(intl, locale) {
  let localisedDatepickeri18nData = {
    months: getLocalisedDate({ month: "long" }, "month"), // [January, ...]
    monthsShort: getLocalisedDate({ month: "short" }, "month"), // [Jan, ...]
    weekdays: getLocalisedDate({ weekday: "long" }, "week"), // [Sunday, Monday, ...]
    weekdaysShort: getLocalisedDate({ weekday: "short" }, "week"), // [Sun, Mon, ...]
    weekdaysAbbrev: getLocalisedDate({ weekday: "narrow" }, "week"), // [S, M, ...]
    cancel: intl.formatMessage({ id: "datepicker.cancel", defaultMessage: "Cancel" }),
    done: intl.formatMessage({ id: "datepicker.ok", defaultMessage: "Ok" }),
  };

  function getLocalisedDate(options, dateType) {
    var baseDate = new Date(Date.UTC(2000, 0, 2)); // a Sunday - required to start from Sunday as the default datepicker does so (aka if we start from Monday the datepicker will show the incorrect Weekday for the dates)
    var localisedDate = [];
    let iterator = dateType === "week" ? 7 : 12;
    for (let i = 0; i < iterator; i++) {
      if (locale === "en") {
        // required as the locale is defaulting to en-US (whom display dates differently to en-GB)
        localisedDate.push(baseDate.toLocaleDateString(`${locale}-GB`, options));
      } else {
        localisedDate.push(baseDate.toLocaleDateString(locale, options));
      }
      if (dateType === "week") {
        baseDate.setDate(baseDate.getDate() + 1);
      } else {
        baseDate.setMonth(baseDate.getMonth() + 1);
      }
    }
    return localisedDate;
  }

  return localisedDatepickeri18nData;
}

export {
  getMonthRange,
  getDayOfWeek,
  getDay,
  getEndOfPreviousMonth,
  getBeginOfNextMonth,
  getBeginOfMonth,
  getBeginOfPreviousMonth,
  getDayRange,
  getWeekNumber,
  getBeginPrevious,
  getBeginNext,
  getEndPrevious,
  getValueRange,
  getDaysInMonth,
  isWeekend,
  getISOLocalMonth,
  getISOLocalDate,
  GetDatepickeri18nData,
};
