import React, { useContext } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import PerformanceDashboard from "./performance_dashboard";
import CalendarDashboard from "./calendar_dashboard";
import BookingDashboard from "./booking_dashboard";
import ReviewDashboard from "./review_dashboard";
import ServiceDashboard from "./service_dashboard";
import PhotosDashboard from "./photos_dashboard";
import GreatHostGuideDashboard from "./great_host_guide_dashboard";
import { greetingForTimeOfDay } from "../../common/util";
import { FormattedMessage } from "react-intl";
import { HostContext, PropertyContext } from "../../global_contexts";
import SmallSpinner from "../utility/small_spinner";

const Dashboard = (props) => {
  const { hostName } = useContext(HostContext);
  const { propertyName, propertyCountry } = useContext(PropertyContext);

  return (
    <div className="row dashboard">
      {hostName && propertyName ? (
        <>
          <div className="col s12">
            <h4 data-testid="dashboard_greeting" className="hide-on-small-only">
              {greetingForTimeOfDay()}
              {" " + hostName + ", "}
              <FormattedMessage
                id="dashboard.title_second_part"
                defaultMessage="here's an overview of"
              />{" "}
              {propertyName}
            </h4>
          </div>
          <CalendarDashboard testMode={props.testMode} />
          <PerformanceDashboard testMode={props.testMode} />
          <BookingDashboard testMode={props.testMode} />
          <ReviewDashboard testMode={props.testMode} />
          <ServiceDashboard />
          <PhotosDashboard testMode={props.testMode} />
          {propertyCountry === "GB" && (
            <GreatHostGuideDashboard testMode={props.testMode} />
          )}
        </>
      ) : (
        <SmallSpinner />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    profile: state.user.profile,
    property: state.property.property_info,
  };
}

export default connect(mapStateToProps, actions)(Dashboard);
