import React, { useContext, useEffect, useRef, useState } from "react";
import { formatDateDDMMYYYY } from "../common/util";
import SmallSpinner from "./utility/small_spinner";
import { ICONS } from "../components/svg/icons";
import Icon from "../components/svg/svg_sprite";
import { FormattedMessage, injectIntl } from "react-intl";
import { LocaleContext, PropertyIDContext } from "../global_contexts";
import { rGET } from "../actions/api";

const M = window.M;

const Bookings = (props) => {
  const { locale } = useContext(LocaleContext);
  const { propertyID } = useContext(PropertyIDContext);
  const [bookings, setBookings] = useState([]);
  const upcomingNetIncomeTooltipRef = useRef(null);
  const pastNetIncomeTooltipRef = useRef(null);

  useEffect(() => {
    initTooltips();
  }, [locale]);

  useEffect(() => {
    setBookings([]);
    getBookingsData();
  }, [propertyID]);

  useEffect(() => {
    initTooltips();
  }, [bookings]);

  function initTooltips() {
    const tooltipOptions = {
      position: "left",
      html: props.intl.formatMessage({
        id: "bookings.net_income_info_popup",
        defaultMessage:
          "This amount is an estimated figure and may change, as there may be costs or fees that have not been applied yet. The final net income figure will be confirmed once the invoice has been generated.",
      }),
    };
    const upcomingNetIncomeTooltipInstance = M.Tooltip.init(
      upcomingNetIncomeTooltipRef.current,
      tooltipOptions
    );
    const pastNetIncomeTooltipInstance = M.Tooltip.init(
      pastNetIncomeTooltipRef.current,
      tooltipOptions
    );
    if (upcomingNetIncomeTooltipInstance) {
      upcomingNetIncomeTooltipInstance.tooltipEl.classList.add("right_arrow");
    }
    if (pastNetIncomeTooltipInstance) {
      pastNetIncomeTooltipInstance.tooltipEl.classList.add("right_arrow");
    }
  }

  function mobileDate(date) {
    const d = date.split("/");
    if (d[0].length === 1) {
      return d[1].length === 1 ? date.slice(0, 3) : date.slice(0, 4);
    } else {
      return d[1].length === 1 ? date.slice(0, 4) : date.slice(0, 5);
    }
  }

  function listUpcomingBookings(UPCOMING_BOOKINGS) {
    return UPCOMING_BOOKINGS.map((booking) => {
      return (
        <tr key={booking.check_in_date}>
          <td className="hide-on-med-and-up">
            {mobileDate(formatDateDDMMYYYY(booking.check_in_date))} to{" "}
            {formatDateDDMMYYYY(booking.check_out_date)}
          </td>
          <td className="show-on-medium-and-up hide-on-med-and-down">
            {formatDateDDMMYYYY(booking.check_in_date)} -{" "}
            {formatDateDDMMYYYY(booking.check_out_date)}
          </td>
          <td>{formatDateDDMMYYYY(booking.booking_taken_date)}</td>
          <td>{booking.guest_name}</td>
          <td
            className={
              booking.platform === "Airbnb"
                ? "airbnb-red"
                : booking.platform === "Bookingcom"
                ? "cobalt-blue"
                : ""
            }>
            {booking.platform_icon_url === "" ? null : (
              <img
                src={booking.platform_icon_url}
                width={20}
                height={20}
                style={{ marginRight: "10px" }}
              />
            )}
            {booking.platform}
          </td>
          <td>{booking.net_revenue > 0 ? `${booking.net_revenue_string}` : "-"}</td>
        </tr>
      );
    });
  }

  function listPreviousBookings(PREVIOUS_BOOKINGS) {
    return PREVIOUS_BOOKINGS.map((booking) => {
      return (
        <tr key={booking.check_in_date}>
          <td className="hide-on-med-and-up">
            {mobileDate(formatDateDDMMYYYY(booking.check_in_date))} to{" "}
            {formatDateDDMMYYYY(booking.check_out_date)}
          </td>
          <td className="show-on-medium-and-up hide-on-med-and-down">
            {formatDateDDMMYYYY(booking.check_in_date)} -{" "}
            {formatDateDDMMYYYY(booking.check_out_date)}
          </td>
          <td>{formatDateDDMMYYYY(booking.booking_taken_date)}</td>
          <td>{booking.guest_name}</td>
          <td
            className={
              booking.platform === "Airbnb"
                ? "airbnb-red"
                : booking.platform === "Bookingcom"
                ? "cobalt-blue"
                : ""
            }>
            {booking.platform_icon_url === "" ? null : (
              <img
                src={booking.platform_icon_url}
                width={20}
                height={20}
                style={{ marginRight: "10px" }}
              />
            )}
            {booking.platform}
          </td>
          <td>{booking.net_revenue > 0 ? `${booking.net_revenue_string}` : "-"}</td>
        </tr>
      );
    });
  }

  function getBookingsData() {
    rGET(`/bookings/${propertyID}/`).then((resp) => {
      const ALL_BOOKINGS = resp && resp.data.results;
      const bookingsData = [];
      bookingsData.push(ALL_BOOKINGS);
      const today = new Date();
      const upcoming_bookings = [];
      const previous_bookings = [];
      ALL_BOOKINGS &&
        ALL_BOOKINGS.forEach((single_booking) => {
          if (single_booking.check_out_date >= today.toISOString().slice(0, 10)) {
            upcoming_bookings.push(single_booking);
          } else {
            previous_bookings.push(single_booking);
          }
        });
      bookingsData.push(upcoming_bookings, previous_bookings);
      setBookings(bookingsData);
    });
  }

  return (
    <div className="row">
      <div className="col s12">
        <div className="row mb-0">
          <div className="col s12">
            <h4>
              <FormattedMessage id="bookings.title" defaultMessage="Bookings" />
            </h4>
          </div>
        </div>
      </div>
      {bookings && bookings[0] ? (
        bookings.length > 0 ? (
          <div className="row">
            <div className="col s12">
              <p>
                <FormattedMessage
                  id="bookings.description"
                  defaultMessage="Please see your performance reports for a full break-down of your earnings."
                />
              </p>
            </div>
            {bookings[1] && bookings[1].length > 0 && (
              <div className="row">
                <div className="col s12">
                  <h5>
                    <FormattedMessage
                      id="bookings.subtitle_one"
                      defaultMessage="Upcoming"
                    />
                  </h5>
                </div>
                <div className="col s12">
                  <table>
                    <thead>
                      <tr>
                        <th width="20%">
                          <FormattedMessage
                            id="bookings.stay_dates"
                            defaultMessage="Stay Dates"
                          />
                        </th>
                        <th width="20%">
                          <FormattedMessage
                            id="bookings.booking_taken_date"
                            defaultMessage="Booking Taken Date"
                          />
                        </th>
                        <th width="25%">
                          <FormattedMessage
                            id="bookings.names"
                            defaultMessage="Names"
                          />
                        </th>
                        <th width="20%">
                          <FormattedMessage
                            id="bookings.channel"
                            defaultMessage="Channel"
                          />
                        </th>
                        <th width="15%">
                          <FormattedMessage
                            id="bookings.net_income"
                            defaultMessage={"Net Income "}
                          />
                          <span
                            className="info_btn"
                            aria-label="Net Revenue Info"
                            ref={upcomingNetIncomeTooltipRef}>
                            <Icon
                              icon={ICONS.INFO_EMPTY.d}
                              width={14}
                              height={14}
                              viewBox="0 0 24 24"
                              color="#01095a"
                            />
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{listUpcomingBookings(bookings[1])}</tbody>
                  </table>
                </div>
              </div>
            )}
            {bookings[2] && bookings[2].length > 0 && (
              <div>
                <div className="col s12">
                  <h5>
                    <FormattedMessage
                      id="bookings.subtitle_two"
                      defaultMessage="Past"
                    />
                  </h5>
                </div>
                <div className="col s12">
                  <table>
                    <thead>
                      <tr>
                        <th width="20%">
                          <FormattedMessage
                            id="bookings.stay_dates"
                            defaultMessage="Stay Dates"
                          />
                        </th>
                        <th width="20%">
                          <FormattedMessage
                            id="bookings.booking_taken_date"
                            defaultMessage="Booking Taken Date"
                          />
                        </th>
                        <th width="25%">
                          <FormattedMessage
                            id="bookings.names"
                            defaultMessage="Names"
                          />
                        </th>
                        <th width="20%">
                          <FormattedMessage
                            id="bookings.channel"
                            defaultMessage="Channel"
                          />
                        </th>
                        <th width="15%">
                          <FormattedMessage
                            id="bookings.net_income"
                            defaultMessage={"Net Income "}
                          />
                          <span
                            className="info_btn"
                            aria-label="Net Revenue Info"
                            ref={pastNetIncomeTooltipRef}>
                            <Icon
                              icon={ICONS.INFO_EMPTY.d}
                              width={14}
                              height={14}
                              viewBox="0 0 24 24"
                              color="#01095a"
                            />
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{listPreviousBookings(bookings[2])}</tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="row">
            <div className="col s12">
              <FormattedMessage
                id="bookings.no_bookings"
                defaultMessage="There have been no bookings yet!"
              />
            </div>
          </div>
        )
      ) : (
        <SmallSpinner />
      )}
    </div>
  );
};

export default injectIntl(Bookings);
