import React, { useContext, useEffect, useRef } from "react";
import { SECTION_DISPLAY_CONTEXTS, MATERIALIZE_REF } from "./contexts";

export const ScrollableSectionsDisplay = (props: any) => {
  const tabsRef = useRef<HTMLUListElement>(null);
  const { updateActiveSection } = useContext(SECTION_DISPLAY_CONTEXTS);
  const MATERIALIZE = useContext(MATERIALIZE_REF);

  const updateActiveSectionLocally = (selected_section_title: any) => {
    updateActiveSection(selected_section_title);
    // Reset reused group components to default state
    document.querySelectorAll(".onboarding_checklist__group_wrapper").forEach((el) => {
      el.classList.add("onboarding_checklist__group_wrapper--active");
      const childGroup = el.querySelector(".onboarding_checklist__group");
      childGroup && childGroup.classList.remove("hide");
    });
  };

  useEffect(() => {
    // Initialize Materialize Tabs
    let aElement = tabsRef.current && tabsRef.current.querySelector("a");
    if (tabsRef.current && aElement) {
      MATERIALIZE.Tabs.init(tabsRef.current);
    }
  }, [props.sections]);

  return (
    <div className="tabs valign-wrapper">
      <ul ref={tabsRef} className="tabs z-depth-1">
        {props.sections &&
          props.sections.map((section: any, index: any) => (
            <li
              onClick={() => {
                updateActiveSectionLocally(section.title);
              }}
              id={"section_" + index}
              key={"section_" + index}
              className="tab"
              data-status="not_started">
              <a href={"#" + index}>{section.title}</a>
            </li>
          ))}
      </ul>
    </div>
  );
};
