import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { numberFormat } from "../utility/currency_format";
import { moneyRates } from "../utility/moneyRates";
import { LocaleContext } from "../../global_contexts";

const KeyManagement = () => {
  const { locale } = useContext(LocaleContext);
  const [currency, setCurrency] = useState(localStorage.getItem("currency"));

  useEffect(() => {
    if (currency !== localStorage.getItem("currency")) {
      setCurrency(localStorage.getItem("currency"));
    }
  }, [localStorage.getItem("currency")]);

  useEffect(() => {
    document.querySelector(".main-container .container").classList.add("full-width");

    // returned function will be called on component unmount
    return () => {
      document
        .querySelector(".main-container .container")
        .classList.remove("full-width");
    };
  }, []);

  return (
    <div className="great-host-guide-pages">
      <div className="row">
        <div className="col s12">
          <NavLink exact to="/great-host-guide/" className="left-align">
            <span className="mr-10">
              <Icon
                icon={ICONS.BACK_ARROW.d}
                width={ICONS.BACK_ARROW.width}
                height={12}
                viewBox="0 0 10 20"
              />
            </span>
            <span className="cobalt-blue">Back to Guide summary</span>
          </NavLink>
          <h4 className="center-align">Key Management</h4>
        </div>
      </div>
      <div className="row top-img-container">
        <img
          className="top-img"
          alt="Key Management"
          src={require(`../../styles/img/key-management@2x-min.jpg`)}
        />
      </div>
      <div className="row">
        <div className="col s12">
          <h5>How Many Sets Of Keys?</h5>
          <p>
            We ask hosts to provide us with 3 sets of keys, as this ensures a smooth
            operation. Don’t worry - we’ll keep them safe for you!
          </p>

          <h5>Guest Keys</h5>
          <p>
            These are the keys which guests will use while staying at your property.
            They’ll live securely in the key safe between guests.
          </p>

          <h5>Other Keys</h5>
          <p>
            We’ll keep these keys secured in the office, allowing our staff to use them
            when required or in case of emergency. We’ll also use the guest keys if
            they’re available, too.
          </p>

          <h5>Don’t Forget Your Set!</h5>
          <p>We recommend that you keep a set of keys for yourself!</p>

          <h5>Lost Keys</h5>
          <p>
            On the rare occasions in which a guest loses a key, we’ll make the Airbnb
            claim for you. We advise potential guests of the{" "}
            {numberFormat(locale, moneyRates(100, currency), currency, 0)} replacement
            fee for lost keys in the listing itself.
          </p>
        </div>
      </div>
    </div>
  );
};

export default KeyManagement;
