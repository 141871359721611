import React, { useContext, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Header from "./components/layout/header";
import * as actions from "./actions";
import Navbar from "./components/layout/navbar";
import UnauthRoute from "./components/unauth";
import Main from "./components/main";
import TermsAndConditions from "./common/terms_and_conditions";
import "./styles/App.css";
import {
  HostContext,
  LocaleContext,
  PreferredLanguageContext,
  PropertyContext,
  PropertyIDContext,
} from "./global_contexts";

interface Props extends RouteComponentProps {
  checkAuthState: any;
  selectProperty: any;
  logoutUser: any;
  authenticated: any;
  profileData: any;
  propertyInfo: any;
}

const App = (props: Props) => {
  const { propertyID, setPropertyID } = useContext<any>(PropertyIDContext);
  const { locale } = useContext(LocaleContext);
  const { setHostID, setHostName } = useContext(HostContext);
  const { setPreferredLanguage } = useContext(PreferredLanguageContext);
  const { setPropertyCountry } = useContext(PropertyContext);
  const usedAccessID = useRef(false);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      props.checkAuthState(props.history);
    }
  }, [localStorage.getItem("token")]);

  useEffect(() => {
    if (propertyID) {
      const proxyPropertyID = checkForAccessID(propertyID);
      // Ensure is only called when we aren't just simply setting the propertyID from the localAccessID
      if (proxyPropertyID) {
        props.propertyInfo(proxyPropertyID, locale, {
          setPreferredLanguage,
          setPropertyCountry,
        });
        props.profileData(proxyPropertyID, locale, { setHostID, setHostName });
      }
    }
  }, [propertyID, locale]);

  // This function compares the accessID with the propertyID, but also stops duplicate calls caused by any overriding of the propertyID
  function checkForAccessID(passedPropertyID: number): number {
    let proxyPropertyID = passedPropertyID;
    const localAccessID = parseInt(localStorage.getItem("access_id") || "");
    if (localAccessID && localAccessID !== passedPropertyID) {
      // If there is a localAccessID, it should be the same as the propertyID, if not override it
      proxyPropertyID = localAccessID;
      localStorage.setItem("property_id", localAccessID.toString());
      setPropertyID(localAccessID);
      // For context, usedAccessID is a 'useRef', which is used to prevent re-renders
      usedAccessID.current = true;
    } else if (
      usedAccessID.current &&
      localAccessID &&
      localAccessID === passedPropertyID
    ) {
      // If we have overriden in this session, we don't want to recall the same API again
      // For reference here, AccessID gets cleared when there is a successful propertyInfo call
      proxyPropertyID = 0;
    }
    return proxyPropertyID;
  }

  function renderAuth() {
    return (
      <div>
        <TermsAndConditions />
        <Navbar />
        <Main />
      </div>
    );
  }

  function renderLogIn() {
    return (
      <div style={{ overflowX: "hidden" }}>
        <Header />
        <UnauthRoute />
      </div>
    );
  }

  return <div>{props.authenticated ? renderAuth() : renderLogIn()}</div>;
};

function mapStateToProps(state: any) {
  return {
    authenticated: state.auth.authenticated,
    property: state.property.property_info,
    profile: state.user.profile,
  };
}

export default withRouter(connect(mapStateToProps, actions)(App));
