import { rGET } from "./api";
import { LIST_PHOTOS, ORIGINAL_FILTER, RECENT_FILTER } from "./types";
import { groupArrBy, isMobile, resizePhotos } from "../common/util";

export function listRooms(list_photos, intl) {
  let list_rooms = Object.keys(groupArrBy(list_photos, "name"));
  list_rooms.forEach(function (room, i) {
    list_rooms[i] = { value: room.replace(/\s/g, "-"), label: room };
  });
  list_rooms.unshift({
    value: "all",
    label: intl.formatMessage({ id: "photos.label.all", defaultMessage: "All Photos" }),
  });
  localStorage.setItem("listRooms", JSON.stringify(list_rooms));
}

async function listDates(propertyID) {
  return rGET(`propertyphotos/${propertyID}/set-list/`).then((resp) => {
    const list_dates = resp && resp.data.results;
    list_dates &&
      list_dates.forEach(function (date, i) {
        list_dates[i] = { value: date, label: date.split("-").join("/") };
      });
    if (list_dates.length > 0 && !localStorage.getItem("originalFilter")) {
      localStorage.setItem("originalFilter", list_dates[0].value);
      if (list_dates.length > 1) {
        localStorage.setItem("recentFilter", list_dates[1].value);
      } else if (list_dates.length === 1) {
        localStorage.setItem("recentFilter", list_dates[0].value);
      }
    }
    return list_dates;
  });
}

export function listPhotos(propertyID, filter, intl) {
  return function (dispatch) {
    rGET(`/propertyphotos/${propertyID}/${filter}/`).then(async (resp) => {
      const list_photos = resp && resp.data.results;
      const width = isMobile() ? 370 : 630;
      if (list_photos) {
        resizePhotos(list_photos, width);
        listRooms(list_photos, intl);
        localStorage.setItem("listPhotos", JSON.stringify(list_photos));
        let list_dates = await listDates(propertyID);
        localStorage.setItem("listDates", JSON.stringify(list_dates));
      }
      dispatch({
        type: LIST_PHOTOS,
        payload: list_photos,
      });
    });
  };
}

export function filterOriginalPhotos(original_filter) {
  return {
    type: ORIGINAL_FILTER,
    payload: original_filter.split("/").join("-"),
  };
}

export function filterRecentPhotos(recent_filter) {
  return {
    type: RECENT_FILTER,
    payload: recent_filter.split("/").join("-"),
  };
}
