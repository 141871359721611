import React, { Component } from "react";
import { Fields, reduxForm, getFormValues, formValueSelector } from "redux-form";
import { connect } from "react-redux";

import * as actions from "../../actions";
import { SECTIONS } from "./fields";
import FormComponent from "./forms/form_component";
import { saveCheck } from "./forms/common";
import { validatePropertyAmenities } from "./forms/validation";
import { compose } from "redux";

const M = (window as any).M;
var initChar = 0;

interface Props {
  checklistValues: any;
  initialValues: any;
  property: any;
  check_status: any;
}

class PropertyAmenities extends Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      property_amenities: {},
      propertyID: localStorage.getItem("property_id")
        ? localStorage.getItem("property_id")
        : undefined,
    };
  }

  componentDidUpdate(prevProps: any) {
    Object.keys(this.props.checklistValues).forEach((key) => {
      if (this.props.checklistValues[key] !== prevProps.checklistValues[key]) {
        this.setState(() => ({
          property_amenities: this.props.checklistValues,
        }));
      }
    });
    if (prevProps.property !== this.props.property) {
      if (prevProps.property.id !== this.props.property.id && this.props.property.id) {
        this.setState(() => ({ propertyID: this.props.property.id }));
        validatePropertyAmenities(this.state.property_amenities);
        M.CharacterCounter.init(document.querySelectorAll(".countChar"));
        initChar = M.CharacterCounter.init(document.querySelectorAll(".countChar"))
          .length;
      }
    }
    if (
      document.querySelectorAll(".countChar").length > 0 &&
      document.querySelectorAll(".countChar").length !== initChar
    ) {
      M.CharacterCounter.init(document.querySelectorAll(".countChar"));
      initChar = M.CharacterCounter.init(document.querySelectorAll(".countChar"))
        .length;
    }
    if (this.props.initialValues) {
      Object.keys(this.props.initialValues).forEach((key) => {
        var elem = document.querySelector(`select.${key}`);
        if (elem) {
          let selectEl = document.querySelector(
            `option[value="${this.props.initialValues[key]}"]`
          );
          if (this.props.initialValues[key] && selectEl) {
            selectEl.setAttribute("selected", "true");
          } else if (this.props.initialValues[key] === "") {
            const noSelectEl = document.querySelector(`option[value=""]`);
            if (noSelectEl) {
              noSelectEl.setAttribute("selected", "true");
              noSelectEl.setAttribute("disabled", "true");
            }
          }
          M.FormSelect.init(elem);
        }
      });
    }
  }

  render() {
    const {
      wifiValue,
      ironValue,
      hairdryerValue,
      vacuumValue,
      tvValue,
      parkingValue,
    }: any = this.props;

    return (
      <form
        id="property_amenities"
        className={`${
          this.props.check_status && this.props.check_status[1].stage
        } fields-cont`}
        onChange={(e) =>
          saveCheck(e, this.state.property_amenities, validatePropertyAmenities)
        }
        onBlur={(e) =>
          saveCheck(e, this.state.property_amenities, validatePropertyAmenities)
        }>
        <p>
          <strong>Property Amenities - Fundamentals</strong>
        </p>
        {this.props &&
          SECTIONS["Property Amenities"].map((section, index) => (
            <Fields
              names={section.names}
              component={FormComponent}
              details={section.details}
              key={section.names[index]}
              conditionalValue={[
                {
                  wifi: wifiValue,
                  iron_and_ironing_board: ironValue,
                  hairdryer: hairdryerValue,
                  vacuum_cleaner: vacuumValue,
                  tv: tvValue,
                  parking: parkingValue,
                },
              ]}
              chargeType={undefined}
              propertyID={this.state.propertyID}
              section="property_amenities"
            />
          ))}
      </form>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    property: state.property.property_info,
    checklist: state.checklist.amenities_checklist,
    update_checklist: state.checklist.update_checklist,
  };
}

const PropertyAmenitiesForm = reduxForm({
  form: "property_amenities",
  validate: validatePropertyAmenities,
  enableReinitialize: true,
});

// Decorate with connect to read form values
const selector = formValueSelector("property_amenities");

const PropertyAmenitiesConnect = connect(
  (state: any) => {
    const wifiValue = selector(state, "wifi");
    const ironValue = selector(state, "iron_and_ironing_board");
    const hairdryerValue = selector(state, "hairdryer");
    const vacuumValue = selector(state, "vacuum_cleaner");
    const tvValue = selector(state, "tv");
    return {
      wifiValue,
      ironValue,
      hairdryerValue,
      vacuumValue,
      tvValue,
      initialValues: state.checklist.amenities_checklist,
      checklistValues: getFormValues("property_amenities")(state) || {},
    };
  },
  { actions }
);

const withConnect = connect(mapStateToProps, actions);

export default compose<any>(
  withConnect,
  PropertyAmenitiesConnect,
  PropertyAmenitiesForm
)(PropertyAmenities);
