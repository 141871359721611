import React, { useContext, useEffect, useRef, useState } from "react";
import { LocaleContext } from "../../../../global_contexts";
import { GetDatepickeri18nData, getISOLocalDate } from "../../../calendar/dates";
import { MATERIALIZE_REF } from "../../contexts";
import { DataField } from "../../schema";
import { useIntl } from "react-intl";

export const InputDateComponent = (props: { onValueChange: any; field: DataField }) => {
  const INTL = useIntl();
  const { locale } = useContext(LocaleContext);
  const [value, setValue] = useState(props.field.value || "");
  const [i18nData, setI18nData] = useState(GetDatepickeri18nData(INTL, locale));
  const dateRef = useRef(null);
  const M = useContext(MATERIALIZE_REF);

  useEffect(() => {
    setI18nData(GetDatepickeri18nData(INTL, locale));
  }, [locale]);

  useEffect(() => {
    initializeDatePicker();
  }, [i18nData]);

  useEffect(() => {
    if (props.field.value) {
      setValue(props.field.value);
    }
  }, [props.field.value]);

  // Ensure ISO format isn't displayed initially (since that's how the server stores the date)
  useEffect(() => {
    if (value.split("-").length > 1) {
      setValue(value.split("-").reverse().join("/"));
    }
  }, [value]);

  function initializeDatePicker(initDate = null) {
    var date_options = {
      autoClose: true,
      defaultDate: initDate ? initDate : new Date(),
      format: "dd/mm/yyyy",
      i18n: i18nData,
      yearRange: [new Date().getFullYear() - 120, new Date().getFullYear() + 10], // allows for 120 years in the past and 10 years in the future to be selectable
      onSelect: (date: any) => {
        props.onValueChange(dateRef.current, getISOLocalDate(date));
      },
    };
    M.Datepicker.init(dateRef.current, date_options);
  }

  return (
    <input
      ref={dateRef}
      id={props.field.key}
      type="text"
      className="datepicker validate input-field--small"
      required={props.field.required}
      value={value}
      placeholder="--/--/----"
      onChange={(e) => {
        setValue(getISOLocalDate(e.currentTarget.value));
      }}
    />
  );
};
