import React, { createContext, useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import SelectProperty from "./select_property";
import OnboardingChecklistTab from "./onboarding_checklist";
import MobileNavbar from "./mobile_navbar";
import DesktopNavbar from "./desktop_navbar";
import { FormattedMessage } from "react-intl";
import { HostContext, LocaleContext, PropertyContext } from "../../global_contexts";
import { MATERIALIZE_REF } from "../dynamic_onboarding/contexts";

export const PropertySelectorContext = createContext({
  propertySelectorOpen: null,
  setPropertySelectorOpen: () => {},
});

const Navbar = (props) => {
  const M = useContext(MATERIALIZE_REF);
  const { hostName } = useContext(HostContext);
  const { locale } = useContext(LocaleContext);
  const { propertyName, propertyCountry } = useContext(PropertyContext);
  const [properties, setProperties] = useState(
    JSON.parse(localStorage.getItem("properties"))
  );
  const [multipleProperties, setMultipleProperties] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [propertySelectorOpen, setPropertySelectorOpen] = useState(false);

  useEffect(() => {
    var elem = document.getElementById("mobile_burger_menu_button");
    new M.Dropdown(elem, { alignment: "right", closeOnClick: false });
  }, []);

  useEffect(() => {
    if (properties) {
      setMultipleProperties(properties.length > 1 ? true : false);
    }
  }, [properties]);

  useEffect(() => {
    if (properties !== JSON.parse(localStorage.getItem("properties"))) {
      setProperties(JSON.parse(localStorage.getItem("properties")));
    }
  }, [localStorage.getItem("properties")]);

  useEffect(() => {
    if (user !== JSON.parse(localStorage.getItem("user"))) {
      setUser(JSON.parse(localStorage.getItem("user")));
    }
  }, [localStorage.getItem("user")]);

  useEffect(() => {
    if (propertySelectorOpen === true) {
      window.addEventListener(
        "click",
        () => {
          // Close property selector if clicked elsewhere
          const activePropertySelector = document.querySelector(
            "li.select-property li.active>.collapsible-header"
          );
          if (activePropertySelector) {
            activePropertySelector.click();
            setPropertySelectorOpen(false);
          }
        },
        { once: true }
      );
    }
  }, [propertySelectorOpen]);

  function close() {
    var elem = document.getElementById("mobile_burger_menu_button");
    var instance = M.Dropdown.getInstance(elem);
    instance.options.closeOnClick = true;
    instance.close();
  }

  function open() {
    var elem = document.getElementById("mobile_burger_menu_button");
    var instance = M.Dropdown.getInstance(elem);
    instance.options.closeOnClick = false;
    if (propertySelectorOpen) {
      instance.close();
    } else {
      instance.open();
    }
  }

  const LIST_OPTIONS = [
    {
      name: "Dashboard",
      icon: ICONS.DASHBOARD,
      link: "/",
    },
    {
      name: "Calendar",
      icon: ICONS.CALENDAR,
      link: "/calendar/",
    },
    {
      name: "Performance",
      icon: ICONS.PERFORMANCE,
      link: "/performance/",
    },
    {
      name: "Invoices",
      icon: ICONS.INVOICE,
      link: "/invoices/",
    },
    {
      name: "Bookings",
      icon: ICONS.BOOKINGS,
      link: "/bookings/",
    },
    {
      name: "Listings",
      icon: ICONS.LISTINGS,
      link: "/listings/",
    },
    {
      name: "Reviews",
      icon: ICONS.STAR_UNFILLED,
      link: "/reviews/",
    },
    {
      name: "Service Log",
      icon: ICONS.SERVICE_LOG,
      link: "/services/",
    },
    {
      name: "Photos",
      icon: ICONS.PHOTOS,
      link: "/photos/",
    },
    {
      name: "Contact Us",
      icon: ICONS.CONTACT_US,
      link: "/contact-us/",
    },
    {
      name: "Preferences",
      icon: ICONS.SETTINGS,
      link: "/preferences/",
    },
    {
      name: "Profile",
      icon: ICONS.PROFILE,
      link: "/profile/",
      isGlobal: true,
    },
    {
      name: "Great Host Guide",
      icon: ICONS.GREAT_HOST_GUIDE,
      link: "/great-host-guide/",
      isGlobal: true,
    },
    {
      name: "Feedback",
      icon: ICONS.CIRCLE,
      link: "/feedback/",
      isGlobal: true,
    },
    {
      name: "Search",
      icon: ICONS.SEARCH,
      link: "/search/",
      isGlobal: true,
    },
  ];

  const ListOptions = LIST_OPTIONS.map((opt) => {
    if (user && !user.is_manager) {
      if (opt.name === "Search") {
        return "";
      }
    }

    let className = "listOpt";

    if (propertySelectorOpen) {
      className += " addOpacity";
    }
    if (!opt.isGlobal && multipleProperties) {
      className += " nav-item-inset";
    }

    if (opt.name === "Photos") {
      return (
        <li className={className} key={opt.name} onClick={() => close()}>
          <NavLink id={opt.name} exact to={opt.link}>
            {opt.name === "Reviews" ? (
              <Icon
                icon={opt.icon.d}
                width={opt.icon.width}
                height={opt.icon.height}
                color="#FFF"
              />
            ) : (
              <Icon icon={opt.icon.d} width={opt.icon.width} height={opt.icon.height} />
            )}
            <span>
              <FormattedMessage id={`sidenav.${opt.name}`} defaultMessage={opt.name} />
            </span>
          </NavLink>
        </li>
      );
    }

    if (opt.name === "Listings") {
      let show_listings_tab = props.profile
        ? props.profile.direct_traffic_listing_info_enabled
        : false;
      if (show_listings_tab === false) {
        return "";
      }
    }

    if (opt.name === "Feedback" && locale !== "en") {
      return "";
    }

    if (opt.name === "Great Host Guide" && propertyCountry !== "GB") {
      return "";
    }

    return (
      <li className={className} key={opt.name} onClick={() => close()}>
        <NavLink id={opt.name} exact to={opt.link}>
          {opt.name === "Reviews" ? (
            <Icon
              icon={opt.icon.d}
              width={opt.icon.width}
              height={opt.icon.height}
              color="#FFF"
            />
          ) : (
            <Icon icon={opt.icon.d} width={opt.icon.width} height={opt.icon.height} />
          )}
          <span>
            <FormattedMessage id={`sidenav.${opt.name}`} defaultMessage={opt.name} />
          </span>
        </NavLink>
      </li>
    );
  });

  return (
    <div className="navbar-fixed">
      <PropertySelectorContext.Provider
        value={{ propertySelectorOpen, setPropertySelectorOpen }}>
        <nav className="navbar-container">
          <div className="nav-wrapper row">
            <MobileNavbar onOpen={open} />
            <DesktopNavbar />
            {props.user && props.user.is_manager ? (
              <ul className="hide-on-med-and-down flex-evenly">
                <li className="dark-blue">
                  <FormattedMessage
                    id="navbar.viewing_as"
                    defaultMessage="Viewing as"
                  />
                  <span className="cobalt-blue"> {hostName}</span>{" "}
                  <FormattedMessage id="navbar.for" defaultMessage="for" />
                  <span className="cobalt-blue"> {propertyName}</span>
                </li>
              </ul>
            ) : null}
            {/* Mobile Burger Menu */}
            <ul id="mobile-navbar" className="dropdown-content">
              {multipleProperties && (
                <li className="select-property" onClick={() => open()}>
                  <SelectProperty />
                </li>
              )}
              {props.checklistStatus &&
                !["requires_check", "complete"].includes(props.checklistStatus) && (
                  <div onClick={() => close()}>
                    <OnboardingChecklistTab />
                  </div>
                )}
              {ListOptions}
            </ul>
            {/* Desktop */}
            <ul className="sidenav sidenav-fixed hide-on-med-and-down">
              <li className="select-property">
                <SelectProperty />
              </li>
              {props.checklistStatus &&
                !["requires_check", "complete"].includes(props.checklistStatus) && (
                  <OnboardingChecklistTab />
                )}
              {ListOptions}
            </ul>
          </div>
        </nav>
      </PropertySelectorContext.Provider>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: JSON.parse(localStorage.getItem("user")),
    profile: JSON.parse(localStorage.getItem("profile")),
    property: state.property.property_info,
    checklistStatus: state.checklist.checklist_status,
  };
}

export default withRouter(connect(mapStateToProps, null)(Navbar));
