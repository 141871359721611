import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";

class Linen extends Component {
  componentDidMount() {
    document.querySelector(".main-container .container").classList.add("full-width");
  }

  render() {
    return (
      <div className="great-host-guide-pages">
        <div className="row">
          <div className="col s12">
            <NavLink exact to="/great-host-guide/" className="left-align">
              <span className="mr-10">
                <Icon
                  icon={ICONS.BACK_ARROW.d}
                  width={ICONS.BACK_ARROW.width}
                  height={12}
                  viewBox="0 0 10 20"
                />
              </span>
              <span className="cobalt-blue">Back to Guide summary</span>
            </NavLink>
            <h4 className="center-align">
              Linen, Laundry and Toiletries - We have you covered
            </h4>
          </div>
        </div>
        <div className="row top-img-container">
          <img
            className="top-img"
            alt="Linen, Laundry and Toiletries"
            src={require(`../../styles/img/linen@2x-min.jpg`)}
          />
        </div>
        <div className="row">
          <div className="col s12">
            <h5>Linen &amp; Laundry</h5>
            <p>
              Linen will arrive with the cleaners or be delivered to the property
              towards the end of the cleaning. We will collect the dirty linen as well.
              <br />
              Please note that we always recommend hosts provide a duvet and two pillows
              for each bed, including sofa beds, along with a dedicated space to store
              them.
              <br />
              We provide crisp white duvet covers, sheets, pillowcases, towels and bath
              mats. A set is provided for each bed and sofa bed.
            </p>

            <h5>Sofa Bed</h5>
            <p>
              If you have a sofa bed in the property this will be considered as a proper
              bed, so we’ll put the linen out for it before the guests arrive. We don’t
              prepare the sofa bed itself ahead of time since guests prefer to use the
              sofa to sit on while they settle in.
            </p>

            <h5>We Provide Consumables</h5>
            <p>
              We provide toiletries (hotel-size shampoo, soap, and shower gel) and
              toilet paper as a standard part of our service, in quantities designed to
              last for two days. We also provide bin bags, kitchen roll, and washing up
              liquid. We charge you for these consumables on a per-booking basis. Please
              ask your local management team for further details.
            </p>
            <p>
              It’s important that we provide the toiletries as guests prefer to come
              into a property with unopened bottles of shampoo or shower gel. If a host
              gives them a big bottle, it may look as if they’ve also been used by
              previous guests. The smaller size of the bottles also gives a more
              professional impression and makes the guests feel they are in a
              well-managed property. You are also freed from needing to worry about
              stock levels and replenishment.
            </p>
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    document.querySelector(".main-container .container").classList.remove("full-width");
  }
}

export default Linen;
