import React from "react";
import PaymentAccount from "../../../direct_debit/direct_debit";

export const DirectDebitComponent = () => {
  return (
    <div className="direct_debit">
      <PaymentAccount dynamicOnboardingComponent={true} />
    </div>
  );
};
