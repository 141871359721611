import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";

class Airbnb extends Component {
  componentDidMount() {
    document.querySelector(".main-container .container").classList.add("full-width");
  }

  render() {
    return (
      <div className="great-host-guide-pages">
        <div className="row">
          <div className="col s12">
            <NavLink exact to="/great-host-guide/" className="left-align">
              <span className="mr-10">
                <Icon
                  icon={ICONS.BACK_ARROW.d}
                  width={ICONS.BACK_ARROW.width}
                  height={12}
                  viewBox="0 0 10 20"
                />
              </span>
              <span className="cobalt-blue">Back to Guide summary</span>
            </NavLink>
            <h4 className="center-align">Airbnb, You and Us - how does it work?</h4>
          </div>
        </div>
        <div className="row top-img-container">
          <img
            className="top-img"
            alt="Airbnb You and Us"
            src={require(`../../styles/img/airbnb@2x-min.jpg`)}
          />
        </div>
        <div className="row">
          <div className="col s12">
            <h5>Airbnb</h5>
            <p>
              We’ll take care of the Airbnb side, but we may need some occasional input
              from your end. Our aim is to make things as easy as possible for you!
            </p>

            <h5>Calendar and Availability</h5>
            <p>
              Thanks to the Client Portal, you can update your calendar availability,
              blocking and unblocking dates, with all the freedom that your Airbnb
              account would give you.
            </p>
            <p>
              Simply select "Edit blocked dates" from the Calendar section of the portal
              and add notes. This will synchronise with Airbnb, Booking.com, and other
              booking sites. You can also block dates for your personal use of the
              property and request a cleaning after your stay.
            </p>

            <h5>Logging Into Airbnb</h5>
            <p>
              As you may be aware, Airbnb has very strict security procedures in place
              to prevent fraudulent access to accounts. In the past, it has been
              possible for multiple devices to access an individual account from
              different locations via a web-browser in incognito mode. We have recently
              discovered, however, that this is no longer a viable strategy.
            </p>
            <p>
              In response to this, we have been required to adjust the way in which we
              manage listings by removing the ability for multiple people to access
              them. As our systems are connected to each listing on a constant,
              real-time basis, this means that hosts will no longer be able to log in to
              their Airbnb accounts. You can contact your Client Success Manager if you
              have any questions or access the Client Portal to check upcoming bookings
              for your property, performance, post-cleaning pictures and much more.
            </p>
            <h5>Using Airbnb as a traveller</h5>
            <p>
              If you would like to use Airbnb for personal travel, you will need to
              register a separate account or revert to using a previously-held account.
            </p>
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    document.querySelector(".main-container .container").classList.remove("full-width");
  }
}

export default Airbnb;
