import React from "react";

const NotFound = () => {
  return (
    <div className="row not-found">
      <div className="col s12 logo-container" />
      <div className="col s12">
        <h1>404 page not found</h1>
      </div>
      <div className="col s12">
        <h3>We are sorry but the page you are looking for does not exist.</h3>
      </div>
    </div>
  );
};

export default NotFound;
