import React, { useState } from "react";
import { ChoiceField } from "../../schema";

export const InputRadioComponent = (props: {
  onValueChange: any;
  field: ChoiceField;
}) => {
  const [value, setValue] = useState(props.field.value);

  return (
    <div id={props.field.key}>
      <div>
        <p className="scale_active_label">
          {props.field.label}
          {props.field.required && "*"}
        </p>
      </div>
      {props.field.choices.map((choice, index) => (
        <div key={choice.label}>
          <label>
            <input
              id={`${props.field.key}_input_${index}`}
              name={props.field.key}
              required={props.field.required}
              type="radio"
              value={choice.value}
              onChange={(e) => {
                setValue(choice.value);
                props.onValueChange(e.currentTarget, choice.value);
              }}
              checked={value === choice.value}
            />
            <span>{choice.label}</span>
          </label>
        </div>
      ))}
    </div>
  );
};
