import React, { useContext, useEffect, useRef, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
  LocaleContext,
  PreferredLanguageContext,
  PropertyIDContext,
} from "../../global_contexts";
import { updateClientPreferredLanguage } from "../profile/preferred_language_display";
import * as actions from "../../actions";

const M = window.M;

const LanguageSelector = (props) => {
  const { setPreferredLanguage } = useContext(PreferredLanguageContext);
  const { locale, setLocale } = useContext(LocaleContext);
  const { propertyID } = useContext(PropertyIDContext);
  const selectRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (props.showPopup !== false && showPopup) {
      generateUpdatePreferredLanguagePopup(locale);
      setShowPopup(false);
    }
  }, [locale]);

  useEffect(() => {
    setTimeout(() => {
      if (
        selectRef.current &&
        locale !== (selectRef.current && selectRef.current.value)
      ) {
        selectRef.current.value = locale;
      }
    }, 100);
  }, [selectRef.current && selectRef.current.value]);

  useEffect(() => {
    // Check if the select element ref is available
    if (selectRef.current) {
      // Initialize Materialize select
      M.FormSelect.init(selectRef.current);
      const languageSelectorParentElement = Array.from(
        document.querySelectorAll('[data-js="language_selector"]')
      );
      const checkForHide = document.querySelectorAll(
        '[data-js="language_selector"].hide'
      );
      if (checkForHide.length > 0) {
        languageSelectorParentElement.forEach((element) => {
          element.classList.add("hide");
        });
      } else {
        languageSelectorParentElement.forEach((element) => {
          element.classList.remove("hide");
        });
      }
    }
  }, []);

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    // Update locale globally
    setLocale(newValue);
    setShowPopup(true);
  };

  function generateUpdatePreferredLanguagePopup(newLocale) {
    const handleToastClick = () => {
      const DISPLAY_LANGUAGE = {
        en: props.intl.formatMessage({
          id: "tc_modal.english",
          defaultMessage: "English",
        }),
        es: props.intl.formatMessage({
          id: "tc_modal.spanish",
          defaultMessage: "Spanish",
        }),
      };
      preferredLanguageToast.dismiss();
      updateClientPreferredLanguage(
        propertyID,
        newLocale,
        { setLocale },
        { setPreferredLanguage }
      );
      M.toast({
        html: `<span>${props.intl.formatMessage({
          id: "preferred_language_toast.success",
          defaultMessage: "Updated preferred language to",
        })} ${DISPLAY_LANGUAGE[`${newLocale}`]}</span>`,
        classes: "preferred_language_toast preferred_language_toast--success",
      });
    };

    // setup update preferred language button for toast
    const toastButton = document.createElement("button");
    toastButton.classList.add("btn-flat", "toast-action");
    toastButton.onclick = handleToastClick;
    toastButton.innerHTML = `${props.intl.formatMessage({
      id: "preferred_language_toast",
      defaultMessage: "Click to update your preferred language",
    })}`;

    const preferredLanguageToast = M.toast({
      html: toastButton,
      classes: "preferred_language_toast",
    });
  }

  return (
    <div data-js="language_selector" className={`select-container`}>
      <select
        onChange={handleSelectChange}
        ref={selectRef}
        name="language"
        id="language_selector"
        className={`browser-default`}
        value={locale}>
        <option value="en">English</option>
        <option value="es">español</option>
      </select>
      <span>▾</span>
    </div>
  );
};

export default connect(null, actions)(injectIntl(LanguageSelector));
