const M = window.M;

export function isTouchScreen() {
  let touchMaxWidth = 1200;
  return window.innerWidth <= touchMaxWidth;
}

export function setToolTip(tooltipElements) {
  const tooltipInstances = M.Tooltip.init(tooltipElements, { margin: 0 });
  if (tooltipInstances && tooltipInstances.length > 0) {
    tooltipInstances.forEach((tooltip) => {
      tooltip.tooltipEl.classList.add("bottom_arrow");
    });
  }
}
