interface internationalisedField {
  GBP?: any[];
  EUR?: any[];
}

// Accounting section
const accounting_names: internationalisedField = {
  GBP: ["long_let_value", "account_number", "sort_code"],
  EUR: ["long_let_value", "iban", "bic"],
};
const accounting_details: internationalisedField = {
  GBP: [
    {
      type: "number",
      label: "What is your property's monthly long-term rental value?",
      helper:
        "How much rent would you collect per month if rented your property long-term (6+ months)?",
      icon: "home",
      section: 0,
    },
    {
      type: "text",
      label: "Your Account Number",
      icon: "account_balance",
      section: 0,
    },
    {
      type: "text",
      label: "Your Sort Code",
      icon: "account_balance",
      section: 0,
    },
  ],
  EUR: [
    {
      type: "number",
      label: "What is your property's monthly long-term rental value?",
      helper:
        "How much rent would you collect per month if rented your property long-term (6+ months)?",
      icon: "home",
      section: 0,
    },
    {
      type: "text",
      label: "Your IBAN",
      icon: "account_balance",
      section: 0,
    },
    {
      type: "text",
      label: "Your BIC",
      icon: "account_balance",
      section: 0,
    },
  ],
};

// Packaging section
const packaging_names: internationalisedField = {
  GBP: ["package"],
  EUR: ["package"],
};
const packaging_details: internationalisedField = {
  GBP: [
    {
      type: "radio",
      options: [
        { name: "Airbnb Only", value: "Airbnb Only" },
        { name: "Multiplatform", value: "Multiplatform" },
      ],
      label: "Select a package",
      icon: "event_available",
      section: 0,
    },
  ],
  EUR: [
    {
      type: "radio",
      options: [
        { name: "Airbnb Only", value: "Airbnb Only" },
        { name: "Multiplatform", value: "Multiplatform" },
      ],
      label: "Select a package",
      icon: "event_available",
      section: 0,
    },
  ],
};

// Airbnb section
const airbnb_names: internationalisedField = {
  GBP: [
    "airbnb_account_exists",
    "airbnb_email",
    "airbnb_password",
    "online_id_verified",
    "offline_id_verified",
    "profile_picture_added",
  ],
  EUR: [
    "airbnb_account_exists",
    "airbnb_email",
    "airbnb_password",
    "online_id_verified",
    "offline_id_verified",
    "profile_picture_added",
  ],
};
const airbnb_details: internationalisedField = {
  GBP: [
    {
      type: "radio",
      label: "",
      section: 0,
      options: [
        { name: "Yes", value: "yes_airbnb_account_exists" },
        { name: "No", value: "no_airbnb_account_exists" },
      ],
    },
    { type: "email", label: "Airbnb email", icon: "email", section: 0 },
    { type: "text", label: "Airbnb password", icon: "security", section: 0 },
    {
      type: "checkbox",
      label: "Online ID verified",
      icon: "short_text",
      section: 0,
    },
    {
      type: "checkbox",
      label: "Offline ID verified",
      icon: "short_text",
      section: 0,
    },
    {
      type: "checkbox",
      label: "Profile picture added",
      icon: "picture_in_picture",
      section: 0,
    },
  ],
  EUR: [
    {
      type: "radio",
      label: "",
      section: 0,
      options: [
        { name: "Yes", value: "yes_airbnb_account_exists" },
        { name: "No", value: "no_airbnb_account_exists" },
      ],
    },
    { type: "email", label: "Airbnb email", icon: "email", section: 0 },
    { type: "text", label: "Airbnb password", icon: "security", section: 0 },
    {
      type: "checkbox",
      label: "Online ID verified",
      icon: "short_text",
      section: 0,
    },
    {
      type: "checkbox",
      label: "Offline ID verified",
      icon: "short_text",
      section: 0,
    },
    {
      type: "checkbox",
      label: "Profile picture added",
      icon: "picture_in_picture",
      section: 0,
    },
  ],
};

export const SECTIONS = {
  "Account Info": [
    {
      title: "Accounting",
      names: accounting_names,
      details: accounting_details,
    },
    {
      title: "Package",
      names: packaging_names,
      details: packaging_details,
    },

    {
      title: "Airbnb",
      names: airbnb_names,
      details: airbnb_details,
    },
  ],

  "Property Details": [
    {
      title: "Property Size",
      names: ["property_size", "floor", "elevator", "floor_area"],
      details: [
        {
          type: "radio",
          options: [
            { name: "House", value: "house" },
            { name: "Apartment", value: "apartment" },
          ],
          label: "Is your property a house or an apartment?",
          helper:
            "If your property shares a front door with other properties, it is considered an ‘Apartment’, otherwise please select ‘House’.",
          icon: "home",
          section: 1,
        },
        {
          type: "number",
          label: "What floor is your property situated on?",
          helper:
            "Write a number here. For ground floor, input 0, or for basement input -1",
          icon: "location_city",
          section: 1,
        },
        {
          type: "radio",
          label: "Is there an elevator?",
          icon: "code",
          section: 1,
          options: [
            { name: "Yes", value: "yes_elevator" },
            { name: "No", value: "no_elevator" },
          ],
        },
        {
          type: "select",
          options: [
            { name: "400 sq ft (Small Apartment)", value: "<400" },
            { name: "600 sq ft (Average Apartment)", value: "600" },
            { name: "800 sq ft (Large Apartment)", value: "800" },
            { name: "1000 sq ft (Small House / V. Large Apartment)", value: "1000" },
            { name: "1200 sq ft (Average House)", value: "1200" },
            { name: "1600 sq ft (Large House)", value: "1600" },
            { name: "2000+ sq ft (V. Large House)", value: ">2000" },
          ],
          label: "What is the approximate size of your property in square feet?",
          icon: "home",
          section: 1,
        },
      ],
    },
    {
      title: "Guest Capacity",
      names: [
        "number_of_bedrooms",
        "number_of_beds",
        "beds",
        "beds_faq",
        "guest_capacity",
        "number_of_bathrooms",
        "garden_type",
        "stand_out_features_faq",
        "parking_type",
        "parking_faq",
      ],
      details: [
        {
          type: "number",
          label: "How many bedrooms are there?",
          icon: "local_hotel",
          section: 1,
        },
        {
          type: "number",
          label: "How many Beds are there? (including sofa bed, air mattresses)",
          icon: "local_hotel",
          section: 1,
        },
        [],
        {
          type: "text",
          label: "Please describe your non-fixed beds",
          helper:
            "Instructions for fold-out beds, location of air-mattress, or other information related to non-fixed beds",
          icon: "weekend",
          section: 1,
        },
        {
          type: "number",
          label: "How many guests can your place accomodate?",
          icon: "people",
          section: 1,
        },
        {
          type: "number",
          label: "How many bathrooms?",
          helper:
            "Please input a number. If you have a room with a toilet but no shower/bath, count it as a 0.5.",
          icon: "wc",
          section: 1,
        },
        {
          type: "select",
          options: [
            { name: "None", value: "no_garden" },
            { name: "Private", value: "private" },
            { name: "Shared", value: "shared" },
          ],
          label: "Do you have a garden?",
          icon: "nature_people",
          section: 1,
        },
        {
          type: "textarea",
          label: "What makes your property stand out from others?",
          icon: "star",
          placeholder:
            "e.g. Recently renovated Victorian townhouse built in 1750’s. Wheelchair accessible with step-free access to home and ground floor bedroom.",
          helper:
            "Historic building? Smart home? Under-floor heating? Fire-place? Hot tub?",
          section: 1,
        },
        {
          type: "select",
          icon: "local_parking",
          options: [
            { name: "Free parking on premises", value: "free_premises" },
            { name: "Free street parking", value: "free_street" },
            { name: "Paid parking off premises", value: "paid_off_premises" },
            { name: "Paid parking on premises", value: "paid_on_premises" },
          ],
          label: "Parking",
          section: 1,
        },
        {
          type: "textarea",
          label: "Parking Details",
          icon: "short_text",
          helper:
            "If you do not offer free parking, please recommend nearby car parks or street parking to the best of your knowledge",
          placeholder:
            "e.g. Free allocated parking space. Letter ‘C’ to the right of the gate when entering car park.",
          section: 1,
        },
      ],
    },
  ],

  "Property Amenities": [
    {
      title: "Fundamentals",
      names: [
        "wifi",
        "wifi_network_name",
        "wifi_password",
        "smoke_detector",
        "carbon_monoxide_detector",
      ],
      details: [
        {
          type: "checkbox",
          label: "Wifi",
          icon: "network_wifi",
          section: 2,
        },
        {
          type: "text",
          label: "Network name",
          icon: "signal_wifi_4_bar_lock",
          helper:
            "Please ensure your WiFi Network Name is entered correctly, including all use of capital and lowercase letters",
          section: 2,
        },
        {
          type: "text",
          label: "Password",
          helper:
            "Please ensure your Password is entered correctly, including all use of capital and lowercase letters",
          icon: "signal_wifi_4_bar_lock",
          section: 2,
        },
        {
          type: "checkbox",
          label: "Smoke Detector",
          icon: "add_alert",
          section: 2,
        },
        {
          type: "checkbox",
          label: "Carbon Monoxide Detector",
          icon: "add_alert",
          section: 2,
        },
      ],
    },
    {
      title: "Essentials",
      names: [
        "iron_and_ironing_board",
        "iron_and_ironing_board_location",
        "hairdryer",
        "hairdryer_location",
        "vacuum_cleaner",
        "vacuum_cleaner_location",
        "utensils",
        "electricity_prepaid",
        "personal_belongings_stored",
      ],
      details: [
        {
          type: "checkbox",
          label: "Iron & Ironing Board",
          icon: "widgets",
          section: 2,
        },
        {
          type: "textarea",
          label: "Location",
          icon: "short_text",
          placeholder: "e.g. Cupboard under the stairs",
          section: 2,
        },
        {
          type: "checkbox",
          label: "Hairdryer",
          icon: "widgets",
          section: 2,
        },
        {
          type: "text",
          label: "Location",
          icon: "short_text",
          placeholder: "e.g. Cabinet in the bathroom",
          section: 2,
        },
        {
          type: "checkbox",
          label: "Vacuum Cleaner / Mop & Bucket",
          icon: "widgets",
          helper:
            "Our cleaners provide their own cleaning products however they will need access to the above",
          section: 2,
        },
        {
          type: "text",
          label: "Location",
          icon: "short_text",
          placeholder: "e.g. Utility room",
          section: 2,
        },
        {
          type: "checkbox",
          label: "Cutlery & Utensils",
          helper:
            "There should be enough plates, cutlery, glasses, pots and pans for the expected number of guests, to avoid complaints. Please confirm you have read this.",
          icon: "restaurant",
          section: 2,
        },
        {
          icon: "whatshot",
          type: "radio",
          label: "Does this property have a pre-paid electricity/gas meter?",
          section: 2,
          options: [
            { name: "Yes", value: "yes_electricity_prepaid" },
            { name: "No", value: "no_electricity_prepaid" },
          ],
        },
        {
          icon: "devices_other",
          type: "radio",
          label: "Will you be keeping any personal belongings in the property?",
          section: 2,
          helper:
            "We recommend locking away any valuable or sentimental possessions. Please try to make sure that storage space e.g. wardrobes, draws are available for guest use.",
          options: [
            { name: "Yes", value: "yes_personal_belongings_stored" },
            { name: "No", value: "no_personal_belongings_stored" },
          ],
        },
      ],
    },
    {
      title: "Amenities",
      names: [
        "tv",
        "tv_faq",
        "desk",
        "kettle",
        "toaster",
        "microwave",
        "oven",
        "hobs",
        "fridge_type",
        "washing_machine_type",
        "dishwasher",
        "lightbulb_location",
        "additional_amenities_faq",
      ],
      details: [
        {
          type: "radio",
          label: "TV",
          icon: "tv",
          helper:
            "Do you have a DVD Player, Amazon Stick, Sky Sports, Apple TV or anything worth shouting about?",
          options: [
            { name: "Yes", value: "yes_tv" },
            { name: "No", value: "no_tv" },
          ],
          section: 2,
        },
        {
          type: "text",
          label: "Information",
          icon: "short_text",
          helper: "Give details of any pay TV services",
          placeholder: "e.g. Sony Smart TV, Freeview, Sky+, Netflix",
          section: 2,
        },
        {
          type: "radio",
          label: "Laptop Friendly Workspace",
          icon: "laptop",
          helper:
            "A table or desk with space for a laptop and a chair that’s comfortable to work in",
          options: [
            { name: "Yes", value: "yes_desk" },
            { name: "No", value: "no_desk" },
          ],
          section: 2,
        },
        {
          type: "radio",
          label: "Kettle",
          icon: "widgets",
          options: [
            { name: "Yes", value: "yes_kettle" },
            { name: "No", value: "no_kettle" },
          ],
          section: 2,
        },
        {
          type: "radio",
          label: "Toaster",
          icon: "widgets",
          options: [
            { name: "Yes", value: "yes_toaster" },
            { name: "No", value: "no_toaster" },
          ],
          section: 2,
        },
        {
          type: "radio",
          label: "Microwave",
          icon: "widgets",
          options: [
            { name: "Yes", value: "yes_microwave" },
            { name: "No", value: "no_microwave" },
          ],
          section: 2,
        },
        {
          type: "checkbox",
          label: "Oven",
          icon: "widgets",
          section: 2,
        },
        {
          type: "radio",
          options: [
            { name: "Electrical", value: "electrical_hobs" },
            { name: "Gas", value: "gas_hobs" },
          ],
          label: "Hobs",
          icon: "widgets",
          section: 2,
        },
        {
          type: "radio",
          options: [
            { name: "Fridge", value: "fridge_only" },
            { name: "Fridge & Freezer", value: "fridge_freezer" },
          ],
          label: "Fridge",
          helper:
            "Our cleaners empty and clean the fridge after every guest stay to ensure it is clean and that there is not any food past its expiration date.",
          icon: "kitchen",
          section: 2,
        },
        {
          type: "select",
          options: [
            { name: "None", value: "no_washer" },
            { name: "Washer", value: "washer_only" },
            { name: "Washer-Dryer", value: "washer_dryer" },
            { name: "Washer & Dryer", value: "washer_and_dryer" },
          ],
          label: "Washing Machine",
          icon: "local_laundry_service",
          section: 2,
        },
        {
          type: "radio",
          label: "Dishwasher",
          icon: "widgets",
          options: [
            { name: "Yes", value: "yes_dishwasher" },
            { name: "No", value: "no_dishwasher" },
          ],
          section: 2,
        },
        {
          type: "textarea",
          label: "Spare light bulb location",
          icon: "description",
          placeholder: "Eg. Kitchen top draw",
          section: 2,
        },
        {
          type: "textarea",
          label: "Additional Amenities",
          helper:
            "Are there any other amenities in your home you would like your guests to know about?",
          icon: "playlist_add",
          placeholder:
            "Eg. Electric fan and drying rack in cupboard under stairs. Nespresso coffee machine.",
          section: 2,
        },
      ],
    },
  ],

  "Guest Info": [
    {
      title: "Guest FAQ",
      names: [
        "storage_space_for_guests_location",
        "nearest_supermarket_faq",
        "local_restaurants_faq",
        "local_attractions_faq",
        "nearest_train_faq",
        "nearest_bus_faq",
      ],
      details: [
        {
          type: "textarea",
          label: "Storage Space Allocated for Guests",
          icon: "storage",
          placeholder: "e.g. Bedroom wardrobes",
          section: 3,
        },
        {
          type: "textarea",
          label: "Where are the nearest shops?",
          icon: "shopping_cart",
          placeholder:
            "e.g. Local newsagent with basic supplies on the corner of the road. Tesco Metro 2 streets away on Lawn Road.",
          section: 3,
        },
        {
          type: "textarea",
          label: "What are the local restaurants / bars?",
          icon: "restaurant_menu",
          placeholder:
            "e.g. Wood-fired pizza restaurant with takeaway on Travis Road, 5 mins walk.",
          section: 3,
        },
        {
          type: "textarea",
          label:
            "Where would you recommend visiting nearby? We cover the tourist attractions so try to keep it local.",
          icon: "where_to_vote",
          placeholder:
            "e.g. Hinksey park and outdoor swimming pool, 2-minute walk. Local market on Woodstock Road every Sunday, 4-minute walk.",
          section: 3,
        },
        {
          type: "textarea",
          label: "What and where is your train/tube?",
          icon: "directions_subway",
          placeholder: "e.g. Liverpool Street station, 5 minutes on foot.",
          section: 3,
        },
        {
          type: "textarea",
          label: "What and where is your nearest bus?",
          icon: "directions_bus",
          placeholder:
            "e.g. Busses to town center, 3 minutes walk on the corner of Bank Square.",
          section: 3,
        },
      ],
    },
    {
      title: "Troubleshooting",
      names: [
        "boiler_how_to_do",
        "boiler_what_to_do",
        "wifi_location",
        "request_for_guest",
      ],
      details: [
        {
          type: "textarea",
          label: "Heating - how to turn on and adjust temperature?",
          icon: "whatshot",
          section: 3,
          helper:
            "The more details the better! E.g. set timers, altering temperature instructions.",
          placeholder:
            "Eg. Simply use the - and + buttons on the thermostat to alter the temperature. On departure please leave the heating set to 16C.",
        },
        {
          type: "textarea",
          label: "Hot water - how to turn on or reset?",
          icon: "hot_tub",
          section: 3,
          placeholder:
            "Eg. If the hot water is not running this may mean it has been turned off or needs resetting. To identify, please check the screen. Green light means it is on and needs resetting by holding down the reset button. No lights means it has been turned off and needs turning on by the switch.",
          helper:
            "Please provide instructions on what the guest should do to get hot water working if it is not running.",
        },
        {
          type: "textarea",
          label: "WIFI Router Location:",
          icon: "router",
          section: 3,
          placeholder: "Eg. Ground floor hallway",
        },
        {
          type: "textarea",
          label: "Do you have any requests for your guests?",
          icon: "assignment",
          section: 3,
          placeholder:
            "Eg. Please water indoor plants. Please adjust thermostat temperature to 12 degrees when leaving.",
        },
      ],
    },
    {
      title: "Rubbish FAQ",
      names: ["rubbish_faq", "rubbish_dates", "rubbish_instructions"],
      details: [
        {
          type: "textarea",
          label:
            "Where are the inside bins located and which bin is designated for what type of waste?",
          icon: "delete",
          placeholder:
            "e.g. Big silver bin in kitchen for general waste. Bin under the sink for recyclables. ",
          section: 3,
        },
        {
          type: "select",
          options: [
            { name: "Not Applicable", value: "-1" },
            { name: "Monday", value: "0" },
            { name: "Tuesday", value: "1" },
            { name: "Wednesday", value: "2" },
            { name: "Thursday", value: "3" },
            { name: "Friday", value: "4" },
            { name: "Saturday", value: "5" },
            { name: "Sunday", value: "6" },
          ],
          label: "What day is rubbish collected?",
          icon: "date_range",
          helper:
            "We will text the guest the day before this as a reminder for them to take rubbish to the collection point",
          section: 3,
        },
        {
          type: "textarea",
          label: "How should rubbish be handled for collection?",
          icon: "description",
          helper:
            "If you have further details listed in your property, please state where this can be found, e.g. More details can be found on the noticeboard in the hallway.",
          placeholder:
            "e.g. Transfer inside rubbish to large bins in back garden. Green bin: general waste. Blue bin: recycling. Roll to street pavement for collection and return to garden after.",
          section: 3,
        },
      ],
    },
  ],

  Onboarding: [
    {
      title: "Access",
      names: [
        "own_keysafe",
        "owned_keysafe_type",
        "keysafe_type",
        "keysafe_code",
        "smartlock_faq",
        "smartlock_code",
        "keysafe_location",
        "key_sets",
        "access_faq",
        "back_door_faq",
      ],
      details: [
        {
          type: "radio",
          label: "Do you have your own key safe?",
          icon: "lock",
          options: [
            { name: "Yes", value: "yes_own_keysafe" },
            { name: "No", value: "no_own_keysafe" },
          ],
          section: 4,
        },
        {
          type: "radio",
          options: [
            { name: "Rail Mounted", value: "rail_mounted" },
            { name: "Wall Mounted", value: "wall_mounted" },
            { name: "Smart Lock", value: "smart_lock" },
          ],
          label: "What type of key safe do you own?",
          helper:
            "We will be in touch in regards to how the smart lock code will be reset moving forward",
          icon: "lock",
          section: 4,
        },
        {
          type: "radio",
          options: [
            { name: "Rail Mounted", value: "rail_mounted" },
            { name: "Wall Mounted", value: "wall_mounted" },
          ],
          label: "What type of key safe would you like?",
          helper:
            "Please note that you can select the wall mounted key safe only if you are allowed to drill the wall of your property.",
          icon: "lock",
          section: 4,
        },
        {
          type: "text",
          label: "Please enter the current code for your key safe",
          icon: "short_text",
          section: 3,
        },
        {
          type: "textarea",
          label: "Please explain how to operate the smart lock",
          icon: "description",
          helper:
            "Please do NOT enter the smart lock access code as we will provide this to them on a separate document for security purposes",
          placeholder:
            "e.g. Place the palm of your hand on the front door keypad to activate. Enter code, wait to hear the noise then turn the knob clockwise (right) to open the door.",
          section: 4,
        },
        {
          type: "textarea",
          label: "Please enter the current code for your smart lock",
          icon: "description",
          helper:
            "We will be in touch in regards to how the smart lock code will be reset moving forward",
          section: 4,
        },
        {
          type: "textarea",
          label: "Location for the key safe",
          icon: "description",
          helper: "Please be extremely specific!",
          placeholder: "",
          section: 4,
        },
        {
          type: "checkbox",
          label:
            "Do you have 3 sets of keys ready (and tested) for us? We often have problems with keys that do not work! Please try them before you hand them over.",
          icon: "vpn_key",
          section: 4,
        },
        {
          type: "textarea",
          label:
            "Is there any other information the guest will require to access your property?",
          icon: "info",
          placeholder:
            "e.g. Gate code for main building is 3468. Fob access to enter main building. Front door handle lifts upwards to open.",
          section: 4,
        },
        {
          type: "textarea",
          label:
            "Is there any other information the guest will require for back doors and windows?",
          icon: "info",
          placeholder:
            "e.g. Key for conservatory and back door. Window keys left on window sills.",
          section: 4,
        },
      ],
    },

    {
      title: "Onboarding",
      names: [
        "start_hosting_date",
        "handover_date",
        "hosting_indefinitely",
        "first_hosting_period_end_date",
        "photography_required",
        "photography_timing",
        "early_photography_date",
      ],
      details: [
        {
          type: "date_picker",
          label: "When would you like to start hosting?",
          icon: "date_range",
          helper:
            "This is your first day of availability, i.e. the day from which guests can start staying at your property. ",
          section: 4,
        },
        {
          type: "date_picker",
          label: "Please select your preferred key handover date",
          static: true,
          helper:
            "On the handover date, we will come to collect 3 sets of keys for your property, install the key safe if required and take photos of utilities for the guest manual. You will need to be there on the day to ‘Pass the Keys’.",
          icon: "date_range",
          section: 4,
        },
        {
          type: "radio",
          label: "Is your property available for the foreseeable future?",
          icon: "home",
          options: [
            { name: "Yes", value: "yes_hosting_indefinitely" },
            { name: "No", value: "no_hosting_indefinitely" },
          ],
          section: 4,
        },
        {
          type: "date_picker",
          label: "When will your first hosting period end?",
          icon: "date_range",
          section: 4,
        },
        {
          type: "radio",
          label: "Would you like us to send a photographer (free of charge)?",
          icon: "assistant_photo",
          options: [
            { name: "Yes", value: "yes_photography_required" },
            { name: "No", value: "no_photography_required" },
          ],
          static: true,
          section: 4,
        },
        {
          type: "radio",
          options: [
            {
              name: "Early Photography Before Handover",
              value: "before_handover_date",
              section: 4,
            },
            {
              name: "Photography On Handover Date",
              value: "on_handover_date",
              section: 4,
            },
          ],
          label: "Please select a date for your photography session",
          static: true,
          helper: `Our photographer will be in touch to confirm. Please make sure your
            home is Airbnb ready for the scheduled date as photos are the most important
            part of your listing! Feel free to add nice touches such as flowers,
            fruit bowls, bed throws, and decorative cushions.`,
          icon: "photo_camera",
          section: 4,
        },
        {
          type: "date_picker",
          label: "Please select a date for your (early) photography session",
          static: true,
          icon: "date_range",
          section: 4,
        },
      ],
    },
  ],
};

export const HELPER_TXT: any = {
  "Account Info": [
    {
      info: [
        {
          strong: "Direct Debit:",
          icon: "account_balance",
          text:
            "You will soon receive an email from onboarding@passthekeys.co.uk titled ‘Direct Debit Mandate’. Please make sure to set up your direct debit through the link in the email as we are unable to proceed without this.",
        },
      ],
    },
    {
      info: [
        {
          strong: "Airbnb Payments:",
          icon: "monetization_on",
          text:
            "Please make sure to go to ‘Account’ and add your bank details to both ‘Payment Methods’ AND ‘Payout Preferences’. We will need this before taking over your account in order for Airbnb to pay you directly.",
        },
      ],
    },
    {
      info: [
        {
          strong: "Verify Account:",
          icon: "check_box",
          link: true,
        },
      ],
    },
    {
      info: [
        {
          strong: "Don't",
          icon: "thumb_down",
          text: "create a new Airbnb listing, we do this for you!",
        },
        {
          strong: "Do",
          icon: "thumb_up",
          text: "upload a smiley profile picture of yourself and a friendly bio.",
        },
        {
          icon: "warning",
          text:
            "Please be aware you will not be able to access your Airbnb account after the takeover, however you can monitor all activity through your Client Portal.",
        },
      ],
    },
  ],
  "Property Details": [],
  "Property Amenities": [
    {
      subject: "TOP TIPS!",
      info: [
        {
          text: `Just because it is not an Airbnb requirement, does not mean it is not important.
        Below are some top tips on how you can make sure your guest has a super
        experience- helping us, help you, get that Super Host Status!`,
        },
        {
          strong: "Pillows - ",
          icon: "PILLOW",
          text:
            "2 pillows per person, e.g. Double bed requires 4 pillows (we provide the linen).",
        },
        {
          strong: "Seating - ",
          icon: "SEATING",
          text: `If your property can accommodate 8 in beds it should accommodate 8 in seating.
          Limited for space? Try a bean bag or fold-up chair.`,
        },
        {
          strong: "Hangers - ",
          icon: "HANGER",
          text: "If you have a wardrobe, please do not forget to add the hangers.",
        },
        {
          strong: "Clothes Drying Rail - ",
          icon: "CLOTHES_DRYING",
          text: "If you do not have a dryer, we would consider this an essential.",
        },
        {
          strong: "Supplies - ",
          icon: "SUPPLIES",
          text: `If you have a washing machine or dishwasher, why not go the extra mile
          and provide the tablets and capsules one would need and save
          your guest a trip to the shop!`,
        },
        {
          strong: "Consumables - ",
          icon: "OLIVE_OIL",
          text: `we wouldn’t advise leaving any perishables in your cupboards but the
          following non-perishables would improve your guest’s experience: salt,
          pepper, oil, tea bags, coffee sachets and sugar.`,
        },
        {
          strong: "We provide the essentials:",
          icon: "ESSENTIALS",
          text:
            "linen, towels, toiletries, toilet paper, washing up liquid, sponge, bin bags, and more.",
        },
      ],
    },
  ],
  "Guest Info": [],
  Onboarding: [
    {
      subject: "Key Handling",
      info: [
        {
          strong: "Three is a magic number. ",
          icon: "THREE",
          text:
            "We require three sets of keys for all entrances to access your property (e.g. three fobs for building gate, three keys for front door, three keys for back garden door).",
        },
        {
          icon: "KEY_HANDLE",
          text:
            "One set of keys will be stored in the free key safe we install and the other two sets are stored in our offices. Should guests lock themselves out, the additional sets will allow us to send one set of keys from the office to grant them entry whilst ensuring we still have one set for backup (e.g. to get copied or for a staff member’s use).",
        },
        {
          icon: "KEY_SAFE",
          text:
            "Please note: the key safe is a free welcome gift from Pass the Keys to help with the ease of guest check-ins. Should we ever part ways, please note, we will need to collect the key safe back.",
        },
      ],
    },
    {
      subject: "Key Handover",
      info: [
        {
          icon: "KEY_HANDOVER",
          text:
            "We recommend scheduling the ‘Key Handover’ date for as soon as you have three sets of keys ready as we are unable to list your property without these. The sooner we list your property, the more time we have to build on your bookings!",
        },
      ],
    },
    {
      subject: "Cleaning",
      info: [
        {
          icon: "SUPPLIES",
          text:
            "From our experience, when we have received lower than a 5* cleaning score from a guest, the feedback is quite commonly unrelated to cleaning issues: from peeling paint in bathrooms to window marks. Due to this, we strongly recommend you schedule a deep clean and carry out the appropriate maintenance work prior to our initial clean so you know your guest experience will get off to a great start!",
        },
      ],
    },
  ],
};

export const INFO_NAMES = {
  package: { section: 0, type: "" },
  long_let_value: { section: 0, type: "PositiveSmallIntegerField" },
  account_number: { section: 0, type: "Charfield_40" },
  sort_code: { section: 0, type: "Charfield_40" },
  iban: { section: 0, type: "Charfield_40" },
  bic: { section: 0, type: "Charfield_40" },
  nif: { section: 0, type: "Charfield_40" },
  airbnb_email: { section: 0, type: "Charfield_255" },
  airbnb_password: { section: 0, type: "Charfield_255" },
  online_id_verified: { section: 0, type: "" },
  offline_id_verified: { section: 0, type: "" },
  profile_picture_added: { section: 0, type: "" },

  property_size: { section: 1, type: "" },
  floor: { section: 1, type: "floorSmallNumber" },
  elevator: { section: 1, type: "" },
  floor_area: { section: 1, type: "" },
  number_of_bedrooms: { section: 1, type: "PositiveSmallIntegerField" },
  number_of_beds: { section: 1, type: "PositiveSmallIntegerField" },
  beds: { section: 1, type: "array" },
  beds_faq: { section: 1, type: "" },
  guest_capacity: { section: 1, type: "PositiveSmallIntegerField" },
  number_of_bathrooms: { section: 1, type: "decimalFieldBathroom" },
  garden_type: { section: 1, type: "" },
  stand_out_features_faq: { section: 1, type: "" },
  parking_type: { section: 1, type: "" },
  parking_faq: { section: 1, type: "" },

  smoke_detector: { section: 2, type: "" },
  carbon_monoxide_detector: { section: 2, type: "" },
  wifi: { section: 2, type: "" },
  wifi_network_name: { section: 2, type: "Charfield_255" },
  wifi_password: { section: 2, type: "Charfield_255" },
  iron_and_ironing_board: { section: 2, type: "" },
  iron_and_ironing_board_location: { section: 2, type: "Charfield_255" },
  hairdryer: { section: 2, type: "" },
  hairdryer_location: { section: 2, type: "Charfield_255" },
  vacuum_cleaner: { section: 2, type: "" },
  vacuum_cleaner_location: { section: 2, type: "Charfield_255" },
  utensils: { section: 2, type: "" },
  electricity_prepaid: { section: 2, type: "" },
  personal_belongings_stored: { section: 2, type: "" },

  tv: { section: 2, type: "" },
  tv_faq: { section: 2, type: "Charfield_255" },
  desk: { section: 2, type: "" },
  kettle: { section: 2, type: "" },
  toaster: { section: 2, type: "" },
  microwave: { section: 2, type: "" },
  oven: { section: 2, type: "" },
  hobs: { section: 2, type: "" },
  fridge_type: { section: 2, type: "" },
  washing_machine: { section: 2, type: "" },
  washing_machine_type: { section: 2, type: "" },
  dishwasher: { section: 2, type: "" },
  lightbulb_location: { section: 2, type: "Charfield_255" },
  additional_amenities_faq: { section: 2, type: "" },

  nearest_supermarket_faq: { section: 3, type: "" },
  local_restaurants_faq: { section: 3, type: "" },
  local_attractions_faq: { section: 3, type: "" },
  nearest_train_faq: { section: 3, type: "Charfield_255" },
  nearest_bus_faq: { section: 3, type: "Charfield_255" },
  boiler_how_to_do: { section: 3, type: "" },
  boiler_what_to_do: { section: 3, type: "" },
  wifi_location: { section: 3, type: "Charfield_255" },
  request_for_guest: { section: 3, type: "" },
  rubbish_faq: { section: 3, type: "" },
  rubbish_dates: { section: 3, type: "array" },
  rubbish_instructions: { section: 3, type: "" },

  own_keysafe: { section: 4, type: "" },
  keysafe_code: { section: 4, type: "Charfield_255" },
  keysafe_type_own: { section: 4, type: "" },
  keysafe_type: { section: 4, type: "" },
  smartlock_faq: { section: 4, type: "" },
  smartlock_code: { section: 4, type: "" },
  key_sets: { section: 4, type: "" },
  keysafe_location: { section: 4, type: "Charfield_255" },
  access_faq: { section: 4, type: "" },
  back_door_faq: { section: 4, type: "" },
  storage_space_for_guests_location: { section: 4, type: "Charfield_255" },
  start_hosting_date: { section: 4, type: "date" },
  hosting_indefinitely: { section: 4, type: "" },
  first_hosting_period_end_date: { section: 4, type: "date" },
  handover_date: { section: 4, type: "date" },
  photography_required: { section: 4, type: "" },
  photography_timing: { section: 4, type: "" },
  early_photography_date: { section: 4, type: "date" },
};

export const DATE_FIELDS = [
  "start_hosting_date",
  "handover_date",
  "first_hosting_period_end_date",
  "early_photography_date",
];
