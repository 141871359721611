import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Days from "./common_dashboard";
import SmallSpinner from "../utility/small_spinner";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { FormattedMessage, useIntl } from "react-intl";
import { DatePartLocalised } from "../utility/date_part_localised";
import { PropertyIDContext } from "../../global_contexts";
import { rGET } from "../../actions/api";

const BookingDashboard = (props) => {
  const intl = useIntl();
  const { propertyID } = useContext(PropertyIDContext);
  const [dashboardBookings, setDashboardBookings] = useState([]);

  useEffect(() => {
    setDashboardBookings([]);
    getDashboardBookingsData();
  }, [propertyID]);

  function mobileBookingsView() {
    const bookingDays = new Days(
      dashboardBookings[0][0].check_in_date,
      dashboardBookings[0][0].check_out_date,
      intl
    );
    const diff = bookingDays.getGapDays();
    return (
      <div className="row">
        <div className="col s12 days">{diff.days}</div>
        <div className="col s12">{diff.msg}</div>
      </div>
    );
  }

  function getDashboardBookingsData() {
    if (!props.testMode) {
      rGET(`/bookings/${propertyID}/dashboard/`).then((resp) => {
        const ALL_BOOKINGS = resp && resp.data.results;
        const bookingsData = [];
        bookingsData.push(ALL_BOOKINGS);
        setDashboardBookings(bookingsData);
      });
    }
  }

  return (
    <div className="col s6">
      <NavLink exact to="/bookings/">
        <div className="card">
          <div className="cardHeader">
            <span className="title">
              <FormattedMessage
                id="dashboard.booking.title"
                defaultMessage="Bookings"
              />
            </span>
            <span className="chevron hide-on-small-only">
              <Icon
                icon={ICONS.CHEVRON.d}
                width={ICONS.CHEVRON.width}
                height={ICONS.CHEVRON.height}
              />
            </span>
          </div>
          <div className="card-content">
            {dashboardBookings && dashboardBookings[0] ? (
              <div>
                {dashboardBookings[0].length === 0 ? (
                  <div>
                    <FormattedMessage
                      id="dashboard.booking.no_bookings"
                      defaultMessage="There have been no bookings yet!"
                    />
                  </div>
                ) : (
                  <div>
                    <div>
                      <div className="hide-on-med-and-up">{mobileBookingsView()}</div>
                      <div className="date-container hide-on-small-only">
                        <div className="date-container-l">
                          <Icon
                            icon={ICONS.CALENDAR_2.d}
                            width={76}
                            height={83}
                            viewBox="0 0 81 88"
                          />
                          <span className="date-day">
                            {dashboardBookings[0].length > 0 && (
                              <DatePartLocalised
                                date={dashboardBookings[0][0]["check_in_date"]}
                                options={{ day: "numeric" }}
                              />
                            )}
                          </span>
                          <span className="date-month">
                            {dashboardBookings[0].length > 0 && (
                              <DatePartLocalised
                                date={dashboardBookings[0][0]["check_in_date"]}
                                options={{ month: "long" }}
                              />
                            )}
                          </span>
                        </div>
                        <div className="arrow-container">
                          <div className="line" />
                          <div className="status">
                            <FormattedMessage
                              id="dashboard.booking.status_booked"
                              defaultMessage="Booked"
                            />
                          </div>
                          <div className="line" />
                          <div className="line-chevron">
                            <Icon
                              icon={ICONS.CHEVRON.d}
                              width={10}
                              height={22}
                              color="#d6d6d6"
                            />
                          </div>
                        </div>
                        <div className="date-container-r">
                          <Icon
                            icon={ICONS.CALENDAR_2.d}
                            width={76}
                            height={83}
                            viewBox="0 0 81 88"
                          />
                          <span className="date-day">
                            {dashboardBookings[0].length > 0 && (
                              <DatePartLocalised
                                date={dashboardBookings[0][0]["check_out_date"]}
                                options={{ day: "numeric" }}
                              />
                            )}
                          </span>
                          <span className="date-month">
                            {dashboardBookings[0].length > 0 && (
                              <DatePartLocalised
                                date={dashboardBookings[0][0]["check_out_date"]}
                                options={{ month: "long" }}
                              />
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <SmallSpinner />
            )}
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default BookingDashboard;
