import React, { useContext, useEffect, useRef, useState } from "react";
import { fillCellCalendar, hideErrorMsgBox, spinner } from "./calendar_service";
import { isTouchScreen } from "./selector";
import { GetDatepickeri18nData, getISOLocalDate } from "./dates";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { FormattedMessage, injectIntl } from "react-intl";
import { LocaleContext, PropertyIDContext } from "../../global_contexts";
import { useForm } from "react-hook-form";
import {
  BlockingReasonErrorContext,
  CalendarDatesContext,
  CalendarFormDataContext,
} from "./calendar_contexts";
import { Link } from "react-router-dom";
import { rPOST, rPUT } from "../../actions/api";

const M = window.M;

const EditCalendar = (props) => {
  const { locale } = useContext(LocaleContext);
  const { propertyID } = useContext(PropertyIDContext);
  const { register, handleSubmit, setValue } = useForm();
  const [i18nData, setI18nData] = useState(GetDatepickeri18nData(props.intl, locale));
  const { calendarFormData, setCalendarFormData } = useContext(CalendarFormDataContext);
  const { blockingReasonError, setBlockingReasonError } = useContext(
    BlockingReasonErrorContext
  );
  const { calendarDates, setCalendarDates } = useContext(CalendarDatesContext);
  const [cancelOptionSelected, setCancelOptionSelected] = useState(false);
  const [requiresClean, setRequiresClean] = useState(true);
  const cleaningTooltipRef = useRef(null);
  const openDatesTooltipRef = useRef(null);
  const cancelBlockTooltipRef = useRef(null);
  const startDateTooltipRef = useRef(null);
  const endDateTooltipRef = useRef(null);

  useEffect(() => {
    // Ensure correct active state of form datepicker labels
    Array.from(document.querySelectorAll(".ld")).forEach((el) => {
      if (calendarDates.start_date === null && calendarDates.end_date === null) {
        el.classList.remove("active");
      } else {
        el.classList.add("active");
      }
    });

    if (calendarDates.start_date === null && calendarDates.end_date !== null) {
      setCalendarDates({ ...calendarDates, start_date: calendarDates.end_date });
    } else if (calendarDates.start_date !== null && calendarDates.end_date === null) {
      setCalendarDates({ ...calendarDates, end_date: calendarDates.start_date });
    } else if (calendarDates.start_date > calendarDates.end_date) {
      setCalendarDates({
        start_date: calendarDates.end_date,
        end_date: calendarDates.start_date,
      });
    }

    setCancelOptionSelected(false);
    setValue("start_date", calendarDates.start_date);
    setValue("end_date", calendarDates.end_date);
    initializeDatePicker();
  }, [calendarDates]);

  useEffect(() => {
    setI18nData(GetDatepickeri18nData(props.intl, locale));
  }, [locale]);

  useEffect(() => {
    initializeDatePicker();
  }, [i18nData]);

  useEffect(() => {
    if (cancelOptionSelected) {
      setCalendarFormData({
        ...calendarFormData,
        start_date_cont_disabled: true,
        end_date_cont_disabled: true,
      });
    } else {
      setCalendarFormData({
        ...calendarFormData,
        start_date_cont_disabled: false,
        end_date_cont_disabled: false,
      });
    }
  }, [cancelOptionSelected]);

  useEffect(() => {
    initTooltips();
  }, [locale, calendarFormData]);

  function initTooltips() {
    const cleaningTooltipInstance = M.Tooltip.init(cleaningTooltipRef.current, {
      position: "top",
      html: props.intl.formatMessage({
        id: "calendar.tooltip.cleaning",
        defaultMessage: "Cleaning will take place on the day of the checkout",
      }),
    });
    if (cleaningTooltipInstance) {
      cleaningTooltipInstance.tooltipEl.classList.add("bottom_arrow");
    }
    const openDatesTooltipInstance = M.Tooltip.init(openDatesTooltipRef.current, {
      position: "top",
      html: props.intl.formatMessage({
        id: "calendar.tooltip.open_dates",
        defaultMessage: "This will make the selected dates available",
      }),
    });
    if (openDatesTooltipInstance) {
      openDatesTooltipInstance.tooltipEl.classList.add("bottom_arrow");
    }
    const cancelBlockTooltipInstance = M.Tooltip.init(cancelBlockTooltipRef.current, {
      position: "top",
      html: props.intl.formatMessage({
        id: "calendar.tooltip.cancel_block",
        defaultMessage:
          "This will cancel your blocked days and the associated clean, if any",
      }),
    });
    if (cancelBlockTooltipInstance) {
      cancelBlockTooltipInstance.tooltipEl.classList.add("bottom_arrow");
    }
    const startDateTooltipInstance = M.Tooltip.init(startDateTooltipRef.current, {
      position: "top",
      html: props.intl.formatMessage({
        id: "calendar.tooltip.start_date",
        defaultMessage:
          "Your property will be available for guests until the standard check-in time set for your property. If you need to use your property in the morning - please, include the previous day to the booked days",
      }),
    });
    if (startDateTooltipInstance) {
      startDateTooltipInstance.tooltipEl.classList.add("bottom_arrow");
    }
    const endDateTooltipInstance = M.Tooltip.init(endDateTooltipRef.current, {
      position: "top",
      html: props.intl.formatMessage({
        id: "calendar.tooltip.end_date",
        defaultMessage:
          "Your property should be available after the standard check-out time. If you need to use your property in the afternoon - please, book until the next day",
      }),
    });
    if (endDateTooltipInstance) {
      endDateTooltipInstance.tooltipEl.classList.add("bottom_arrow");
    }
  }

  function initializeDatePicker(initDate) {
    var start_elems = document.querySelectorAll("#start_date_cont");
    var end_elems = document.querySelectorAll("#end_date_cont");
    var start_options = {
      autoClose: true,
      defaultDate: initDate ? initDate : new Date(),
      minDate: new Date(),
      format: "dd/mm/yyyy",
      onSelect: (date) => {
        props.addSelectingMode();
        start_elems.forEach((el) => {
          Array.from(document.querySelectorAll(".ld")).map((el) =>
            el.classList.add("active")
          );
        });
        setCalendarDates({ ...calendarDates, start_date: getISOLocalDate(date) });
      },
      i18n: i18nData,
    };
    var end_options = {
      autoClose: true,
      defaultDate: initDate ? initDate : new Date(),
      minDate: new Date(),
      format: "dd/mm/yyyy",
      onSelect: (date) => {
        props.addSelectingMode();
        end_elems.forEach((el) => {
          Array.from(document.querySelectorAll(".ld")).map((el) =>
            el.classList.add("active")
          );
        });
        setCalendarDates({ ...calendarDates, end_date: getISOLocalDate(date) });
      },
      i18n: i18nData,
    };
    M.Datepicker.init(start_elems, start_options);
    M.Datepicker.init(end_elems, end_options);
  }

  function clear(e) {
    let modal_el = document.querySelector("#modalTS");
    if (modal_el) {
      var instance = M.Modal.getInstance(modal_el);
      instance.close();
    }
    if (e) {
      e.preventDefault();
    }
    if (document.querySelector("textarea.text-r")) {
      document.querySelector("textarea.text-r").style.height = "80px";
    }
    setCalendarDates({ start_date: null, end_date: null });
    // reset calendar form data to default
    setCalendarFormData({
      type_colour: null,
      start_date_cont_disabled: false,
      end_date_cont_disabled: false,
      availability_form_disabled: false,
      save_btn_disabled: false,
      is_date_available: true,
      guest_name: null,
      worm_id: null,
      worm_type: null,
      worm_note: null,
      clean_within_two_days: false,
      clean_time: null,
    });
    clearFormRadios();
    setCancelOptionSelected(false);
    // reset error note
    setBlockingReasonError(null);
    hideErrorMsgBox();
    // reset form notifications
    formNoteEvent();
    formCleanEvent();
    props.removeSelectingMode();
  }

  function onSubmit(values) {
    ensureCorrectValues(values);
    let errors = validate(values);
    if (!Object.keys(errors).length) {
      // compensates the selected dates so that the last selected date is the 'checkout' date
      let checkOutDate = new Date(values.end_date);
      checkOutDate.setDate(checkOutDate.getDate() - 1);
      let actualCheckOutDate = checkOutDate.toISOString().split("T")[0];
      // If only one date is selected then we assume that that is not the checkout date
      if (values.end_date !== values.start_date) {
        values.end_date = actualCheckOutDate;
      }

      let modal_el = document.querySelector("#modalTS");
      modifyCalendarDates(values);
      clear();
      setRequiresClean(true);
      if (modal_el) {
        var instance = M.Modal.getInstance(modal_el);
        instance.close();
      }
    } else {
      setBlockingReasonError(errors.notes);
    }
  }

  function modifyCalendarDates(data) {
    spinner(false);
    let start_date = data.start_date;
    let end_date = data.end_date;
    if (!data.id) {
      rPOST(
        `block/${propertyID}/${start_date}/to/${end_date}/?language_code=${locale}`,
        data
      )
        .then((resp) => {
          responseSuccess(resp);
        })
        .catch((err) => {
          responseErrorCaught(err);
        });
    } else {
      rPUT(`block/${propertyID}/?language_code=${locale}`, data)
        .then((resp) => {
          responseSuccess(resp);
        })
        .catch((err) => {
          responseErrorCaught(err);
        });
    }
  }

  function responseSuccess(resp) {
    let block_calendar_dates = resp.data;
    if (block_calendar_dates.success) {
      // Refreshes the calendar display to ensure accurate data
      const startDate = document
        .querySelector(".calendar_tiles_row > div:first-of-type")
        .id.slice(6);
      const endDate = document
        .querySelector(".calendar_tiles_row > div:last-of-type")
        .id.slice(6);
      fillCellCalendar(propertyID, startDate, endDate, locale, props.intl, {
        setCalendarDates,
      });
    }
    spinner(true);
  }

  function responseErrorCaught(err) {
    let block_calendar_dates_error;
    try {
      block_calendar_dates_error = JSON.parse(err).message;
    } catch {
      if (err.response) {
        block_calendar_dates_error = err.response.data.message;
      } else {
        block_calendar_dates_error = err.status;
      }
    }
    const ErrorMsgBox = document.querySelectorAll("#CPCalendarErrorMsg");
    ErrorMsgBox.forEach((box) => {
      // show error box
      box.classList.remove("hide");
      // build and display relevant error message
      props.setCPErrorMessage(`${block_calendar_dates_error}`);
    });
    spinner(true);
  }

  function validate(formProps) {
    const errors = {};
    for (var key of Object.keys(formProps)) {
      switch (true) {
        case key === "block_reason" &&
          formProps[key] === null &&
          formProps["available"] === false:
          errors.notes = props.intl.formatMessage({
            id: "calendar.form.error_select_reason",
            defaultMessage: "Please select a reason",
          });
          break;
        case key === "notes" && formProps["available"] === false:
          if (
            (!formProps[key] ||
              formProps[key].length === 0 ||
              formProps[key].trim() === "") &&
            formProps["block_reason"] !== "IGNORE"
          ) {
            if (formProps[key].trim() === "") {
              errors.notes = props.intl.formatMessage({
                id: "calendar.form.error_enter_reason",
                defaultMessage: "Please enter a reason",
              });
            } else {
              errors.notes = props.intl.formatMessage({
                id: "calendar.form.error_select_or_enter_reason",
                defaultMessage: "Please select or enter a reason",
              });
            }
          }
          break;
        default:
          break;
      }
    }
    return errors;
  }

  function ensureCorrectValues(values) {
    let screen = isTouchScreen() ? "#touch-screen" : "#desk-screen";
    const YES_RADIO = document.querySelector(`${screen} [type="radio"]#required`);

    values.id = calendarFormData.worm_id;

    // detect worm type and set if it requires a clean
    if (calendarFormData.worm_type === "superblock") {
      values.requires_clean = false;
    } else if (calendarFormData.worm_type === "host_use") {
      values.requires_clean = true;
    }

    // Only entered when a new block is being made
    if (values.id === null) {
      const CONFIRMED_YES = document.querySelector(`${screen} [no_clean_option="YES"]`);
      const CONFIRMED_NO = document.querySelector(`${screen} [no_clean_option="NO"]`);

      if (CONFIRMED_YES && CONFIRMED_NO) {
        if (CONFIRMED_NO.classList.contains("confirmation_option--selected")) {
          values.requires_clean = false;
        } else if (CONFIRMED_YES.classList.contains("confirmation_option--selected")) {
          values.requires_clean = true;
        }
      }
    }

    values.requires_clean =
      values.requires_clean === "on" ||
      values.requires_clean === true ||
      (YES_RADIO && YES_RADIO.checked === true)
        ? true
        : false;

    // Grab value from block reason section and handle accordingly
    const SELECTED_BLOCK_REASON_RADIOS = document.querySelectorAll(
      `${screen} [type="radio"][name="block_reason"]`
    );
    SELECTED_BLOCK_REASON_RADIOS.forEach((radio) => {
      if (radio.checked) {
        values.block_reason = radio.value;
        if (
          values.block_reason !==
            props.intl.formatMessage({
              id: "calendar.form.other",
              defaultMessage: "other",
            }) &&
          (!values.notes || values.notes === "")
        ) {
          values.notes = values.block_reason;
        }
      }
    });

    // check whether cancelling block
    let id_el = isTouchScreen() ? "#touch-screen" : "#desk-screen";
    let cancelBlockEl = document.querySelector(
      `${id_el} #cancel_block [type='checkbox']`
    );
    if (cancelBlockEl && cancelBlockEl.checked === true) {
      values.available = true;
    } else {
      values.available = false;
    }

    // catches scenarios where a note isn't present on the CalDay
    if (!values.notes && values.id) {
      values.notes = "Unavailable";
    }

    // check whether start and end dates in values align with what's in the form
    if (
      values.start_date !== calendarDates.start_date ||
      values.end_date !== calendarDates.end_date
    ) {
      values.start_date = calendarDates.start_date;
      values.end_date = calendarDates.end_date;
    }

    // If note is null and we are blocking a date with an existing worm, use the note from that worm
    if (values.available === false && values.id !== null) {
      const noteTooltip = document
        .querySelector(
          `[date-js="${document
            .querySelector(`.worm[worm_id="${values.id}"]`)
            .getAttribute("worm_placement")}"]>li>small`
        )
        .getAttribute("data-tooltip");
      values.notes = noteTooltip;
      values.block_reason = "other";
      if (values.notes === "") {
        values.block_reason = "IGNORE";
      }
    }
  }

  function handleBlockReasonRadio(newSelected) {
    let screen = isTouchScreen() ? "#touch-screen" : "#desk-screen";
    const PERSONAL_USE_RADIO = document.querySelector(
      `${screen} [type="radio"]#personal_use`
    );
    const GUEST_STAYING_RADIO = document.querySelector(
      `${screen} [type="radio"]#guest_staying`
    );
    const MAINTENANCE_RADIO = document.querySelector(
      `${screen} [type="radio"]#maintenance`
    );
    const OTHER_RADIO = document.querySelector(`${screen} [type="radio"]#other`);

    switch (newSelected) {
      case "personal_use":
        PERSONAL_USE_RADIO.checked = true;
        GUEST_STAYING_RADIO.checked = false;
        MAINTENANCE_RADIO.checked = false;
        OTHER_RADIO.checked = false;
        break;
      case "guest_staying":
        PERSONAL_USE_RADIO.checked = false;
        GUEST_STAYING_RADIO.checked = true;
        MAINTENANCE_RADIO.checked = false;
        OTHER_RADIO.checked = false;
        break;
      case "maintenance":
        PERSONAL_USE_RADIO.checked = false;
        GUEST_STAYING_RADIO.checked = false;
        MAINTENANCE_RADIO.checked = true;
        OTHER_RADIO.checked = false;
        break;
      case "other":
        PERSONAL_USE_RADIO.checked = false;
        GUEST_STAYING_RADIO.checked = false;
        MAINTENANCE_RADIO.checked = false;
        OTHER_RADIO.checked = true;
        break;
      default:
        break;
    }
  }

  function AvailableForm() {
    return (
      <div id="blocking_reason" className="row mb-0">
        <div className="col s12 mb-10">
          <p className="mt-5">
            <FormattedMessage
              id="calendar.form.reason.title"
              defaultMessage="What is the reason you're blocking these days?"
            />
          </p>
        </div>
        <div className="blocking_option col s12 mt-0">
          <label
            onClick={() => {
              formNoteEvent("personal_use");
            }}
            htmlFor="personal_use">
            <FormattedMessage
              id="calendar.form.reason.one"
              defaultMessage="Myself / relative staying in the property">
              {(value_text) => (
                <input
                  id="personal_use"
                  {...register("block_reason")}
                  type="radio"
                  value={value_text}
                  onClick={() => handleBlockReasonRadio("personal_use")}
                />
              )}
            </FormattedMessage>
            <span>
              <FormattedMessage
                id="calendar.form.reason.one"
                defaultMessage="Myself / relative staying in the property"
              />
            </span>
          </label>
        </div>
        <div className="blocking_option col s12">
          <label
            onClick={() => {
              formNoteEvent("guest_staying");
            }}
            htmlFor="guest_staying">
            <FormattedMessage
              id="calendar.form.reason.two"
              defaultMessage="A guest is staying at the property">
              {(value_text) => (
                <input
                  id="guest_staying"
                  {...register("block_reason")}
                  type="radio"
                  value={value_text}
                  onClick={() => handleBlockReasonRadio("guest_staying")}
                />
              )}
            </FormattedMessage>
            <span>
              <FormattedMessage
                id="calendar.form.reason.two"
                defaultMessage="A guest is staying at the property"
              />
            </span>
          </label>
          <div className="notification_box notification_box__guest_stay hide">
            <p>
              <FormattedMessage
                id="calendar.form.reason.two_prompt_first_part"
                defaultMessage="Please consider having your guest booking fully managed by Pass the Keys"
              />{" "}
              -{" "}
              <Link className="booking_text_box--link" to="/contact-us/">
                <FormattedMessage
                  id="calendar.form.booking_contact"
                  defaultMessage="contact"
                />
              </Link>{" "}
              <FormattedMessage
                id="calendar.form.reason.two_prompt_second_part"
                defaultMessage="your local account manager to arange for it."
              />
            </p>
            <div className="close_icon" onMouseDown={formNoteEvent}>
              ×
            </div>
          </div>
        </div>
        <div className="blocking_option col s12">
          <label
            onClick={() => {
              formNoteEvent("maintenance");
            }}
            htmlFor="maintenance">
            <FormattedMessage
              id="calendar.form.reason.three"
              defaultMessage="Maintenance work">
              {(value_text) => (
                <input
                  id="maintenance"
                  {...register("block_reason")}
                  type="radio"
                  value={value_text}
                  onClick={() => handleBlockReasonRadio("maintenance")}
                />
              )}
            </FormattedMessage>
            <span>
              <FormattedMessage
                id="calendar.form.reason.three"
                defaultMessage="Maintenance work"
              />
            </span>
          </label>
        </div>
        <div className="blocking_option col s12">
          <label
            onClick={() => {
              formNoteEvent("other");
            }}
            htmlFor="other">
            <FormattedMessage id="calendar.form.reason.four" defaultMessage="Other">
              {(value_text) => (
                <input
                  id="other"
                  {...register("block_reason")}
                  type="radio"
                  value={value_text}
                  onClick={() => handleBlockReasonRadio("other")}
                />
              )}
            </FormattedMessage>
            <span>
              <FormattedMessage id="calendar.form.reason.four" defaultMessage="Other" />
            </span>
          </label>
          <div id="notes" form_data="notes_field" className="col s12 mb-0 mt-5 hide">
            <textarea
              className="materialize-textarea text-r mb-0"
              {...register("notes")}
            />
          </div>
        </div>
        <div>
          <small className="error">{blockingReasonError}</small>
        </div>
        <div id="clean_serv" className="col full-width">
          <div className="baseline">
            <div className="form_cleaning__title">
              <FormattedMessage
                id="calendar.form.cleaning_prompt"
                defaultMessage="Would you like cleaning to be scheduled?"
              />
              <div className="tooltip">
                <span
                  id="cleaning-info-tooltip"
                  className="info_btn"
                  ref={cleaningTooltipRef}>
                  <Icon
                    icon={ICONS.INFO.d}
                    width={ICONS.INFO.width}
                    height={ICONS.INFO.height}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="input-field col s6 mt-0 pb-10">
            <label
              onClick={() => {
                formCleanEvent("close_notification");
              }}
              htmlFor="required">
              <input
                id="required"
                {...register("requires_clean")}
                type="radio"
                value="on"
                checked={requiresClean}
              />
              <span>
                <FormattedMessage
                  id="calendar.form.cleaning.yes"
                  defaultMessage="Yes"
                />
              </span>
            </label>
          </div>
          <div className="input-field col s6 mt-0 pb-10">
            <label
              onClick={() => {
                formCleanEvent("open_notification");
              }}
              htmlFor="no_required">
              <input
                id="no_required"
                {...register("requires_clean")}
                type="radio"
                value="off"
                checked={!requiresClean}
              />
              <span>
                <FormattedMessage id="calendar.form.cleaning.no" defaultMessage="No" />
              </span>
            </label>
          </div>
          <div className="notification_box notification_box__confirm_no_clean arrow_point_no hide">
            <div
              no_clean_option="NO"
              onClick={() => {
                formCleanEvent("close_notification");
              }}
              onMouseDown={() => {
                formCleanEvent("select_option", "NO");
              }}
              className="confirmation_option">
              <p>
                <FormattedMessage
                  id="calendar.form.cleaning.confirmation_no"
                  defaultMessage="I confirm that the property will be cleaned and sanitised, fresh linens left on beds, and consumables replenished."
                />
              </p>
            </div>
            <div
              no_clean_option="YES"
              onClick={() => {
                formCleanEvent("close_notification");
              }}
              onMouseDown={() => {
                formCleanEvent("select_option", "YES");
              }}
              className="confirmation_option">
              <p>
                <FormattedMessage
                  id="calendar.form.cleaning.confirmation_yes"
                  defaultMessage="I would like to keep the cleaning job arranged by Pass the Keys."
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function UnavailableForm() {
    if (calendarFormData && calendarFormData.type_colour === "green") {
      return (
        <div className="row mb-0">
          <p className="booking_text_box">
            <FormattedMessage
              id="calendar.form.cant_change"
              defaultMessage="You can’t change or cancel the booking made by a guest."
            />
          </p>
          <p className="booking_text_box mt-10">
            <FormattedMessage id="calendar.form.please" defaultMessage="Please" />,{" "}
            <Link className="booking_text_box--link" to="/contact-us/">
              <FormattedMessage id="calendar.form.contact" defaultMessage="contact" />
            </Link>{" "}
            <FormattedMessage
              id="calendar.form.local_manager_text"
              defaultMessage="your local account manager if you have questions or need to make any amendments."
            />
          </p>
        </div>
      );
    } else if (calendarFormData && calendarFormData.clean_within_two_days) {
      return (
        <div className="row mb-0">
          <p className="booking_text_box">
            <FormattedMessage
              id="calendar.form.cant_change_upcoming"
              defaultMessage="You can’t change or cancel the blocked days, as the cleaning job associated with it is less than 48 hours ahead."
            />
          </p>
          <p className="booking_text_box mt-10">
            <FormattedMessage id="calendar.form.please" defaultMessage="Please" />,{" "}
            <Link className="booking_text_box--link" to="/contact-us/">
              <FormattedMessage id="calendar.form.contact" defaultMessage="contact" />
            </Link>{" "}
            <FormattedMessage
              id="calendar.form.local_manager_text"
              defaultMessage="your local account manager if you have questions or need to make any amendments."
            />
          </p>
        </div>
      );
    } else {
      const handleCancellationChange = () => {
        document.querySelectorAll("#start_date_cont").forEach((sdc) => {
          sdc.classList.toggle("disabled");
        });
        document.querySelectorAll("#end_date_cont").forEach((edc) => {
          edc.classList.toggle("disabled");
        });
        setCancelOptionSelected(!cancelOptionSelected);
      };

      return (
        <div className="row mb-0">
          <h6 className="ml-10 mb-0">
            <FormattedMessage id="calendar.form.or" defaultMessage="Or" />
          </h6>
          <div className="input-field ml-10 mt-0">
            <div className="input-field">
              <div id="cancel_block" className="cont-checkbox">
                <label className="cancel_block_checkbox">
                  <input
                    type="checkbox"
                    className="filled-in"
                    onChange={handleCancellationChange}
                    checked={cancelOptionSelected}
                  />
                  <span />
                  {calendarFormData && calendarFormData.worm_id === null ? (
                    <FormattedMessage
                      id="calendar.form.open_dates"
                      defaultMessage="Open dates"
                    />
                  ) : (
                    <FormattedMessage
                      id="calendar.form.cancel_block"
                      defaultMessage="Cancel the block"
                    />
                  )}
                </label>
                {calendarFormData && calendarFormData.worm_id === null ? (
                  <div className="tooltip">
                    <span
                      id="open-dates-info-tooltip"
                      className="info_btn"
                      ref={openDatesTooltipRef}>
                      <Icon
                        icon={ICONS.INFO.d}
                        width={ICONS.INFO.width}
                        height={ICONS.INFO.height}
                      />
                    </span>
                  </div>
                ) : (
                  <div className="tooltip">
                    <span
                      id="cancel-block-info-tooltip"
                      className="info_btn"
                      ref={cancelBlockTooltipRef}>
                      <Icon
                        icon={ICONS.INFO.d}
                        width={ICONS.INFO.width}
                        height={ICONS.INFO.height}
                      />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  function formCleanEvent(event, data) {
    let screen = isTouchScreen() ? "#touch-screen" : "#desk-screen";
    const YES_OPTION = document.querySelector(
      `${screen} .notification_box__confirm_no_clean [no_clean_option="YES"]`
    );
    const NO_OPTION = document.querySelector(
      `${screen} .notification_box__confirm_no_clean [no_clean_option="NO"]`
    );
    const STR_SELECTED = "confirmation_option--selected";
    const SAVE_BTN = document.querySelector(`${screen} #save-btn`);
    const NOTIF_BOX = document.querySelector(
      `${screen} .notification_box__confirm_no_clean`
    );

    switch (event) {
      case "open_notification":
        // This needs to be done to avoid an issue if YES_OPTION is selected and then they attempt to select No again
        setRequiresClean(false);
        // Ensure the correct option is selected by default
        YES_OPTION.classList.add(STR_SELECTED);
        NO_OPTION.classList.remove(STR_SELECTED);

        SAVE_BTN.classList.add("disabled");
        NOTIF_BOX.classList.remove("hide");
        break;
      case "close_notification":
        if (
          YES_OPTION.classList.contains(STR_SELECTED) ||
          NOTIF_BOX.classList.contains("hide")
        ) {
          // User wants clean
          setRequiresClean(true);
        }

        SAVE_BTN.classList.remove("disabled");
        NOTIF_BOX.classList.add("hide");
        break;
      case "select_option":
        // Ensures that whichever option is clicked becomes selected
        if (data === "NO" && !NO_OPTION.classList.contains(STR_SELECTED)) {
          YES_OPTION.classList.remove(STR_SELECTED);
          NO_OPTION.classList.add(STR_SELECTED);
        } else if (data === "YES" && !YES_OPTION.classList.contains(STR_SELECTED)) {
          YES_OPTION.classList.add(STR_SELECTED);
          NO_OPTION.classList.remove(STR_SELECTED);
        }
        break;
      default:
        // Triggered when clearing to clean the form
        if (SAVE_BTN && NOTIF_BOX) {
          SAVE_BTN.classList.remove("disabled");
          NOTIF_BOX.classList.add("hide");
        }
        break;
    }
  }

  function returnCalendarFormData(data_to_return) {
    let Classes;
    switch (data_to_return) {
      case "start_date":
        Classes = "input-field col s12 ";
        if (calendarFormData && calendarFormData.start_date_cont_disabled) {
          Classes += "disabled ";
        } else {
          document.querySelectorAll("#start_date_cont").forEach((sdc) => {
            sdc.classList.remove("disabled");
          });
        }
        return Classes;
      case "end_date":
        Classes = "input-field col s12 ";
        if (calendarFormData && calendarFormData.end_date_cont_disabled) {
          Classes += "disabled ";
        } else {
          document.querySelectorAll("#end_date_cont").forEach((edc) => {
            edc.classList.remove("disabled");
          });
        }
        return Classes;
      case "availability_form":
        Classes = "col ";
        let af = document.querySelector("#clear-selected");
        if (af && af.classList.contains("hide")) {
          Classes += "hide ";
        }
        if (calendarFormData && calendarFormData.availability_form_disabled) {
          Classes += "disabled ";
        }
        document
          .querySelectorAll("#cancel_block [type='checkbox']")
          .forEach((checkbox) => {
            checkbox.checked = false;
          });
        return Classes;
      case "save_button":
        Classes = "btn ";
        if (calendarFormData && calendarFormData.save_btn_disabled) {
          Classes += "disabled ";
        }
        return Classes;
      default:
        break;
    }
  }

  function calculateTitleForForm() {
    if (calendarFormData && calendarFormData.type_colour === "green") {
      return (
        <>
          <FormattedMessage id="calendar.form.booking_by" defaultMessage="Booking by" />{" "}
          {calendarFormData.guest_name}
        </>
      );
    }
    return calendarFormData && calendarFormData.is_date_available === true ? (
      <FormattedMessage id="calendar.form.block_days" defaultMessage="Block days" />
    ) : (
      <FormattedMessage
        id="calendar.form.change_block_days"
        defaultMessage="Change your blocked days"
      />
    );
  }

  function CleanTimeInfo() {
    let screen = isTouchScreen() ? "#touch-screen" : "#desk-screen";
    if (screen === "#touch-screen") {
      if (calendarFormData && calendarFormData.clean_time !== null) {
        return (
          <div id="cleaning_time">
            <Icon icon={ICONS.CLEANING.d} width={20} height={20} viewBox="0 0 40 40" />
            <p>
              <FormattedMessage
                id="tooltip.cleaning_from"
                defaultMessage="Cleaning From"
              />
              : {calendarFormData.clean_time}
            </p>
          </div>
        );
      }
    }
  }

  return (
    <form id="edit_cal" className="custom-input-form">
      <div className="top">
        <div className="col mb-0">
          {calculateTitleForForm()}
          <div id="start_date_cont" className={returnCalendarFormData("start_date")}>
            <input
              className="datepicker no-color"
              type="text"
              defaultValue={calendarDates.start_date}
              {...register("start_date", { required: true })}
            />
            <span className="st">
              {calendarDates.start_date &&
                getISOLocalDate(calendarDates.start_date, true)}
            </span>
            <label className="ld" htmlFor="start_date">
              <FormattedMessage
                id="calendar.form.start_date"
                defaultMessage="Start Date"
              />
              <span id="sd_help" className="ml-5 fs-75">
                <FormattedMessage
                  id="calendar.form.start_date_help"
                  defaultMessage="(from property check-in time onwards)"
                />
              </span>
              <div className="tooltip">
                <span
                  id="start-date-info-tooltip"
                  className="info_btn"
                  ref={startDateTooltipRef}>
                  <Icon
                    icon={ICONS.INFO.d}
                    width={ICONS.INFO.width}
                    height={ICONS.INFO.height}
                  />
                </span>
              </div>
            </label>
          </div>
          <div id="end_date_cont" className={returnCalendarFormData("end_date")}>
            <input
              className="datepicker no-color"
              type="text"
              defaultValue={calendarDates.end_date}
              {...register("end_date", { required: true })}
            />
            <span className="ed">
              {calendarDates.end_date && getISOLocalDate(calendarDates.end_date, true)}
            </span>
            <label className="ld" htmlFor="end_date">
              <FormattedMessage id="calendar.form.end_date" defaultMessage="End Date" />
              <span id="ed_help" className="ml-5 fs-75">
                <FormattedMessage
                  id="calendar.form.end_date_help"
                  defaultMessage="(until property check-out time)"
                />
              </span>
              <div className="tooltip">
                <span
                  id="end-date-info-tooltip"
                  className="info_btn"
                  ref={endDateTooltipRef}>
                  <Icon
                    icon={ICONS.INFO.d}
                    width={ICONS.INFO.width}
                    height={ICONS.INFO.height}
                  />
                </span>
              </div>
            </label>
            {CleanTimeInfo()}
          </div>
        </div>

        <div
          id="availability-form"
          className={returnCalendarFormData("availability_form")}>
          {calendarFormData && calendarFormData.is_date_available === true
            ? AvailableForm()
            : UnavailableForm()}
        </div>
      </div>
      <div className="bottom row mt-25 hide">
        <div className="col s12">
          <div className="form_buttons">
            <button id="clear-btn" className="btn" onClick={(e) => clear(e)}>
              <FormattedMessage id="preferences.clear" defaultMessage="Clear" />
            </button>
            <button
              id="save-btn"
              className={returnCalendarFormData("save_button")}
              onClick={handleSubmit((values) => onSubmit({ ...values }))}>
              <FormattedMessage
                id="calendar.form.save_changes"
                defaultMessage="Save Changes"
              />
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export function formNoteEvent(event) {
  let screen = isTouchScreen() ? "#touch-screen" : "#desk-screen";
  const GUEST_STAY_NOTIFICATION = document.querySelector(
    `${screen} .notification_box__guest_stay`
  );
  const NOTES_FIELD = document.querySelector(`${screen} [form_data="notes_field"]`);

  // defaults (we want them to happen every time, hence we can't add it to a 'default' case)
  if (GUEST_STAY_NOTIFICATION) {
    GUEST_STAY_NOTIFICATION.classList.add("hide");
  }
  if (NOTES_FIELD) {
    NOTES_FIELD.classList.add("hide");
  }

  switch (event) {
    case "personal_use":
      break;
    case "guest_staying":
      GUEST_STAY_NOTIFICATION.classList.remove("hide");
      break;
    case "maintenance":
      break;
    case "other":
      NOTES_FIELD.classList.remove("hide");
      NOTES_FIELD.children[0].focus();
      break;
    default:
      break;
  }
}

export function clearFormRadios() {
  let screen = isTouchScreen() ? "#touch-screen" : "#desk-screen";
  const RADIOS = document.querySelectorAll(`${screen} [type="radio"]`);
  RADIOS.forEach((radio) => {
    radio.checked = false;
  });
}

export default injectIntl(EditCalendar);
