import React, { useContext, useEffect, useState } from "react";
import { TextField } from "../../schema";
import { LocaleContext } from "../../../../global_contexts";
import { useIntl } from "react-intl";

export const InputAccountNumberComponent = (props: {
  onValueChange: any;
  setFieldError: any;
  field: TextField;
}) => {
  const INTL = useIntl();
  const { locale } = useContext(LocaleContext);
  const [value, setValue] = useState(props.field.value || "");
  const accountNumberRegex = /^\d{7,8}$/;

  useEffect(() => {
    const EL = document.getElementById(props.field.key);
    if (EL && EL.classList.contains("invalid")) {
      showPatternError();
    }
  }, [locale]);

  function showPatternError() {
    props.setFieldError(
      INTL.formatMessage({
        id: "dynamic_onboarding.account_number_pattern_error",
        defaultMessage: "Please enter a valid Account Number",
      })
    );
  }

  return (
    <input
      id={props.field.key}
      type="text"
      className="validate input-field--medium"
      required={props.field.required}
      value={value}
      placeholder="00000000"
      pattern={accountNumberRegex.source}
      onChange={(e) => {
        setValue(e.currentTarget.value);
      }}
      onBlur={(e) => {
        if (
          e.currentTarget.value === "" ||
          accountNumberRegex.test(e.currentTarget.value)
        ) {
          props.onValueChange(e.currentTarget, e.currentTarget.value);
        } else {
          showPatternError();
        }
      }}
    />
  );
};
