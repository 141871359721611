import React from "react";
import { FormattedMessage } from "react-intl";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";

const OptionPanel = (props) => {
  return (
    <div className="notification-option-container">
      <div
        id={props.containerId}
        className={
          "notification-option" + (props.active ? " notification-option-selected" : "")
        }>
        <span
          className={"notification-option-checked " + (!props.active ? "hide" : "")}>
          <Icon
            icon={ICONS.CHECKED.d}
            width={ICONS.CHECKED.width}
            height={ICONS.CHECKED.height}
          />
        </span>
        <label htmlFor={props.inputId}>
          <h5>
            <FormattedMessage
              id={`profile.notifications.${props.title}`}
              defaultMessage={props.title}>
              {(title) => title}
            </FormattedMessage>
          </h5>
          <p>
            <FormattedMessage
              id={`profile.notifications.${props.title}_description`}
              defaultMessage={props.description}>
              {(description) => description}
            </FormattedMessage>
          </p>
          <div className="radio-container center-align">
            <input
              disabled={props.isManager}
              type="radio"
              id={props.inputId}
              name="involvement"
              value={props.value}
              onChange={props.onChange}
              checked={props.active}
            />
            <span />
          </div>
        </label>
      </div>
    </div>
  );
};

export default OptionPanel;
