import React, { useEffect, useState } from "react";
import { TextField } from "../../schema";

export const InputTextComponent = (props: { onValueChange: any; field: TextField }) => {
  const [value, setValue] = useState(props.field.value || "");

  useEffect(() => {
    if (props.field.value) {
      setValue(props.field.value);
    }
  }, [props.field.value]);

  return (
    <input
      id={props.field.key}
      type="text"
      className="validate input-field--large"
      required={props.field.required}
      placeholder=""
      value={value}
      onChange={(e) => {
        setValue(e.currentTarget.value);
      }}
      onBlur={(e) => {
        props.onValueChange(e.currentTarget, e.currentTarget.value);
      }}
    />
  );
};
