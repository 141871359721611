import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { displayFullDate } from "../common/util";
import NotFound from "./not_found";
import { ICONS } from "./svg/icons";
import Icon from "./svg/svg_sprite";
import { FormattedMessage } from "react-intl";

const Review = (props) => {
  const [review, setReview] = useState(
    localStorage.getItem("review") ? JSON.parse(localStorage.getItem("review")) : null
  );

  useEffect(() => {
    setReview(JSON.parse(localStorage.getItem("review")));
  }, [localStorage.getItem("review")]);

  function contentRender() {
    if (!review) {
      return errorNotFound();
    }
    return review.id === +props.match.params.id ? reviewInfo() : errorNotFound();
  }

  function reviewInfo() {
    return (
      <div>
        <div className="row reviews">
          <div className="col s12">
            <h4>
              <FormattedMessage id="reviews.title" defaultMessage="Reviews" />
            </h4>
          </div>
        </div>
        <div className="row">
          <NavLink exact to="/reviews/" className="col s12 pb-10 left-align">
            <span className="mr-10">
              <Icon
                icon={ICONS.BACK_ARROW.d}
                width={ICONS.BACK_ARROW.width}
                height={12}
                viewBox="0 0 10 18"
              />
            </span>
            <span className="cobalt-blue">
              <FormattedMessage
                id="reviews.back_to_reviews"
                defaultMessage="Back to all reviews"
              />
            </span>
          </NavLink>
          <div className="col s12 name center-align">
            <h5 className="mb-0">{review.guest_name}</h5>
          </div>
        </div>
        <div className="row">
          <div className="col s12 reviews center-align">
            <div className="stars">
              <Icon
                icon={ICONS.STAR.d}
                width={55}
                height={51}
                viewBox="0 0 30 26"
                color={review.rating >= 1 ? "#ec9a3a" : "rgba(236, 154, 58, 0.2)"}
              />
              <Icon
                icon={ICONS.STAR.d}
                width={55}
                height={51}
                viewBox="0 0 30 26"
                color={review.rating >= 2 ? "#ec9a3a" : "rgba(236, 154, 58, 0.2)"}
              />
              <Icon
                icon={ICONS.STAR.d}
                width={55}
                height={51}
                viewBox="0 0 30 26"
                color={review.rating >= 3 ? "#ec9a3a" : "rgba(236, 154, 58, 0.2)"}
              />
              <Icon
                icon={ICONS.STAR.d}
                width={55}
                height={51}
                viewBox="0 0 30 26"
                color={review.rating >= 4 ? "#ec9a3a" : "rgba(236, 154, 58, 0.2)"}
              />
              <Icon
                icon={ICONS.STAR.d}
                width={55}
                height={51}
                viewBox="0 0 30 26"
                color={review.rating === 5 ? "#ec9a3a" : "rgba(236, 154, 58, 0.2)"}
              />
            </div>
            <div className="date-review pt-20">
              <FormattedMessage
                id="reviews.reviewed_on"
                defaultMessage="Reviewed on "
              />
              <span className="date mr-15">
                {displayFullDate(review.created.slice(0, 10))}
              </span>
              <span>{review.platform}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            <div>
              <div>
                <span className="mr-10">
                  <Icon
                    icon={ICONS.EYE.d}
                    width={ICONS.EYE.width}
                    height={20}
                    viewBox="0 0 24 25"
                  />
                </span>
                <span className="bold">
                  <FormattedMessage
                    id="reviews.public_review"
                    defaultMessage="Public review"
                  />
                </span>
              </div>
              <p>{review.comments}</p>
            </div>
            {review.private_feedback && (
              <div className="pt-10">
                <div>
                  <span className="mr-10">
                    <Icon
                      icon={ICONS.LOCK.d}
                      width={20}
                      height={20}
                      viewBox="0 0 24 25"
                    />
                  </span>
                  <span className="bold">
                    <FormattedMessage
                      id="reviews.private_review"
                      defaultMessage="Private comments"
                    />
                  </span>
                </div>
                <p>{review.private_feedback}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  function errorNotFound() {
    return <NotFound />;
  }

  return <div>{contentRender()}</div>;
};

export default Review;
