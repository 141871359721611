import React from "react";
import { formatDateDDMMYYYY } from "./util";
import PaymentAccount from "../components/direct_debit/direct_debit";

function textInfo(info) {
  return (
    <div className="">
      <p>{info}</p>
    </div>
  );
}

function inputSelect(name, error, details, propsInput, multiple = false) {
  let selectClass = error ? "error txt-field " : "";
  selectClass += propsInput.skipped ? " skipped txt-field" : "";
  return (
    <div id={name} className={selectClass}>
      <p className="title">{details.label}</p>
      <div className="input-field" value={name}>
        <i
          className={
            details["icon"] === "code"
              ? "material-icons prefix elevator"
              : "material-icons prefix"
          }>
          {details["icon"]}
        </i>
        {multiple ? (
          <select multiple className={name} name={name} {...propsInput}>
            <option value="" disabled>
              ------
            </option>
            {details["options"].map((option, i) => (
              <option
                key={`multiple-${i}`}
                value={option.value}
                defaultValue={propsInput.value.includes(option.value)}>
                {option.name}
              </option>
            ))}
          </select>
        ) : (
          <select className={name} name={name} {...propsInput}>
            <option value="" disabled>
              ------
            </option>
            {details["options"].map((option, i) => (
              <option
                key={`detail-${i}`}
                value={option.value}
                defaultValue={propsInput.value === option.value}>
                {option.name}
              </option>
            ))}
          </select>
        )}
        {details.helper && <span className="helper-text">{details.helper}</span>}
      </div>
      {error && <small className="rusty-red">{error}</small>}
    </div>
  );
}

function inputRadio(name, error, details, propsInput, info = "") {
  let statePayment = "initial";
  if (name === "package") {
    statePayment = propsInput.value && propsInput.value.length && "in_progress";
  }
  let radioClasses = "input-field ";
  radioClasses += details.class ? details.class : "";
  radioClasses += error ? "error " : " ";
  radioClasses += propsInput.skipped ? "skipped" : "";
  let id_rn;
  if (name !== "beds_info") {
    id_rn = name;
  } else {
    radioClasses += " beds_info";
  }
  propsInput.value =
    propsInput.value === true
      ? `yes_${name}`
      : propsInput.value === false
      ? `no_${name}`
      : propsInput.value;
  return (
    <div>
      {name === "tv" && (
        <p>
          <strong>Property Amenities - General</strong>
        </p>
      )}
      {name === "package" && (
        <div>
          <PaymentAccount status={statePayment} />
          <p>
            <strong>Account Info - Platforms</strong>
          </p>
        </div>
      )}
      {name === "own_keysafe" && (
        <p>
          <strong>Property Access</strong>
        </p>
      )}
      {name === "hosting_indefinitely" && (
        <p>
          <strong>Initial Clean: </strong>
          We have automatically scheduled the initial clean for the day before the first
          day of availability. This will be charged to you on your first invoice. This
          is a mandatory clean in order for us to prepare your property for your first
          booking with our fresh linen and consumables.{" "}
        </p>
      )}
      <div id={id_rn} className={radioClasses}>
        {name === "airbnb_account_exists" ? (
          <p className="title">
            Do you already have an airbnb hosting account, with good reviews, that you{" "}
            <strong>do not</strong> use for traveling?
          </p>
        ) : (
          <p className="title">{details.label}</p>
        )}
        <p className="radio-cont">
          {details["options"].map((option, i) => (
            <label key={`radio-${i}`} htmlFor={option.value}>
              <input
                {...propsInput}
                id={option.value}
                name={name}
                type="radio"
                value={option.value}
                checked={propsInput.value === option.value}
              />
              <span>{option.name}</span>
            </label>
          ))}
        </p>
        {details.helper && <span className="helper-text">{details.helper}</span>}
        {propsInput.value === "no_photography_required" && (
          <span className="helper-text">
            Please make sure we have access to the photos of your property for the
            listing.
          </span>
        )}
      </div>
      {info.length > 0 && textInfo(info)}
      {error && <small className="rusty-red">{error}</small>}
    </div>
  );
}

function inputDatePicker(name, error, details, propsInput) {
  const dateSelected = propsInput.value;
  let datePickerClass = error ? "error txt-field " : " ";
  datePickerClass += propsInput.skipped ? "skipped txt-field" : "";
  return (
    <div>
      {name === "start_hosting_date" && (
        <p>
          <strong>Onboarding - Availability</strong>
        </p>
      )}
      <div id={name} className={datePickerClass}>
        <p className="title">{details.label}</p>
        <div className="input-field">
          <i className="material-icons prefix">{details["icon"]}</i>
          <input
            name={name}
            {...propsInput}
            className="datepicker no-color"
            type="text"
            value={propsInput.value}
          />
          <span className="date-field">
            {dateSelected && formatDateDDMMYYYY(dateSelected)}
          </span>
          {details.helper && (
            <span className="helper-text mt-10">{details.helper}</span>
          )}
          {error && <small className="rusty-red">{error}</small>}
        </div>
      </div>
    </div>
  );
}

function inputCheckbox(name, error, details, propsInput) {
  let checkboxClasses = "cont-checkbox";
  checkboxClasses += error ? " error " : " ";
  checkboxClasses += details.class ? details.class : "";
  checkboxClasses += propsInput.skipped ? " skipped" : "";
  return (
    <div className="input-field">
      <div id={name} className={checkboxClasses}>
        <label>
          <input
            {...propsInput}
            type="checkbox"
            className="filled-in"
            checked={propsInput.value}
            value={propsInput.value}
          />
          <span />
        </label>
        <p>{details.label}</p>
      </div>
      {details.helper && <span className="helper-text mt-10">{details.helper}</span>}
      {error && <small className="rusty-red">{error}</small>}
    </div>
  );
}

function inputTextarea(name, error, details, propsInput, maxLength = "") {
  let txtareaClasses = error ? "error txt-field " : " ";
  txtareaClasses += details.class ? details.class : "";
  txtareaClasses += propsInput.skipped ? " skipped txt-field " : "";
  let inputClass =
    maxLength.length > 0
      ? "materialize-textarea countChar mb-5"
      : "materialize-textarea mb-5";
  return (
    <div>
      {name === "storage_space_for_guests_location" && (
        <div className="pb-20">
          <p>
            <strong>Guest Info - Local Information</strong>
          </p>
          <p>
            The information you provide on this checklist will go straight into the
            ‘Guest Manual’, so please make sure it is clear and informative.
          </p>
        </div>
      )}
      {name === "boiler_how_to_do" && (
        <p>
          <strong>Guest Info - Troubleshooting</strong>
        </p>
      )}
      {name === "rubbish_faq" && (
        <p>
          <strong>Guest Info - Rubbish Handling</strong>
        </p>
      )}
      <div id={name} className={txtareaClasses}>
        <p className="title">{details.label}</p>
        <div className="input-field">
          <i className="material-icons prefix">{details["icon"]}</i>
          <textarea
            className={inputClass}
            {...propsInput}
            placeholder={details.placeholder}
            data-length={maxLength}
            name={name}
            value={propsInput.value}
          />
          {details.helper && <span className="helper-text">{details.helper}</span>}
          {error && <small className="rusty-red">{error}</small>}
        </div>
      </div>
    </div>
  );
}

// input default return input type text or number
function inputDefault(name, error, details, propsInput, maxLength = "") {
  let txtClasses = error ? "error txt-field " : " ";
  txtClasses += details.class ? details.class : "";
  txtClasses += propsInput.skipped ? " skipped txt-field " : " ";
  let iptClass = maxLength.length > 0 ? "mb-10 countChar" : "mb-10";
  return (
    <div id={name} className={txtClasses}>
      {name === "long_let_value" && (
        <p>
          <strong>Accounting</strong>
        </p>
      )}
      {name === "account_number" && (
        <p>
          <strong>Payout Account</strong>
          <br />
          This is the bank account we will pay money into, e.g. from multi-platform
          booking earnings.
        </p>
      )}
      <p className="title">{details.label}</p>
      <div className="input-field">
        <i className="material-icons prefix">{details["icon"]}</i>
        <input
          {...propsInput}
          className={iptClass}
          type={details.type}
          name={name}
          data-length={maxLength}
          min={
            ["number_of_beds", "guest_capacity", "number_of_bathrooms"].includes(name)
              ? 1
              : null
          }
          step={name === "number_of_bathrooms" ? 0.5 : 1}
          value={propsInput.value}
        />
        {details.helper && <span className="helper-text">{details.helper}</span>}
      </div>
      {error && <small className="rusty-red">{error}</small>}
      {name === "airbnb_password" && (
        <div className="mt-20 dark-blue">
          Please confirm the following has been completed on your Airbnb account
        </div>
      )}
    </div>
  );
}

export {
  inputSelect,
  inputRadio,
  inputDatePicker,
  inputCheckbox,
  inputTextarea,
  inputDefault,
};
