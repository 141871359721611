import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions";
import { rGET } from "../../actions/api";
import {
  photosObj,
  findElIndex,
  arrayMoveEl,
  isMobile,
  resizePhotos,
} from "../../common/util";
import SmallSpinner from "../utility/small_spinner";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";

class ContainerPhotos extends Component {
  UNSAFE_componentWillMount() {
    if (this.props.list_dates) {
      let originalFilter = this.props.filterOriginalPhotos(
        this.props.list_dates[0].label
      ).payload;
      let recentFilter =
        this.props.list_dates.length > 1
          ? this.props.filterRecentPhotos(this.props.list_dates[1].label).payload
          : this.props.filterRecentPhotos(this.props.list_dates[0].label).payload;
      localStorage.setItem("originalFilter", originalFilter);
      localStorage.setItem("recentFilter", recentFilter);
    }
  }

  checkDatePhoto(photoSel, filter) {
    let recentPhotos = JSON.parse(localStorage.getItem("listRecentPhotos"));
    let listRecentPhotos = [];
    if (recentPhotos) {
      listRecentPhotos =
        recentPhotos.length > 0
          ? recentPhotos
          : JSON.parse(localStorage.getItem("listPhotos"));
    } else {
      localStorage.setItem("listRecentPhotos", localStorage.getItem("listPhotos"));
      listRecentPhotos = JSON.parse(localStorage.getItem("listPhotos"));
    }
    let listPhotos = JSON.parse(localStorage.getItem("listPhotos"));
    switch (true) {
      case RegExp(listRecentPhotos[0].date).test(photoSel):
        localStorage.setItem(
          "detailPhotos",
          JSON.stringify(
            arrayMoveEl(
              listRecentPhotos,
              findElIndex(listRecentPhotos, "link", photoSel),
              0
            )
          )
        );
        this.props.history.push(`/photos/${filter}`);
        break;
      case RegExp(listPhotos[0].date).test(this.photoSel):
        localStorage.setItem(
          "detailPhotos",
          JSON.stringify(
            arrayMoveEl(listPhotos, findElIndex(listPhotos, "link", photoSel), 0)
          )
        );
        this.props.history.push(`/photos/${filter}`);
        break;
      default:
        rGET(`/propertyphotos/${localStorage.getItem("property_id")}/${filter}/`).then(
          (resp) => {
            const photos = resp.data.results;
            const width = isMobile() ? 370 : 630;
            resizePhotos(photos, width);
            localStorage.setItem(
              "detailPhotos",
              JSON.stringify(
                arrayMoveEl(photos, findElIndex(photos, "link", photoSel), 0)
              )
            );
            this.props.history.push(`/photos/${filter}`);
          }
        );
        break;
    }
  }

  removeFilter(el, link) {
    let filter =
      el === "originalFilter"
        ? localStorage.getItem("recentFilter")
        : localStorage.getItem("originalFilter");
    localStorage.removeItem(el);
    localStorage.setItem("photoSel", link);
    this.checkDatePhoto(link, filter);
  }

  listPhotos(photos) {
    return Object.keys(photos).map((room, i) => {
      return (
        <div key={room} className={room.replace(/\s/g, "-")}>
          <h4 className="room-title">{room.replace(/-/g, " ")}</h4>
          <div className="room-photos row">
            <div className="col s6">
              {photos[room].map((photo, id) => {
                return (
                  <div key={photo.id} id={`photo-${photo.id}`}>
                    <div
                      className="card-image link"
                      onClick={() => this.removeFilter("recentFilter", photo.link)}>
                      <img
                        className="loading"
                        src={photo.link}
                        onError={(e) =>
                          (e.target.src = photo.link.replace(/\?.+/g, ""))
                        }
                        alt=""
                      />
                      <div className="container-lens-mobile hide-on-med-and-up">
                        <Icon
                          icon={ICONS.LENS.d}
                          width={20}
                          height={20}
                          color="#ffffff"
                          viewBox="0 4 32 32"
                        />
                      </div>
                      <div className="container-lens hide-on-small-only">
                        <Icon
                          icon={ICONS.LENS.d}
                          width={ICONS.LENS.width}
                          height={ICONS.LENS.height}
                          color="#ffffff"
                          viewBox="0 0 32 32"
                        />
                      </div>
                    </div>
                    <p className="originalFilter">
                      {this.props.original_filter &&
                        this.props.original_filter.replace(/-/g, "/")}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="col s6">
              {photos[room].map((photo, id) => {
                return (
                  <div key={photo.id} id={`photo-${photo.id}`}>
                    <div
                      className="card-image link"
                      onClick={() =>
                        this.removeFilter("originalFilter", photo.recentLink)
                      }>
                      <img
                        className="loading"
                        src={photo.recentLink}
                        onError={(e) =>
                          (e.target.src = photo.recentLink.replace(/\?.+/g, ""))
                        }
                        alt=""
                      />
                      <div className="container-lens-mobile hide-on-med-and-up">
                        <Icon
                          icon={ICONS.LENS.d}
                          width={20}
                          height={20}
                          color="#ffffff"
                          viewBox="0 4 32 32"
                        />
                      </div>
                      <div className="container-lens hide-on-small-only">
                        <Icon
                          icon={ICONS.LENS.d}
                          width={ICONS.LENS.width}
                          height={ICONS.LENS.height}
                          color="#ffffff"
                          viewBox="0 0 32 32"
                        />
                      </div>
                    </div>
                    <p className="recentFilter">
                      {this.props.recent_filter &&
                        this.props.recent_filter.replace(/-/g, "/")}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          {Object.keys(photos).length - 1 !== i && <div className="split-line-rooms" />}
        </div>
      );
    });
  }

  render() {
    return (
      <div id="container-photos">
        {this.props.list_photos ? (
          <div>
            {this.props.mostRecentPhotos
              ? this.listPhotos(
                  photosObj(this.props.list_photos, this.props.mostRecentPhotos, "name")
                )
              : this.listPhotos(
                  photosObj(this.props.list_photos, this.props.list_photos, "name")
                )}
          </div>
        ) : (
          <SmallSpinner />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    list_dates: JSON.parse(localStorage.getItem("listDates")),
    list_photos: state.photos.list_photos,
    original_filter: state.photos.original_filter,
    recent_filter: state.photos.recent_filter,
  };
}

export default withRouter(connect(mapStateToProps, actions)(ContainerPhotos));
