import React, { useState } from "react";
import { TextField } from "../../schema";

export const InputPasswordComponent = (props: {
  onValueChange: any;
  field: TextField;
}) => {
  const [value, setValue] = useState(props.field.value || "");
  const [showPassword, setShowPassword] = useState(false);

  const onIconClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <i className="material-icons suffix" onClick={onIconClick}>
        {showPassword ? "visibility" : "visibility_off"}
      </i>
      <input
        id={props.field.key}
        type={showPassword ? "text" : "password"}
        className="validate"
        required={props.field.required}
        value={value}
        placeholder={showPassword ? "password" : "********"}
        autoComplete={"new-password"}
        onChange={(e) => {
          setValue(e.currentTarget.value);
        }}
        onBlur={(e) => {
          props.onValueChange(e.currentTarget, e.currentTarget.value);
        }}
      />
    </>
  );
};
