import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";

class Cleaning extends Component {
  componentDidMount() {
    document.querySelector(".main-container .container").classList.add("full-width");
  }

  render() {
    return (
      <div className="great-host-guide-pages">
        <div className="row">
          <div className="col s12">
            <NavLink exact to="/great-host-guide/" className="left-align">
              <span className="mr-10">
                <Icon
                  icon={ICONS.BACK_ARROW.d}
                  width={ICONS.BACK_ARROW.width}
                  height={12}
                  viewBox="0 0 10 20"
                />
              </span>
              <span className="cobalt-blue">Back to Guide summary</span>
            </NavLink>
            <h4 className="center-align">Cleaning - We'll do that for you</h4>
          </div>
        </div>
        <div className="row top-img-container">
          <img
            className="top-img"
            alt="Cleaning"
            src={require(`../../styles/img/cleaning@2x-min.jpg`)}
          />
        </div>
        <div className="row">
          <div className="col s12">
            <h5>Cleaning Fee? Seems Steep?</h5>
            <p>
              While it is called the "cleaning fee", it is actually made up of 3 parts:
            </p>
            <ul>
              <li>50% cleaning</li>
              <li>40% linen hire</li>
              <li>10% delivery</li>
            </ul>
            <p>
              We’re always trying to improve services and streamline costs, and we
              promise to pass this on to you.
            </p>
            <h5>Who Pays For Cleaning?</h5>
            <p>
              Guests pay for the cost of their post-stay cleaning, and we’ll arrange it
              for the day they check out.
            </p>
            <h5>Cleaning Schedule</h5>
            <p>
              Cleaning happens at checkout. The cleaners will arrive between the local
              check in times for your property. Sometimes our cleaners will prioritise
              same day check in cleans, and your property may be cleaned outside of the
              default hours. Leaving the property guest ready.
            </p>
            <h5>Our Cleaning Service</h5>
            <p>
              Our cleaners follow the PTK Cleaning Protocol which was established during
              the pandemic to ensure that we provide a high standard of domestic
              cleaning and additionally we sanitise your property for safety reasons
              between guests. They will clean all the rooms by dusting, hoovering,
              mopping, wiping surfaces, removing rubbish, cleaning the fridge, cleaning
              the bathrooms, and making the beds as well as changing the linens. They
              will also ensure there are enough toiletries for the guests.
            </p>
            <p>
              We provide Supplementary Cleaning services across all our properties, to
              support the upkeep of your property. These services are charged outside of
              the guest cleaning fee and our management fees.
            </p>
            <p>
              We will contact you on an individual basis to advise when these services
              are advised or required. You can also reach out directly to your local
              management team to request these services ad hoc...
            </p>

            <ul>
              <li>Cleaning of windows</li>
              <li>Garden &amp; garden shed or patio cleaning</li>
              <li>Cleaning of the garage or yard work</li>
              <li>
                Any ‘deep’ cleaning, such as washing walls or doors, carpet deep
                cleaning, oven cleaning, furniture treatment, cleaning of heavily soiled
                areas, surfaces that are difficult to reach, etc
              </li>
              <li>
                Any removal activities, such as animal waste removal, mould or
                bio-hazardous substance removal, extermination of pests, etc
              </li>
              <li>Lifting heavy furniture</li>
              <li>Watering plants inside or outside the house</li>
            </ul>
            <p>
              Our cleaners use standard cleaning chemicals such as Dettol, Harpic, etc.
              We’re unable to make allowances to work with tailor-made cleaning
              detergents for individual properties.
            </p>
            <h5>Rubbish</h5>
            <p>
              Please provide us with the relevant instructions, such as the location of
              bins and bin bags, especially if there are several types, and rubbish
              collection dates. We’ll communicate these to your guests and our cleaners
              through the property manuals. Your local council or your building may
              adjust how they handle rubbish collection from time to time. Please make
              sure to update us when the adjustments are made.
            </p>
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    document.querySelector(".main-container .container").classList.remove("full-width");
  }
}

export default Cleaning;
