export const numberFormat = (
  locale,
  value,
  currency = "GBP",
  maxDecimal = 2,
  notation = "standard"
) => {
  let localeToUse = null;

  if (locale === "en") {
    localeToUse = "en-GB"; // (since the default is en-US whom display things differently)
  } else {
    localeToUse = locale;
  }

  return new Intl.NumberFormat(localeToUse, {
    style: "currency",
    currency: currency != null ? currency : "GBP",
    maximumFractionDigits: maxDecimal,
    unitDisplay: "short",
    notation: notation,
    minimumFractionDigits: 0,
  }).format(value);
};
