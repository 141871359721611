import React, { useContext, useEffect, useRef, useState } from "react";
import { MATERIALIZE_REF } from "../../contexts";
import { ChoiceField } from "../../schema";

export const InputSelectComponent = (props: {
  onValueChange: any;
  field: ChoiceField;
}) => {
  const [value, setValue] = useState(props.field.value || "");
  const selectRef = useRef(null);
  const MATERIALIZE = useContext(MATERIALIZE_REF);

  useEffect(() => {
    // Initialize Materialize select
    if (selectRef.current) {
      MATERIALIZE.FormSelect.init(selectRef.current);
    }

    // Cleanup function
    return () => {
      // Destroy Materialize select when the component unmounts
      if (selectRef.current) {
        const instance = MATERIALIZE.FormSelect.getInstance(selectRef.current);
        if (instance) {
          instance.destroy();
        }
      }
    };
  }, []);

  useEffect(() => {
    // Update Materialize select when the field changes (e.g. when the locale is changed)
    MATERIALIZE.FormSelect.init(selectRef.current);
  }, [props.field]);

  return (
    <select
      ref={selectRef}
      id={props.field.key}
      required={props.field.required}
      value={value}
      onChange={(e) => {
        setValue(e.currentTarget.value);
        props.onValueChange(e.currentTarget, e.currentTarget.value);
      }}>
      <option value="" disabled>
        Please choose an option
      </option>
      {props.field.choices.map((choice, index) => (
        <option key={`${props.field.key}_${index}`} value={choice.value}>
          {choice.label}
        </option>
      ))}
    </select>
  );
};
