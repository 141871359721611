import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import * as actions from "../actions";
import { ICONS } from "../components/svg/icons";
import Icon from "../components/svg/svg_sprite";
import ReactToPrint from "react-to-print";
import { FormattedMessage, useIntl } from "react-intl";
import { MATERIALIZE_REF } from "../components/dynamic_onboarding/contexts";
import { PropertyIDContext } from "../global_contexts";
import { rGET, rPOST } from "../actions/api";
import HostKeyTerms from "../components/profile/key_terms";

const TermsAndConditions = (props) => {
  const M = useContext(MATERIALIZE_REF);
  const INTL = useIntl();
  const { propertyID } = useContext(PropertyIDContext);
  const [instanceTCModal, setInstanceTCModal] = useState(null);
  const [acceptedTCTimestamp, setAcceptedTCTimestamp] = useState(null);
  const [acceptedTCLanguageCode, setAcceptedTCLanguageCode] = useState(null);
  const [termsAndConditions, setTermsAndConditions] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(null);
  const [TCHtmlContent, setTCHtmlContent] = useState(null);
  const termsCondModal = useRef(null);
  const componentTCRef = useRef(null);
  const DISPLAY_LANGUAGE = {
    en: INTL.formatMessage({
      id: "tc_modal.english",
      defaultMessage: "English",
    }),
    es: INTL.formatMessage({
      id: "tc_modal.spanish",
      defaultMessage: "Spanish",
    }),
  };

  function acceptTC(id) {
    const data = { terms_and_conditions_id: id };
    if (propertyID) {
      acceptNewTC(data);
    }
  }

  useEffect(() => {
    if (termsCondModal.current) {
      setInstanceTCModal(M.Modal.init(termsCondModal.current));
    }
  }, [termsCondModal.current]);

  useEffect(() => {
    if (instanceTCModal && props.setInstanceTCModalInProfile) {
      props.setInstanceTCModalInProfile(instanceTCModal);
    }
  }, [instanceTCModal]);

  useEffect(() => {
    if (propertyID) {
      acceptedTC(propertyID);
    }
  }, [propertyID]);

  useEffect(() => {
    if (termsAndConditions && termsCondModal.current) {
      // if not being generated via the profile page
      if (!props.terms_and_conditions_accepted) {
        instanceTCModal.open();
      }
    } else {
      if (instanceTCModal) {
        instanceTCModal.close();
        instanceTCModal.destroy();
      }
    }
  }, [JSON.stringify(termsAndConditions)]);

  useEffect(() => {
    if (props.profile?.accepted_terms_and_conditions_timestamp) {
      setAcceptedTCTimestamp(
        new Date(
          props.profile.accepted_terms_and_conditions_timestamp
        ).toLocaleDateString("en-GB")
      ); // specifcally en-GB since the requirements state we want to display this date as dd/mm/yyyy
    }
    if (props.profile?.terms_and_conditions_accepted_in_language) {
      setAcceptedTCLanguageCode(
        props.profile.terms_and_conditions_accepted_in_language
      );
    }
    if (props.profile?.accepted_terms) {
      setTermsAccepted(props.profile.accepted_terms);
    }
  }, [props.profile]);

  useEffect(() => {
    if (termsAndConditions || props.terms_and_conditions_accepted) {
      setTCHtmlContent(
        parse(termsAndConditions?.html_content || props.terms_and_conditions_accepted)
      );
    }
  }, [termsAndConditions, props.terms_and_conditions_accepted]);

  function acceptedTC() {
    rGET(`/terms_and_conditions/evaluate/${propertyID}/`).then((resp) => {
      let terms_and_conditions = null;
      if (resp?.data?.terms_and_conditions) {
        terms_and_conditions = resp.data.terms_and_conditions;
      }
      setTermsAndConditions(terms_and_conditions);
    });
  }

  function acceptNewTC(data) {
    rPOST(`/terms_and_conditions/evaluate/${propertyID}/`, data);
    setTermsAccepted(true);
  }

  return (
    <div ref={termsCondModal} id="termsCondModal" className="modal">
      <span
        className="close modal-close"
        onClick={() => instanceTCModal && instanceTCModal.close()}>
        <Icon
          icon={ICONS.DELETE.d}
          width={12}
          height={12}
          viewBox="0 0 24 24"
          color="#ffffff"
        />
      </span>
      <div className="tc-title">
        <div className="show-on-small hide-on-med-and-up">
          <Icon
            icon={ICONS.CONTRACT.d}
            width={35}
            height={35}
            viewBox="0 0 47 47"
            color="#f5a423"
          />
        </div>
        <div className="hide-on-small-only">
          <Icon
            icon={ICONS.CONTRACT.d}
            width={50}
            height={50}
            viewBox="0 0 47 47"
            color="#f5a423"
          />
        </div>
        <span>
          <FormattedMessage
            id="tc_modal.title"
            defaultMessage="Terms &amp; Conditions"
          />
        </span>
        {acceptedTCTimestamp && termsAccepted ? (
          <p className="grey-text">
            <FormattedMessage
              id="tc_modal.subtitle_part_one"
              defaultMessage="You accepted our terms and conditions on"
            />{" "}
            {acceptedTCTimestamp}
            {acceptedTCLanguageCode
              ? " " +
                INTL.formatMessage({
                  id: "tc_modal.subtitle_part_two",
                  defaultMessage: "in",
                }) +
                " " +
                DISPLAY_LANGUAGE[`${acceptedTCLanguageCode}`]
              : null}
          </p>
        ) : null}
      </div>
      <div className="modal-content">
        <div className="tc" ref={componentTCRef}>
          <style>{`@media print {
            @page {
              size: auto;
              margin: 20mm 0 10mm 0;
            }
            .tc {
              font-size: 12pt;
              max-widt: 668px;
              margin-left: 66pt!important;
              margin-right: 66pt!important;
              margin-top: 60pt!important;
              margin-bottom: 60pt!important;
            }
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              margin-top: 15pt;
              margin-bottom: 15pt;

              span {
                font-size: 11pt;
              }
            }
            h1 {
              font-size: 14pt;
            }
            .table-box td {
              padding: 5px;
            }
            .icon-agreement {
              float: left;
            }
            .detailed_list {
              &>div {
                display: flex;

                &>div {
                  padding: 0;
                  padding-bottom: 15px;
                  width: 44%;

                  &:first-of-type {
                    padding-right: 15px;
                  }

                  &:last-of-type {
                    padding-left: 15px;
                  }
                }
              }

              .last_row>div {
                padding-bottom: 0;
              }
            }
          }`}</style>
          {props.profile?.terms_and_conditions?.has_key_terms ? (
            <div className="information">
              <h3>
                <FormattedMessage
                  id="profile.key_terms"
                  defaultMessage={"Your Key Terms"}
                />
              </h3>
              <div className="agreement-container">
                {props.profile.properties_managed.map((property, i) => (
                  <HostKeyTerms
                    key={i}
                    property={property}
                    single={props.profile.properties_managed.length > 1 ? false : true}
                    inTC={true}
                  />
                ))}
              </div>
            </div>
          ) : null}
          {TCHtmlContent ? TCHtmlContent : null}
        </div>
      </div>
      <div className="fd modal-footer">
        <button
          id="laterTC"
          className={`modal-close waves-effect ${
            termsAccepted || !termsAndConditions
              ? "btn"
              : "large cobalt-blue secondary-button"
          }`}>
          {termsAccepted || !termsAndConditions ? (
            <FormattedMessage id="tc_modal.close" defaultMessage="Close" />
          ) : (
            <FormattedMessage
              id="tc_modal.accept_later"
              defaultMessage="Accept Later"
            />
          )}
        </button>
        {termsAccepted || !termsAndConditions ? null : (
          <button
            id="agreeTC"
            className="modal-close waves-effect btn large"
            onClick={() => {
              termsAndConditions && acceptTC(termsAndConditions.id);
            }}>
            <FormattedMessage id="tc_modal.accept_now" defaultMessage="Accept Now" />
          </button>
        )}
        <ReactToPrint
          trigger={() => (
            <button id="printBT" className="btn">
              <i className="material-icons">print</i>
            </button>
          )}
          content={() => componentTCRef.current}
          copyStyles={false}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    profile: state.user.profile,
  };
}

export default connect(mapStateToProps, actions)(TermsAndConditions);
