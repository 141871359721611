import React, { useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import { EditContact } from "./edit_contact";
import { PropertyIDContext } from "../../global_contexts";
import { useForm } from "react-hook-form";
import { MATERIALIZE_REF } from "../dynamic_onboarding/contexts";
import { rDELETE, rGET, rPOST, rPUT } from "../../actions/api";

export const Contacts = (props) => {
  const M = useContext(MATERIALIZE_REF);
  const { register, handleSubmit } = useForm();
  const { propertyID } = useContext(PropertyIDContext);
  const addContact = useRef();
  const [errors, setErrors] = useState({});
  const [selectedContact, setSelectedContact] = useState({});
  const [listContacts, setListContacts] = useState([]);

  useEffect(() => {
    if (propertyID) {
      getContacts();
    }
  }, [propertyID]);

  useEffect(() => {
    M.Modal.init(document.getElementById("modalDelete"));
    M.FormSelect.init(document.getElementById("contact_selector"));
  }, []);

  function getContacts() {
    if (!props.testMode) {
      rGET(`/propertycontact/${propertyID}/`).then((resp) => {
        if (resp) {
          setListContacts(resp.data.results);
        }
      });
    }
  }

  function createContact(newContactData) {
    if (!props.testMode) {
      rPOST(`/propertycontact/${propertyID}/`, newContactData).then((resp) => {
        const newContact = resp && resp.data;
        setListContacts([newContact, ...listContacts]);
        if (addContact.current) {
          addContact.current.disabled = false;
        }
      });
    } else {
      newContactData.id = listContacts.length + 1;
      setListContacts([newContactData, ...listContacts]);
      if (addContact.current) {
        addContact.current.disabled = false;
      }
    }
  }

  function updateContact(contact) {
    if (!props.testMode) {
      rPUT(`/propertycontact/${contact.property}/${contact.id}/`, contact).then(() =>
        getContacts()
      );
    } else {
      let tempListContacts = listContacts;
      tempListContacts.forEach((el, index) => {
        if (el.id === contact.id) {
          tempListContacts[index] = contact;
        }
      });
      setListContacts(tempListContacts);
    }
  }

  function confirmDelete(contact) {
    if (!props.testMode) {
      rDELETE(`/propertycontact/${contact.property}/${contact.id}/`);
      const deletedEl = document.getElementById(contact.id);
      deletedEl.remove();
    } else {
      let tempListContacts = listContacts;
      tempListContacts = tempListContacts.filter((el) => el.id !== contact.id);
      setListContacts(tempListContacts);
    }
  }

  function removeContact(contact) {
    setSelectedContact(contact);
    if (!props.testMode) {
      let instance = M.Modal.getInstance(document.getElementById("modalDelete"));
      instance.open();
    } else {
      confirmDelete(contact);
    }
  }

  function editContact(contact) {
    setSelectedContact(contact);
    let instance = M.Modal.init(document.getElementById("modalEdit"));
    instance.open();
  }

  function contactFormSubmit({ contact_type, name, telephone, email }) {
    const newContact = { contact_type, name, telephone, email };
    let temp_errors = validate({ contact_type, name, telephone });
    setErrors(temp_errors);
    if (Object.getOwnPropertyNames(temp_errors).length === 0) {
      if (addContact.current) {
        addContact.current.disabled = true;
      }
      newContact.property = propertyID;
      createContact(newContact);
    }
  }

  return (
    <div className="row">
      <div id="modalEdit" className="modal">
        <div className="modal-content">
          <EditContact
            selectedContact={selectedContact}
            updateContact={updateContact}
          />
        </div>
      </div>

      <div id="modalDelete" className="modal">
        <div className="modal-content">
          <p>
            <FormattedMessage
              id="preferences.contacts.delete_confirmation"
              defaultMessage="Are you sure you want to delete"
            />{" "}
            {selectedContact.name}?
          </p>
          <div className="btn-container">
            <button
              id="btnCloseModal"
              className="btn modal-action modal-close mr-15"
              value={selectedContact}
              onClick={() => confirmDelete(selectedContact)}>
              <FormattedMessage id="preferences.contacts.yes" defaultMessage="Yes" />
            </button>
            <button id="btnCloseModal" className="btn modal-action modal-close">
              <FormattedMessage id="preferences.contacts.no" defaultMessage="No" />
            </button>
          </div>
        </div>
      </div>

      <div className="col s12">
        <h5 className="title">
          <FormattedMessage id="preferences.contacts.title" defaultMessage="Contacts" />
        </h5>
        <p>
          <FormattedMessage
            id="preferences.contacts.emergency_contact_subtitle"
            defaultMessage="Please specify the people we should contact in emergencies."
          />
        </p>
        <p className="pb-20">
          <FormattedMessage
            id="preferences.contacts.emergency_contact_description"
            defaultMessage="If you provide us with the contact details of your contractor we will always
          attempt to call them first. If they don't answer or they aren't available,
          we will proceed according to your preset options."
          />
        </p>
        {listContacts && (
          <ul className="collection">
            {listContacts.map((contact) => {
              return (
                <div key={contact.id} data-testid={`contact_${contact.id}`}>
                  <li className="collection-item avatar" id={contact.id}>
                    <img
                      alt="Default"
                      src={require(`../../styles/img/default-user-min.png`)}
                      className="circle"
                    />
                    <p data-testid={`contact_${contact.id}_details`}>
                      {contact.name} <br />
                      <FormattedMessage
                        id={`contact.contact_type.${contact.contact_type}`}
                        defaultMessage={contact.contact_type}
                      />{" "}
                      <br />
                      {contact.telephone} <br />
                      {contact.email} <br />
                    </p>
                    <span
                      className="secondary-content"
                      data-testid={`delete_contact_${contact.id}`}
                      id="deleteContact"
                      value={contact}
                      onClick={() => removeContact(contact)}>
                      <Icon
                        icon={ICONS.DELETE.d}
                        width={15}
                        height={15}
                        viewBox="0 0 24 24"
                        color="#b81c17"
                      />
                    </span>
                    <span
                      className="secondary-content"
                      data-testid={`edit_contact_${contact.id}`}
                      id="editContact"
                      value={contact}
                      onClick={() => editContact(contact)}>
                      <Icon
                        icon={ICONS.PENCIL.d}
                        width={15}
                        height={15}
                        viewBox="0 0 27 27"
                      />
                    </span>
                  </li>
                </div>
              );
            })}
          </ul>
        )}
        <form
          id="contacts"
          className="contact-container"
          onSubmit={handleSubmit(contactFormSubmit.bind(this))}>
          <div className="input-field">
            <select
              data-testid="create_contact_select"
              id="contact_selector"
              {...register("contact_type")}
              defaultValue={""}>
              <FormattedMessage
                id="contact.contact_type.Contact Type"
                defaultMessage="Contact Type">
                {(localised_text) => (
                  <option value="" disabled>
                    {localised_text}: ---
                  </option>
                )}
              </FormattedMessage>
              <FormattedMessage
                id="contact.contact_type.Primary Contact"
                defaultMessage="Primary Contact">
                {(localised_text) => (
                  <option value="Primary Contact">{localised_text}</option>
                )}
              </FormattedMessage>
              <FormattedMessage
                id="contact.contact_type.Emergency"
                defaultMessage="Emergency">
                {(localised_text) => (
                  <option value="Emergency">{localised_text}</option>
                )}
              </FormattedMessage>
              <FormattedMessage
                id="contact.contact_type.Warrant Provider"
                defaultMessage="Warrant Provider">
                {(localised_text) => (
                  <option value="Warrant Provider">{localised_text}</option>
                )}
              </FormattedMessage>
              <FormattedMessage
                id="contact.contact_type.Contractor"
                defaultMessage="Contractor">
                {(localised_text) => (
                  <option value="Contractor">{localised_text}</option>
                )}
              </FormattedMessage>
            </select>
            <div className="error">{errors.contact_type}</div>
          </div>

          <div className="input-field">
            <FormattedMessage id="preferences.contacts.name" defaultMessage="Name">
              {(placeholder_text) => (
                <input
                  data-testid="create_contact_name"
                  id="name"
                  {...register("name")}
                  type="text"
                  placeholder={placeholder_text}
                />
              )}
            </FormattedMessage>
            <div className="error">{errors.name}</div>
          </div>

          <div className="input-field">
            <FormattedMessage
              id="preferences.contacts.telephone"
              defaultMessage="Telephone">
              {(placeholder_text) => (
                <input
                  data-testid="create_contact_telephone"
                  id="telephone"
                  {...register("telephone")}
                  type="text"
                  placeholder={placeholder_text}
                />
              )}
            </FormattedMessage>
            <div className="error">{errors.telephone}</div>
          </div>

          <div className="input-field">
            <FormattedMessage id="preferences.contacts.email" defaultMessage="Email">
              {(placeholder_text) => (
                <input
                  data-testid="create_contact_email"
                  id="email"
                  {...register("email")}
                  type="email"
                  placeholder={placeholder_text}
                />
              )}
            </FormattedMessage>
          </div>

          <div className="input-field">
            <button
              ref={addContact}
              data-testid="create_contact_button"
              id="addContact"
              className="btn"
              action="submit">
              <FormattedMessage id="preferences.contacts.add" defaultMessage="Add" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export function validate(formProps) {
  var telephoneValidator = /^(\(\d+\)|\d+)\s*(\d+\s*-?\s*)*\d+\s*$/;
  var errors = {};
  for (var key of Object.keys(formProps)) {
    switch (true) {
      case key === "contact_type":
        if (!formProps[key]) {
          errors[key] = "Please select what type of contact";
        }
        break;
      case key === "name":
        if (!formProps[key] || formProps[key].length === 0) {
          errors[key] = "This field is required";
        }
        break;
      case key === "telephone":
        if (formProps[key] && !telephoneValidator.test(formProps[key])) {
          errors[key] = "Please enter a valid telephone number";
        } else if (!formProps[key] || formProps[key].length === 0) {
          errors[key] = "This field is required";
        }
        break;
      default:
        break;
    }
  }
  return errors;
}
