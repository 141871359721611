import React from "react";
import { NavLink } from "react-router-dom";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import LanguageSelector from "./language_selector";

const MobileNavbar = (props) => {
  return (
    <div className="container hide-on-large-only">
      <div className="row">
        <div className={`language_switcher right right-align col s4`}>
          <LanguageSelector />
        </div>
        <a
          id="mobile_burger_menu_button"
          href="/"
          className="menu hide-on-large-only dropdown-trigger left col s4 left-align"
          data-target="mobile-navbar"
          onClick={() => {
            props.onOpen();
          }}>
          <Icon
            className="menu"
            icon={ICONS.MENU.d}
            width={ICONS.MENU.width}
            height={ICONS.MENU.height}
          />
        </a>
        <div className="mobile_logo center col s4">
          <ul>
            <li>
              <NavLink exact to="" className="nav-logo">
                <div className="hide-on-large-only valign-wrapper">
                  <Icon
                    width={22}
                    height={55}
                    viewBox="0 0 156 194"
                    multiplePaths={[
                      { d: ICONS.LOGO_MOBILE_3.d, color: "#F5A423" },
                      { d: ICONS.LOGO_MOBILE_2.d, color: "#87AF4B" },
                      { d: ICONS.LOGO_MOBILE_1.d, color: "#020CB9" },
                    ]}
                  />
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;
