import React, { useEffect, useState } from "react";
import { Bed, ChoiceList, DataFieldType } from "../../schema";
import { InputSelectComponent } from "../inputs/select";

export const BespokeBedComponent = (props: {
  key_prop: string;
  field: Bed;
  size_choices: ChoiceList;
  removeBed: any;
  updateBed: any;
}) => {
  const [sizeValue, setSizeValue] = useState(props.field.size);

  useEffect(() => {
    setSizeValue(props.field.size);
  }, [props.field.size]);

  const onValueChange = (e: any) => {
    setSizeValue(e.value);
    props.updateBed(props.field, e.value);
  };

  return (
    <div className="bed">
      <i className="material-icons" onClick={() => props.removeBed(props.field)}>
        delete_forever
      </i>
      <InputSelectComponent
        onValueChange={onValueChange}
        field={{
          value: sizeValue,
          key: props.key_prop,
          required: false,
          choices: props.size_choices,
          type: DataFieldType.CHOICE,
        }}
        key={props.key_prop}
      />
    </div>
  );
};
