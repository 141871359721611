import React, { useContext, useEffect, useState } from "react";
import { DataField } from "../../schema";
import { LocaleContext, PropertyContext } from "../../../../global_contexts";
import { useIntl } from "react-intl";

export const InputIBANComponent = (props: {
  onValueChange: any;
  setFieldError: any;
  field: DataField;
}) => {
  const INTL = useIntl();
  const { locale } = useContext(LocaleContext);
  const { propertyCountry } = useContext(PropertyContext);
  const placeholderIBANs: { [key: string]: string } = {
    GB: "GB33BUKB20201555555555",
    ES: "ES7921000813610123456789",
  };
  const [value, setValue] = useState(props.field.value || "");
  const [placeholder, setPlaceholder] = useState(placeholderIBANs["GB"]);
  const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/;

  useEffect(() => {
    const EL = document.getElementById(props.field.key);
    if (EL && EL.classList.contains("invalid")) {
      showPatternError();
    }
  }, [locale]);

  function showPatternError() {
    props.setFieldError(
      INTL.formatMessage({
        id: "dynamic_onboarding.iban_pattern_error",
        defaultMessage: "Please enter a valid IBAN",
      })
    );
  }

  useEffect(() => {
    if (propertyCountry && propertyCountry in placeholderIBANs) {
      setPlaceholder(placeholderIBANs[propertyCountry]);
    }
  }, [propertyCountry]);

  return (
    <input
      id={props.field.key}
      type="text"
      className="validate input-field--large"
      pattern={ibanRegex.source}
      required={props.field.required}
      value={value}
      placeholder={placeholder}
      onChange={(e) => {
        setValue(e.currentTarget.value);
      }}
      onBlur={(e) => {
        if (e.currentTarget.value === "" || ibanRegex.test(e.currentTarget.value)) {
          props.onValueChange(e.currentTarget, e.currentTarget.value);
        } else {
          showPatternError();
        }
      }}
    />
  );
};
