import React from "react";
import { FormattedMessage } from "react-intl";
import { DynamicOnboardingForm } from "./form";
import { SectionColourKey } from "./section_colour_key";

export function DynamicOnboardingChecklistContent() {
  return (
    <div className="onboarding_checklist col">
      <div className="onboarding_checklist__information">
        <h2>
          <FormattedMessage
            id="onboarding_checklist.text"
            defaultMessage="Onboarding Checklist"
          />
        </h2>
        <p className="mb-0">
          <FormattedMessage
            id="onboarding_checklist.description"
            defaultMessage="Please complete the questions below to the best of your knowledge. We cannot
          proceed to keys handover or manage any bookings without a completed
          checklist. Once you have completed all sections, please confirm by clicking
          ‘Submit’."
          />
        </p>
        <SectionColourKey />
      </div>
      <div className="onboarding_checklist__form">
        <DynamicOnboardingForm />
      </div>
    </div>
  );
}
